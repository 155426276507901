import React, { Component } from "react";
import Select from "react-select";

export default class SelectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClearable: true,
      isDisabled: "",
      // isDisabled: this.props?.disabled || false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      stateNameDefault: undefined,
      commonState:this.props.commonState || "",
      watcher: "No",
    };
  }

  componentDidUpdate = () => {
    if (this.state.stateNameDefault != this.props?.defaultValue) {
      this.setState({ stateNameDefault: this.props?.defaultValue });
    }
    if (this.props?.disabled != undefined) { //for disabling and enabling 
      if (this.state.isDisabled != this.props?.disabled) {
        this.setState({ isDisabled: this.props?.disabled })
      }
    }
  };

  render() {
    let { isClearable, isDisabled, isLoading, isRtl, isSearchable } =
      this.state;
    // let idDisabledVal = this.props?.disabled || this.state.isDisabled;

    const checkDefault = () => {
      let val = this.props?.options?.filter(
        (e) => this.state.stateNameDefault === e.value
      )[0];
      if (this.state.commonState !== val) {
        this.setState({ commonState: val });
      }
    };
    if (this.state.watcher == "No") {
      checkDefault();
    }

    // console.log(JSON.parse(localStorage?.getItem("authUser"))?.userType); 

    const checkRights = () => {
      if (this.state.isDisabled == false) {
        return false
      } else if (this.props.userType == "admin" || this.props.userType == undefined) {
        return true
      }else{
        return false
      }
      // if (this.props.userType == "admin" || this.props.userType == undefined && this.state.isDisabled == false) {
      //   // console.log("yes this is admin so can edit");
      //   return false
      // } else {
      //   // console.log("No this is not admin so can not edit");
      //   return true
      // }
    }

    return (
      <Select
        className="basic-single"
        classNamePrefix="select"
        isDisabled={checkRights()}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name="color"
        options={this.props?.options}
        onChange={(e) => {
          if (this.state.watcher != "yes") {
            this.setState({ watcher: "yes" });
          }
          this.setState({ commonState: e });
          this.props?.onChange(e || "");
        }}
        value={this.props.value}
        onInputChange={this.props?.onInputChange || ""}
        placeholder={this.props?.placeholder || "Select..."}
      />
    );
  }
}
