import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

export function PaymentHistory({ paymentsData, refundsData, onToggle }) {
  let [payments, changePayments] = useState(true);
  let [refund, setRefund] = useState(false);
  let [summary, setSummary] = useState(false);
  return (
    <div className="mainParentSectionDiv">
      <div className="addProductModal">
        <Modal
          isOpen={true}
          className={"addProductModalInner"}
          size="md"
          toggle={onToggle}
        >
          <ModalHeader toggle={onToggle}>Payment History</ModalHeader>
          <div className="d-flex flex-row pt-4">
            {/* <Button
              color={summary ? "success" : ""}
              onClick={() => {
                setSummary(true);
                changePayments(false);
                setRefund(false);
              }}
              className="mx-2"
            >
              Summary
            </Button> */}
            <Button
              color={payments ? "success" : ""}
              onClick={() => {
                changePayments(true);
                setSummary(false);
                setRefund(false);
              }}
              className="mx-2"
            >
              Payments
            </Button>
            <Button
              color={refund ? "success" : ""}
              onClick={() => {
                setRefund(true);
                setSummary(false);
                changePayments(false);
              }}
            >
              Refunds
            </Button>
          </div>

          {payments ? (
            <ModalBody
              className={paymentsData?.length > 0 ? "paymentodalbody" : null}
            >
              {paymentsData?.length > 0 ? (
                <table>
                  <tr style={{ border: "1px solid" }}>
                    <th className="text-center">Date</th>
                    <th className="text-center">Time</th>
                    <th className="text-center">Pay Id</th>
                    <th className="text-center">Paid Amount</th>
                    <th className="text-center">Payment Method</th>
                    <th className="text-center">Mode</th>
                    <th className="text-center">Created By</th>
                    <th className="text-center">Acknowledge By</th>
                  </tr>
                  {paymentsData?.map((eachPayment) => (
                    <tr>
                      <td className="text-center">
                        {new Date(
                          eachPayment?.paymentId?.createdAt
                        )?.toLocaleDateString()}
                      </td>
                      <td className="text-center">
                        {new Date(
                          eachPayment?.paymentId?.updatedAt
                        )?.toLocaleTimeString()}
                      </td>
                      <td className="text-center">
                        {eachPayment?.payId ||
                          eachPayment?.paymentId?.razorPayPaymentsRecord
                            ?.TransactionRefNo ?  eachPayment?.paymentId?.razorPayPaymentsRecord
                            ?.TransactionRefNo : "N/A"}
                      </td>
                      <td className="text-center">{eachPayment?.amt} Rs</td>
                      <td className="text-center textCapitalize">
                        {eachPayment?.paymentId?.razorPayPaymentsRecord?.payload
                          ?.payment?.entity?.method ||
                          eachPayment?.paymentId?.razorPayPaymentsRecord
                            ?.paymentMedium}
                      </td>
                      <td className="text-center textCapitalize">
                        {eachPayment?.paymentId?.mode}
                      </td>
                      <td>
                        {eachPayment?.paymentId?.mode === "offline" ? (
                          <td className="text-center textCapitalize">
                            {eachPayment?.paymentId?.createdBy?.fullName}
                          </td>
                        ) : (
                          <div className="offlinepaymentcreatedby">
                            <td>Null</td>
                          </div>
                        )}
                      </td>
                      <td>
                        {eachPayment?.paymentId?.mode === "offline" ? (
                          <td className="text-center textCapitalize">
                            {eachPayment?.paymentId?.updatedBy?.fullName}
                          </td>
                        ) : (
                          <div className="offlinepaymentcreatedby">
                            <td>Null</td>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>No payments are Available</p>
              )}
            </ModalBody>
          ) : (
            ""
          )}

          {refund ? (
            <ModalBody>
              {refundsData.length > 0 ? (
                <table>
                  <tr style={{ border: "1px solid" }}>
                    <th className="text-center">Refund Amount</th>
                    <th className="text-center">Date</th>
                    <th className="text-center">Time</th>
                    <th className="text-center">Pay Id</th>
                    <th className="text-center">Refunds Id</th>
                    <th className="text-center">Created By</th>
                  </tr>
                  {refundsData?.map((eachRefund) => (
                    <tr>
                      <td className="text-center">{eachRefund?.amt} Rs.</td>
                      <td className="text-center">
                        {new Date(
                          eachRefund?.refundId.createdAt
                        )?.toLocaleDateString()}
                      </td>
                      <td className="text-center">
                        {new Date(
                          eachRefund?.refundId.createdAt
                        )?.toLocaleTimeString()}
                      </td>
                      <td className="text-center">
                        {eachRefund?.refundId?.razorPayRefundRecord?.payment_id}
                      </td>
                      <td className="text-center">
                        {eachRefund?.refundId?.razorPayRefundRecord?.id}
                      </td>
                      <td className="text-center">
                        {eachRefund?.refundId?.createdBy?.fullName
                          ? eachRefund?.refundId?.createdBy?.fullName
                          : "Null"}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>No Refunds are Available</p>
              )}
            </ModalBody>
          ) : (
            ""
          )}

          {/* {summary ? (
            <ModalBody
              className={paymentsData?.length > 0 ? "paymentodalbody" : null}
            >
              {paymentsData?.length > 0 ? (
                <table>
                  <tr style={{ border: "1px solid" }}>
                    <th className="text-center">Paid Amount</th>
                    <th className="text-center">Payment Method</th>
                    <th className="text-center">Date</th>
                    <th className="text-center">Mode</th>
                    <th className="text-center">Available Balance</th>
                  </tr>
                  {paymentsData?.map((eachPayment) => (
                    <tr>
                      <td className="text-center">{eachPayment?.amt} Rs</td>
                      <td className="text-center textCapitalize">
                        {eachPayment?.paymentId?.razorPayPaymentsRecord?.payload
                          ?.payment?.entity?.method ||
                          eachPayment?.paymentId?.razorPayPaymentsRecord
                            ?.paymentMedium}
                      </td>
                      <td className="text-center">
                        {new Date(
                          eachPayment?.paymentId?.createdAt
                        )?.toLocaleDateString()}
                      </td>
                      <td className="text-center textCapitalize">
                        {eachPayment?.paymentId?.mode}
                      </td>
                      <td className="text-center">
                        {eachPayment?.payId ||
                          eachPayment?.paymentId?.razorPayPaymentsRecord
                            ?.TransactionRefNo}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>No summary</p>
              )}
            </ModalBody>
          ) : (
            ""
          )} */}
        </Modal>
      </div>
    </div>
  );
}
