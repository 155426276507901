import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label, Row, Col } from "reactstrap";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import Producticon from "../../../images/productCategoryicon.png";
import Suppliericon from "../../../images/supplierIconTwo.png";
import Logisticsicon from "../../../images/logisticsIconThree.png";
import AccordionComponent from "./AccordionComponent";
import SelectComponent from "./SelectComponent";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import userprofile from "../../../images/userprofile.png";
// import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SwitchButton from "../../../common/components/common/switchButton";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import {
  fetchproductSortData,
  fetchProductFilterSkuData,
  fetchCategoryData,
  fetchSellerData,
  syncAllSku,
  fetchProductAllFilterData,
  Product_addCategory,
  Product_addSku,
  GetSkusList,
  productDeleteData,
  productEditData,
  fetchProductViewData,
  categoryBySku,
  getProductCount,
} from "../../../store/actions";
import moment from "moment";
import { Link } from "react-router-dom";
import Addbuyerpic from "../../../images/addbuyerpic.png";
import Pagination from "@material-ui/lab/Pagination";
import defaultfish from "../../../images/fish-57.png";
import imagepending from "../../../images/image-pending.jpg";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import {
  NUMBER_REGEX,
  NAME_REGEX,
  SKU_NAME_REGEX,
  ONLY_NUMBER_REGEX,
  QUANTITY_PRICE_REGEX,
} from "../../../common/components/common/Rejex";
import {
  handleErrorMsg,
  handleKeyPress,
  isVinculum,
} from "../../../common/components/common/GlobalFunctions";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import info from "../../../images/info.svg";
let timeOut;
let debounceThis;
class ProductMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AccordionCollapse: false,
      toggleDiv: false,
      toggleCatDiv: false,
      // toggleSkuDiv: false,
      categories: [],
      Seller: [],
      Size: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
      ],
      skucategories: [],
      sku: [],
      category: "",
      seller: "",
      size: "",
      minPrice: "",
      maxPrice: "",
      SelectedSku: "",
      addProduct_category: "",
      addProduct_categoryImg: undefined,
      // addProduct_categoryImg_name: "",
      categoryDisplayImg: undefined,
      // addProduct_skuName: "",
      // addProduct_skuName_img_name: "",
      // skuDisplayImg: undefined,
      // addProduct_categoryType: "",
      addProduct_skuImg: undefined,
      deleteConfirmModal: false,
      editModal: false,
      currentPage: 1,
      limit: 10,
      productToDeleteId: "",
      productToEditId: "",
      totalQuantity: "",
      deliveryDate: "",
      skuName: "",
      dataLoading: false,
      categoryInputName: "",
      skuInputName: "",
    };
  }

  debounceValue = (val, stateName) => {
    this.onChange(stateName, val);
    if (timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      this.listOfProductList();
    }, 1000);
  };

  toggleFilter = () => {
    this.setState({
      toggleDiv: !this.state.toggleDiv,
      toggleCatDiv: false,
      // toggleSkuDiv: false,
    });
  };
  toggleAddCatergory = () => {
    this.setState({
      toggleCatDiv: !this.state.toggleCatDiv,
      // toggleSkuDiv: false,
      toggleDiv: false,
    });
  };
  toggleAddSKU = () => {
    this.setState({
      // toggleSkuDiv: !this.state.toggleSkuDiv,
      toggleCatDiv: false,
      toggleDiv: false,
    });
  };
  onChangeEvent(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }

  onFilterData(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }
  filterProductData() {
    this.setState({
      toggleDiv: false,
    });
    this.listOfProductList();
  }

  deleteConfirmModalToggle = () => {
    this.setState({
      deleteConfirmModal: !this.state.deleteConfirmModal,
    });
  };
  editModalToggle = () => {
    this.setState({
      editModal: !this.state.editModal,
    });
  };
  handleChange = () => {
    this.setState({
      toggleCatDiv: false,
      // toggleSkuDiv: false,
      toggleDiv: false,
    });
  };

  addCategoryData = () => {
    let formData = new FormData();
    formData.append("categoryName", this.state.addProduct_category);

    let allFiles = Object.values(this.state.addProduct_categoryImg);
    for (let x of allFiles) {
      formData.append("file", x);
    }

    this.setState({
      dataLoading: true,
    });
    this.props?.Product_addCategory(formData, (res) => {
      if (res.message === "Category has been created successfully") {
        NotificationManager.success(res.message, "Category Created");

        this.setState({
          addProduct_categoryImg: undefined,
          // addProduct_categoryImg_name: "",
          categoryDisplayImg: undefined,
          addProduct_category: "",
        });
        document.getElementById("namecategory").value = "";
        this.listOfCategory();
      } else {
        // NotificationManager.error(res.message, "Error");
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
      this.toggleAddCatergory();
    });
  };
  // addSkuData = () => {
  //   let formData = new FormData();
  //   formData.append("skuName", this.state.addProduct_skuName);
  //   formData.append("categoryId", this.state.addProduct_categoryType.value);
  //   // formData.append("skuimage", this.state.addProduct_skuImg)

  //   let allFiles = Object.values(this.state.addProduct_skuImg);
  //   for (let x of allFiles) {
  //     formData.append("file", x);
  //   }
  //   this.setState({
  //     dataLoading: true,
  //   });
  //   this.props?.Product_addSku(formData, (res) => {
  //     if (res.message === "SKU has been created successfully") {
  //       // console.log("inside successs");
  //       NotificationManager.success(res.message, "SKU Created");
  //       this.setState({
  //         addProduct_skuName: "",
  //         addProduct_categoryType: "",
  //         addProduct_skuImg: undefined,
  //         skuDisplayImg: undefined,
  //       });

  //       document.getElementById("namesku").value = "";
  //     } else {
  //       // console.log("inside error");
  //       // NotificationManager.error(res.message, "Error");
  //       handleErrorMsg(res);
  //     }
  //     this.setState({
  //       dataLoading: false,
  //     });
  //     this.toggleAddSKU();
  //   });
  // };
  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleChange);
  }
  componentDidMount() {
    this.listOfProductList();
    this.listOfSellerAndCategory();
    this.listOfCategory();
    this.getAllCount();
  }
  getAllCount = () => {
    this.props.getProductCount();
  };
  componentDidUpdate = () => {
    let categoryOption = [];
    if (!this.state.skucategories.length) {
      if (this.props?.categoryData.docs?.length) {
        this.props?.categoryData.docs.forEach((item) => {
          categoryOption.push({ label: item.categoryName, value: item._id });
        });
        this.setState({
          skucategories: categoryOption,
        });
      }
    }
  };

  listOfProductList = (offset = 0) => {
    this.setState({
      dataLoading: true,
    });
    this.props?.fetchproductSortData(
      offset,
      this.state.limit,
      this.state.maxPrice,
      this.state.minPrice,
      this.state.category.value ? this.state.category.value : "",
      this.state.size,
      this.state.seller.value ? this.state.seller.value : "",
      this.state.SelectedSku.value ? this.state.SelectedSku.value : "",
      "",
      "",
      "",
      this.state.skuName,
      "",
      "",
      () => {
        this.setState({
          dataLoading: false,
        });
      }
    );
  };
  listOfSellerAndCategory = () => {
    this.setState({
      dataLoading: true,
    });
    this.props?.fetchSellerData((res) => {
      if (res.sellerList && res.sellerList.length > 0) {
        this.setState({
          Seller: res.sellerList.map((ele) => {
            return {
              label: ele.fullName ? ele.fullName : "Null",
              value: ele._id,
            };
          }),

          dataLoading: false,
        });
      }
    });
  };
  listOfCategory = () => {
    this.props?.fetchCategoryData(
      0,
      10,
      this.state.categoryInputName,
      (res) => {
        if (res.docs && res.docs.length > 0) {
          this.setState({
            categories: res.docs.map((ele) => {
              return { label: ele.categoryName, value: ele._id };
            }),
          });
        }
      }
    );
  };

  listOfSku = (payload) => {
    this.props?.GetSkusList(0, 10, this.state.skuInputName, payload, (res) => {
      if (res.docs) {
        this.setState({
          sku: res.docs.map((ele) => {
            return {
              label: ele.skuName,
              value: ele._id,
            };
          }),
        });
      }
    });
  };

  productDeleteData(id) {
    this.setState({
      dataLoading: true,
    });
    this.props?.productDeleteData(id, (res) => {
      if (res && res.message === "Product has been deleted") {
        this.listOfProductList((this.state.currentPage - 1) * this.state.limit);
        NotificationManager.success(res.message, "Delete Successful");
      } else {
        // NotificationManager.error(res.message, "Error");
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
  }
  productRequestEditData() {
    // let deliveryDate = {
    //   from: moment(this.state.deliveryDate[0]).format(
    //     "YYYY-MM-DD"
    //   ),
    //   to: moment(this.state.deliveryDate[1]).format("YYYY-MM-DD"),
    // };
    let formData = new FormData();
    if (this.state.deliveryDate) formData.append("deliveryDate", this.state.deliveryDate);
    if (this.state.totalQuantity)
      formData.append("availableQuantity", this.state.totalQuantity);
    this.setState({
      dataLoading: true,
    });
    this.props?.productEditData(
      formData,
      this.props?.productViewData?._id,
      (res) => {
        // console.log(res);
        if (res && res.message === "Product has been successfully updated") {
          NotificationManager.success(res.message, "Product Updated!!");
          this.listOfProductList(
            (this.state.currentPage - 1) * this.state.limit
          );
          isVinculum(res?.product?.isVinculum?.toString(), "Product");
          this.props.history.push("/product");
        } else {
          // NotificationManager.error(res.message, "Error");
          handleErrorMsg(res);
          isVinculum(res?.product?.isVinculum?.toString(), "Product");
        }
        this.setState({
          dataLoading: false,
        });
      }
    );
    this.editModalToggle();
  }
  productInfo = (productId) => {
    this.setState({
      isEditMode: true,
      dataLoading: true,
    });
    // console.log(productId, "productId");
    if (productId) {
      this.props?.fetchProductViewData(productId, (res) => {
        this.setState(
          {
            dataLoading: false,
            id: this.props?.productViewData?._id,
            totalQuantity: this.props?.productViewData?.availableQuantity,
            price: JSON.stringify(this.props?.productViewData?.rate),
            //deliveryDate: moment(this.props.productViewData.availableDeliveryDate.to).format("DD-MM-YYYY"),
            // deliveryDate: this.props.productViewData.availableDeliveryDate
            //   ? [
            //     moment(
            //       this.props.productViewData.availableDeliveryDate.from
            //     ).format("YYYY-MM-DD"),
            //     moment(
            //       this.props.productViewData.availableDeliveryDate.to
            //     ).format("YYYY-MM-DD"),
            //   ]
            //   : moment(),
            deliveryDate: this.props?.productViewData?.deliveryDate
              ? moment(this.props?.productViewData?.deliveryDate).format(
                  "YYYY-MM-DD"
                )
              : moment(),
          },
          () => {
            // console.log(this.state.deliveryDate);
          }
        );
      });
    }
  };
  onChange(name, val) {
    this.setState({
      [`${name}`]: val,
      currentPage: 1,
    });
  }
  syncAllSkuProduct = () => {
    this.setState({
      dataLoading: true,
    });
    this.props?.syncAllSku((res) => {
      // console.log(res,"ressellersync")
      if (
        res?.status === 200 ||
        res?.status === 201 ||
        res?.message === "Some Products are sync with vinculum"
      ) {
        NotificationManager.success(res.message, "Successful");
        this.getAllCount();
      } else {
        // NotificationManager.error(res.message, "Error");
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
  };
  handleChangePage = (event, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.listOfProductList(offset);
  };
  render() {
    const totPages = Math.ceil(
      this.props?.listOfProductData?.length / this.state.limit
    );

    let timingFunc;
    let inputCategory = React.createRef(null);
    const valueDebouncing = (val) => {
      if (timingFunc) {
        clearTimeout(timingFunc);
      }
      timingFunc = setTimeout(() => {
        this.setState({ addProduct_category: val });
      }, 500);
    };
    const getFIle = (file) => {
      this.setState({ addProduct_categoryImg: file });

      if (file != undefined) {
        let fileReader = new FileReader();
        fileReader.onload = () => {
          this.setState({
            categoryDisplayImg: fileReader.result,
          });
        };
        fileReader.readAsDataURL(file[0]);
      }
    };

    const clearAll = (name) => {
      if (name === "category") {
        document.getElementById("namecategory").value = "";
        this.toggleAddCatergory();
      } else {
        document.getElementById("namesku").value = "";
        this.toggleAddSKU();
      }
    };

    //for sku
    let inputSku = React.createRef(null);
    let timingFunc_sku;
    const valueDebouncing_sku = (val) => {
      if (timingFunc_sku) {
        clearTimeout(timingFunc_sku);
      }
      timingFunc_sku = setTimeout(() => {
        this.setState({ addProduct_skuName: val });
      }, 500);
    };
    // const getFIle_sku = (file) => {
    //   this.setState({ addProduct_skuImg: file });
    //   if (file != undefined) {
    //     let fileReader = new FileReader();
    //     fileReader.onload = () => {
    //       // console.log(fileReader.result);
    //       this.setState({
    //         skuDisplayImg: fileReader.result,
    //       });
    //     };
    //     fileReader.readAsDataURL(file[0]);
    //   }
    // };

    // const clearAllSku = () => {
    //   document.getElementById("namesku").value = "";
    //   this.toggleAddSKU();
    // };

    let { categories, Seller, Size, sku } = this.state;
    var data = {};
    data = {
      columns: [
        {
          label: <span>SKU</span>,
          field: "sku",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Category</span>,
          field: "category",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Vinculum</span>,
          field: "vinculum",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Size/Count</span>,
          field: "count",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Price</span>,
          field: "price",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Quantity Available</span>,
          field: "quantityAvailable",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Seller</span>,
          field: "seller",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Listing Date</span>,
          field: "date",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Expiry Date</span>,
          field: "deliveryDate",
          sort: "asc",
          width: 50,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    if (
      this.props?.listOfProductData?.docs &&
      this.props?.listOfProductData?.docs?.length > 0
    ) {
      this.props?.listOfProductData?.docs.forEach((ele) => {
        data.rows.push({
          sku: (
            <div className="d-flex align-items-center skuAlign">
              <div className="d-flex align-items-center skuImage">
                <img
                  src={
                    ele?.images[0]
                      ? ele?.images[0]
                      : ele?.sku?.skuImage[0]
                      ? ele?.sku?.skuImage[0]
                      : imagepending
                  }
                  alt="productimg"
                  className="w-100"
                ></img>
              </div>
              &nbsp;&nbsp;&nbsp;
              <p className="mb-0">
                {ele.sku?.skuName ? ele.sku?.skuName : "null"}
              </p>
            </div>
          ),
          // ele.sku?.skuName ? ele.sku?.skuName : "N/A",
          category: ele.category?.categoryName
            ? ele.category?.categoryName
            : "Null",
          vinculum: ele?.isVinculum
            ? ele?.isVinculum?.toString() === "true"
              ? "true"
              : ele?.isVinculum?.toString() === "false"
              ? "false"
              : "pending"
            : "N/A",
          count: ele.size,
          price: (
            <div className="d-flex align-itemms-center">
              &nbsp;<span>{ele?.maxRate ? ele?.maxRate : 0}</span>
            </div>
          ),
          quantityAvailable: ele.availableQuantity
            ? ele.availableQuantity
            : "Null",
          seller:
            ele?.sellerWithRate?.length > 1
              ? "Multiple"
              : ele?.sellerWithRate[0]?.seller?.fullName
              ? ele?.sellerWithRate[0]?.seller?.fullName
              : "Null",
          date: <div>{moment(ele.createdAt).format("DD/MM/YYYY")}</div>,
          deliveryDate: ele.deliveryDate ? (
            <div>{moment(ele.deliveryDate).format("DD/MM/YYYY")}</div>
          ) : (
            "Null"
          ),
          // availableDeliveryDate: ele.availableDeliveryDate?.to ? (
          //   <div>
          //     {" "}
          //     {moment(ele.availableDeliveryDate?.from).format(
          //       "DD/MM/YYYY"
          //     )} - {moment(ele.availableDeliveryDate?.to).format("DD/MM/YYYY")}
          //   </div>
          // ) : (
          //   "N/A"
          // ),
          actions: (
            <div className="d-flex align-items-center tableBtns ">
              <Link>
                <Button
                  className="btn lightBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ productToEditId: ele._id }, () => {
                      this.editModalToggle();
                      this.productInfo(ele._id);
                    });
                  }}
                >
                  Edit
                </Button>
              </Link>
              &nbsp;&nbsp;
              <Link to={`/ProductInfo/${ele._id}?isEditMode=${false}`}>
                <Button className="btn lightBtn">View</Button>
              </Link>{" "}
              &nbsp;&nbsp;
              <Link to={`/ProductInfo/${ele._id}?isEditMode=${true}`}>
                <Button className="btn lightBtn">Edit</Button>
              </Link>
              &nbsp;&nbsp;
              <Link>
                <Button
                  className="btn lightBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ productToDeleteId: ele._id }, () => {
                      this.deleteConfirmModalToggle();
                    });
                  }}
                >
                  Delete
                </Button>
              </Link>
            </div>
          ),
        });
      });
    }
    document.body.addEventListener("click", this.handleChange);

    const debouncing = (e, stateName) => {
      if (debounceThis) {
        clearTimeout(debounceThis);
        debounceThis = null;
      }
      debounceThis = setTimeout(() => {
        if (stateName === "categoryInputName") {
          this.setState({ [`${stateName}`]: e }, () => {
            this.listOfCategory();
          });
        } else {
          this.setState({ [`${stateName}`]: e }, () =>
            this.listOfSku({
              categoryId: this.state?.category?.value
                ? this.state?.category?.value
                : "",
            })
          );
        }
      }, 500);
    };
    return (
      <>
        <div className="mb-3">
          <Row >
            <Col xs={12} sm={6} md={6} lg={6} className='margin'>
              <div className="d-flex align-items-center custCreationDiv ProductCategoryDiv">
                <img src={Producticon} alt="productIcon" />
                <div className="w-100">
                  <p className="mb-0">
                    <b>Product Category</b>
                  </p>
                  <div className="productMainDivStyle pos-rel">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        this.toggleAddCatergory();
                      }}
                    >
                      Add category
                    </span>
                    {/* &nbsp;&nbsp;|&nbsp;&nbsp; */}
                    {/* <span
                      onClick={(e) => {
                        e.stopPropagation();
                        this.toggleAddSKU();
                        this.props?.fetchCategoryData((res) => {
                          // console.log(res);
                          if (res && res.length > 0) {
                            this.setState({
                              categories: res?.docs.map((ele) => {
                                return {
                                  label: ele.categoryName,
                                  value: ele._id,
                                };
                              }),
                            });
                            // console.log(this.state.categories);
                          }
                        });
                      }}
                    >
                      Add SKU
                    </span> */}
                    <div
                      className={`logoutDropdown productMainFilterDropdown1 productMainCatPopupDropdown ${
                        this.state.toggleCatDiv ? "displayBlock" : "displayNone"
                      }`}
                    >
                      <div class="arrow-up"></div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Form>
                          <div>
                            <div className="inputDiv">
                              <Label for="namecategory">
                                Category Name
                                <span style={{ color: "red" }}>*</span>
                              </Label>
                              <Input
                                id="namecategory"
                                name="namecategory"
                                value={this.state.addProduct_category}
                                invalid={
                                  NAME_REGEX.test(
                                    this.state.addProduct_category
                                  ) ||
                                  this.state.addProduct_category?.length == 0
                                    ? false
                                    : true
                                }
                                placeholder="Type..."
                                type="text"
                                onChange={(e) => {
                                  // valueDebouncing(e.target.value)
                                  this.onChangeEvent(
                                    "addProduct_category",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <br />
                            <div className="d-flex align-items-center">
                              <div className="productPopupStyleImg">
                                <img
                                  style={{ overflow: "hidden" }}
                                  src={
                                    this.state.categoryDisplayImg != undefined
                                      ? this.state.categoryDisplayImg
                                      : Addbuyerpic
                                  }
                                  alt="add catergory"
                                />
                              </div>

                              <div className="buyerBtn">
                                <p className="m-1">
                                  {/* {this.state.addProduct_categoryImg_name} */}
                                </p>
                                <div
                                  onClick={() => inputCategory.current?.click()}
                                  className="btn darkBtn"
                                >
                                  Add Image
                                </div>
                                <span style={{ color: "red" }}>*</span>
                                <input
                                  multiple
                                  accept=".jpg, .jpeg, .png"
                                  ref={inputCategory}
                                  hidden={true}
                                  type="file"
                                  className="btn darkBtn"
                                  onChange={(e) => getFIle(e.target.files)}
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex align-items-center">
                            <Button
                              onClick={() => {
                                this.setState({
                                  addProduct_category: "",
                                  namecategory: "",
                                  addProduct_categoryImg: undefined,
                                  // addProduct_categoryImg_name: "",
                                  categoryDisplayImg: undefined,
                                });
                                clearAll("category");
                              }}
                              className="btn lightBtn"
                            >
                              Clear all
                            </Button>
                            <Button
                              onClick={this.addCategoryData}
                              className="btn darkBtn"
                              disabled={
                                !(
                                  this.state.addProduct_category &&
                                  this.state.addProduct_categoryImg
                                )
                              }
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                    {/* <div
                      className={`logoutDropdown productMainFilterDropdown productMainCatPopupDropdown skuPopup ${
                        this.state.toggleSkuDiv ? "displayBlock" : "displayNone"
                      }`}
                    >
                      <div class="arrow-up"></div>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Form>
                          <div>
                            <div className="inputDiv">
                              <Label for="namesku">
                                Name<span style={{ color: "red" }}>*</span>
                              </Label>

                              <Input
                                id="namesku"
                                name="namesku"
                                value={this.state.addProduct_skuName}
                                invalid={
                                  SKU_NAME_REGEX.test(
                                    this.state.addProduct_skuName
                                  ) ||
                                  this.state.addProduct_skuName?.length == 0
                                    ? false
                                    : true
                                }
                                placeholder="Type..."
                                type="text"
                                onChange={(e) => {
                                  this.onChangeEvent(
                                    "addProduct_skuName",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <br />
                            <div className="inputDiv">
                              <Label>Select Category</Label>
                              <span style={{ color: "red" }}>*</span>
                              <SelectComponent
                                onChange={(data) => {
                                  this.setState({
                                    addProduct_categoryType: data,
                                  });
                                }}
                                options={this.state.categories}
                                value={
                                  this.state.addProduct_categoryType
                                    ? this.state.addProduct_categoryType
                                    : ""
                                }
                              />
                            </div>
                            <br />
                            <div className="d-flex align-items-center">
                              <div className="productPopupStyleImg">
                                <img
                                  style={{ overflow: "hidden" }}
                                  src={
                                    this.state.skuDisplayImg != undefined
                                      ? this.state.skuDisplayImg
                                      : Addbuyerpic
                                  }
                                  alt="add catergory"
                                />
                              </div>
                              <div className="buyerBtn">
                                <p className="m-1">
                                  {" "}
                                </p>
                                <div
                                  onClick={() => inputSku.current?.click()}
                                  className="btn darkBtn"
                                >
                                  Add Image
                                </div>
                                <span style={{ color: "red" }}>*</span>
                                <input
                                  multiple
                                  accept=".jpg, .jpeg, .png"
                                  ref={inputSku}
                                  hidden={true}
                                  type="file"
                                  className="btn darkBtn"
                                  onChange={(e) => getFIle_sku(e.target.files)}
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex align-items-center">
                            <Button
                              className="btn lightBtn"
                              onClick={() => {
                                this.setState({
                                  addProduct_skuName: "",
                                  addProduct_categoryType: "",
                                  addProduct_skuImg: undefined,
                                  skuDisplayImg: undefined,
                                });
                                clearAll("sku");
                              }}
                            >
                              Clear all
                            </Button>
                            <Button
                              onClick={this.addSkuData}
                              className="btn darkBtn"
                              disabled={
                                !(
                                  this.state.addProduct_skuName &&
                                  this.state.addProduct_categoryType &&
                                  this.state.addProduct_skuImg
                                )
                              }
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} className='margin'>
              <Link style={{ textDecoration: "none" }} to={"/AddProduct"}>
                <div className="d-flex align-items-center custCreationDiv AddProductDiv">
                  <img src={Suppliericon} alt="supplierIcon" />
                  <div className="w-100">
                    <p className="mb-0">
                      <b>Add Product</b>
                      <br />
                      <span>(On Behalf Of Seller)</span>
                    </p>
                    <button className="rightBtnDiv">Add New</button>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </div>

       
        <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h4>Product List</h4>
            <div>
              <Form className="d-flex flex-sm-row flex-column ps-3 pe-3 align-item-start  justify-content-end tableHeaderDivCss">
                <div
                  className="pos-rel d-flex  flex-sm-row flex-column ps-3 pe-3  align-items-start"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {/* <SwitchButton
                    name="Horeca"
                    marginStyle="0px"
                    value={this.state.Horeca}
                    onChange={() => {
                      this.setState({ Horeca: !this.state.Horeca }, () =>
                        this.listOfProductList()
                      );
                    }}
                  /> */}
                  <div>
                    <Tippy
                      content={
                        <>
                          <div>
                            Total Product &nbsp;:&nbsp;
                            {this.props?.productCountData?.count?.length > 0
                              ? this.props?.productCountData?.count[0]
                                  ?.totalCount
                              : 0}
                          </div>
                          <div>
                            Sync Count &nbsp;:&nbsp;
                            {this.props?.productCountData?.count?.length > 0
                              ? this.props?.productCountData?.count[0]
                                  ?.trueCount
                              : 0}
                          </div>
                          <div>
                            Not Sync Count &nbsp;:&nbsp;
                            {this.props?.productCountData?.count?.length > 0
                              ? this.props?.productCountData?.count[0]
                                  ?.falseCount
                              : 0}
                          </div>
                          <div>
                            Pending Count &nbsp;:&nbsp;
                            {this.props?.productCountData?.count?.length > 0
                              ? this.props?.productCountData?.count[0]
                                  ?.pendingCount
                              : 0}
                          </div>
                        </>
                      }
                      disabled={false}
                    >
                      <span className="queryToolTip">
                        <img src={info} alt="infoImageIcon"></img>
                      </span>
                    </Tippy>
                  </div>
                  <div className="mb-0 mr-4">
                    <Button
                      size="sm"
                      type="button"
                      onClick={this.syncAllSkuProduct}
                      className="infoButtonSpacing"
                    >
                      {" "}
                      <span className="btn-label">
                        <i className="fa fa-refresh"></i>
                      </span>
                      <span
                        className="fontRoboto154"
                        style={{ marginLeft: "10px" }}
                      >
                        Sync
                      </span>
                    </Button>
                  </div>

                  <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv pt-0 debounceInput">
                    <Input
                      name="search"
                      placeholder="Sku Name"
                      type="text"
                      className="mb-0"
                      value={this.state.skuName}
                      autoComplete="off"
                      onChange={(e) => {
                        this.debounceValue(e.target.value, "skuName");
                      }}
                      // invalid={
                      //   SKU_NAME_REGEX.test(this.state.skuName) ||
                      //   this.state.skuName?.length == 0
                      //     ? false
                      //     : true
                      // }
                    />
                  </FormGroup>
                  <Button
                    className="btn lightBtn w-200 mobShowFilter"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.toggleFilter();
                    }}
                  >
                    Show Filters
                  </Button>
                  <div
                    className={`logoutDropdown productMainFilterDropdown ${
                      this.state.toggleDiv ? "displayBlock" : "displayNone"
                    }`}
                  >
                    <div class="arrow-up"></div>
                    <div>
                      <AccordionComponent AccordTitle={"Category"}>
                        <SelectComponent
                          options={categories}
                          onChange={(val) => {
                            if (val) {
                              this.onFilterData("category", val);
                              let payload = {
                                categoryId: val.value,
                              };
                              this.listOfSku(payload);
                            } else {
                              this.onFilterData("category", val);
                              this.setState({ sku: [] });
                            }
                          }}
                          onInputChange={(e) => {
                            debouncing(e.trimStart(), "categoryInputName");
                          }}
                          value={this.state.category ? this.state.category : ""}
                        />
                      </AccordionComponent>
                      <AccordionComponent AccordTitle={"SKU"}>
                        <SelectComponent
                          options={sku}
                          invalid={
                            SKU_NAME_REGEX.test(this.state.SelectedSku) ||
                            this.state.SelectedSku?.length == 0
                              ? false
                              : true
                          }
                          onChange={(val) => {
                            this.onFilterData("SelectedSku", val);
                          }}
                          onInputChange={(e) => {
                            if (e) {
                              debouncing(e.trimStart(), "skuInputName");
                            }
                          }}
                          value={
                            this.state.SelectedSku ? this.state.SelectedSku : ""
                          }
                        />
                      </AccordionComponent>
                      <AccordionComponent AccordTitle={"Seller"}>
                        <SelectComponent
                          options={Seller}
                          // invalid= {(NAME_REGEX.test(this.state.seller) || this.state.seller?.length == 0) ? false : true}
                          onChange={(val) => {
                            this.onFilterData("seller", val);
                          }}
                          value={this.state.seller ? this.state.seller : ""}
                        />
                      </AccordionComponent>
                      <AccordionComponent AccordTitle={"Size"}>
                        {/* <SelectComponent
                          // options={Size}
                          onChange={(val) => {
                            this.onFilterData("size", val);
                          }}
                          value={this.state.size ? this.state.size : ""}
                        /> */}
                        <Input
                          placeholder="search by size"
                          type="number"
                          onChange={(e) => {
                            this.onFilterData("size", e.target.value);
                          }}
                          value={this.state.size}
                        />
                      </AccordionComponent>

                      <AccordionComponent AccordTitle={"Price"}>
                        <Form>
                          <div>
                            <div className="d-flex align-items-center inputDiv">
                              <Label for="exampleInput">INR</Label>
                              &nbsp;&nbsp;
                              <Input
                                id="exampleInput"
                                name="text"
                                value={this.state.minPrice}
                                // maxLength={7}
                                invalid={
                                  ONLY_NUMBER_REGEX.test(this.state.minPrice) ||
                                  this.state.minPrice?.length == 0
                                    ? false
                                    : true
                                }
                                placeholder="MINIMUM"
                                type="text"
                                // onChange={(e) => {
                                //   this.onFilterData("minPrice", e.target.value);
                                // }}
                                onChange={(e) => {
                                  if (ONLY_NUMBER_REGEX.test(e.target.value)) {
                                    this.onFilterData(
                                      "minPrice",
                                      e.target.value
                                    );
                                  }
                                  // else {
                                  //   this.onFilterData("minPrice", "");
                                  // }
                                }}
                              />
                            </div>
                            <br />
                            <div className="d-flex align-items-center inputDiv">
                              <Label for="exampleInput1">INR</Label>
                              &nbsp;&nbsp;
                              <Input
                                id="exampleInput1"
                                name="text"
                                // maxLength={6}
                                placeholder="MAXIMUM"
                                value={this.state.maxPrice}
                                type="text"
                                // onChange={(e) => {
                                //   this.onFilterData("maxPrice", e.target.value);
                                // }}
                                onKeyPress={(evt) => {
                                  handleKeyPress(evt);
                                }}
                                onChange={(e) => {
                                  if (ONLY_NUMBER_REGEX.test(e.target.value)) {
                                    this.onFilterData(
                                      "maxPrice",
                                      e.target.value
                                    );
                                  }
                                  // else {
                                  //   this.onFilterData("minPrice", "");
                                  // }
                                }}
                              />
                            </div>
                            <br />
                          </div>
                        </Form>
                      </AccordionComponent>

                      <Form>
                        <div className="d-flex align-items-center">
                          <Button
                            outline
                            onClick={() => {
                              this.setState(
                                {
                                  category: "",
                                  seller: "",
                                  size: "",
                                  minPrice: "",
                                  maxPrice: "",
                                  SelectedSku: "",
                                  currentPage: 1,
                                  sku: [],
                                },
                                () => {
                                  this.listOfProductList();
                                  this.setState({ toggleDiv: false });
                                }
                              );
                            }}
                          >
                            Clear all
                          </Button>
                          <Button
                            outline
                            className="buttonDivStyle"
                            onClick={() => {
                              this.setState(
                                {
                                  currentPage: 1,
                                },
                                () => {
                                  this.filterProductData();
                                }
                              );
                            }}
                          >
                            Apply Filter
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
                &nbsp;&nbsp;&nbsp;
                {/* <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv">
                 <Input
                   name="search"
                   placeholder="search placeholder"
                   type="search"
                   className="mb-0"
                   onChange={(e) => {
                     this.props.fetchProductFilterSkuData(e.target.value);
                   }}
                 />
                 <svg
                   width="16"
                   height="17"
                   viewBox="0 0 16 17"
                   fill="none"
                   xmlns="http://www.w3.org/2000/svg"
                 >
                   <path
                     d="M11.6391 12.192L14.8511 15.418L11.6391 12.192ZM1.03907 7.35996C1.0444 6.20746 1.39103 5.08238 2.0352 4.12671C2.67938 3.17103 3.59222 2.42761 4.65852 1.99026C5.72482 1.55291 6.89678 1.44124 8.02649 1.66935C9.1562 1.89746 10.193 2.45511 11.0061 3.27194C11.8191 4.08876 12.372 5.12814 12.5949 6.25889C12.8178 7.38964 12.7007 8.56107 12.2584 9.62534C11.8162 10.6896 11.0685 11.599 10.1099 12.2388C9.15125 12.8785 8.02457 13.2199 6.87207 13.22C5.32201 13.2147 3.83735 12.5947 2.74382 11.4961C1.65029 10.3975 1.0372 8.91002 1.03907 7.35996V7.35996Z"
                     stroke="#5E5E5E"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                   />
                 </svg>
               </FormGroup> */}
                {/* <FormGroup className="d-flex align-items-center tableHeaderSelectDiv">
                 <Label for="exampleSelect">Sort by</Label>&nbsp;&nbsp;
                 <Input id="exampleSelect" name="select" type="select">
                   <option>Latest</option>
                   <option>option2</option>
                   <option>option3</option>
                   <option>option4</option>
                   <option>option5</option>
                 </Input>
               </FormGroup> */}
                <FormGroup className="d-flex flex-md-row  align-items-center tableHeaderSelectDiv selectDivTwo">
                  <Label for="exampleSelect">Show</Label>&nbsp;&nbsp;
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    onChange={(e) => {
                      this.setState(
                        {
                          limit: e.target.value,
                          currentPage: 1,
                        },
                        () => {
                          this.listOfProductList();
                        }
                      );
                      // setTimeout(() => {
                      //   this.listOfProductList();
                      // }, 500);
                    }}
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                  </Input>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
        {this.state.dataLoading ? (
          <div className="ViewPagesLoder">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="DeleteModal">
              <MDBModal
                isOpen={this.state.deleteConfirmModal}
                toggle={this.deleteConfirmModalToggle}
                className={this.props.className}
                size="md"
              >
                <MDBModalHeader toggle={this.deleteConfirmModalToggle}>
                  Confirm
                </MDBModalHeader>
                <MDBModalBody>
                  Are you sure you want to delete the product?
                </MDBModalBody>
                <MDBModalFooter>
                  <Button
                    color="danger"
                    onClick={() => {
                      this.productDeleteData(this.state.productToDeleteId);
                      this.deleteConfirmModalToggle();
                    }}
                  >
                    Delete
                  </Button>{" "}
                  <Button
                    color="secondary"
                    onClick={this.deleteConfirmModalToggle}
                  >
                    Cancel
                  </Button>
                </MDBModalFooter>
              </MDBModal>
            </div>
            <div className="EditModal">
              <MDBModal
                isOpen={this.state.editModal}
                toggle={this.editModalToggle}
                className={this.props.className}
                size="sm"
              >
                <MDBModalHeader toggle={this.editModalToggle}>
                  Edit Product
                </MDBModalHeader>
                <Form>
                  <MDBModalBody>
                    <Row>
                      {/* <Col xs={12} className="mb-15">
                        <FormGroup>
                          <Label for="price">Price</Label>
                          <Input
                            id="price"
                            name="price"
                            type="text"
                            value={this.state.price}
                            maxLength={10}
                            // onChange={(e) => {
                            //   let regularExpressionNumber = /^[0-9]*$/;
                            //   if (
                            //     regularExpressionNumber.test(e.target.value)
                            //   ) {
                            //     this.onChange("price", e.target.value);
                            //   } else {
                            //     this.onChange("price", "");
                            //   }
                            // }}
                            // onKeyPress={(evt) => {
                            //   handleKeyPress(evt);
                            // }}
                            onChange={(e) => {
                              if (QUANTITY_PRICE_REGEX.test(e.target.value)) {
                                this.onChange("price", e.target.value);
                              } else {
                                this.onChange("price", "");
                              }
                            }}
                            // onChange={(e) =>
                            //   this.onChange("price", e.target.value)
                            // }
                          />
                        </FormGroup>
                      </Col> */}
                      <Col xs={12} className="mb-15">
                        <FormGroup>
                          <Label for="quantity">Total Quantity Available</Label>
                          <Input
                            id="quantity"
                            name="quantity"
                            type="number"
                            maxLength="7"
                            value={this.state.totalQuantity}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            // onChange={(e) =>
                            //   this.onChange("totalQuantity", e.target.value)
                            // }
                            onKeyPress={(evt) => {
                              handleKeyPress(evt);
                            }}
                            onChange={(e) => {
                              if (QUANTITY_PRICE_REGEX.test(e.target.value)) {
                                this.onChange(
                                  "totalQuantity",
                                  e.target.value.trim()
                                );
                              } else {
                                this.onChange("totalQuantity", "");
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} className="mb-15">
                        <FormGroup>
                          <Label for="date">Expiry Date</Label>
                          <Input
                            id="date"
                            name="date"
                            type="date"
                            value={this.state.deliveryDate}
                            onChange={(e) => {
                              this.setState({
                                deliveryDate: e.target.value,
                              });
                            }}
                            disabled={false}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </MDBModalBody>
                  <MDBModalFooter>
                    <button
                      className="btn lightBtn"
                      onClick={(e) => {
                        e.preventDefault();
                        this.productRequestEditData();
                      }}
                      disabled={
                        this.state.isEditMode === "false" ? true : false
                      }
                    >
                      Save
                    </button>{" "}
                    <Button color="secondary" onClick={this.editModalToggle}>
                      Cancel
                    </Button>
                  </MDBModalFooter>
                </Form>
              </MDBModal>
            </div>
            <div className="dashInnerMainDiv">
              <div className="dataTableStyles">
                <MDBDataTable
                  className="text-center text-capitalize"
                  responsive
                  hover
                  data={data}
                  paging={false}
                  searching={false}
                  displayEntries={false}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p className="mb-0">
                    Showing{" "}
                    {this.props?.listOfProductData?.length != 0
                      ? (this.state.currentPage - 1) * this.state.limit + 1
                      : (this.state.currentPage - 1) * this.state.limit}{" "}
                    to{" "}
                    {(this.state.currentPage - 1) * this.state.limit +
                      (this.props?.listOfProductData.docs?.length
                        ? this.props?.listOfProductData.docs.length
                        : "")}{" "}
                    {} entries of {this.props?.listOfProductData?.length}
                  </p>
                </div>
                <div className="custPaginationDiv">
                  <Pagination
                    shape="rounded"
                    count={totPages}
                    page={this.state.currentPage}
                    color="primary"
                    onChange={this.handleChangePage}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    listOfProductData: state.rfu.productdata,
    categoryData: state.rfu.categorydata,
    listOfsellerData: state.rfu.sellerData,
    addedProductCategory: state.rfu.added_product_category,
    addedProductSku: state.rfu.added_product_sku,
    allSkusList: state.rfu.allSkusData,
    productViewData: state.rfu.productviewdata,
    productCountData: state.rfco.productCountData,
  };
};

export default connect(mapStateToProps, {
  fetchProductFilterSkuData,
  fetchCategoryData,
  fetchproductSortData,
  fetchSellerData,
  fetchProductAllFilterData,
  Product_addCategory,
  Product_addSku,
  GetSkusList,
  syncAllSku,
  productDeleteData,
  productEditData,
  categoryBySku,
  fetchProductViewData,
  getProductCount,
})(ProductMain);
