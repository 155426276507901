import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import React from 'react'
// let hourSeconds = 3600;

export const ClockTimerComponent = ({ duration, size }) => {
    return (
        <CountdownCircleTimer
            isPlaying
            duration={duration * 60}
            // initialRemainingTime={(duration * 60) % hourSeconds}
            size={size}
            strokeWidth={6}
            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
            colorsTime={[7, 5, 2, 0]}
        >
            {({ remainingTime }) => Math.ceil(remainingTime / 60)}
        </CountdownCircleTimer>
    )
}