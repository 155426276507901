import React, { Component } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import SelectComponent from '../../Product/common/SelectComponent';
import { ACCOUNT_NUMBER, ADDRESS_REGEX, CREDIT_CYCLE_REGEX, EMAIL_REGEX, IFSC_CODE, NAME_REGEX, NUMBER_REGEX, PHONE_NUMBER_ERROR_REGEX, PINCODE_ERROR_REGEX, PINCODE_REGEX } from '../../../common/components/common/Rejex';
import { userTypeList } from '../../../common/userTypeList';
import SwitchButton from '../../../common/components/common/switchButton';
import { stateList } from '../../../common/StateList';
import walleticon1 from "../../../images/walleticon1.jpg";
import { assignUnsupportedFileFormat } from '../../../common/components/common/GlobalFunctions';
import { debouncing } from '../../../common/constants';

export class CustomerInformationContent extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <Form>
                <Row>
                    <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label for="fullName">Name</Label>
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                pattern="/^[a-zA-Z]{2,20}(?: [a-zA-Z]{1,20})?(?: [a-zA-Z]{1,20})?$/"
                                disabled={
                                    this.props.isEditMode === "false" ? true : false
                                }
                                value={
                                    this.props.name
                                }
                                invalid={
                                    NAME_REGEX.test(this.props.name) ||
                                        this.props.name?.length == 0
                                        ? false
                                        : true
                                }
                                onChange={(e) => {
                                    this.props.onChange("name", e.target.value);
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label for="exampleEmail">Nature Of Business</Label>
                            <SelectComponent
                                value={
                                    // this.state?.natureOfBusinnessEdit
                                    //   ? this.state?.natureOfBusinnessEdit
                                    //   : this.state?.natureOfBusinnessEdit === undefined
                                    //     ? this.state.natureOfBusinness
                                    //     : ""
                                    this.props.natureOfBusinness
                                }
                                disabled={
                                    this.props.isEditMode === "false" ? true : false
                                }
                                onChange={(e) =>
                                    this.props.onChange("natureOfBusinness", e)
                                }
                                options={this.props.businessType}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label for="exampleEmail">Email</Label>
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                // pattern="/^[a-zA-Z]+ [a-zA-Z]+$/"
                                // maxLength={20}
                                disabled={
                                    this.props.isEditMode === "false" ? true : false
                                }
                                value={
                                    // this.state?.emailEdit
                                    //   ? this.state?.emailEdit
                                    //   : this.state?.emailEdit === undefined
                                    //     ? this.state?.email
                                    //     : ""
                                    this.props.email
                                }
                                invalid={
                                    EMAIL_REGEX.test(this.props.email) ||
                                        this.props.email?.length == 0
                                        ? false
                                        : true
                                }
                                onChange={(e) => {
                                    this.props.onChange("email", e.target.value);
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label for="exampleEmail">Mobile Number</Label>
                            <Input
                                id="mobileno"
                                name="mobileno"
                                type="text"
                                // maxLength={10}
                                disabled={
                                    this.props.isEditMode === "false" ? true : false
                                }
                                invalid={
                                    PHONE_NUMBER_ERROR_REGEX.test(this.props.mobNo)
                                        ? false
                                        : true
                                }
                                value={
                                    // this.state?.mobNoEdit
                                    //   ? this.state?.mobNoEdit
                                    //   : this.state?.mobNoEdit === undefined
                                    //     ? this.state.mobNo
                                    //     : ""
                                    this.props.mobNo
                                }
                                onChange={(e) => {
                                    if (NUMBER_REGEX.test(e.target.value)) {
                                        this.props.onChange("mobNo", e.target.value);
                                    }
                                    // else {
                                    //   this.onChange("mobNo", "");
                                    // }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label for="exampleEmail">User Type</Label>

                            <SelectComponent
                                disabled={
                                    this.props.isEditMode == "false" ? true : false
                                }
                                // defaultValue={this.props?.customerviewData?.userType}
                                value={
                                    // this.state?.userTypeEdit
                                    //   ? this.state?.userTypeEdit
                                    //   : this.state?.userTypeEdit === undefined
                                    //     ? this.state.userType
                                    //     : ""
                                    this.props.userType
                                }
                                onChange={(e) => this.props.onChange("userType", e)}
                                options={userTypeList}
                                userType={this.props.userType_localStorage}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            {this.props.userType.value === "buyer" &&
                                this.props.Horeca != undefined && (
                                    <SwitchButton
                                        disabled={
                                            this.props.isEditMode == "false" ? true : false
                                        }
                                        name="Horeca Customer"
                                        value={this.props.Horeca}
                                        onChange={(value) => {
                                            this.props.onChange("Horeca", value);
                                        }}
                                    />
                                )}
                        </FormGroup>
                    </Col>
                    {this.props?.userType?.value === "buyer" && (
                        <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Credit Cycle (in days)
                                </Label>
                                <Input
                                    id="creditcycle"
                                    name="creditcycle"
                                    type="text"
                                    maxLength={2}
                                    minLength={1}
                                    disabled={
                                        this.props.isEditMode === "false" ? true : false
                                    }
                                    invalid={
                                        CREDIT_CYCLE_REGEX.test(this.props.creditCycle)
                                            ? false
                                            : true
                                    }
                                    value={this.props.creditCycle}
                                    onChange={(e) => {
                                        if (CREDIT_CYCLE_REGEX.test(e.target.value)) {
                                            this.props.onChange("creditCycle", e.target.value);
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    )}
                </Row>
            </Form>
        )
    }
}
export class BasicInformationContent extends Component {
    render() {
        return (
            <Form>
                <Row>
                    <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label for="address">Address</Label>
                            <Input
                                id="address"
                                name="address"
                                type="text"
                                disabled={
                                    this.props.isEditMode === "false" ? true : false
                                }
                                value={
                                    // this.state?.addrsEdit
                                    //   ? this.state?.addrsEdit
                                    //   : this.state?.addrsEdit === undefined
                                    //     ? this.state.addrs
                                    //     : ""
                                    this.props.addrs
                                }
                                invalid={
                                    ADDRESS_REGEX.test(this.props.addrs) ||
                                        this.props.addrs?.length == 0
                                        ? false
                                        : true
                                }
                                onChange={(e) =>
                                    this.props.onChange("addrs", e.target.value)
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label for="state">State</Label>

                            <SelectComponent
                                disabled={
                                    this.props.isEditMode == "false" ? true : false
                                }
                                value={
                                    // this.state?.stateEdit
                                    //   ? this.state?.stateEdit
                                    //   : this.state?.stateEdit === undefined
                                    //     ? this.state.state
                                    //     : ""
                                    this.props.state
                                }
                                onChange={(e) => this.props.onChange("state", e)}
                                options={stateList}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label for="exampleEmail">Pin</Label>
                            <Input
                                id="pin"
                                name="pin"
                                type="text"
                                maxLength={6}
                                invalid={
                                    PINCODE_ERROR_REGEX.test(this.props.pin) ||
                                        this.props.pin?.length == 0
                                        ? false
                                        : true
                                }
                                disabled={
                                    this.props.isEditMode === "false" ? true : false
                                }
                                value={
                                    // this.state?.pinEdit
                                    //   ? this.state?.pinEdit
                                    //   : this.state?.pinEdit === undefined
                                    //     ? this.state.pin
                                    //     : ""
                                    this.props.pin
                                }
                                onChange={(e) => {
                                    if (
                                        PINCODE_REGEX.test(e.target.value) &&
                                        e.target.value.length <= 10
                                    ) {
                                        this.props.onChange("pin", e.target.value);
                                    } else {
                                        this.props.onChange("pin", "");
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label for="source">Source</Label>
                            <Input
                                id="source"
                                name="source"
                                type="text"
                                disabled={true}
                                value={this.props.src}
                                onChange={(e) => this.props.onChange("src", e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label for="member">Member Since</Label>
                            <Input
                                id="member"
                                name="member"
                                type="text"
                                disabled={true}
                                value={this.props.memberSince}
                                onChange={(e) =>
                                    this.props.onChange("memberSince", e.target.value)
                                }
                            />
                        </FormGroup>
                    </Col>
                    {this.props?.userType?.value === "buyer" ? (
                        <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                            <FormGroup>
                                <Label>Sales Person</Label>
                                <SelectComponent
                                    disabled={
                                        this.props.isEditMode === "false" ? true : false
                                    }
                                    value={this.props.selectedSalesInfo}
                                    onInputChange={(a) => {
                                        debouncing(() => {
                                            this.props.onChange("salesName", a);
                                            this.props.callingSales();
                                        });
                                    }}
                                    onChange={(e) => {
                                        this.props.onChange("selectedSalesInfo", e);
                                    }}
                                    options={this.props.salesData}
                                />
                            </FormGroup>
                        </Col>
                    ) : (
                        <></>
                    )}
                </Row>
            </Form>
        )
    }
}
export class AccountInformationContent extends Component {
    render() {
        return (
            <>
                <div className="walletdiv">
                    <div className="outerdivbaldiv">
                        <div className="innerbaldiv">
                            <img src={walleticon1} />
                            <h4 className="accbal">Account Balance</h4>
                        </div>
                        <h5 className="balamt">
                            Rs.{" "}
                            {this.props?.customerviewData?.bank?.availableBalance ??
                                0}
                        </h5>
                    </div>
                </div>
                <Form className="accountinfodiv">
                    <Row>
                        <Col xs={12} sm={10} md={10}>
                            <Row>
                                <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                                    <FormGroup>
                                        <Label for="accno">
                                            Account Number
                                            <span style={{ color: "red" }}>*</span>
                                        </Label>
                                        <Input
                                            id="accno"
                                            name="accno"
                                            maxLength={18}
                                            placeholder="Bank Acc no."
                                            type="text"
                                            disabled={
                                                this.props.isEditMode === "false"
                                                    ? true
                                                    : this.props.accNo &&
                                                        this.props?.customerviewData.bank?.accountNo
                                                        ? true
                                                        : false
                                            }
                                            value={this.props.accNo}
                                            invalid={
                                                this.props?.customerviewData.bank?.accountNo
                                                    ? false
                                                    : ACCOUNT_NUMBER.test(this.props.accNo) ||
                                                        !this.props.accNo
                                                        ? false
                                                        : true
                                            }
                                            onChange={(e) =>
                                                this.props.onChange("accNo", e.target.value)
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={4} className="mb-15">
                                    <FormGroup>
                                        <Label for="accholdername">
                                            IFSC Code<span style={{ color: "red" }}>*</span>
                                        </Label>
                                        <Input
                                            id="accholdername"
                                            name="accholdername"
                                            placeholder="IFSC Code"
                                            type="text"
                                            disabled={
                                                this.props.isEditMode === "false" ? true : false
                                            }
                                            value={this.props.IFSC}
                                            invalid={
                                                IFSC_CODE.test(this.props.IFSC) ||
                                                    !this.props.IFSC
                                                    ? false
                                                    : true
                                            }
                                            onChange={(e) =>
                                                this.props.onChange("IFSC", e.target.value)
                                            }
                                        />
                                    </FormGroup>
                                </Col>
                                {this.props.accountVerified === "Verified" ? (
                                    <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        className="mb-15 pdrl-15"
                                    >
                                        <div className="custTableListingDivStyle pdrl-15">
                                            <Row className="pd-10 borderedPlainBottomDiv ">
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    User Name :
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    {this.props.userName}
                                                </Col>
                                            </Row>
                                            <Row className="pd-10 borderedPlainBottomDiv ">
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    Bank Name :
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    {this.props.bankName}
                                                </Col>
                                            </Row>
                                            <Row className="pd-10 borderedPlainBottomDiv ">
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    Bank Branch :
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    {this.props.bankBranch}
                                                </Col>
                                            </Row>
                                            <Row className="pd-10 borderedPlainBottomDiv ">
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    MICR :
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    {this.props.micr}
                                                </Col>
                                            </Row>
                                            <Row className="pd-10 borderedPlainBottomDiv ">
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    Status :
                                                </Col>
                                                <Col xs={12} sm={12} md={6} lg={4}>
                                                    {this.props.accountVerified}
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                ) : (
                                    ""
                                )}
                            </Row>
                            {this.props.bankVerificationDetails ? null : (
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <Button
                                            className="btn lightBtn"
                                            disabled={
                                                this.props.isEditMode === "false" ? true : false
                                            }
                                            onClick={() => this.props.verify()}
                                        >
                                            Verify
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Col>
                        {this.props.bankVerificationDetails ? (
                            <Col xs={12} sm={4} md={3} lg={2}>
                                <div className="d-flex align-items-center">
                                    <div className="ml-3">
                                        <Label for="accholdername">Cancelled Cheque</Label>
                                        <p className="m-0">{this.props.chequeImageName}</p>
                                        <div>
                                            <Button
                                                onClick={() =>
                                                    this.props.cancelledChequeImage.current?.click()
                                                }
                                                disabled={
                                                    this.props.isEditMode === "false"
                                                        ? true
                                                        : false
                                                }
                                                className="btn darkBtn"
                                            >
                                                {" "}
                                                Upload
                                            </Button>
                                        </div>
                                        <input
                                            multiple
                                            accept=".jpg, .jpeg, .png"
                                            ref={this.props.cancelledChequeImage}
                                            hidden={true}
                                            type="file"
                                            className="btn darkBtn"
                                            onChange={(e) => this.props.getFIle(e.target.files)}
                                        />
                                    </div>
                                </div>
                                <br />
                                {this.props.displayImage !== "" ? (
                                    <div>
                                        <div>Preview Image</div>
                                        <div className="custCancelledChequeImageDiv">
                                            <img
                                                style={{ overflow: "hidden" }}
                                                src={this.props.displayImage}
                                                alt="Cancelled cheque"
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </Col>
                        ) : null}
                    </Row>
                </Form>
            </>
        )
    }
}
export class KycInformationContent extends Component {
    render() {
        return (
            <Form>
                <Row>
                    <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label for="kycdocumenttype">
                                Identity Proof<span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                                id="kycdocumenttype"
                                name="kycdocumenttype"
                                placeholder="Kyc Document type"
                                type="select"
                                disabled={
                                    this.props.isEditMode === "false" ? true : false
                                }
                                value={this.props.kycDocumentType}
                                onChange={(e) =>
                                    this.props.onChange("kycDocumentType", e.target.value)
                                }
                            >
                                <option value=""></option>
                                <option value="aadharCard">Aadhar Card</option>
                                <option value="panCard">Pan Card</option>
                                <option value="Passport">Passport</option>
                                <option value="Driving_licence">
                                    Driving licence
                                </option>{" "}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label>
                                Choose file<span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                                id="choosefile"
                                name="choosefile"
                                placeholder="Choose file"
                                multiple
                                type="file"
                                accept=".png, .jpg,.jpeg ,.doc,.docx,.Mp4,.xml,.pdf,.dotm,.wps,.rtf,.odt,.htm,.html,.WEBM,.dotx"
                                disabled={
                                    this.props.isEditMode === "false" ? true : false
                                }
                                // onChange={(e) =>
                                //   this.setState(
                                //     {
                                //       formDataIdentity: Object.values(e.target.files),
                                //     },
                                //     () => {
                                //       console.log(this.state.formDataIdentity, this.state.kycDocumentType);
                                //       this.getKycDocuments(
                                //         e.target.files,
                                //         "identityProof"
                                //       );
                                //     }
                                //   )
                                // }
                                onChange={(e) => {
                                    this.props.keyInfoOnChange(e,'identityProof','identityProof_images_formData')
                                }}
                            />
                        </FormGroup>
                    </Col>
                    {/* {this.state.kycDisplayImage &&
                  this.state.kycDisplayImage.length > 0
                  ? this.state.kycDisplayImage.map((ele, index) => (
                    <Col xs={12} sm={4} md={3} lg={2}  className="mb-15">
                      <div>
                        {!ele.disable ? (
                          <a
                            class="remove-image"
                            onClick={() => this.removeImage(index)}
                            style={{
                              display: "inline",
                              cursor: "pointer",
                              float: "right",
                            }}
                          >
                            X
                          </a>
                        ) : (
                          ""
                        )}
                        <div className="productImageDivStyle">
                          <img src={ele.selectedFile} alt={ele.name} />
                        </div>
                      </div>
                    </Col>
                  ))
                  : ""} */}
                    {Object.entries(this.props.identityProof_images)?.map(
                        (arr) =>
                            arr[1]?.map((ele) => (
                                <Col xs={12} sm={4} md={3} lg={2} className="mb-15">
                                    <div>
                                        {
                                            <a
                                                style={{
                                                    display: "inline",
                                                    cursor: "pointer",
                                                    float: "right",
                                                    position: "relative",
                                                    right: "3px",
                                                    top: "5px",
                                                }}
                                                download
                                                href={ele}
                                                className="fa"
                                            >
                                                &#xf019;
                                            </a>
                                        }
                                        <div className="productImageDivStyle">
                                            <img
                                                onClick={() =>
                                                    this.props.onChange( 'imageToBePoppedUp', ele )
                                                }
                                                src={ele}
                                                onError={(e) => {
                                                    assignUnsupportedFileFormat(e);
                                                    e.target.value = "";
                                                }}
                                                alt={arr[0]}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            ))
                    )}
                    {this.props.identityProof_images_added?.map(
                        (ele, index) => (
                            <Col xs={12} sm={4} md={3} lg={2} className="mb-15">
                                <div>
                                    {
                                        <a
                                            class="remove-image"
                                            onClick={() => this.props.removeImage(index)}
                                            style={{
                                                display: "inline",
                                                cursor: "pointer",
                                                float: "right",
                                            }}
                                        >
                                            X
                                        </a>
                                    }
                                    <div className="productImageDivStyle">
                                        <img
                                            onClick={() =>
                                                this.props.onChange('imageToBePoppedUp',ele?.selectedFile)
                                            }
                                            src={ele?.selectedFile}
                                            alt={ele?.name}
                                            onError={(e) => {
                                                assignUnsupportedFileFormat(e);
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                        )
                    )}
                </Row>
                {/* ------------------Address Proof ---------------- */}
                <Row>
                    <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label for="kycdocumenttype">
                                Address Proof<span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                                id="addressDocType"
                                name="addressDocType"
                                placeholder="address doc type"
                                type="select"
                                disabled={
                                    this.props.isEditMode === "false" ? true : false
                                }
                                value={this.props.addressDocType}
                                onChange={(e) =>
                                    this.props.onChange("addressDocType", e.target.value)
                                }
                            >
                                <option value=""></option>
                                <option value="aadharCard">Aadhar Card</option>
                                <option value="panCard">Pan Card</option>
                                <option value="Passport">Passport</option>
                                <option value="Driving_licence">
                                    Driving licence
                                </option>{" "}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                            <Label>
                                Choose file<span style={{ color: "red" }}>*</span>
                            </Label>
                            <Input
                                id="choosefiles"
                                multiple
                                name="choosefiles"
                                placeholder="Choose file"
                                type="file"
                                accept=".png, .jpg,.jpeg ,.doc,.docx,.Mp4,.xml,.pdf,.dotm,.wps,.rtf,.odt,.htm,.html,.WEBM,.dotx"
                                disabled={
                                    this.props.isEditMode === "false" ? true : false
                                }
                                // onChange={(e) =>
                                //   this.setState(
                                //     {
                                //       formDataAddress: Object.values(e.target.files),
                                //     },
                                //     () => {
                                //       this.getKycDocuments(
                                //         e.target.files,
                                //         "addressProof"
                                //       );
                                //     }
                                //   )
                                // }addressProof
                                onChange={(e) => {
                                    this.props.keyInfoOnChange(e,'addressProof','addressProof_images_formData')
                                }}
                            />
                        </FormGroup>
                    </Col>

                    {/* {this.state.addressProofImage &&
                  this.state.addressProofImage.length > 0
                  ? this.state.addressProofImage.map((ele, index) => (
                    <Col xs={12} sm={4} md={3} lg={2}  className="mb-15">
                      <div>
                        {!ele.disable ? (
                          <a
                            class="remove-image"
                            onClick={() => this.removeImageAddress(index)}
                            style={{
                              display: "inline",
                              cursor: "pointer",
                              float: "right",
                            }}
                          >
                            X
                          </a>
                        ) : (
                          ""
                        )}
                        <div className="productImageDivStyle">
                          <img src={ele.selectedFile} alt={ele.name} />
                        </div>
                      </div>
                    </Col>
                  ))
                  : ""} */}
                    {/* {console.log(Object.entries(this.state.addressProof_images))}; */}
                    {Object.entries(this.props?.addressProof_images)?.map(
                        (arr) =>
                            arr[1]?.map((ele) => (
                                <Col xs={12} sm={4} md={3} lg={2} className="mb-15">
                                    <div>
                                        {
                                            <a
                                                style={{
                                                    display: "inline",
                                                    cursor: "pointer",
                                                    float: "right",
                                                    position: "relative",
                                                    right: "3px",
                                                    top: "5px",
                                                }}
                                                download
                                                href={ele}
                                                className="fa"
                                            >
                                                &#xf019;
                                            </a>
                                        }
                                    </div>
                                    <div className="productImageDivStyle">
                                        <img
                                            src={ele}
                                            alt={arr[0]}
                                            onClick={() => {
                                                this.props.onChange( 'imageToBePoppedUp', ele);
                                            }}
                                            onError={(e) => {
                                                assignUnsupportedFileFormat(e);
                                            }}
                                        />
                                    </div>
                                </Col>
                            ))
                    )}
                    {this.props.addressProof_images_added?.map((ele, index) => (
                        <Col
                            // onClick={() => this.setState({ imageToBePoppedUp: ele?.selectedFile })}
                            xs={12}
                            sm={4}
                            md={3}
                            lg={2}
                            className="mb-15"
                        >
                            <div>
                                {
                                    <a
                                        class="remove-image"
                                        onClick={() => this.props.removeImageAddress(index)}
                                        style={{
                                            display: "inline",
                                            cursor: "pointer",
                                            float: "right",
                                        }}
                                    >
                                        X
                                    </a>
                                }
                                <div className="productImageDivStyle">
                                    <img
                                        onClick={() =>
                                            this.props.onChange('imageToBePoppedUp', ele?.selectedFile)
                                        }
                                        src={ele.selectedFile}
                                        alt={ele.name}
                                        onError={(e) => {
                                            assignUnsupportedFileFormat(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <div className="mt-8">
                            <div>
                                <Button
                                    className="btn darkBtn w-200"
                                    disabled={
                                        this.props?.customerviewData
                                            ?.userAccountStatus === "Completed" ||
                                            this.props?.customerviewData
                                                ?.userAccountStatus ===
                                            "Awaiting User Confirmation"
                                            ? true
                                            : !(
                                                (this.props.identityProof_images_added
                                                    ?.length > 0 &&
                                                    this.props.kycDocumentType) ||
                                                (this.props.addressProof_images_added
                                                    ?.length > 0 &&
                                                    this.props.addressDocType)
                                            )
                                    }
                                    onClick={() => this.props.submitKyc()}
                                >
                                    Submit for Kyc
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <div className="mt-8">
                            <div>
                                <Button
                                    className="btn lightBtn w-200"
                                    disabled={
                                        this.props?.customerviewData
                                            ?.userAccountStatus === "KYC Verified" ||
                                            this.props?.customerviewData
                                                ?.userAccountStatus === "New" ||
                                            this.props.isEditMode === "false"
                                            ? //    ||
                                            // this.props?.customerviewData
                                            //   ?.userAccountStatus ===
                                            // "Awaiting User Confirmation"
                                            true
                                            : false
                                    }
                                    onClick={() => this.props.triggerKycFormSms()}
                                >
                                    Resend Verificaion Link
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form>
        )
    }
}
