import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getApi,
  postApi,
  postLogin,
  deleteApi,
  patchApi,
  patchApiFormData,
  patchApiFormData2,
  postApiFormData,
  getApiForVerification,
  postApiVerification,
  getApiWithoutToken,
  postApiWithoutToken,
  getApiBody,
} from "../helpers/auth_helper";
import { productDeleteData } from "./actions";
import { types } from "./actionTypes";
import { NotificationManager } from "react-notifications";

function* middlewarefetchfunction() {
  const userId = JSON.parse(localStorage.getItem("authUser"));
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/${
      userId._id || ""
    }`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.PROFILE_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("Failed to fetch profile data", e);
  }
}

function* customerViewFunction({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/${id}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.CUSTOMER_VIEW_DATA_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("error occured", e);
  }
}
function* middlewareCustomerEditData({ payload, id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/updatecustomer?userId=${id}`;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}

function* middlewareDeliverySlotList({ callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/enum/enum/getByType/DeliverySlot`;
    let response = yield call(getApi, uri);
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}

function* middlewareRemoveDeliverySlotList({
  payload,
  ID,
  EnumType,
  callback,
}) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/enum/${ID}?EnumType=${EnumType}&Status=removing`;
    let response = yield call(patchApi, uri, payload);
    yield put({
      type: types.REMOVE_DELIVERY_SLOT_LIST_DATA_SUCCESS,
      ID,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}

function* enumEditData({ payload, enumType, id, status, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/enum/${id}?EnumType=${enumType}&Status=${status}`;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    // console.log("failed to fetch dashboard data", e);
  }
}

function* middlewarePreApprovedProductList({ buyerId, offset, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/preApprovedProds/getApprovedProd?buyerId=${buyerId}&offset=${offset}&limit=10`;
    let uri1 = `https://api.freshr.dev.cloudtrack.in/preApprovedProds/getApprovedProd?buyerId=632443e55301eefd0b1564ad&offset=0&limit=1`;

    let response = yield call(getApi, uri);
    // console.log(response);
    yield put({
      type: types.PRE_APPROVED_PRODUCT_LIST_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}

function* middlewareDeletePreApprovedProduct({ productId, buyerId, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/preApprovedProds/removeSingleProd?productId=${productId}&buyerId=${buyerId}`;
    let response = yield call(deleteApi, uri);
    yield put({
      type: types.DELETE_PRE_APPROVED_PRODUCT_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}

function* middlewareHistoryPreApprovedProduct({
  preApprovedId,
  productId,
  callback,
}) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/approvedProdUpdateLogs/getApprovedLog?preApprovedId=${preApprovedId}&productId=${productId}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.HISTORY_PRE_APPROVED_PRODUCT_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}

function* middlewareEditPreApprovedProduct({
  productId,
  buyerId,
  payload,
  callback,
}) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/preApprovedProds/updatePreApprovedProd?productId=${productId}&buyerId=${buyerId}`;
    let response = yield call(patchApi, uri, payload);
    yield put({
      type: types.EDIT_PRE_APPROVED_PRODUCT_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}

function* middlewareAddPreApprovedProduct({ payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/preApprovedProds/create`;
    let response = yield call(postApi, uri, payload);
    yield put({
      type: types.ADD_PRE_APPROVED_PRODUCT_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}

function* middlewareUserEditData({ payload, id, callback }) {
  try {
    // console.log(payload, id, callback);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/user/updateuser?userId=${id}`;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}
function* middlewareCustomerSearchData({ id }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/getlist?phone=${id}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.CUSTOMER_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("error occured");
  }
}
function* workUserLogin({ payload: { data, userType, history }, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/auth/login?userType=${userType}`;
    let response = yield call(postLogin, uri, data);

    // yield put({
    //   type: types.USER_LOGIN_SUCCESS,
    //   payload: response,
    // });
    callback(response);
    // if (response && response.message == "Login successfull!!") {
    //   history.push("/Dashboard");
    // }
  } catch (e) {
    // console.log("failed to fetch dashboard data", e);
  }
}

function* middlewarefetchfunction_Dashboard() {
  // // console.log(a);
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/customer/count`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.DASHBOARD_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("error occured");
  }
}
function* middlewareCustomerSortData({
  offset,
  limit,
  searchCustomer,
  status,
  source,
  userName,
  sort,
  usertype,
  callback,
}) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/getlist?offset=${offset}&limit=${limit}&phone=${searchCustomer}&userAccountStatus=${status}&userAccountSource=${source}&userName=${userName}&userType=${usertype}`;
    if (sort && sort === "Oldest") {
      uri = uri + `&sort={"createdAt":1}`;
    }
    let response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.CUSTOMER_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("error occured");
  }
}
function* middlewareCustomerDeleteData({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/${id}`;
    let response = yield call(deleteApi, uri);
    callback(response);
    //   yield put({
    //     type: types.USER_VIEW_DATA_SUCCESS,
    //     payload: response,
    //   });
  } catch (e) {
    // console.log("error occured");
  }
}
function* middlewareUserRequestData({
  offset,
  limit,
  userAccountSource,
  callback,
}) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/service-request/services?offset=${offset}&limit=${limit}&userAccountSource=${userAccountSource}`;
    let response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.USER_REQUEST_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("error occured");
  }
}
function* middlewareUserViewData({ id }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/service-request/${id}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.USER_VIEW_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("error occured");
  }
}
function* middlewareUseRequestDeleteData({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/service-request/${id}`;
    let response = yield call(deleteApi, uri);
    callback(response);
    //   yield put({
    //     type: types.USER_VIEW_DATA_SUCCESS,
    //     payload: response,
    //   });
  } catch (e) {
    // console.log("error occured");
  }
}
function* middlewareUseRequestEditData({ payload, id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/service-request/${id}`;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}

function* middlewareProductSortData({
  offset = 0,
  limit = 10,
  maxPrice = "",
  minPrice = "",
  category = "",
  size = "",
  seller = "",
  SelectedSku = "",
  preApproved = "",
  deliverySlot = "",
  buyerId = "",
  skuName = "",
  isVinculum = "",
  limra = "",
  callback,
}) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/products/getlist?offset=${offset}&limit=${limit}&maxprice=${maxPrice}&minprice=${minPrice}&category=${category}&size=${size}&seller=${seller}&sku=${SelectedSku}&preApproved=${preApproved}&deliverySlot=${deliverySlot}&buyerId=${buyerId}&skuName=${skuName}&isVinculum=${isVinculum}&limra=${limra}`;
    let response = yield call(getApi, uri);

    yield put({
      type: types.PRODUCT_DATA_SUCESSS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("error occured", e);
  }
}
function* middlewareProductFilterData({ payload }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/products/getlist?offset=0&limit=${payload}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.PRODUCT_DATA_SUCESSS,
      payload: response.docs,
    });
  } catch (e) {
    // console.log("error occured");
  }
}
function* middlewareProductFilterSkuData({ id }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/products/getlist?sku=${id}`;
    let response = yield call(getApi, uri);
    // console.log(response);
    yield put({
      type: types.PRODUCT_DATA_SUCESSS,
      payload: response.docs,
    });
  } catch (e) {
    // console.log("error occured");
  }
}
function* middlewareProductViewData({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/${id}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.PRODUCT_VIEW_DATA_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}
function* middlewareCategoryData({ offset, limit, categoryName, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/categories?offset=${offset}&limit=${limit}&categoryName=${categoryName}`;
    let response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.CATEGORY_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("error occured");
  }
}

function* middlewareUserCountData() {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/count/countUserRequest`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.USER_COUNT_DATA_SUCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("error occured");
  }
}
function* middlewareSellerData({ callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/user/list/getlistseller`;
    let response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.SELLER_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("error occured", e);
  }
}

function* middlewareSyncAllSellerToVinc({ userType, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/vinculum/allVendorSync?userType=${userType}`;
    let response = yield call(postApi, uri);
    callback(response);
    yield put({
      type: types.ALL_SELLER_SYNC_VINCULLUM_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("error occured", e);
  }
}
function* middlewareProductAllFilterData({
  category,
  seller,
  size,
  minPrice,
  maxPrice,
}) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/products/getlist?maxprice=${maxPrice}&minprice=${minPrice}&category=${category}&seller=${seller}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.PRODUCT_ALL_FILTER_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("error occured");
  }
}

function* fetchSellerData() {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/user/list/getlistseller`;
    let response = yield call(getApi, uri);
    yield put({ type: types.PRODUCT_SELLER_DATASUCCESS, payload: response });
  } catch (e) {
    // console.log("failed to fetch dashboard data", e);
  }
}

function* workVerifyAccount({ payload, callback }) {
  try {
    // console.log("verify account from saga", payload);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/bankDetails/verifybank`;
    let response = yield call(postApi, uri, payload);
    // console.log(response);
    callback(response);
    yield put({ type: types.VERIFY_BANK_ACCOUNT_SUCCESS, payload: response });
  } catch (e) {
    // console.log("failed to verify Bank Account", e);
  }
}

function* fetchProductData({ callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/categories`;
    let response = yield call(getApi, uri);
    callback(response);
    // console.log(response);
    yield put({
      type: types.PRODUCT_PRODUCT_CATEGORYSUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("failed to fetch dashboard data", e);
  }
}
function* fetchEnum({ callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/enum/enum`;
    let response = yield call(getApi, uri);
    // console.log(response);
    yield put({
      type: types.ENUM_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("failed to fetch dashboard data", e);
  }
}

function* fetchskusbyid(actiondata) {
  try {
    // // console.log(actiondata);
    let values = actiondata.value;
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/listbycategoryid`;
    let response = yield call(postApi, uri, values);
    // console.log(response);
    yield put({ type: types.PRODUCT_GET_SKU_BYID_SUCCESS, payload: response });
  } catch (e) {
    // console.log("failed to fetch dashboard data", e);
  }
}
function* createAProduct({ data, callback }) {
  try {
    // console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/create`;
    let response = yield call(postApiFormData, uri, data);
    // console.log(response);
    callback(response);
    yield put({ type: types.PRODUCT_CREATE_SUCCESS, payload: response });
  } catch (e) {
    // console.log("failed to create product", e);
  }
}
function* ProductPostAddCategory({ data, callback }) {
  try {
    // console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/create`;
    let response = yield call(postApiFormData, uri, data);
    // console.log(response);
    callback(response);
    yield put({ type: types.PRODUCTS_ADD_CATEGORY_SUCCESS, payload: response });
  } catch (e) {
    // console.log("failed to post category data", e);
  }
}
function* productFetchAllSkus({ offset, limit, skuName, payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/list?offset=${offset}&limit=${limit}&skuName=${skuName}&categoryId=${payload?.categoryId}`;
    let response = yield call(getApi, uri);
    // console.log(response);
    callback(response);
    yield put({ type: types.PRODUCTS_GET_ALL_SKUS_SUCCESS, payload: response });
  } catch (e) {
    // console.log("failed to get skus list", e);
  }
}

function* ProductPostAddSku({ data, callback }) {
  try {
    // console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/create`;
    let response = yield call(postApiFormData, uri, data);
    // console.log(response);
    callback(response);
    yield put({ type: types.PRODUCTS_ADD_SKU_SUCCESS, payload: response });
  } catch (e) {
    // console.log("failed to post Sku data", e);
  }
}

function* customerPostData({ data, callback }) {
  try {
    // console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/create`;
    let response = yield call(postApi, uri, data);
    // console.log(response);
    yield put({ type: types.CUSTOMER_ADDITION_SUCCESS, payload: response });
    callback(response);
  } catch (e) {
    // console.log("failed to post Sku data", e);
  }
}

function* middlewareProductDeleteData({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/${id}`;
    let response = yield call(deleteApi, uri);
    callback(response);
    //   yield put({
    //     type: types.USER_VIEW_DATA_SUCCESS,
    //     payload: response,
    //   });
  } catch (e) {
    // console.log("error occured");
  }
}

function* middlewareProductEditData({ payload, id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/${id}`;
    let response = yield call(patchApiFormData, uri, payload);
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}

function* workTriggerOtp({ payload, callback }) {
  try {
    // console.log(payload);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/thirdparty/verification/sendkycotp`;
    let response = yield call(postApiVerification, uri, payload);
    // console.log(response);
    callback(response);
    yield put({ type: types.TRIGGER_OTP_SUCCESS, payload: response });
  } catch (e) {
    // console.log("failed to trigger otp", e);
  }
}

function* workVerifyOtp({ payload, callback }) {
  try {
    // console.log(payload);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/thirdparty/verification/verifykycotp`;
    let response = yield call(postApiVerification, uri, payload);
    // console.log(response);
    callback(response);
    yield put({ type: types.VERIFY_OTP_SUCCESS, payload: response });
  } catch (e) {
    // console.log("failed to Verify otp", e);
  }
}

function* workTriggerKycForm({ payload, callback }) {
  try {
    // console.log(payload);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/thirdparty/verification/kyc`;
    let response = yield call(postApi, uri, payload);
    // console.log(response);
    callback(response);
    yield put({ type: types.TRIGGER_KYC_FORM_SUCCESS, payload: response });
  } catch (e) {
    // console.log("failed to Trigger KYC form link sms", e);
  }
}

function* WorkSkuList({ offset, limit, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/list?offset=${offset}&limit=${limit}`;
    let response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.SKU_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("Failed to fetch SKU Category List", e);
  }
}
// WorkSyncAllSku
function* WorkSyncAllSku({ callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/vinculum/syncAllSku`;
    let response = yield call(postApi, uri);
    callback(response);
    yield put({
      type: types.SYNC_ALL_SKU_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("Failed to fetch SKU Category List", e);
  }
}

function* WorkSkuDeleteData({ id, callback }) {
  // console.log(id);
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/${id}?isDeleted=true`;
    let response = yield call(deleteApi, uri);
    callback(response);
  } catch (e) {
    // console.log("Failed to Delete SKU Data", e);
  }
}

function* WorkSkuEditData({ id, payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/${id}`;
    let response = yield call(patchApiFormData, uri, payload);
    callback(response);
  } catch (e) {
    // console.log("Failed to Edit SKU Data", e);
  }
}

function* WorkSkuViewData({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/${id}`;
    let response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.SKU_VIEW_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("Failed to fetch SKU Category View Data", e);
  }
}

function* WorkCategoryBySkuData({ payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sku/listbycategoryid`;
    let response = yield call(postApi, uri, payload);
    callback(response);
    // yield put({
    //   type: types.SKU_CATEGORY_VIEW_DATA_SUCCESS,
    //   payload: response,
    // });
  } catch (e) {
    // console.log("Failed to fetch Category by SKU Data", e);
  }
}

function* WorkCategoryData({ offset, limit, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/categories?offset=${offset}&limit=${limit}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.CATEGORYS_DATA_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("Failed to fetch Category Data", e);
  }
}

function* WorkCategoryDeleteData({ id, callback }) {
  // console.log(id);
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/${id}`;
    let response = yield call(deleteApi, uri);
    callback(response);
  } catch (e) {
    // console.log("Failed to Delete Category Data", e);
  }
}

function* WorkCategoryViewData({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/${id}`;
    let response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.CATEGORY_VIEW_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("Failed to fetch Category View Data", e);
  }
}

function* WorkCategoryEditData({ id, payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/${id}`;
    let response = yield call(patchApiFormData, uri, payload);
    callback(response);
  } catch (e) {
    // console.log("Failed to Edit Category Data", e);
  }
}

function* workUploadCancelledCheque({ data, userId, callback }) {
  try {
    // console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/docs-upload?userId=${userId}`;
    let response = yield call(patchApiFormData, uri, data);
    // console.log(response);
    callback(response);
    yield put({
      type: types.UPLOAD_CANCELLED_CHEQUE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("failed to upload cancelled cheque image", e);
  }
}

function* workUploadKycDocuments({ data, userId, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/docs-upload?userId=${userId}`;
    let response = yield call(patchApiFormData, uri, data);
    // console.log(response);
    callback(response);
    yield put({ type: types.UPLOAD_KYC_DOCUMENT_SUCCESS, payload: response });
  } catch (e) {
    // console.log("failed to upload KYC document", e);
  }
}

function* getSosData({ data, callback }) {
  try {
    // console.log(data);
    // let uri = `${process.env.REACT_APP_BACKEND_URL}/product/products/getlist?offset=${data.offset}&limit=${data.limit}`;
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sos/sos?offset=${data.offset}&limit=${data.limit}&search={"fullName":"${data.buyer}","sosId":"${data.sosId}"}`;
    // let uri = `${process.env.REACT_APP_BACKEND_URL}/sos/sos?offset=${data.offset}&limit=${data.limit}&search={"fullName":"${data.buyer}"}`
    if (data.sort) {
      uri =
        uri +
        `&sort={"${data.sort.split(" ")[0]}":"${data.sort.split(" ")[1]}"}`;
    }
    // &sodId=${data.sosId}&buyer=${data.buyer}
    let response = yield call(getApi, uri);
    // // console.log(response);
    callback(response);
    // yield put({ type: types.SOS_REQUEST_SUCCESS, payload: response });
  } catch (e) {
    // console.log("failed to fetch SOS Data", e);
  }
}

function* updateSos({ data, payload, callback }) {
  // console.log("works");
  // console.log(data);
  // console.log(payload);
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sos/${data}`;
    let response = yield call(patchApi, uri, payload);
    yield put({
      type: types.SOS_UPDATE_REQUEST_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("error occured");
  }
}
// deleteSos

function* GetOrderData({ data, callback }) {
  // console.log(data , "hi");
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/orders?offset=${data.offset}&limit=${data.limit}&paymentStatus=${data.paymentStatus}&orderStatus=${data.orderStatus}&orderId=${data.orderId}&buyerId=${data.buyerName}&sortKey=${data.sortKey}&sortValue=${data.sortValue}&paymentMode=${data.paymentMode}&isHoreca=${data.buyerType}&from=${data.from}&to=${data.to}`;
    // if (data?.sortKey && data?.sortValue) {
    //   uri =
    //     uri +
    //     // `&sort={"${data.sort.split(" ")[0]}":"${data.sort.split(" ")[1]}"}`;
    //     `&sortKey=${data.sortKey}&sortValue=${data.sortValue}`;

    // }
    let response = yield call(getApi, uri);
    yield put({
      type: types.ORDERS_DATA_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("failed to fetch SOS Data", e);
  }
}

function* middlewareRepeatOrder({ orderId, callback }) {
  // console.log(data , "hi");
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/repeatOrder/${orderId}`;
    let response = yield call(postApi, uri);
    yield put({
      type: types.REPEAT_ORDER_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("failed to fetch SOS Data", e);
  }
}
function* middlewareOrderViewDaata({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/${id}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.ORDER_VIEW_DATA_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("failed to fetch order View");
  }
}

function* middlewareApproveOrder({ orderId, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/approveOrder/${orderId}`;
    let response = yield call(patchApi, uri);
    yield put({
      type: types.APPROVE_ORDER_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("failed to fetch order View");
  }
}

function* middlewareOrderCancel({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/cancle-order/${id}`;
    let response = yield call(deleteApi, uri);
    callback(response);
  } catch (e) {
    // console.log("failed to cancel order ");
  }
}

function* middlewareBuyerSearch({ offset, limit, userName, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/getlist?offset=${offset}&limit=${limit}&userType=buyer&userName=${userName}`;
    // if (userName) {
    //   uri = `${process.env.REACT_APP_BACKEND_URL}/customer/getlist?userType=buyer&userName=${userName}`;
    // }
    let response = yield call(getApi, uri);
    callback(response);
  } catch (e) {
    // console.log("failed to Buyer Search ");
  }
}

function* middlewareOrderUpdate({ data, orderId, orderActions, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/updateOrder/${orderId}/${orderActions}`;
    // let uri = `${process.env.REACT_APP_BACKEND_URL}/order/${orderId}/${orderActions}`;
    let response = yield call(patchApi, uri, data);
    callback(response);
  } catch (e) {
    // console.log("failed to order Update ");
  }
}

function* middlewareAddTransport({ id, data, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/addTransport/${id}`;
    let response = yield call(patchApi, uri, data);
    callback(response);
  } catch (e) {
    // console.log("failed to add transport ");
  }
}

function* middlewareAddDiscount({ payload, id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/discount/initiateDiscount/${id}`;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    // console.log("failed to add 'discount' ");
  }
}

function* middlewarePaymentLink({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/payment/initiatepayment/${id}`;
    let response = yield call(getApi, uri);
    callback(response);
  } catch (e) {
    // console.log("Failed to fetch payment link", e);
  }
}

function* middlewarePaymentIntiate({ payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/refunds/refund/`;
    let response = yield call(postApi, uri, payload);
    callback(response);
  } catch (e) {
    // console.log("Failed to fetch payment intiate", e);
  }
}

function* middlewareResendPayment({ payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/payment/resendpaymentLink`;
    let response = yield call(postApi, uri, payload);
    callback(response);
  } catch (e) {
    // console.log("Failed to fetch resend payment", e);
  }
}

function* customerViewVerifyFunction({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/customer/one/${id}`;
    let response = yield call(getApiForVerification, uri);
    yield put({
      type: types.CUSTOMER_VERIFICATION_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("error occured", e);
  }
}

function* GettingAllSOSTypes({ callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sos/allSosTypes`;
    let response = yield call(getApiWithoutToken, uri);
    callback(response);
  } catch (e) {
    // console.log("error occured", e);
  }
}

function* creatingSos({ data, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/sos/create`;
    let response = yield call(postApi, uri, data);
    callback(response);
  } catch (e) {
    // console.log("error occured", e);
  }
}

function* getBuyerInfo({ data, callback }) {
  try {
    // console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/orderData/buyerPhoneNumber/${data?.orderId}`;
    // console.log(uri);
    let response = yield call(getApiWithoutToken, uri);
    callback(response);
  } catch (e) {
    // console.log("error occured", e);
  }
}

function* sendLoginOtp({ data, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/auth/generateotp`;
    let response = yield call(postApiWithoutToken, uri, data);
    callback(response);
  } catch (e) {
    // console.log("error occured", e);
  }
}

function* VerifyLoginOtp({ data, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/auth/verifyotp?userType=buyer`;
    let response = yield call(postApiWithoutToken, uri, data);
    callback(response);
  } catch (e) {
    // console.log("error occured", e);
  }
}

function* workFetchAllAdmin({
  offset,
  limit,
  searchAdminByPhone,
  searchAdminByName,
  sort,
  id,
  userType,
  callback,
}) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/user/list/getlistuser?offset=${offset}&limit=${limit}&phone=${searchAdminByPhone}&userName=${searchAdminByName}&userId=${id}&userType=${userType}`;
    // let uri = `http://fbef-182-48-242-126.in.ngrok.io/user/list/allAdmin`;
    if (sort && sort === "Oldest") {
      uri = uri + `&sort={"createdAt":1}`;
    }

    let response = yield call(getApi, uri);
    // console.log(response);
    yield put({
      type: types.ADMIN_ALL_DATA_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // console.log("error occured", e);
  }
}

function* singleAdmin({
  offset,
  limit,
  searchAdminByPhone,
  searchAdminByName,
  sort,
  id,
  callback,
}) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/user/list/getlistuser?offset=${offset}&limit=${limit}&phone=${searchAdminByPhone}&userName=${searchAdminByName}&userId=${id}`;

    let response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.ADMIN_ALL_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    // console.log("error occured", e);
  }
}

function* workDeleteAdminData({ id, callback }) {
  // console.log(id);
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/user/${id}`;
    let response = yield call(deleteApi, uri);
    callback(response);
  } catch (e) {
    // console.log("Failed to Delete Category Data", e);
  }
}

function* workCreateAdminData({ data, callback }) {
  // console.log(id);
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/user/create/admin`;
    let response = yield call(postApi, uri, data);
    callback(response);
  } catch (e) {
    // console.log("Failed to Delete Category Data", e);
  }
}

// edit admin api
function* editAdminData({ id, payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/user/updateadmin/userId=${id}`;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    // console.log("Failed to Edit SKU Data", e);
  }
}

function* createCart({ data, deliveryDate, cartId, callback }) {
  try {
    // console.log(data);
    let uri;
    if (!cartId)
      uri = `${process.env.REACT_APP_BACKEND_URL}/cart/create-cart?deliveryDate=${deliveryDate}`;
    else
      uri = `${process.env.REACT_APP_BACKEND_URL}/cart/updateCart/${cartId}?deliveryDate=${deliveryDate}`;
    let response = yield call(!cartId ? postApi : patchApi, uri, data);
    callback(response);
  } catch (e) {
    callback(e);
  }
}

function* GetCart({ data, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/cart/carts?buyerId=${data?.buyerId}`;
    let response = yield call(getApi, uri);
    callback(response);
  } catch (e) {
    callback(e);
  }
}

function* DeleteCart({ data, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/cart/removeSingleProdfromcart/${data.productId}?buyerId=${data.buyerId}`;
    let response = yield call(deleteApi, uri);
    callback(response);
  } catch (e) {
    callback(e);
  }
}

function* PlaceOrder({ data, callback }) {
  try {
    // console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/order/create-order`;
    let response = yield call(postApiFormData, uri, data);
    callback(response);
  } catch (e) {
    callback(e);
  }
}

function* GettingApproval({ data, orderId, callback }) {
  try {
    // console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/payment/createOfflinePayments/${orderId}`;
    let response = yield call(postApiFormData, uri, data);
    callback(response);
  } catch (e) {
    callback(e);
  }
}

function* AcknoledgingApproval({ data, paymentId, callback }) {
  try {
    // console.log(data, JSON.stringify(data));
    let uri = `${process.env.REACT_APP_BACKEND_URL}/payment/offlinePaymentsAcknowledgement/${paymentId}`;
    let response = yield call(postApi, uri, data);
    callback(response);
  } catch (e) {
    callback(e);
  }
}

function* getAllPayments({
  offset,
  limit,
  paymentMedium,
  mode,
  paymentStatus,
  createdBy,
  orderId,
  sortKey,
  sortValue,
  callback,
  q,
}) {
  try {
    // console.log(data);
    let uri = `${process.env.REACT_APP_BACKEND_URL}/payment/all/getAllPayments?offset=${offset}&limit=${limit}&paymentMedium=${paymentMedium}&mode=${mode}&paymentStatus=${paymentStatus}&createdBy=${createdBy}&orderId=${orderId}&sortKey=${sortKey}&sortValue=${sortValue}`;
    let response = yield call(getApi, uri);
    callback(response);
  } catch (e) {
    callback(e);
  }
}
function* middlewareSellerCountData({ userType }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/user/getVendorSyncStatus/count?userType=${userType}`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.SELLER_COUNT_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {}
}
function* middlewareProductCountData() {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/getProductSyncStatus/count`;
    let response = yield call(getApi, uri);
    yield put({
      type: types.PRODUCT_COUNT_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {}
}

function* middlewareFetchSellerByCategory({ id, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/getSellers/${id}`;
    let response = yield call(getApi, uri);
    callback(response);
  } catch (e) {
    callback(e);
  }
}
function* middlewareFetchSellerAssignedByCategory({
  id,
  offset,
  limit,
  sellerName,
  MultipleSellerId,
  callback,
}) {
  try {
    let uri =
      `${process.env.REACT_APP_BACKEND_URL}/category/sellerAssigned/${id}?offset=${offset}&limit=${limit}&sellerName=${sellerName}&sellerIds=` +
      JSON.stringify(MultipleSellerId);
    let response = yield call(getApi, uri);
    yield put({
      type: types.SELLER_ASSIGNED_BY_CATEGORY_SUCCESS,
      payload: response,
    });
    callback(response);
  } catch (e) {
    // callback(e);
  }
}
function* middlewareAddMultipleSeller({ id, payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/${id}?mappingStatus=mapping `;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    callback(e);
  }
}
function* middlewareAddMultipleSellerByCategory({ id, payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/category/mapSellersToCategory/${id}`;
    let response = yield call(patchApi, uri, payload);
    callback(response);
  } catch (e) {
    callback(e);
  }
}

function* middlewareUpdateLimraRate({ payload, callback }) {
  try {
    let uri = `${process.env.REACT_APP_BACKEND_URL}/product/AlllimraProductUpdate`;
    let response = yield call(postApi, uri, payload);
    callback(response);
  } catch (e) {
    callback(e);
  }
}
function* watchfetchrequest() {
  yield takeEvery(types.PROFILE_DATA, middlewarefetchfunction);
}

function* watchCustomerViewRequest() {
  yield takeEvery(types.CUSTOMER_VIEW_DATA, customerViewFunction);
}
function* watchCustomerEditData() {
  yield takeEvery(types.CUSTOMER_EDIT_DATA, middlewareCustomerEditData);
}
function* watchDeliverySlotList() {
  yield takeEvery(types.DELIVERY_SLOT_LIST_DATA, middlewareDeliverySlotList);
}
function* watchRemoveDeliverySlot() {
  yield takeEvery(
    types.REMOVE_DELIVERY_SLOT_LIST_DATA,
    middlewareRemoveDeliverySlotList
  );
}

function* watchPreApprovedProductData() {
  yield takeEvery(
    types.PRE_APPROVED_PRODUCT_LIST,
    middlewarePreApprovedProductList
  );
}

function* watchDeletePreApprovedProduct() {
  yield takeEvery(
    types.DELETE_PRE_APPROVED_PRODUCT,
    middlewareDeletePreApprovedProduct
  );
}

function* watchHistoryPreApprovedProduct() {
  yield takeEvery(
    types.HISTORY_PRE_APPROVED_PRODUCT,
    middlewareHistoryPreApprovedProduct
  );
}

function* watchEditPreApprovedProduct() {
  yield takeEvery(
    types.EDIT_PRE_APPROVED_PRODUCT,
    middlewareEditPreApprovedProduct
  );
}

function* watchAddPreApprovedProduct() {
  yield takeEvery(
    types.ADD_PRE_APPROVED_PRODUCT,
    middlewareAddPreApprovedProduct
  );
}

function* watchUserEditData() {
  yield takeEvery(types.USER_DATA_UPDATE, middlewareUserEditData);
}

function* watchfetchrequestDashboard() {
  yield takeEvery(types.DASHBOARD_DATA, middlewarefetchfunction_Dashboard);
}
function* watchCustomerSortData() {
  yield takeEvery(types.CUSTOMER_SORT_DATA, middlewareCustomerSortData);
}
function* watchUserRequestData() {
  yield takeEvery(types.USER_REQUEST_DATA, middlewareUserRequestData);
}
function* watchUserViewData() {
  yield takeEvery(types.USER_VIEW_DATA, middlewareUserViewData);
}
function* watchUserRequestDeleteData() {
  yield takeEvery(
    types.USER_REQUEST_DELETE_DATA,
    middlewareUseRequestDeleteData
  );
}
function* watchUserRequestEditData() {
  yield takeEvery(types.USER_REQUEST_EDIT_DATA, middlewareUseRequestEditData);
}

function* watchUserLogin() {
  yield takeEvery(types.USER_LOGIN, workUserLogin);
}

function* watchProductSortData() {
  yield takeEvery(types.PRODUCT_SORT_DATA, middlewareProductSortData);
}
function* watchProductFilterData() {
  yield takeEvery(types.PRODUCT_FILTER_DATA, middlewareProductFilterData);
}
function* watchProductFilterSkuData() {
  yield takeEvery(
    types.PRODUCT_FILTER_DATA_BY_SKU,
    middlewareProductFilterSkuData
  );
}
function* watchProductViewData() {
  yield takeEvery(types.PRODUCT_VIEW_DATA, middlewareProductViewData);
}
function* watchCategoryData() {
  yield takeEvery(types.CATEGORY_DATA, middlewareCategoryData);
}
function* watchUserCountData() {
  yield takeEvery(types.USER_COUNT_DATA, middlewareUserCountData);
}
function* watchCustomerDeleteData() {
  yield takeEvery(types.CUSTOMER_DELETE_DATA, middlewareCustomerDeleteData);
}
function* watchSellerData() {
  yield takeEvery(types.SELLER_DATA, middlewareSellerData);
}
function* watchSyncAllSellerToVinc() {
  yield takeEvery(
    types.ALL_SELLER_SYNC_VINCULLUM,
    middlewareSyncAllSellerToVinc
  );
}
function* watchProductAllFilterData() {
  yield takeEvery(
    types.PRODUCT_ALL_FILTER_DATA,
    middlewareProductAllFilterData
  );
}

function* watchFetchSellerData() {
  yield takeEvery(types.PRODUCT_SELLER_DATA, fetchSellerData);
}
function* watchFetchProductCateogry() {
  yield takeEvery(types.PRODUCT_PRODUCT_CATEGORY, fetchProductData);
}
function* watchFetchSkuByID() {
  yield takeEvery(types.PRODUCT_GET_SKU_BYID, fetchskusbyid);
}
function* watchProductCreate() {
  yield takeEvery(types.PRODUCT_CREATE, createAProduct);
}
function* watchProductAddCategory() {
  yield takeEvery(types.PRODUCTS_ADD_CATEGORY, ProductPostAddCategory);
}
function* watchProductGetSkus() {
  yield takeEvery(types.PRODUCTS_GET_ALL_SKUS, productFetchAllSkus);
}
function* watchProductAddSku() {
  yield takeEvery(types.PRODUCTS_ADD_SKU, ProductPostAddSku);
}
function* watchCustomerAddition() {
  yield takeEvery(types.CUSTOMER_ADDITION, customerPostData);
}
function* watchProductDeleteData() {
  yield takeEvery(types.PRODUCT_DELETE_DATA, middlewareProductDeleteData);
}
function* watchProductEditData() {
  yield takeEvery(types.PRODUCT_EDIT_DATA, middlewareProductEditData);
}
function* watchVerifyAccount() {
  yield takeEvery(types.VERIFY_BANK_ACCOUNT, workVerifyAccount);
}
function* watchTriggerOtp() {
  yield takeEvery(types.TRIGGER_OTP, workTriggerOtp);
}
function* watchVerifyOtp() {
  yield takeEvery(types.VERIFY_OTP, workVerifyOtp);
}
function* watchTriggerKycForm() {
  yield takeEvery(types.TRIGGER_KYC_FORM, workTriggerKycForm);
}
function* watchSkuList() {
  yield takeEvery(types.SKU_DATA, WorkSkuList);
}

function* watchSyncAllSku() {
  yield takeEvery(types.SYNC_ALL_SKU, WorkSyncAllSku);
}
function* watchSkuDeleteData() {
  yield takeEvery(types.SKU_DELETE_DATA, WorkSkuDeleteData);
}
function* watchSkuEditData() {
  yield takeEvery(types.SKU_EDIT_DATA, WorkSkuEditData);
}
function* watchSkuViewData() {
  yield takeEvery(types.SKU_VIEW_DATA, WorkSkuViewData);
}
function* watchCategoryBySkuData() {
  yield takeEvery(types.CATEGORY_BY_SKU_DATA, WorkCategoryBySkuData);
}
function* watchCategorysData() {
  yield takeEvery(types.CATEGORYS_DATA, WorkCategoryData);
}
function* watchCategoryDeleteData() {
  yield takeEvery(types.CATEGORY_DELETE_DATA, WorkCategoryDeleteData);
}
function* watchCategoryViewData() {
  yield takeEvery(types.CATEGORY_VIEW_DATA, WorkCategoryViewData);
}
function* watchCategoryEditData() {
  yield takeEvery(types.CATEGORY_EDIT_DATA, WorkCategoryEditData);
}
function* watchUploadCancelledCheque() {
  yield takeEvery(types.UPLOAD_CANCELLED_CHEQUE, workUploadCancelledCheque);
}
function* watchUploadKycDocument() {
  yield takeEvery(types.UPLOAD_KYC_DOCUMENT, workUploadKycDocuments);
}
function* watchSosData() {
  yield takeEvery(types.SOS_REQUEST, getSosData);
}
function* watchSosDelete() {
  yield takeEvery(types.SOS_UPDATE_REQUEST, updateSos);
}
function* watchOrderData() {
  yield takeEvery(types.ORDERS_DATA, GetOrderData);
}

function* watchRepeatOrder() {
  yield takeEvery(types.REPEAT_ORDER, middlewareRepeatOrder);
}
function* watchOrderViewData() {
  yield takeEvery(types.ORDER_VIEW_DATA, middlewareOrderViewDaata);
}
function* watchApproveOrder() {
  yield takeEvery(types.APPROVE_ORDER, middlewareApproveOrder);
}
function* watchOrderCancel() {
  yield takeEvery(types.ORDER_CANCEL, middlewareOrderCancel);
}
function* watchBuyerSearch() {
  yield takeEvery(types.BUYER_SEARCH, middlewareBuyerSearch);
}
function* watchOrderUpdate() {
  yield takeEvery(types.ORDER_UPDATE, middlewareOrderUpdate);
}
function* watchAddTransport() {
  yield takeEvery(types.ADD_TRANSPORT, middlewareAddTransport);
}
function* watchAddDiscount() {
  yield takeEvery(types.ORDER_DISCOUNT, middlewareAddDiscount);
}
function* watchIssuePaymentLink() {
  yield takeEvery(types.ISSUE_PAYMENT_LINK, middlewarePaymentLink);
}
function* watchPaymentIntiate() {
  yield takeEvery(types.INTIATE_REFUND, middlewarePaymentIntiate);
}
function* watchResendPayment() {
  yield takeEvery(types.RESEND_PAYMENT, middlewareResendPayment);
}
function* watchFetchEnum() {
  yield takeEvery(types.ENUM, fetchEnum);
}
function* watchFetchEnumEdit() {
  yield takeEvery(types.ENUM_EDIT, enumEditData);
}
function* watchCustomerVerifyequest() {
  yield takeEvery(types.CUSTOMER_VERIFICATION, customerViewVerifyFunction);
}
function* watchGettingAllSos() {
  yield takeEvery(types.GET_SOS_TYPES, GettingAllSOSTypes);
}
function* watchCreatingAllSos() {
  yield takeEvery(types.CREATE_SOS, creatingSos);
}
function* watchGetBuyerInfo() {
  yield takeEvery(types.GET_BUYER_INFO, getBuyerInfo);
}
function* watchSendLoginOtp() {
  yield takeEvery(types.SEND_OTP_LOGIN, sendLoginOtp);
}
function* watchVerifyLoginOtp() {
  yield takeEvery(types.VERIFY_OTP_LOGIN, VerifyLoginOtp);
}

function* watchAllAdminData() {
  yield takeEvery(types.ADMIN_ALL_DATA, workFetchAllAdmin);
}

function* watchDeleteAdminData() {
  yield takeEvery(types.ADMIN_DELETE_DATA, workDeleteAdminData);
}

function* workCreateAdmin() {
  yield takeEvery(types.ADMIN_CREATE_DATA, workCreateAdminData);
}

function* worksingleAdmin() {
  yield takeEvery(types.ADMIN_VIEW_DATA, singleAdmin);
}

function* workCreateCart() {
  yield takeEvery(types.CREATE_CART, createCart);
}
function* workGetCart() {
  yield takeEvery(types.GET_CART, GetCart);
}
function* workCreateOrder() {
  yield takeEvery(types.PLACE_ORDER, PlaceOrder);
}

function* workDeleteCart() {
  yield takeEvery(types.DELETE_CART, DeleteCart);
}

function* workGetApproval() {
  yield takeEvery(types.GET_APPROVAL, GettingApproval);
}

function* workAcknowledgeApproval() {
  yield takeEvery(types.ACKNOWLEDGE_APPROVAL, AcknoledgingApproval);
}

function* workGettingAllPayments() {
  yield takeEvery(types.GET_PAYMENTS, getAllPayments);
}
function* watchSellerCountData() {
  yield takeEvery(types.SELLER_COUNT_DATA, middlewareSellerCountData);
}
function* watchProductCountData() {
  yield takeEvery(types.PRODUCT_COUNT_DATA, middlewareProductCountData);
}
function* watchFetchSellerByCategory() {
  yield takeEvery(
    types.SELLER_BY_CATEGORY_DATA,
    middlewareFetchSellerByCategory
  );
}
function* watchFetchSellerAssignedByCategory() {
  yield takeEvery(
    types.SELLER_ASSIGNED_BY_CATEGORY,
    middlewareFetchSellerAssignedByCategory
  );
}

function* watchAddMultipleSeller() {
  yield takeEvery(types.MULTLI_SELLER_MAPPED, middlewareAddMultipleSeller);
}
function* watchAddMultipleSellerByCategory() {
  yield takeEvery(
    types.MULTLI_SELLER_MAPPED_CATEGORY,
    middlewareAddMultipleSellerByCategory
  );
}

function* watchUpdateLimraRate() {
  yield takeEvery(types.UPDATE_LIMRA_RATE, middlewareUpdateLimraRate);
}

export default function* rootSaga() {
  yield all([
    fork(watchfetchrequest),
    fork(watchfetchrequestDashboard),
    fork(watchCustomerViewRequest),
    fork(watchCustomerSortData),
    fork(watchUserRequestData),
    fork(watchUserViewData),
    fork(watchUserRequestDeleteData),
    fork(watchUserRequestEditData),
    fork(watchUserLogin),
    fork(watchProductSortData),
    fork(watchProductFilterData),
    fork(watchProductFilterSkuData),
    fork(watchCustomerEditData),
    fork(watchDeliverySlotList),
    fork(watchRemoveDeliverySlot),
    fork(watchPreApprovedProductData),
    fork(watchDeletePreApprovedProduct),
    fork(watchHistoryPreApprovedProduct),
    fork(watchEditPreApprovedProduct),
    fork(watchAddPreApprovedProduct),
    fork(watchUserEditData),
    fork(watchCategoryData),
    fork(watchUserCountData),
    fork(watchCustomerDeleteData),
    fork(watchSellerData),
    fork(watchSyncAllSellerToVinc),
    fork(watchFetchSellerData),
    fork(watchFetchProductCateogry),
    fork(watchFetchSkuByID),
    fork(watchProductCreate),
    fork(watchProductAddCategory),
    fork(watchProductGetSkus),
    fork(watchProductAddSku),
    fork(watchCustomerAddition),
    fork(watchProductDeleteData),
    fork(watchProductViewData),
    fork(watchProductEditData),
    fork(watchVerifyAccount),
    fork(watchTriggerOtp),
    fork(watchVerifyOtp),
    fork(watchTriggerKycForm),
    fork(watchSkuList),
    fork(watchSyncAllSku),
    fork(watchSkuDeleteData),
    fork(watchSkuEditData),
    fork(watchSkuViewData),
    fork(watchCategoryBySkuData),
    fork(watchCategorysData),
    fork(watchCategoryDeleteData),
    fork(watchCategoryViewData),
    fork(watchCategoryEditData),
    fork(watchUploadCancelledCheque),
    fork(watchUploadKycDocument),
    fork(watchSosData),
    fork(watchSosDelete),
    fork(watchOrderData),
    fork(watchRepeatOrder),
    fork(watchOrderViewData),
    fork(watchApproveOrder),
    fork(watchOrderCancel),
    fork(watchBuyerSearch),
    fork(watchOrderUpdate),
    fork(watchAddTransport),
    fork(watchAddDiscount),
    fork(watchIssuePaymentLink),
    fork(watchPaymentIntiate),
    fork(watchResendPayment),
    fork(watchFetchEnum),
    fork(watchFetchEnumEdit),
    fork(watchCustomerVerifyequest),
    fork(watchGettingAllSos),
    fork(watchCreatingAllSos),
    fork(watchGetBuyerInfo),
    fork(watchSendLoginOtp),
    fork(watchVerifyLoginOtp),
    fork(watchAllAdminData),
    fork(watchDeleteAdminData),
    fork(workCreateAdmin),
    fork(worksingleAdmin),
    fork(workCreateCart),
    fork(workGetCart),
    fork(workCreateOrder),
    fork(workDeleteCart),
    fork(workGetApproval),
    fork(workAcknowledgeApproval),
    fork(workGettingAllPayments),
    fork(watchSellerCountData),
    fork(watchProductCountData),
    fork(watchFetchSellerByCategory),
    fork(watchFetchSellerAssignedByCategory),
    fork(watchAddMultipleSeller),
    fork(watchAddMultipleSellerByCategory),
    fork(watchUpdateLimraRate),
  ]);
}
