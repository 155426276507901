import React, { Component, createRef } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Accordion,
  AccordionHeader,
  AccordionBody,
  AccordionItem,
  Table,
} from "reactstrap";
import detailsslidesmallimage from "../../../images/detailsslidesmallimage.png";
import vector from "../../../images/Edit icon.svg";
import { connect } from "react-redux";
import {
  fetchOrderViewData,
  orderCancel,
  orderUpdate,
  addTransport,
  issuePaymentLink,
  intiateRefund,
  resendPayment,
  orderDiscount,
  getApproval,
} from "../../../store/actions";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { EXTRACT_NUMBER_REGEX } from "../../../common/components/common/Rejex";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import bagIcons from "../../../images/Order bag icon.svg";
import deleteIcons from "../../../images/Trash icon.svg";
import VoucherCode from "./VoucherCode";
import AddProducts from "./AddProducts";
import { GenerateQr } from "../../../common/components/GenerateQr";
import { PaymentHistory } from "./paymentHistory";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import {
  TRANSACTION_NUMBER,
  FOUR_DIGIT_REGEX,
  NUMBER_REGEX,
  QUANTITY_PRICE_REGEX,
  ORDER_QUANTIY_REGEX,
  ORDER_PRICE_REGEX,
} from "../../../common/components/common/Rejex";
import {
  handleErrorMsg,
  handleKeyPress,
  handleKeyPressDotValid,
} from "../../../common/components/common/GlobalFunctions";
import Limra from "../../../images/Limra.png";
import plusIcon from "../../../images/plusicon.svg";
import OrderedProducts from "./OrderedProducts";
class Orderdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      trackNumber: 0,
      deliveryDate: "",
      isEditMode: false,
      createdAt: "",
      price: "",
      staticOrderedProductList: [],
      orderedProductList: [],
      modifiedProducts: {},
      totalOrderedCost: "",
      showModal: false,
      showDiscountModal: false,
      totalProductValue: "",
      commission: "",
      totalCost: "",
      packagingCost: "",
      addProducts: false,
      deleteConfirmModal: false,
      orderId: "",
      orderStatus: "",
      paymentStatus: "",
      productToDeleteId: "",
      vehicleNumber: "",
      driverName: "",
      mobileNumber: "",
      liveTrackingLink: "",
      isVehicleValid: false,
      isMobileValid: false,
      netDifference: "",
      payLinkId: "",
      rates: [],
      amount: 0,
      orderCancelPopUp: false,
      orderLogs: [],
      accordianForLogs: false,
      orderLogsObjForUpdated: {},
      cartId: {},
      mappedOriginalProductDetails: {},
      mappedDiscountProductDetails: [],
      paymentHistoryModal: false,
      paymentsData: [],
      refundsData: [],
      offlinePaymentData: { paymentMedium: "cash" },
      dataLoading: false,
      horeca: false,
      todayDate: "",
      sampleOrder: "",
      couponName: "",
      couponAmount: "",
      accordianForLogs1: undefined,
    };
    this.orderQrCanvas = createRef(null);
  }
  updateTodayDate() {
    if (this.state.todayDate.length < 1) {
      // let dateWithZeroes = new Date()
      //   .toLocaleDateString()
      //   ?.split("/")
      let year = `${new Date().getFullYear()}`;
      let month = `${new Date().getMonth() + 1}`;
      let day = `${new Date().getDate()}`;

      if (day.length < 2) day = `0${day}`;
      if (month.length < 2) month = `0${month}`;

      let dateWithZeroes = `${year}-${month}-${day}`;
      // console.log(dateWithZeroes);
      this.setState({
        todayDate: dateWithZeroes,
      });
    }
  }
  componentDidUpdate = () => {
    this.updateTodayDate();
  };

  componentDidMount = () => {
    this.updateTodayDate();
    this.OrderInfo();
  };
  OrderInfo = () => {
    // console.log("length", this.state.staticOrderedProductList.length);
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("isEditMode");
    this.setState({
      isEditMode: myParam,
      dataLoading: true,
    });
    var queryParams = this.props?.computedMatch?.params?.orderid;
    if (queryParams) {
      this.props?.fetchOrderViewData(queryParams, (res) => {
        const arrProductList = [];
        if (
          arrProductList.length === 0 &&
          this.props?.orderViewData?.singleOrder?.orderedProductList
        ) {
          for (
            let i = 0;
            i <
            this.props?.orderViewData?.singleOrder?.orderedProductList?.length;
            i++
          ) {
            let data = {
              ...this.props?.orderViewData?.singleOrder?.orderedProductList[i],
            };
            data["weight" + i] = true;
            data["rate" + i] = true;
            arrProductList.push(data);
          }
        }
        this.setState(
          {
            dataLoading: false,
            id: this.props?.orderViewData?.singleOrder?._id,
            deliveryDate: this.props?.orderViewData?.singleOrder?.deliveryDate
              ? moment(
                  this.props?.orderViewData?.singleOrder?.deliveryDate
                ).format("ll")
              : "N/A",
            createdAt: this.props?.orderViewData?.singleOrder?.createdAt
              ? moment(
                  this.props?.orderViewData?.singleOrder?.createdAt
                ).format("L")
              : "N/A",
            orderedProductList: arrProductList.length > 0 ? arrProductList : [],
            staticOrderedProductList:
              arrProductList.length > 0 &&
              this.state.staticOrderedProductList.length < 1
                ? arrProductList
                : this.state.staticOrderedProductList,
            orderLogs: this.props?.orderViewData?.singleOrder?.orderLogs,
            cartId: this.props?.orderViewData?.singleOrder?.cartId,
            totalOrderedCost:
              this.props?.orderViewData?.singleOrder?.totalOrderedCost,
            totalProductValue:
              this.props?.orderViewData?.singleOrder?.totalProductValue,
            paymentsData: this.props?.orderViewData?.singleOrder?.payments,
            refundsData: this.props?.orderViewData?.singleOrder?.refunds,
            commission: this.props?.orderViewData?.singleOrder?.commission
              ? this.props?.orderViewData?.singleOrder?.commission
              : 0,
            totalCost: this.props?.orderViewData?.singleOrder?.totalCost
              ? this.props?.orderViewData?.singleOrder?.totalCost
              : "",
            packagingCost: this.props?.orderViewData?.singleOrder?.packagingCost
              ? this.props?.orderViewData?.singleOrder?.packagingCost
              : 0,
            transportCost: this.props?.orderViewData?.singleOrder?.transportCost
              ? this.props?.orderViewData?.singleOrder?.transportCost
              : 0,
            orderId: this.props?.orderViewData?.singleOrder?.orderId
              ? this.props?.orderViewData?.singleOrder?.orderId
              : "",
            orderStatus: this.props?.orderViewData?.singleOrder?.orderStatus
              ? this.props?.orderViewData?.singleOrder?.orderStatus
              : "",
            sampleOrder: this.props?.orderViewData?.singleOrder?.sampleOrder
              ? this.props?.orderViewData?.singleOrder?.sampleOrder
              : "",
            couponName: this.props?.orderViewData?.singleOrder?.couponCode
              ? this.props?.orderViewData?.singleOrder?.couponCode?.couponName
              : "",
            couponAmount: this.props?.orderViewData?.singleOrder?.couponCode
              ? this.props?.orderViewData?.singleOrder?.couponCode?.couponAmount
              : "",
            paymentStatus: this.props?.orderViewData?.singleOrder?.paymentStatus
              ? this.props?.orderViewData?.singleOrder?.paymentStatus
              : "",
            netDifference:
              this.props?.orderViewData?.singleOrder?.netDifference,
            payLinkId: this.props?.orderViewData?.singleOrder?.payLinkId
              ? this.props?.orderViewData?.singleOrder?.payLinkId
              : "",
            rates: this.props?.orderViewData?.singleOrder?.rates
              ? this.props?.orderViewData?.singleOrder?.rates
              : "",
            horeca: this.props?.orderViewData?.singleOrder
              ?.orderedProductList[0]?.productId?.Horeca
              ? this.props?.orderViewData?.singleOrder?.orderedProductList[0]
                  ?.productId?.Horeca
              : false,
          },
          () => this.filterOrderLogs()
        );
      });
    }
  };
  filterOrderLogs() {
    // filtering/arranging for cartOriginalData
    let originalProductCost = {};
    this.state.cartId?.productList?.forEach((a) => {
      originalProductCost[a?.productId?._id] = a;
    });
    this.setState({ mappedOriginalProductDetails: originalProductCost });

    // filtering/arranging for orderUpdatedData and orderDiscountedData
    let updatedOne = [];
    let discountOne = [];
    this.state.orderLogs.forEach((a) => {
      if (a?.type === "Updated") updatedOne.push(a);
      if (a?.type === "Discounted") discountOne.push(a);
    });

    // console.log(updatedOne);
    let objForUpdatedProducts = {};
    updatedOne.forEach((a) => {
      a?.updatedData?.orderedProductList.forEach((b) => {
        objForUpdatedProducts[b.productId] = objForUpdatedProducts[b.productId]
          ? [
              ...objForUpdatedProducts[b.productId],
              { ...b, updatedBy: a?.updatedBy, createdAt: a?.createdAt },
            ]
          : [{ ...b, updatedBy: a?.updatedBy, createdAt: a?.createdAt }];
      });
    });
    this.setState({
      mappedOriginalProductDetails: originalProductCost,
      orderLogsObjForUpdated: objForUpdatedProducts,
      mappedDiscountProductDetails: discountOne,
    });
  }
  componentDidUpdate() {
    if (
      this.props?.orderViewData?.singleOrder?._id &&
      this.state.id !== this.props?.orderViewData?.singleOrder?._id &&
      this.state.trackNumber < 1
    ) {
      this.setState({ trackNumber: 1 }, () => {
        this.OrderInfo();
      });
    }
  }
  cancelOrder = (status) => {
    let orderActions;
    if (status === "Cancel") {
      orderActions = "adminCancelsOrder";
    } else if (status === "Finalised") {
      orderActions = "adminFinalizesOrder";
    } else {
      if (status === "Transport") {
        orderActions = "transportDetailsAdded";
      }
    }
    let data = {
      addedProducts: [],
      deletedProducts: [],
      modifiedProducts: [],
    };
    this.setState({
      dataLoading: true,
    });
    this.props?.orderUpdate(data, this.state.id, orderActions, (res) => {
      if (status !== "Transport") {
        if (
          res.message === "Order has been updated" ||
          res.message === "Order has been finalized"
        ) {
          NotificationManager.success(res.message, "Order Updated");
          if (status === "Cancel") {
            this.props.history.push("/Orders");
          } else {
            this.OrderInfo();
          }
        } else {
          // NotificationManager.error(res.message, "Error");
          handleErrorMsg(res);
        }
      } else {
        this.OrderInfo();
      }
      this.setState({
        dataLoading: false,
      });
    });
  };
  hideConfirmModalToggle = () => {
    this.setState({
      showModal: !this.state.showModal,
      vehicleNumber: "",
      mobileNumber: "",
      liveTrackingLink: "",
      driverName: "",
      isVehicleValid: false,
      isMobileValid: false,
    });
  };
  hideConfirmDiscountModalToggle = () => {
    this.setState({
      showDiscountModal: !this.state.showDiscountModal,
      amount: "",
    });
  };
  hideConfirmModalToggleProduct = () => {
    this.setState({ addProducts: !this.state.addProducts });
  };
  deleteConfirmModalToggle = () => {
    this.setState({
      deleteConfirmModal: !this.state.deleteConfirmModal,
    });
  };
  editPrice = (i, val) => {
    let dataup = [...this.state.orderedProductList];
    dataup[i][val + i] = false;
    this.setState({
      orderedProductList: dataup,
    });
  };
  orderUpdate = (i, name, value) => {
    let compareData = this.state.staticOrderedProductList;
    let dataUsing = this.state.orderedProductList;
    let dataup = this.state.modifiedProducts;
    dataUsing[i][name] = value;

    // console.log(compareData);
    // console.log(value, compareData[i][name], value == compareData[i][name]);
    // if (value == compareData[i][name]) {
    //   delete dataup[i];
    // } else {
    dataup[i] = dataUsing[i];
    dataup[i][name] = value;
    // }

    this.setState({
      modifiedProducts: { ...Object.values(dataup) },
      orderedProductList: dataUsing,
    });
    // console.log(this.state.orderedProductList, this.state.modifiedProducts);
  };
  updateOrderRequest = () => {
    let orderActions =
      this.state.orderStatus === "Placed"
        ? "adminUpdatesOrder"
        : "adminUpdatesOrder";
    const modifiedOrders = [];
    Object.values(this.state?.modifiedProducts).forEach((ele) => {
      if (ele && ele.productId)
        modifiedOrders.push({
          productId: ele?.productId?._id,
          quantity: parseFloat(ele?.quantity),
          rate: parseFloat(ele?.rate),
        });
    });

    let data = {
      addedProducts: [],
      deletedProducts: [],
      modifiedProducts: modifiedOrders,
    };
    this.setState({
      dataLoading: true,
    });
    this.props?.orderUpdate(data, this.state.id, orderActions, (res) => {
      if (res && res.message === "Order has been updated") {
        NotificationManager.success(res.message, "Order Updated");
        this.setState(
          {
            modifiedProducts: {},
            staticOrderedProductList: this.state.orderedProductList,
          },
          () => {
            this.OrderInfo();
          }
        );
      } else {
        // NotificationManager.error(res.message, "Error");
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
  };
  onDeleteProductRequest(id) {
    let orderActions = "adminUpdatesOrder";
    let data = {
      addedProducts: [],
      deletedProducts: [
        {
          productId: id,
        },
      ],
      modifiedProducts: [],
    };
    this.setState({
      dataLoading: true,
    });
    this.props?.orderUpdate(data, this.state.id, orderActions, (res) => {
      if (res && res.message === "Order has been updated") {
        NotificationManager.success(res.message, "Order Updated");
        this.OrderInfo();
      } else {
        // NotificationManager.error(res.message, "Error");
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
  }
  onChange = (name, val) => {
    this.setState({
      [`${name}`]: val,
    });
  };
  addTransport = () => {
    let data = {
      vehicleNumber: this.state.vehicleNumber ? this.state.vehicleNumber : "",
      driverName: this.state.driverName ? this.state.driverName : "",
      mobileNumber: this.state.mobileNumber ? this.state.mobileNumber : "",
      liveTrackingLink: this.state.liveTrackingLink
        ? this.state.liveTrackingLink
        : "",
    };
    this.setState({
      dataLoading: true,
    });
    this.props?.addTransport(
      this.state.id ? this.state.id : "",
      data,
      (res) => {
        if (res.data) {
          NotificationManager.success(
            "Transport Added Successfully",
            "Transport Added"
          );
          this.cancelOrder("Transport");
        } else {
          // NotificationManager.error(res.message, "Error");
          handleErrorMsg(res);
        }
        this.setState({
          dataLoading: false,
        });
      }
    );
    this.hideConfirmModalToggle();
  };
  addDiscount = () => {
    let originalCost =
      this.props?.orderViewData?.singleOrder?.originalCost ?? 0;
    if (this.state.amount <= originalCost && this.state.amount > 0) {
      this.setState({
        dataLoading: true,
      });
      let payload = {
        amount: this.state.amount ? parseFloat(this.state.amount) : 0,
      };
      this.props?.orderDiscount(payload, this.state.id, (res) => {
        if (res && res.message === "Discount Added Successfully") {
          NotificationManager.success(res.message, "Discount Added");
          this.OrderInfo();
        } else {
          // NotificationManager.error(res.message, "Error");
          handleErrorMsg(res);
        }
        this.setState({
          dataLoading: false,
        });
      });
      this.setState({ showDiscountModal: false, amount: "" });
    } else {
      NotificationManager.error(
        "Discount can not be higher than total amount!",
        "Error"
      );
    }
  };

  paymentLink = (e) => {
    e.preventDefault();
    this.setState({
      dataLoading: true,
    });
    // if (this.state.payLinkId) {
    //   let payload = {
    //     orderId: this.state.id,
    //   };
    //   this.props?.resendPayment(payload, (res) => {
    //     if (res && res.message === "Payment Link has been sent successfully") {
    //       NotificationManager.success(res.message, "Resend Payment");
    //       this.props?.history.push("/Orders");
    //     } else {
    //       NotificationManager.error(res.message, "Error");
    //     }
    //   });
    // } else {
    this.props?.issuePaymentLink(this.state.id, (res) => {
      if (
        res &&
        [
          "Payment Link has been sent successfully",
          "Payment link has been sent successfully",
          "Payment has been created successfully",
        ].includes(res?.message)
      ) {
        NotificationManager.success(res?.message, "Success");
        this.props?.history.push("/Orders");
      } else {
        // NotificationManager.error(res?.message, "Error");
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
    // }
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  approvaltoggle = () => {
    this.setState({
      approvalmodal: !this.state.approvalmodal,
    });
  };
  paymentRefund = (e) => {
    this.setState({
      dataLoading: true,
    });
    e.preventDefault();
    const payload = {
      orderId: this.state.id ? this.state.id : "",
    };
    this.props?.intiateRefund(payload, (res) => {
      if (res && res.message === "Refund has been created successfully") {
        NotificationManager.success(res.message, "Payment Refund successfully");
        this.props.history.push("/Orders");
      } else {
        // NotificationManager.error(res.message, "Error");
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
  };
  cancelOrderToggle = () => {
    this.setState({
      orderCancelPopUp: !this.state.orderCancelPopUp,
    });
  };
  percentageCommission = (commisionName) => {
    let commisionValue = "x";
    if (commisionName && this.state.rates.length > 0) {
      const filtercommisionValue = this.state.rates.filter(
        (ele) => ele.EnumType === commisionName
      )[0]?.EnumValue;

      commisionValue = filtercommisionValue ? filtercommisionValue : "x";
    }
    return commisionValue;
  };
  setStateForOfflinePayment = (key, valueOrFiles) => {
    let obj = {};
    obj[key] = valueOrFiles;
    this.setState({
      offlinePaymentData: { ...this.state.offlinePaymentData, ...obj },
    });
  };
  onChange(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }
  getApprovalOfflinePayment = (e) => {
    e.preventDefault();
    // console.log(this.state.offlinePaymentData);
    // console.log(Object.entries(this.state.offlinePaymentData));
    let form = new FormData();

    let DataArray = Object.entries(this.state.offlinePaymentData);
    // console.log(DataArray);
    while (DataArray.length > 0) {
      let value = DataArray.shift();
      // console.log(value,value[0],value[1]);

      if (Array.isArray(value[1])) {
        while (value[1].length > 0) {
          form.append(value[0], value[1].shift());
        }
      } else {
        form.append(value[0], value[1]);
      }
    }
    this.setState({
      dataLoading: true,
    });
    this.props.getApproval(form, this.state.id, (res) => {
      if (res && res?.message === "Payment has been created successfully") {
        NotificationManager.success(res?.message, "success");
        this.setState({ offlinePaymentData: { paymentMedium: "cash" } });
        this.toggle();
        this.approvaltoggle();
      } else {
        // NotificationManager.error(
        //   Array.isArray(res?.message)
        //     ? res?.message[0]
        //     : res?.message?.message || res?.message,
        //   "Error"
        // );
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
  };
  accordionToggle = (id) => {
    if (this.state.accordianForLogs1 == id) {
      this.setState({
        accordianForLogs1: undefined,
      });
    } else {
      this.setState({
        accordianForLogs1: id,
      });
    }
  };
  productDelete(id){
    this.setState(
      {
          productToDeleteId:
              id,
      },
      () => {
          this.deleteConfirmModalToggle();
      }
  );
  }
  render() {
    const { orderStatus, paymentStatus } = this.state;
    return (
      <>
        <div className="mainParentSectionDiv">
          {this.state.dataLoading ? (
            <div className="ViewPagesLoder1">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <div className="cancelOrderwarning">
                <Modal
                  funk={true}
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                >
                  <ModalHeader toggle={this.toggle}>
                    Offline Payment
                  </ModalHeader>
                  <ModalBody>
                    <Col xs={12} sm={12} md={12} className="mb-15">
                      <FormGroup>
                        <Label for="amt" className="orderlable">
                          Amount <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          // onChange={(e) =>{this.setState({"amount": e.target.value})}
                          // onChange={(e) => this.setState({amount : e.target.value})}
                          onKeyPress={(evt) => {
                            handleKeyPress(evt);
                          }}
                          onChange={(e) => {
                            // console.log(this.state.totalCost);
                            if (
                              QUANTITY_PRICE_REGEX.test(e.target.value) &&
                              e.target.value.length <= 7 &&
                              e.target.value <= this.state.totalCost
                            ) {
                              this.setStateForOfflinePayment(
                                "amount",
                                e.target.value
                              );
                            } else {
                              this.setStateForOfflinePayment("amount", "");
                            }
                          }}
                          value={this.state.offlinePaymentData["amount"]}
                          id="amt"
                          name="amt"
                          placeholder="Type..."
                          type="number"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={12} className="mb-15">
                      <FormGroup>
                        <Label for="amt" className="orderlable">
                          Date<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          onChange={(e) =>
                            this.setStateForOfflinePayment(
                              "paymentDate",
                              e.target.value
                            )
                          }
                          value={this.state.offlinePaymentData["paymentDate"]}
                          id="date"
                          name="date"
                          placeholder="Type..."
                          type="date"
                          max={`${this.state.todayDate}`}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={12} className="mb-15">
                      <FormGroup>
                        <Label for="paymethod" className="orderlable">
                          {" "}
                          Payment Method<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          id="paymethod"
                          name="paymethod"
                          placeholder="Type..."
                          type="select"
                          value={this.state.offlinePaymentData["paymentMedium"]}
                          onChange={(e) =>
                            this.setStateForOfflinePayment(
                              "paymentMedium",
                              e.target.value
                            )
                          }
                        >
                          <option value="cash">Cash</option>
                          <option value="upi">UPI</option>
                          <option value="netbanking">Net Banking</option>
                          <option value="wallet">Wallet</option>
                          <option value="card">Card</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={12} className="mb-15">
                      <FormGroup>
                        <Label for="amt" className="orderlable">
                          Transaction Ref No
                        </Label>
                        <Input
                          value={
                            this.state.offlinePaymentData["TransactionRefNo"]
                          }
                          // maxLength={25}
                          onChange={(e) => {
                            this.setStateForOfflinePayment(
                              "TransactionRefNo",
                              e.target.value
                            );
                            this.onChange("TransactionRefNo", e.target.value);
                          }}
                          invalid={
                            TRANSACTION_NUMBER.test(
                              this.state.offlinePaymentData["TransactionRefNo"]
                            )
                              ? false
                              : true
                          }
                          id="trancno"
                          name="trancno"
                          placeholder="Type..."
                          type="text"
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} sm={12} md={12} className="mb-15">
                      <FormGroup>
                        <Label for="amt" className="orderlable">
                          Payment Proof<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          id="payproof"
                          name="payproof"
                          placeholder="Type..."
                          type="file"
                          multiple={true}
                          onChange={(e) =>
                            this.setStateForOfflinePayment("paymentProof", [
                              ...e?.target?.files,
                            ])
                          }
                        />
                      </FormGroup>
                    </Col>
                  </ModalBody>
                  <ModalFooter className="footermodal">
                    <Button
                      color="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          approvalmodal: true,
                        });
                      }}
                      className="offlinepaymentbtn"
                    >
                      Get Approval
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>

              <div className="cancelOrderwarning">
                <Modal
                  isOpen={this.state.orderCancelPopUp}
                  toggle={this.cancelOrderToggle}
                  className={this.props.className}
                  size="md"
                >
                  <ModalHeader toggle={this.cancelOrderToggle}>
                    Confirm
                  </ModalHeader>
                  <ModalBody>
                    Are you sure you want to cancel the order?
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      onClick={() => {
                        // this.onDeleteCustomerRequest(this.state.customerToDeleteId);
                        this.cancelOrder("Cancel");
                        this.cancelOrderToggle();
                      }}
                    >
                      OK
                    </Button>{" "}
                    <Button color="secondary" onClick={this.cancelOrderToggle}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>

              <div className="cancelOrderwarning">
                <Modal
                  funk={true}
                  isOpen={this.state.approvalmodal}
                  toggle={this.approvaltoggle}
                >
                  <ModalHeader toggle={this.approvaltoggle}>
                    Get Approval
                  </ModalHeader>
                  <ModalBody>
                    Are you sure you want to Approve the payment
                  </ModalBody>
                  <ModalFooter className="footermodal">
                    <Button onClick={(e) => this.getApprovalOfflinePayment(e)}>
                      Get Approval
                    </Button>
                    <Button
                      color="secondary"
                      onClick={this.approvaltoggle}
                      className="offlinepaymentbtn"
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>

              <div className="modalDriver">
                <Modal
                  isOpen={this.state.showModal}
                  toggle={this.hideConfirmModalToggle}
                  className={"modalDriverInner"}
                  size="md"
                >
                  <ModalHeader toggle={this.hideConfirmModalToggle}>
                    Add Transport
                  </ModalHeader>
                  <div className="popupFormParentDivCss">
                    <div className="popupFormDivsCss">
                      <label>Vehicle Number</label>
                      &nbsp; <span>*</span>
                      <input
                        type="text"
                        value={
                          this.state.vehicleNumber
                            ? this.state.vehicleNumber
                            : ""
                        }
                        onChange={(e) => {
                          const vehicleRegex1 =
                            /^[A-Z]{2}[0-9]{2}[A-Z]{1}[0-9]{4}$/;
                          const vehicleRegex2 =
                            /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/;
                          if (
                            vehicleRegex1.test(e.target.value) ||
                            vehicleRegex2.test(e.target.value)
                          ) {
                            this.setState({
                              isVehicleValid: false,
                              vehicleNumber: e.target.value,
                            });
                          } else {
                            this.setState({
                              isVehicleValid: true,
                              vehicleNumber: e.target.value,
                            });
                          }
                        }}
                      />
                      {this.state.isVehicleValid && (
                        <p className="novehicleValid">
                          Vehicle Number is not Valid
                        </p>
                      )}
                    </div>
                    <div className="popupFormDivsCss">
                      <label>Driver Name</label>
                      &nbsp; <span>*</span>
                      <input
                        type="text"
                        value={
                          this.state.driverName ? this.state.driverName : ""
                        }
                        onChange={(e) =>
                          this.onChange("driverName", e.target.value)
                        }
                      />
                    </div>
                    <div className="popupFormDivsCss">
                      <label>Mobile Number</label>
                      &nbsp; <span>*</span>
                      <input
                        type="text"
                        value={
                          this.state.mobileNumber ? this.state.mobileNumber : ""
                        }
                        maxLength={10}
                        onChange={(e) => {
                          let regularExpressionNumber = /^[0-9]*$/;
                          if (regularExpressionNumber.test(e.target.value)) {
                            this.setState({
                              isMobileValid: false,
                              mobileNumber: e.target.value,
                            });
                          } else {
                            this.setState({
                              isMobileValid: true,
                              mobileNumber: e.target.value,
                            });
                          }
                        }}
                      />
                      {this.state.isMobileValid && (
                        <p className="novehicleValid">
                          Mobile Number is not Valid
                        </p>
                      )}
                    </div>
                    <div className="popupFormDivsCss">
                      <label>Live location Tracking Link</label>
                      <input
                        type="text"
                        value={
                          this.state.liveTrackingLink
                            ? this.state.liveTrackingLink
                            : ""
                        }
                        onChange={(e) =>
                          this.onChange("liveTrackingLink", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {/* <ModalFooter> */}
                  <div className="">
                    <Button
                      className="btn darkBtn locationbtn"
                      disabled={
                        this.state.isEditMode === "false"
                          ? true
                          : !(
                              (
                                orderStatus === "Finalized" &&
                                this.state.driverName &&
                                this.state.mobileNumber &&
                                this.state.vehicleNumber &&
                                !this.state.isVehicleValid &&
                                !this.state.isMobileValid &&
                                this.state.mobileNumber.length === 10
                              )
                              // && this.state.netDifference === 0
                            )
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        // this.checkVehicleRegex(this.state.vehicleNumber);
                        this.addTransport();
                      }}
                    >
                      Submit
                    </Button>{" "}
                  </div>
                  {/* </ModalFooter> */}
                </Modal>
                <Modal
                  isOpen={this.state.showDiscountModal}
                  toggle={this.hideConfirmDiscountModalToggle}
                  className={"modalDriverInner"}
                  size="md"
                >
                  <ModalHeader toggle={this.hideConfirmDiscountModalToggle}>
                    Add Discount
                  </ModalHeader>
                  <div className="popupFormParentDivCss">
                    <div className="popupFormDivsCss">
                      <label>Amount</label>
                      <input
                        type="number"
                        maxLength="10"
                        value={this.state.amount}
                        // onChange={(e) => {
                        //   if (e.target.value <= this.state.totalCost) {
                        //     this.onChange("amount", e.target.value);
                        //   }
                        // }}
                        onKeyPress={(evt) => {
                          handleKeyPress(evt);
                        }}
                        onChange={(e) => {
                          // console.log(this.state.totalCost);
                          let originalCost =
                            this.props?.orderViewData?.singleOrder
                              ?.originalCost;
                          if (
                            e.target.value.length <= 7 &&
                            e.target.value <= originalCost
                          ) {
                            this.onChange("amount", e.target.value);
                          }
                          // else {
                          //   this.orderUpdate(i, "rate", "");
                          // }
                        }}
                      />
                    </div>
                  </div>
                  {/* <ModalFooter> */}
                  <div className="">
                    <Button
                      className="btn darkBtn locationbtn"
                      onClick={(e) => {
                        e.preventDefault();
                        this.addDiscount();
                      }}
                    >
                      Submit
                    </Button>{" "}
                  </div>
                  {/* </ModalFooter> */}
                </Modal>
              </div>

              {this.state.addProducts && (
                <AddProducts
                  onToggle={this.hideConfirmModalToggleProduct}
                  orderModal={this.state.addProducts}
                  orderId={this.state.id}
                  OrderInfo={this.OrderInfo}
                  toggleSpinner={(a) => this.setState({ dataLoading: a })}
                  isHoreca={this.state.horeca}
                  buyerId={this.state.cartId?.userId?._id}
                  isHorecaApproveStatus={this.state.cartId?.userId?.Horeca}
                />
              )}

              {this.state.paymentHistoryModal && (
                <PaymentHistory
                  paymentsData={this.state.paymentsData}
                  refundsData={this.state.refundsData}
                  onToggle={() =>
                    this.setState({
                      paymentHistoryModal: !this.state.paymentHistoryModal,
                    })
                  }
                />
              )}
              <div>
                <Modal
                  isOpen={this.state.deleteConfirmModal}
                  toggle={this.deleteConfirmModalToggle}
                  className={this.props.className}
                  size="md"
                >
                  <ModalHeader toggle={this.deleteConfirmModalToggle}>
                    Confirm
                  </ModalHeader>
                  <ModalBody>Are sure you want to delete?</ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      onClick={() => {
                        this.onDeleteProductRequest(
                          this.state.productToDeleteId
                        );
                        this.deleteConfirmModalToggle();
                      }}
                    >
                      Delete
                    </Button>{" "}
                    <Button
                      color="secondary"
                      onClick={this.deleteConfirmModalToggle}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
              <div className="pd-15 bg-white borderedDiv">
                <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
                  <h4>Order Details</h4>
                  <div>&nbsp;</div>
                  {this.state.sampleOrder ? (
                    <h4>
                      {" "}
                      <Button className="sampleButton mr-4">Sample</Button>
                    </h4>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  <hr />
                  <ul className="d-flex align-items-center justify-content-between orderHeader">
                    <img src={bagIcons} alt="imageLoading" className="bagIcon" />

                    <li>{this.props?.orderViewData?.singleOrder?.buyerName}</li>
                    <li>{this.state.createdAt ? this.state.createdAt : ""}</li>
                    <li>
                      Order ID:<br/>{this.state.orderId ? this.state.orderId : ""}
                    </li>
                    <li>
                      Amount:<br/>
                      {this.props?.orderViewData?.singleOrder?.originalCost ??
                        ""}{" "}
                      Rs
                    </li>
                    <li>
                      Order Status:<br/>
                      {this.state.orderStatus ? this.state.orderStatus : ""}
                    </li>
                    <li>
                      Payment Status:<br/>
                      {this.state.paymentStatus ? this.state.paymentStatus : ""}
                    </li>
                    <li className="borderRightNone paddingPayment">
                      Delivery:<br/>
                      {this.props?.orderViewData?.singleOrder?.deliveryDate
                        ? moment(
                            this.props?.orderViewData?.singleOrder?.deliveryDate
                          ).format("DD/MM/YY")
                        : "N/A"}
                    </li>
                    <li className="borderRightNone">
                      <div className="d-flex flex-row">
                        <div className="LandScapeDisplayNone">
                        <button
                          disabled={
                            this.state.isEditMode === "false"
                              ? true
                              : !(
                                  orderStatus === "Placed" ||
                                  orderStatus === "Updated"
                                )
                          }
                          onClick={() => {
                            this.setState({
                              addProducts: true,
                            });
                          }}
                          className="btn lightBtn"
                        >
                          Add Product
                        </button>
                        </div>
                        <button
                          onClick={() =>
                            this.setState({
                              paymentHistoryModal:
                                !this.state.paymentHistoryModal,
                            })
                          }
                          className="btn lightBtn mx-2"
                        >
                          Payment History
                        </button>
                        <div className="LandScapeDisplay">
                          <img disabled={
                            this.state.isEditMode === "false"
                              ? true
                              : !(
                                  orderStatus === "Placed" ||
                                  orderStatus === "Updated"
                                )
                          }
                          onClick={() => {
                            this.setState({
                              addProducts: true,
                            });
                          }} src={plusIcon}/>
                        {/* </button> */}
                        </div>
                      </div>
                    </li>
                  </ul>
                  <hr />
                </div>
                <div className="formDivCss">
                  <Form>
                    <Row>
                      {this.state.orderedProductList.map((ele, i) => (
                        <>
                        <Row className="LandScapeDisplayNone" key={i}>
                        <OrderedProducts
                                      key={i}
                                      orderViewData={this.props?.orderViewData}
                                      ele={ele}
                                      i={i}
                                      isEditMode={ this.state.isEditMode}
                                      orderedProductList={this.state.orderedProductList}
                                      orderStatus={orderStatus}
                                      productDelete={this.productDelete}
                                      orderUpdate={this.orderUpdate}
                                      editPrice={this.editPrice}
                                      mappedOriginalProductDetails={this.state.mappedOriginalProductDetails}
                                      orderLogsObjForUpdated={this.state.orderLogsObjForUpdated}
                                    />
                        </Row>
                        <Accordion
                                open={this.state.accordianForLogs1}
                                toggle={(newIndex) => {
                                  this.accordionToggle(newIndex);
                                }}
                                className='pb-3 LandScapeDisplay'
                              >
                                <AccordionItem style={{width:'100%', margin:'10px'}}>
                                  <AccordionHeader targetId={i} className="justify-content-center">
                                              <b>
                                              {ele?.skuName
                                                ? ele?.skuName.toUpperCase()
                                                : ele?.productId?.sku?.skuName
                                                  ? ele?.productId?.sku?.skuName.toUpperCase()
                                                  : "N/A"}
                                              </b>
                                              <>
                                              &nbsp;&nbsp;Product Id:{" "}
                                              {ele?.productId?.productId
                                                ? ele?.productId?.productId
                                                : "null"}
                                              </>
                                            </AccordionHeader>
                                  <AccordionBody accordionId={i}>
                                    <OrderedProducts
                                      key={i}
                                      i={i}
                                      orderViewData={this.props?.orderViewData}
                                      ele={ele}
                                      isEditMode={ this.state.isEditMode}
                                      orderedProductList={this.state.orderedProductList}
                                      orderStatus={orderStatus}
                                      productDelete={this.productDelete}
                                      orderUpdate={this.orderUpdate}
                                      editPrice={this.editPrice}
                                      mappedOriginalProductDetails={this.state.mappedOriginalProductDetails}
                                      orderLogsObjForUpdated={this.props.orderLogsObjForUpdated}
                                    />
                                  </AccordionBody>
                                </AccordionItem>
                              </Accordion>
                        </>
                      ))}
                      <hr />
                      {!this.state.horeca && (
                        <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                          <div className="colordercostDiv">
                            <div className="d-flex justify-content-between align-items-center ">
                              <div>Packaging Cost :</div>
                              <p className="mb-0">
                                {this.state.packagingCost
                                  ? this.state.packagingCost
                                  : 0}{" "}
                                Rs
                              </p>
                            </div>
                            <p className="commision">
                              (@ Rs {this.percentageCommission("PackagingCost")}{" "}
                              per Kg)
                            </p>
                          </div>
                        </Col>
                      )}
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15 ">
                        <div className="colordercostDiv">
                          <div className="d-flex  justify-content-between align-items-center">
                            <div>Transport Cost :</div>
                            <p className="mb-0">
                              {this.state.transportCost
                                ? this.state.transportCost
                                : 0}{" "}
                              Rs
                            </p>
                          </div>
                          <p className="commision">
                            (@ Rs {this.percentageCommission("TransportCost")}{" "}
                            per km)
                          </p>
                        </div>
                      </Col>
                      {!this.state.horeca && (
                        <Col xs={12} sm={12} md={6} lg={4} className="mb-15 ">
                          <div className="colordercostDiv">
                            <div className="d-flex  justify-content-between align-items-center">
                              <div>Freshr Commission :</div>
                              <p className="mb-0">
                                {this.state.commission
                                  ? this.state.commission
                                  : 0}{" "}
                                Rs
                              </p>
                            </div>
                            <p className="commision">
                              (@ {this.percentageCommission("Commission")} %)
                            </p>
                          </div>
                        </Col>
                      )}
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <div className="colordercostDiv amountDiv">
                          <div className="d-flex justify-content-between align-items-center ">
                            <div>Amount Paid :</div>
                            <p className="mb-0">
                              {this.props?.orderViewData?.singleOrder
                                ?.amountPaid ?? ""}{" "}
                              Rs
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15 ">
                        <div className="colordercostDiv amountDiv">
                          <div className="d-flex  justify-content-between align-items-center">
                            <div>Amount Refund : </div>
                            <p className="mb-0">
                              {this.props?.orderViewData?.singleOrder
                                ?.amountRefund ?? ""}{" "}
                              Rs
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15 ">
                        <div className="colordercostDiv amountDiv">
                          <div className="d-flex  justify-content-between align-items-center">
                            <div>Net Difference :</div>
                            <p className="mb-0">
                              {this.props?.orderViewData?.singleOrder
                                ?.netDifference ?? ""}{" "}
                              Rs
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15 ">
                        <div className="colordercostDiv amountDiv">
                          <div className="d-flex  justify-content-between align-items-center">
                            <div>Discount :</div>
                            <p className="mb-0">
                              {this.props?.orderViewData?.singleOrder
                                ?.discount ?? ""}{" "}
                              Rs
                            </p>
                          </div>
                        </div>
                      </Col>

                      <Col xs={8} sm={10} md={9} className="mb-15 ">
                        <div className="d-flex  justify-content-between align-items-center colcostLastOrder">
                          <div>Total</div>
                          <p className="mb-0">
                            {this.state.totalCost ? this.state.totalCost : 0} Rs
                          </p>
                        </div>
                      </Col>

                      <Col
                        xs={4}
                        sm={2}
                        md={3}
                        className="mb-15"
                        style={{ paddingLeft: "8px" }}
                      >
                        <button
                          className="btn lightBtn discountOrderButton"
                          disabled={
                            this.state.isEditMode === "false"
                              ? true
                              : !(
                                  orderStatus === "Placed" ||
                                  orderStatus === "Updated"
                                )
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              showDiscountModal: true,
                            });
                          }}
                        >
                          Discount
                        </button>
                      </Col>

                      {this.state.sampleOrder ? (
                        <VoucherCode
                          TotalAmount={this.state.couponAmount}
                          couponName={this.state.couponName}
                        />
                      ) : (
                        ""
                      )}
                      { (
                          <Row xs={12} sm={12} md={12} lg={12}>
                          {this?.state?.mappedDiscountProductDetails?.map(
                            (a) => {
                              return (
                                <Col xs={12} sm={12} md={12} lg={12} className="mb-15">
                                <div className="colordercostDiv">
                                  <div className="d-flex  justify-content-between align-items-center">
                                    <div>Discount By : <span style={{fontWeight:400}}>{a?.updatedBy?.fullName}</span> </div>
                                    <p className="mb-0">
                                      {a?.updatedData?.discount}{" "} Rs
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              )
                            })}
                        </Row>
                      )}
                      <Row>
                        {this.props?.orderViewData?.singleOrder
                          ?.lastPaymentLinkSentDetails && (
                          <div className="d-flex  justify-content-between align-items-center colcostLastOrder mb-25">
                            <div>
                              {`Additional payment link of Rs
                         ${
                           this.props?.orderViewData?.singleOrder
                             ?.lastPaymentLinkSentDetails?.amount
                         }
                          sent at ${new Date(
                            this.props?.orderViewData?.singleOrder?.lastPaymentLinkSentDetails?.sentAt
                          ).toLocaleTimeString()}
                           on ${new Date(
                             this.props?.orderViewData?.singleOrder?.lastPaymentLinkSentDetails?.sentAt
                           ).toLocaleDateString()}`}
                            </div>
                          </div>
                        )}
                        <Col xs={12} lg={12} md={12} xl={12}>
                          <div className="d-flex align-items-center justify-content-between">
                            <button
                              className="btn lightBtn buttonWidth"
                              disabled={
                                this.state.isEditMode === "false" ||
                                orderStatus === "Cancelled"
                                  ? true
                                  : !(
                                      this.state.netDifference > 0 &&
                                      orderStatus !== "Placed" &&
                                      orderStatus !== "Updated"
                                    )
                              }
                              onClick={(e) => {
                                this.paymentLink(e);
                              }}
                            >
                              Issue payment link
                            </button>
                            <button
                              className="btn lightBtn buttonWidth"
                              disabled={
                                this.state.isEditMode === "false"
                                  ? true
                                  : !(
                                      this.state.netDifference < 0 &&
                                      (orderStatus === "Finalized" ||
                                        orderStatus === "In Transit")
                                    )
                              }
                              onClick={(e) => {
                                this.paymentRefund(e);
                              }}
                            >
                              Initiate Refund
                            </button>
                            <button
                              className="btn lightBtn buttonWidth"
                              disabled={
                                this.state.isEditMode === "false"
                                  ? true
                                  : !(
                                      orderStatus === "Placed" ||
                                      orderStatus === "Updated" ||
                                      orderStatus === "ApprovePending"
                                    )
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  orderCancelPopUp: true,
                                });
                              }}
                            >
                              Cancel
                            </button>
                            <button
                                className="btn lightBtn buttonWidth"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    modal: true,
                                  });
                                }}
                                disabled={
                                  // this.props?.orderViewData?.singleOrder?.orderStatus  == "canc"
                                  this.state.isEditMode === "false" ||
                                  orderStatus === "Cancelled"
                                    ? true
                                    : !(this.state.netDifference > 0)
                                }
                              >
                                Offline Payment
                              </button>
                            <button
                              className="btn lightBtn buttonWidth"
                              disabled={
                                this.state.isEditMode === "false"
                                  ? true
                                  : !(
                                      (orderStatus === "Finalized")
                                      // && this.state.netDifference === 0
                                    )
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  showModal: true,
                                });
                              }}
                            >
                              Proceed to Transport
                            </button>
                              <GenerateQr
                          // disabled={this.state.isEditMode}
                          orderId={this.state.orderId}
                          data={this.state.id}
                          qrWidth={420}
                          logoSize={0.07}
                        />
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          lg={12}
                          className="mt-2 "
                          style={{
                            paddingRight: "0px",
                          }}
                        >
                          <div>
                            <div className="d-flex justify-content-end w-100">
                              <button
                                className="btn darkBtn btn-right buttonWidth"
                                disabled={
                                  this.state.isEditMode === "false"
                                    ? true
                                    : !(
                                        orderStatus === "Placed" ||
                                        orderStatus === "Updated"
                                      )
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.updateOrderRequest();
                                }}
                              >
                                Update
                              </button>

                              <button
                                className="btn darkBtn buttonWidth"
                                disabled={
                                  this.state.isEditMode === "false"
                                    ? true
                                    : !(
                                        orderStatus === "Placed" ||
                                        orderStatus === "Updated"
                                      )
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.cancelOrder("Finalised");
                                }}
                              >
                                Finalise
                              </button>
                            </div>
                            {/* <div className="d-flex align-items-center justify-content-start"> */}
                          </div>
                          {/* </div> */}
                        </Col>
                      </Row>

                      <div className="d-flex align-items-center justify-content-between">
                      </div>
                    </Row>
                  </Form>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    orderViewData: state.rfo.orderView,
  };
};
export default connect(mapStateToProps, {
  orderCancel,
  fetchOrderViewData,
  orderUpdate,
  addTransport,
  issuePaymentLink,
  intiateRefund,
  resendPayment,
  orderDiscount,
  getApproval,
})(Orderdetails);
