import React, { Component } from "react";
import Slider from "react-slick";
import { CardImg, Container, Card, Row, Col } from "reactstrap";

export default class ProductCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          src: this.props.images[0],
          altText: "Slide One",
        },
      ],
    };
  }
  componentDidMount() {
    if (this.props.images?.length !== 1 && this.props.images?.length > 0) {
      this.setState({
        items: this.props.images.map((item) => {
          return { src: item, altText: "carouselImage" };
        }),
      });
    }
  }
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
    };

    return (
      <Container>
        {/* <Slider {...settings}> */}
        {/* {this.state.items.map((item, index) => {
              return (
                <div>
                  <img variant="top" src={item.src} />
                </div>
              );
            })} */}
        {/* </Slider> */}

        <Slider {...settings}>
          {this.state.items?.map((ele, index) => {
            if (index < 3) {
              return (
                <Col
                  // xs={12}
                  // sm={4}
                  // md={3}
                  // lg={3}
                  // xl={3}
                  className="md-top imagecenter"
                >
                  <img
                    src={ele.src}
                    alt="img loading"
                    className="imgWidthDelivery"
                  />
                  <a download href={ele.src} className="fa downloadbtn">
                    &#xf019;
                  </a>
                </Col>
              );
            }
          })}
        </Slider>
      </Container>
    );
  }
}
