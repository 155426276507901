import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
var data = {};
var data1 = {};
var dataLandScape = {};
export default class ProductTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let rows = this.props?.rows;
  
    data = {
      columns: [
        {
          label: "SKU",
          field: "sku",
          sort: "asc",
          width: 200,
        },

        {
          label: "Category",
          field: "category",
          sort: "asc",
          width: 200,
        },

        {
          label: "Size/Count",
          field: "size",
          sort: "asc",
          width: 200,
        },

        {
          label: "MOQ",
          field: "moq",
          sort: "asc",
          width: 200,
        },
        {
          label: <span>Range</span>,
          field: "range",
          sort: "asc",
          width: 400,
        },

        {
          label: "Price",
          field: "price",
          sort: "asc",
          width: 200,
        },

        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 200,
        },
      ],
      rows: rows,
    };
    data1 = {
      columns: [
        {
          label: <span>SKU</span>,
          field: "sku",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Category</span>,
          field: "category",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Size/Count</span>,
          field: "count",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>MOQ</span>,
          field: "moq",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Range</span>,
          field: "range",
          sort: "asc",
          width: 400,
        },
        {
          label: <span>Price</span>,
          field: "price",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Action</span>,
          field: "action",
          sort: "asc",
          width: 50,
        },
      ],
      rows: rows,
    };
    dataLandScape ={
      columns: [
        {
          label: <span>SKU</span>,
          field: "sku",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Price</span>,
          field: "price",
          sort: "asc",
          width: 800,
        },
        {
          label: <span>MOQ</span>,
          field: "moq",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Action</span>,
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
      rows: rows,
    }
    if(this.props.limra){
      let moq = dataLandScape?.columns[1]
      let price = dataLandScape?.columns[2]
      dataLandScape.columns[1] = price;
      dataLandScape.columns[2] = moq;

      dataLandScape.columns.splice(1,0,
        {
          label: <span>Range</span>,
          field: "range",
          sort: "asc",
          width: 500,
        },
      )
    }
    return (
      <>
      <div className=" mt-2 addProductList LandScapeDisplayNone">
        <MDBDataTable
          className="text-center text-capitalize addProductstyle"
          responsive
          hover
          sortable={true}
          data={this.props?.modelHeader === "addProduct" ? data1 : data}
          paging={false}
          searching={false}
          noBottomColumns
        />
      </div>
      <div className="text-center text-capitalize  addProductList LandScapeDisplay">
          <MDBDataTable
            className="text-center text-capitalize addProductstyle"
            responsive
            hover
            sortable={true}
            data={this.props?.modelHeader === "addProduct" ? dataLandScape : data}
            paging={false}
            searching={false}
            noBottomColumns
          />
        </div>
      </>
    );
  }
}
