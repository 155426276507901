import React, { Component } from 'react'
import { MDBDataTable } from "mdbreact";
var data = {};

export default class HistoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
      }
      render() {
        let rows = this.props?.rows;
        data = {
          columns: [
            {
              label: "SKU",
              field: "sku",
              sort: "asc",
              width: 200,
            },
    
            {
              label: "Category",
              field: "category",
              sort: "asc",
              width: 200,
            },
    
            {
              label: "Size/Count",
              field: "size",
              sort: "asc",
              width: 200,
            },
    
            {
              label: "MOQ",
              field: "moq",
              sort: "asc",
              width: 200,
            },
    
            {
              label: "Range",
              field: "range",
              sort: "asc",
              width: 200,
            },
    
          ],
          rows: rows,
        };
       
        return (
          <div>
            <MDBDataTable
             className="text-center text-capitalize"
             responsive
             hover
             sortable={true}
             data={data}
             paging={false}
             searching={false}
             noBottomColumns
            />
          </div>
        );
      }
    }
