import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  Input,
  FormGroup,
} from "reactstrap";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import SwitchButton from "../../../common/components/common/switchButton";
import { EditPreApprovedProduct } from "../../../store/actions";
import { NUMBER_REGEX } from "../../../common/components/common/Rejex";
import { handleKeyPress } from "../../../common/components/common/GlobalFunctions";
class EditPriceModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: "",
      buyerId: "",
      productId: "",
      fixedPriceEdit: "",
      minPriceEdit: "",
      maxPriceEdit: "",
      dataLoding: false,
      updateDisable: true,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.productToEditId !== this.props.productToEditId ||
      prevProps.modal !== this.props.modal
    ) {
      this.setState(
        {
          buyerId: this.props?.buyerId,
          productId: this.props?.productToEditId,
          fixedPriceEdit: this.props?.fixedPriceEdit,
          minPriceEdit: this.props?.minPriceEdit,
          maxPriceEdit: this.props?.maxPriceEdit,
          price: this?.props?.fixedPriceEdit 
        },
        () => {
          const { fixedPriceEdit, price, minPriceEdit, maxPriceEdit } =
            this.state;
          if (!this.props.isLimra)
            if (
              parseInt(fixedPriceEdit) >= minPriceEdit &&
              parseInt(fixedPriceEdit) <= maxPriceEdit
            ) {
              this.setState({ updateDisable: false });
            } else {
              this.setState({ updateDisable: true });
            }
          else this.setState({ updateDisable: false });
        }
      );
    }
  };
  reset = () => {
    this.setState({
      price: "",
      updateDisable: false,
    });
  };
  updatePrice = () => {
    let currentPrice = this.state.price
      ? this.state.price
      : this.state.fixedPriceEdit;

    let payload = {
      fixedRate: currentPrice?.toString(),
    };
    if (this.props.limraActive && this.props.isLimra) {
      delete payload.fixedRate;
      payload.limra = this.props.isLimra;
      payload.limraConstant = this.props.limraConstant;
    }
    this.setState({ dataLoading: true });
    this.props.EditPreApprovedProduct(
      this.state.productId,
      this.state.buyerId,
      payload,
      (res) => {
        if (res["message"] === "Fixed rate has been updated successfully") {
          NotificationManager.success(res["message"], "Success");
          this.setState({ dataLoading: false }, () => {
            this.props.getAllData();
            this.props.toggle();
            this.reset();
          });
        } else {
          NotificationManager.error(res["message"], "Error");
          this.setState({ dataLoading: false });
        }
      }
    );
  };

  onInputChange = (e) => {
    e.preventDefault();
    this.setState({ price: e.target.value }, () => {
      const { price, minPriceEdit, maxPriceEdit } = this.state;
      if (!this.props.isLimra)
        if (
          parseInt(price) >= minPriceEdit &&
          parseInt(price) <= maxPriceEdit &&
          !this.props.isLimra
        ) {
          this.setState({ updateDisable: false });
        } else {
          this.setState({ updateDisable: true });
        }
      else this.setState({ updateDisable: false });
    });
  };

  render() {
    let totalPrice = this.props.limraRate + this.props.limraConstant;
    return (
      <div>
        <Modal
          isOpen={this.props.modal}
          toggle={() => {
            this.setState({ updateDisable: false, price: "" }, () => {
              this.props.toggle();
            });
          }}
          className="editPriceModel"
        >
          <ModalHeader
            toggle={() => {
              this.setState({ updateDisable: false, price: "" }, () => {
                this.props.toggle();
              });
            }}
          >
            <div className="d-flex">
              <div>Edit Price </div>
              {this.props.limraActive && (
                <>
                  <span>
                    <SwitchButton
                      value={this.props.isLimra}
                      onChange={(value) => {
                        if (value) {
                          this.setState({
                            updateDisable: false,
                            price: this.props.limraRate
                          });
                        }else{
                          this.setState({
                            price: this.props.preApprovedRate > 0 ? this.props.preApprovedRate :this.props.maxPriceEdit
                          });
                        }
                        this.props.onChange("isLimra", value);
                      }}
                      marginStyle="-28px 7px -9px 20px"
                      disabled={
                        this.props.isEditMode === "false" ? true : false
                      }
                    />
                  </span>
                  <p>LIMRA</p>
                </>
              )}
            </div>
          </ModalHeader>
          <ModalBody>
            {/* <br />
            <p className="text-start">Add New Price</p>
            <InputGroup>
              <Input
                placeholder="Price"
                type="number"
                name="price"
                id="price"
                defaultValue={this.props?.fixedPriceEdit}
                onChange={(e) => {
                  this.onInputChange(e);
                }}
              />
              <InputGroupText>
                <span className="text-muted">/Kg</span>
              </InputGroupText>
            </InputGroup>
            <br /> */}
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} className="mb-15">
                <FormGroup>
                {this.props.isLimra ?'':
                 <p className="text-start" style={{fontSize:'12px'}}>
                  {`Range (₹ ${this.state.minPriceEdit} - ₹ ${this.state.maxPriceEdit})`}
                </p>}
                  <p className="text-start"> Add New Price</p>
                  <InputGroup>
                    <Input
                      placeholder="Price"
                      type="number"
                      name="price"
                      id="price"
                      disabled={this.props.isLimra ? true : false}
                      value={
                        this.props.isLimra
                          ? this.props.limraRate
                          : this.state.price
                      }
                      onChange={(e) => {
                        this.onInputChange(e);
                      }}
                    />
                    <InputGroupText>
                      <span className="text-muted">/Kg</span>
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>
              </Col>
              {this.props.isLimra && (
                <>
                  <Col xs={12} sm={12} md={6} lg={6} className="mb-15">
                    <FormGroup>
                      <p className="text-start">Update Constant</p>
                      <InputGroup>
                        <InputGroupText onClick={() => this.props.onHandleIncrement()}>
                          <span
                            className="text-muted limraPlusIcon"
                            
                          >
                            +
                          </span>
                        </InputGroupText>
                        <Input
                          id="category"
                          name="category"
                          placeholder="Fish"
                          value={this.props.limraConstant}
                          type="text"
                          onKeyPress={(evt) => {
                            handleKeyPress(evt);
                          }}
                          disabled={
                            this.props.isEditMode === "false" ? true : false
                          }
                          onChange={(e) => {
                            if (NUMBER_REGEX.test(e.target.value)) {
                              this.props.onChange(
                                "limraConstant",
                                e.target.value.trim()
                              );
                            }
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} className="mb-15">
                    <FormGroup>
                      <p className="text-start">Total Price</p>
                      <Input
                        id="category"
                        name="category"
                        placeholder="Fish"
                        value={totalPrice}
                        type="text"
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-center align-items-center flex-wrap">
            <Button
              color="secondary"
              onClick={this.updatePrice}
              size="md"
              disabled={this.state.updateDisable? true:false}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default connect(null, { EditPreApprovedProduct })(EditPriceModel);
