import React, { Component } from "react";
import Slider from "react-slick";
import ProductsModal from "./ProductModal";

export default class ProductDetailImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProductsModal: false,
    };
  }
  toggleProductsModal = () => {
    this.setState({
      ProductsModal: !this.state.ProductsModal,
    });
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "slides",
    };
    return (
      <div className="DetailspageSliderDiv">
        <Slider {...settings}>
          {this.props.images?.map((data, index) => {
            return (
              <div key={index}>
                <img
                  style={{
                    objectFit: "fill",
                    width: "auto",
                    height: "333px",
                  }}
                  src={data}
                  alt="more-images"
                  onClick={() => this.toggleProductsModal()}
                />
              </div>
            );
          })}
        </Slider>
        <ProductsModal
          images={this.props.images}
          isOpen={this.state.ProductsModal}
          toggle={this.toggleProductsModal}
        />
      </div>
    );
  }
}
