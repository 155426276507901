import React, { Component } from 'react'
import { Container, Row, Col } from "reactstrap";
import MainHeader from '../../components/common/MainHeader'
import Sku from './Common/Sku';
export default class SKU extends Component {
    render() {
        return (
            <div>
            <MainHeader pageTitle={"SKU"}/>  
            <div className="mainParentSectionDiv">
                    <Container fluid className="pd-15">
                        <Row className="d-flex align-items-stretch">
                            <Col xs={12} sm={12} md={12} className="pd-0">
                                {/* <SosRequests /> */}
                                <Sku/>
                            </Col>
                        </Row>
                    </Container>
                </div>                 
            </div>
        )
    }
}
