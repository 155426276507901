import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
export default class RepeatDialogue extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="repeatModal">
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.props.toggle}></ModalHeader>
          <ModalBody>
            <div className="text-center card-title">
              {" "}
              <h5>{this.props.body}</h5>
            </div>
            <div className="text-center card-title-desc mt-3">
              {" "}
              Please Select Delivery Date
            </div>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center RepeatModalFooter">
            <Button
              className="btn-success editButton"
              size="sm"
              onClick={() => {
                this.props.actionFunc();
              }}
            >
              {this.props.action}
            </Button>{" "}
            <Button className="cancelButton" onClick={this.props.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
