import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import {
  Input,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Button,
  Col,
  InputGroupText,
} from "reactstrap";
import searchIcon from "../../../images/search.png";
import { NotificationManager } from "react-notifications";
import imagePending from "../../../images/image-pending.jpg";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import { handleErrorMsg } from "../../../common/components/common/GlobalFunctions";
import {
  AddPreApprovedProduct,
  fetchproductSortData,
  fetchEnum,
} from "../../../store/actions";
import Pagination from "./PaginateData";
import ProductTable from "./ProductTable";
import SwitchButton from "../../../common/components/common/switchButton";
import { connect } from "react-redux";
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var rowsList;
let timeOut;
class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skuName: "",
      buyerId: "",
      currentPage: 1,
      limit: 5,
      dataLoading: false,
      moqIncDec: {},
      isLimra: false,
      LimraConstant: 0,
      productListing: [],
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.buyerId !== this.props.buyerId) {
      this.setState({ buyerId: this.props?.buyerId }, () => {
        this.getAllData();
      });
    }
    if (prevProps.modal !== this.props.modal) {
      this.setState({ buyerId: this.props?.buyerId }, () => {
        this.getAllData();
      });
    }
  };
  componentDidMount = () => {
    this.fetchEnumLimraConstant();
  };
  fetchEnumLimraConstant = () => {
    this.props.fetchEnum((enumData) => {
      if (enumData) {
        let LimraConstant = enumData?.allEnums?.find(
          (ele) => ele?.EnumType === "LimraConstant"
        );
        this.setState({ LimraConstant: LimraConstant?.EnumValue });
      }
    });
  };
  getAllData = () => {
    let skuName = this.state.skuName ? this.state.skuName.trim() : "";
    this.props.fetchproductSortData(
      offset,
      10,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      skuName,
      "",
      this.state.isLimra ? this.state.isLimra : "",
      (res) => {
        let productlisting = res?.docs?.length > 0 ? res.docs : [];
        productlisting.forEach((ele) => {
          ele.enumlimraConstant = this.state.LimraConstant;
        });
        this.setState({
          productListing: productlisting,
        });
      }
    );
  };
  debounceValue = (val, stateName) => {
    this.setState(
      {
        skuName: val,
      },
      () => {
        if (timeOut) {
          clearTimeout(timeOut);
        }
        timeOut = setTimeout(() => {
          offset = 0;
          this.getAllData();
        }, 2000);
      }
    );
  };
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };
  createPreApproveProd = (e, id) => {
    e.preventDefault();
    let { buyerId, moqIncDec } = this.state;
    if (buyerId && id) {
      let formObj = {
        productId: id ? id : "",
        fixedRate: this.state.moqIncDec[id].toString(),
        buyerId: buyerId ? buyerId : "",
      };
      this.setState({ dataLoading: true });
      this.props.AddPreApprovedProduct(formObj, (res) => {
        if (
          res["message"] === "Pre Approved Product has been added successfully"
        ) {
          NotificationManager.success(res["message"], "Success");
          this.setState({ dataLoading: false }, () => {
            this.props.toggle();
            this.getAllData();
            this.props.getAllData();
          });
        } else {
          handleErrorMsg(res);
          this.setState({ dataLoading: false });
          this.getAllData();
        }
      });
    }
  };
  createPreApproveProdLimra = (e, id, index) => {
    e.preventDefault();
    let { buyerId, productListing } = this.state;
    if (buyerId && id) {
      let formObj = {
        productId: id ? id : "",
        limraConstant: Number(productListing[index]?.enumlimraConstant),
        limra: true,
        fixedRate: (
          productListing[index]?.enumlimraConstant +
          productListing[index]?.limra?.limraRate
        ).toString(),
        buyerId: buyerId ? buyerId : "",
      };
      this.setState({ dataLoading: true });
      this.props.AddPreApprovedProduct(formObj, (res) => {
        if (
          res["message"] === "Pre Approved Product has been added successfully"
        ) {
          NotificationManager.success(res["message"], "Success");
          this.setState({ dataLoading: false }, () => {
            this.props.toggle();
            this.getAllData();
            this.props.getAllData();
          });
        } else {
          handleErrorMsg(res);
          this.setState({ dataLoading: false });
          this.getAllData();
        }
      });
    }
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * 10;
    this.getAllData();
  };
  updateMoqIncDec = (id, action, value) => {
    let keyValue = {};
    if (action === "add") {
      keyValue[id] = parseInt(value) ? parseInt(value) : 1;
    } else {
      keyValue[id] =
        action === "dec"
          ? this.state.moqIncDec[id] - 15
          : action === "inc"
          ? this.state.moqIncDec[id] + 10
          : this.state.moqIncDec[id];
    }
    if (keyValue[id] < 1) keyValue[id] = 1;

    this.setState({ moqIncDec: { ...this.state.moqIncDec, ...keyValue } });
  };
  updateMoqIncDecLimra = (index, name, val, id) => {
    let productData = [...this.state?.productListing];
    if (name === "inc") {
      let selectProductInc = this.state.productListing.find(
        (ele) => ele?._id === id
      );
      productData[index].enumlimraConstant =
        selectProductInc?.enumlimraConstant + 5;
    } else {
      productData[index].enumlimraConstant = val;
    }
    this.setState({
      productListing: productData.length > 0 ? productData : [],
    });
  };
  onChange(name, val) {
    this.setState(
      {
        [`${name}`]: val,
      },
      () => {
        this.getAllData();
      }
    );
  }
  render() {
    rowsList = [];
    //products data
    if (this.state?.productListing && this.state?.productListing?.length > 0) {
      totCnt = this.props?.listOfProductData?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = offset + this.props?.listOfProductData?.length;
      totPages = Math.ceil(totCnt / 10);
      this.state?.productListing?.forEach((ele, index) => {
        let keyValue = {};
        keyValue[ele?._id] = ele?.minPrice;
        if (
          !this.state.moqIncDec[ele?._id] &&
          this.state.moqIncDec[ele?._id] != 0
        ) {
          this.setState({
            moqIncDec: { ...this.state.moqIncDec, ...keyValue },
          });
        }
        rowsList.push({
          sku: (
            <div className="d-flex align-items-center skuAlign LandScapeDisplaysku">
              <div className="d-flex align-items-center skuImage">
                <img
                  src={
                    ele?.images[0]
                      ? ele?.images[0]
                      : ele?.sku?.skuImage[0]
                      ? ele?.sku?.skuImage[0]
                      : imagePending
                  }
                  alt="productimg"
                  className="w-100"
                ></img>
              </div>
              &nbsp;&nbsp;&nbsp;
              <p className="mb-0">
                {ele.sku?.skuName ? ele.sku?.skuName : "N/A"}
              </p>
            </div>
          ),
          category: ele.category?.categoryName
            ? ele.category?.categoryName
            : "N/A",
          count: <div className="size-count">{ele.size} kg</div>,

          moq: (
            <div className="d-flex align-itemms-center">
              &nbsp;<span>{ele.moq ? ele.moq : ""} / {ele?.moqUnits}</span>
            </div>
          ),
          range: ` ₹ ${ele?.minPrice} - ₹ ${ele?.maxPrice}`,
          price: this.state.isLimra ? (
            <div className="orderbuyerdetailflex LandScapeDisplayprice">
              <input
                className="form-control buyerdetailmodalinput productPriceInput"
                value={`${ele?.limra?.limraRate} /${ele?.moqUnits ?? "Kgs"}`}
                style={{width:'80px'}}
                readOnly
              />
              <InputGroup style={{marginLeft:'30px',}}>
              <InputGroupText className="productPriceInput">
              <span
                style={{ display:'inline'}}
                disable={!ele?.minPrice}
                onClick={() =>
                  this.updateMoqIncDecLimra(index, "inc", "", ele?._id)
                }
                className="text-muted limraPlusIcon"
              >
                +
              </span>
              </InputGroupText>
              <input
                style={{ display:'inline'}}
                className="form-control buyerdetailmodalinput productPriceInput"
                value={ele?.limraConstant > 0 ? ele?.limraConstant : ele?.enumlimraConstant}
                onChange={(e) =>
                  this.updateMoqIncDecLimra(
                    index,
                    "add",
                    e?.target?.value,
                    ele?._id
                  )
                }
              />
              </InputGroup>
            </div>
          ) : (
            <div className="orderbuyerdetailflex">
              <span
                disable={!ele?.minPrice}
                onClick={() => this.updateMoqIncDec(ele?._id, "dec", "")}
                className="plusicon"
                style={{ cursor: "pointer" }}
              >
                -
              </span>

              <input
                className="form-control productPriceInput priceinputSize"
                value={this.state.moqIncDec[ele?._id]}
                onChange={(e) =>
                  this.updateMoqIncDec(ele?._id, "add", e?.target?.value)
                }
              />
              <span
                disable={!ele?.minPrice}
                onClick={() => this.updateMoqIncDec(ele?._id, "inc", "")}
                className="minusicon"
                style={{ cursor: "pointer" }}
              >
                +
              </span>
            </div>
          ),

          action: (
            <div className="d-flex align-items-center tableBtns ">
              <Button
                className="btn lightBtn"
                onClick={(e) => {
                  this.state.isLimra
                    ? this.createPreApproveProdLimra(e, ele._id, index)
                    : this.createPreApproveProd(e, ele._id);
                }}
                disabled={
                  this.state.moqIncDec[ele?._id] <= ele?.minPrice &&
                  this.state.moqIncDec[ele?._id] >= ele?.maxPrice &&
                  !this.state.isLimra
                    ? true
                    : false
                }
              >
                Add
              </Button>
              &nbsp;&nbsp;
            </div>
          ),
        });
      });
    } else {
      totCnt = 0;
    }

    return (
      <div>
        <Modal
          isOpen={this.props.modal}
          toggle={() => {
            this.setState({ skuName: "", moqIncDec: {} }, () => {
              this.props.toggle();
            });
          }}
          className="editProductQuantity modal-dialog modal-dialog-scrollable"
          size="md"
        >
          <ModalHeader
            toggle={() => {
              this.setState({ skuName: "", moqIncDec: {} }, () => {
                this.props.toggle();
              });
            }}
          >
            Add Product
          </ModalHeader>
          <ModalBody>
            <Container fluid className="p-0">
              <Row>
                <Col xs={12} className="">
                  <Grid
                    className=""
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={2} sm={2} md={2}>
                      <div className="d-flex">
                        <div> </div>
                        <span>
                          <SwitchButton
                            value={this.state.isLimra}
                            onChange={(value) =>
                              this.onChange("isLimra", value)
                            }
                            marginStyle="-18px 9px -17px 20px"
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                          />
                        </span>
                        <p className="limraToggle">LIMRA</p>
                      </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}></Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <div className="input-border">
                        <InputGroup>
                          <Input
                            className="form-control border-end-0 border"
                            type="search"
                            name="sku"
                            id="example-search-input"
                            value={this.state.skuName}
                            autoComplete="off"
                            onChange={(e) => {
                              this.debounceValue(e.target.value, "skuName");
                            }}
                            placeholder="Search By SKU..."
                            aria-label="Search"
                            icon="fas fa-times-circle"
                          />
                          <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                          <span class="input-group-append">
                            <button
                              class="btn btn-outline border-left-0 border"
                              type="button"
                            >
                              <img
                                className="pl-1"
                                src={searchIcon}
                                alt=""
                              ></img>
                            </button>
                          </span>
                        </InputGroup>
                      </div>
                    </Grid>
                  </Grid>
                  {this.state.dataLoading ? (
                    <div className="ViewPagesLoder">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <>
                      <ProductTable rows={rowsList} modelHeader="addProduct" limra={true} />
                      <Pagination
                        fromVal={fromVal}
                        toVal={toVal}
                        totCnt={totCnt}
                        offset={offset}
                        totPages={totPages}
                        handleChangePage={this.handleChangePage}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => ({
  listOfProductData: state.rfu?.productdata,
});
export default connect(mapStateToProps, {
  AddPreApprovedProduct,
  fetchproductSortData,
  fetchEnum,
})(AddProduct);
