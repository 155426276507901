import React, { Component } from 'react'
import {Nav,NavItem,NavLink} from 'reactstrap'
import { Link } from 'react-router-dom';



export default class SubHeader extends Component {
    render() {
            const pdrl25 = {padding:'0px 25px'}
        return (
            <div style={pdrl25}>
               
                
            </div>
        )
    }
}


