import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import { Grid } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import ProductTable from "./Common/ProductTable";
import LoadingSpinner from "../../common/components/common/LoadingSpinner";
import { connect } from "react-redux";
import {
  preApprovedProductData,
  DeletePreApprovedProduct,
  fetchEnum,
} from "../../store/actions";
import editIcon from "../../images/edit.png";
import EditPriceModel from "./Common/EditPriceModel";
import HistoryModel from "./Common/HistoryModel";
import AddProduct from "./Common/AddProduct";
import ConfirmModel from "./Common/ConfirmModel";
import Pagination from "./Common/PaginateData";
import imagePending from "../../images/image-pending.jpg";
import Limra from "../../images/Limra.png";
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
class PreApprovedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preApproveProd: [],
      preApproveProdId: "",
      productToEditId: "",
      productEditFixedPrice: "",
      productEditMaxPrice: "",
      productEditMinPrice: "",
      buyerId: "",
      dataLoading: false,
      productToDeleteId: "",
      editPriceModel: false,
      historyPriceModel: false,
      addProductModel: false,
      deleteModel: false,
      currentHistory: "",
      data: "",
      limraPriceModel: false,
      limraRate: 0,
      limraConstant: 0,
      isLimra: false,
      limraActive: false,
      preApprovedRate: 0,
    };
  }
  //editPrice
  toggle = () => {
    this.setState({
      editPriceModel: !this.state.editPriceModel,
    });
  };

  //history
  toggle1 = () => {
    this.setState({
      historyPriceModel: !this.state.historyPriceModel,
    });
  };
  //addPrice
  toggle2 = () => {
    this.setState({
      addProductModel: !this.state.addProductModel,
    });
  };
  toggle3 = () => {
    this.setState({
      deleteModel: !this.state.deleteModel,
    });
  };
  deletePreApprovedProduct = () => {
    this.setState({ deleteModel: true });
  };

  deletepreApproveProd = () => {
    this.props.DeletePreApprovedProduct(
      this.state.productToDeleteId,
      this.state.buyerId,
      (res) => {
        if (res?.message === "Product removed successfully") {
          NotificationManager.success(res["message"], "Success");
          this.setState({ deleteModel: false }, () => {
            this.getAllData();
          });
        }
      }
    );
  };

  componentDidMount = () => {
    this.getAllData();
    this.getEnumData();
  };
  getEnumData = () => {
    this.props.fetchEnum((res) => {
      let enumLimraConstant = allEnums.filter(
        (enumItem) => (enumItem.EnumType = "LimraConstant")
      );
      this.setState({
        limraConstant:
          enumLimraConstant.length > 0 ? enumLimraConstant?.EnumValue : 0,
      });
    });
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.custId !== this.props.custId) {
      this.getAllData();
    }
  };
  getAllData = () => {
    this.setState({ buyerId: this.props?.custId, dataLoading: true }, () => {
      this.props.preApprovedProductData(this.props?.custId, offset, (res) => {
        if (res) {
          let data = res?.docs;
          this.setState({
            preApproveProd: data,
            dataLoading: false,
            data: res,
          });
        }
      });
    });
  };
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * 10;
    this.getAllData();
  };
  onHandleIncrement = () => {
    this.setState({
      limraConstant: this.state.limraConstant + 5,
    });
  };
  onChange = (name, val) => {
    this.setState({
      [`${name}`]: name === "limraConstant" ? Number(val) : val,
    });
  };
  render() {
    const { preApproveProd, data } = this.state;

    let row = [];
    if (preApproveProd) {
      totCnt = data?.length ? data?.length : 0;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = offset + preApproveProd?.length;
      totPages = Math.ceil(totCnt / 10);
      preApproveProd.forEach((preProds) => {
        let skuImage = preProds?.sku?.skuImage[0];
        let skuName = preProds?.sku?.skuName;
        let categoryName = preProds?.category?.categoryName;

        row.push({
          sku: (
            <div className="d-flex align-items-center skuAlign">
              <div className="d-flex align-items-center skuImage">
                <img
                  src={skuImage ? skuImage : imagePending}
                  alt="productimg"
                  className="w-100"
                ></img>
              </div>
              &nbsp;&nbsp;&nbsp;
              <p className="mb-0">{skuName ? skuName : "---"}</p>
            </div>
          ),
          category: categoryName ? categoryName : "N/A",
          size: preProds ? `${preProds?.size} /kg` : "N/A",
          moq: (
            <div className="d-flex align-itemms-center">
              &nbsp;
              <span>{preProds ? preProds?.moq : "N/A"} kgs</span>
            </div>
          ),
          range: ` ₹ ${preProds?.minPrice ? preProds?.minPrice : "N/A"} - ₹ ${
            preProds?.maxPrice ? preProds?.maxPrice : "N/A"
          }`,
          price: `₹ ${preProds?.fixedRate} /kg`,
          actions: (
            <div className="d-flex align-items-center tableBtns ">
              <div style={{ cursor: "pointer", marginRight: "29px" }}>
                <img
                  src={preProds?.limraRateType === "limra" ? Limra : editIcon}
                  className={
                    preProds?.limraRateType === "limra" ? "limraIcons" : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState(
                      {
                        productToEditId: preProds._id,
                        productEditFixedPrice: preProds?.fixedRate,
                        productEditMaxPrice: preProds?.maxPrice,
                        productEditMinPrice: preProds?.minPrice,
                        limraActive: preProds?.limra?.isActive ?? false,
                        limraRate: preProds?.limra?.limraRate ?? 0,
                        limraConstant: preProds?.limraConstant ?? this.state.limraConstant,
                        preApprovedRate: preProds?.preApprovedRate ?? 0,
                        isLimra:
                          preProds?.limraRateType === "limra" ? true : false,
                      },
                      () => {
                        this.toggle();
                      }
                    );
                  }}
                ></img>
              </div>
              &nbsp;&nbsp;
              <Button
                className="btn lightBtn ml-4 mr-4"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(
                    {
                      productToDeleteId: preProds?._id,
                    },
                    () => {
                      this.deletePreApprovedProduct();
                    }
                  );
                }}
              >
                Delete
              </Button>
              &nbsp;&nbsp;
              <Button
                className="btn lightBtn"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(
                    {
                      productToEditId: preProds?._id,
                      preApproveProdId: preProds?.preApprovedId,
                      currentHistory: preProds,
                    },
                    () => {
                      this.toggle1();
                    }
                  );
                }}
              >
                History
              </Button>
            </div>
          ),
        });
      });
    }

    return (
      <div className="mt-1">
        {this.state.dataLoading ? (
          <div className="ViewPagesLoder">
            <LoadingSpinner />
          </div>
        ) : (
          <Container fluid className="p-0">
            <Row>
              <Col xs={12} className="">
                <Card>
                  <CardBody>
                        <div className="d-flex justify-content-between">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item-list active">
                            Your Product List
                          </li>
                        </ol>
                        <div>
                          <Button
                            className="btn darkbtn"
                            color="secondary"
                            onClick={() => this.toggle2()}
                            disabled={
                              this?.props?.isEditMode === "false" ? true : false
                            }
                          >
                            Add New Product
                          </Button>
                        </div>
                        </div>
                    <ProductTable rows={row ? row : []} />
                    <Pagination
                      fromVal={fromVal}
                      toVal={toVal}
                      totCnt={totCnt}
                      offset={offset}
                      totPages={totPages}
                      handleChangePage={this.handleChangePage}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}

        <EditPriceModel
          toggle={this.toggle}
          modal={this.state.editPriceModel}
          buyerId={this.state.buyerId}
          productToEditId={this.state.productToEditId}
          fixedPriceEdit={this.state.productEditFixedPrice.toString()}
          maxPriceEdit={this.state.productEditMaxPrice}
          minPriceEdit={this.state.productEditMinPrice}
          isLimra={this.state.isLimra}
          limraActive={this.state.limraActive}
          limraRate={this.state.limraRate}
          limraConstant={this.state.limraConstant}
          onChange={this.onChange}
          onHandleIncrement={this.onHandleIncrement}
          isEditMode={this?.props?.isEditMode}
          getAllData={this.getAllData}
          preApprovedRate = {this.state?.preApprovedRate}
        />
        <HistoryModel
          toggle={this.toggle1}
          modal={this.state.historyPriceModel}
          currentHistory={this.state.currentHistory}
          productId={this.state.productToEditId}
          preApproveProdId={this.state.preApproveProdId}
        />
        <AddProduct
          toggle={this.toggle2}
          modal={this.state.addProductModel}
          modelHeader="addProduct"
          // data={this.state.data ? this.state.data : []}
          buyerId={this.state.buyerId}
          getAllData={this.getAllData}
        />
        <ConfirmModel
          toggle={this.toggle3}
          modal={this.state.deleteModel}
          deleteCall={this.deletepreApproveProd}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    preApprovedProduct: state.response,
    EnumData: state.rfe.EnumData,
  };
};

export default connect(mapStateToProps, {
  preApprovedProductData,
  DeletePreApprovedProduct,
  fetchEnum,
})(PreApprovedProducts);
