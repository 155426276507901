export const userTypeList = [
    {
        value: "seller",
        label: "Seller",
    },
    {
        value: "buyer",
        label: "Buyer",
    },
    {
        value: "logistic",
        label: "Logistic",
    },
]

export const makerCheckerList = [
    {
        value: "maker",
        label: "Maker",
    },
    {
        value: "checker",
        label: "Checker",
    },
]