export const types = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGOUT: "USER_LOGOUT",
  PROFILE_DATA: "PROFILE_DATA",
  PROFILE_DATA_SUCCESS: "PROFILE_DATA_SUCCESS",
  CUSTOMER_DATA_SUCCESS: "CUSTOMER_DATA_SUCCESS",
  CUSTOMER_DELETE_DATA: " CUSTOMER_DELETE_DATA",
  CUSTOMER_VIEW_DATA_SUCCESS: " CUSTOMER_VIEW_DATA_SUCCESS",
  CUSTOMER_VIEW_DATA: " CUSTOMER_VIEW_DATA",
  CUSTOMER_SORT_DATA: "CUSTOMER_SORT_DATA",
  CUSTOMER_EDIT_DATA: "CUSTOMER_EDIT_DATA",

  PRE_APPROVED_PRODUCT_LIST: "PRE_APPROVED_PRODUCT_LIST",
  PRE_APPROVED_PRODUCT_LIST_SUCCESS: "PRE_APPROVED_PRODUCT_LIST_SUCCESS",

  DELETE_PRE_APPROVED_PRODUCT: "DELETE_PRE_APPROVED_PRODUCT",
  DELETE_PRE_APPROVED_PRODUCT_SUCCESS: "DELETE_PRE_APPROVED_PRODUCT_SUCCESS",

  HISTORY_PRE_APPROVED_PRODUCT: "HISTORY_PRE_APPROVED_PRODUCT",
  HISTORY_PRE_APPROVED_PRODUCT_SUCCESS: "HISTORY_PRE_APPROVED_PRODUCT_SUCCESS",

  EDIT_PRE_APPROVED_PRODUCT: "EDIT_PRE_APPROVED_PRODUCT",
  EDIT_PRE_APPROVED_PRODUCT_SUCCESS: "EDIT_PRE_APPROVED_PRODUCT_SUCCESS",

  ADD_PRE_APPROVED_PRODUCT: "ADD_PRE_APPROVED_PRODUCT",
  ADD_PRE_APPROVED_PRODUCT_SUCCESS: "ADD_PRE_APPROVED_PRODUCT_SUCCESS",

  CUSTOMER_SEARCH_DATA: "CUSTOMER_SEARCH_DATA",
  DASHBOARD_DATA: "DASHBOARD_DATA",
  DASHBOARD_DATA_SUCCESS: "DASHBOARD_DATA_SUCCESS",
  USER_REQUEST_DATA: "USER_REQUEST_DATA",
  USER_REQUEST_DATA_SUCCESS: "USER_REQUEST_SUCCESS",
  USER_VIEW_DATA: "USER_VIEW_DATA",
  USER_VIEW_DATA_SUCCESS: "USER_VIEW_DATA_SUCCESS",
  USER_DATA_UPDATE: "USER_DATA_UPDATE",
  USER_REQUEST_DELETE_DATA: "USER_REQUEST_DELETE_DATA",
  USER_REQUEST_EDIT_DATA: " USER_REQUEST_EDIT_DATA",
  USER_REQUEST_FILTER_DATA: "USER_REQUEST_FILTER_DATA",
  USER_REQUEST_FILTER_DATA_SUCCESS: "USER_REQUEST_FILTER_DATA_SUCCESS",
  PRODUCT_DATA_SUCESSS: "PRODUCT_DATA_SUCCESS",
  PRODUCT_SORT_DATA: " PRODUCT_SORT_DATA",
  PRODUCT_FILTER_DATA: "PRODUT_FILTER_DATA",
  PRODUCT_FILTER_DATA_BY_SKU: "PRODUCT_FILTER_DATA_BY_SKU",
  PRODUCT_VIEW_DATA: "PRODUCT_VIEW_DATA",
  PRODUCT_VIEW_DATA_SUCCESS: "PRODUCT_VIEW_DATA_SUCCESS",
  CATEGORY_DATA: "CATEGORY_DATA",
  CATEGORY_DATA_SUCCESS: "CATEGORY_DATA_SUCCESS",
  USER_COUNT_DATA: "USER_COUNT_DATA",
  USER_COUNT_DATA_SUCESS: "USER_COUNT_DATA_SUCCESS",
  SELLER_DATA_SUCCESS: "SELLER_DATA_SUCCESS",
  SELLER_DATA: "SELLER_DATA",
  ALL_SELLER_SYNC_VINCULLUM: "ALL_SELLER_SYNC_VINCULLUM",
  ALL_SELLER_SYNC_VINCULLUM_SUCCESS: "ALL_SELLER_SYNC_VINCULLUM_SUCCESS",
  SYNC_ALL_SKU_SUCCESS: "SYNC_ALL_SKU_SUCCESS",
  SYNC_ALL_SKU: "SYNC_ALL_SKU",
  PRODUCT_ALL_FILTER_DATA: "PRODUCT_ALL_FILTER_DATA",
  PRODUCT_ALL_FILTER_DATA_SUCCESS: "PRODUCT_ALL_FILTER_DATA_SUCCESS",

  PRODUCT_SELLER_DATA: "PRODUCT_SELLERDATA",
  // USER_COUNT_DATA_SUCESS: "USER_COUNT_DATA_SUCCESS",
  PRODUCT_SELLER_DATASUCCESS: "PRODUCT_SELLERDATASUCCESS",
  // SELLER_DATA_SUCCESS:"SELLER_DATA_SUCCESS",
  PRODUCT_PRODUCT_CATEGORY: "PRODUCT_PRODUCTCATEGORY",
  // SELLER_DATA:"SELLER_DATA",
  PRODUCT_PRODUCT_CATEGORYSUCCESS: "PRODUCT_PRODUCT_CATEGORYSUCCESS",
  // PRODUCT_ALL_FILTER_DATA:"PRODUCT_ALL_FILTER_DATA",
  PRODUCT_GET_SKU_BYID: "PRODUCT_GET_SKU_BYID",
  PRODUCT_GET_SKU_BYID_SUCCESS: "PRODUCT_GET_SKU_BYID_SUCCESS",
  PRODUCT_CREATE: "PRODUCT_CREATE",
  PRODUCT_CREATE_SUCCESS: "PRODUCT_CREATE_SUCCESS",
  PRODUCTS_ADD_CATEGORY: "PRODUCTS_ADD_CATEGORY",
  PRODUCTS_ADD_CATEGORY_SUCCESS: "PRODUCTS_ADD_CATEGORY_SUCCESS",
  PRODUCTS_ADD_SKU: "PRODUCTS_ADD_SKU",
  PRODUCTS_ADD_SKU_SUCCESS: "PRODUCTS_ADD_SKU_SUCCESS",
  PRODUCTS_GET_ALL_SKUS: "PRODUCTS_GET_ALL_SKUS",
  PRODUCTS_GET_ALL_SKUS_SUCCESS: "PRODUCTS_GET_ALL_SKUS_SUCCESS",
  CUSTOMER_ADDITION: "CUSTOMER_ADDITION",
  CUSTOMER_ADDITION_SUCCESS: "CUSTOMER_ADDITION_SUCCESS",
  PRODUCT_DELETE_DATA: "PRODUCT_DELETE_DATA",
  PRODUCT_EDIT_DATA: "PRODUCT_EDIT_DATA",
  VERIFY_BANK_ACCOUNT: "VERIFY_BANK_ACCOUNT",
  VERIFY_BANK_ACCOUNT_SUCCESS: "VERIFY_BANK_ACCOUNT_SUCCESS",
  TRIGGER_OTP: "TRIGGER_OTP",
  TRIGGER_OTP_SUCCESS: "TRIGGER_OTP_SUCCESS",
  VERIFY_OTP: "VERIFY_OTP",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  TRIGGER_KYC_FORM: "TRIGGER_KYC_FORM",
  TRIGGER_KYC_FORM_SUCCESS: "TRIGGER_KYC_FORM_SUCCESS",
  SKU_DATA: " SKU_DATA",
  SKU_DATA_SUCCESS: "SKU_DATA_SUCCESS",
  SKU_DELETE_DATA: "SKU_DELETE_DATA",
  SKU_EDIT_DATA: "SKU_EDIT_DATA",
  SKU_VIEW_DATA: "SKU_CATEGORY_VIEW_DATA",
  SKU_VIEW_DATA_SUCCESS: "SKU_CATEGORY_VIEW_DATA_SUCCESS",
  CATEGORY_BY_SKU_DATA: "CATEGORY_BY_SKU_DATA",
  CATEGORYS_DATA: "CATEGORYS_DATA",
  CATEGORYS_DATA_SUCCESS: "CATEGORYS_DATA_SUCCESS",
  CATEGORY_DELETE_DATA: "CATEGORY_DELETE_DATA",
  CATEGORY_VIEW_DATA: "CATEGORY_VIEW_DATA",
  CATEGORY_VIEW_DATA_SUCCESS: "CATEGORY_VIEW_DATA_SUCCESS",
  CATEGORY_EDIT_DATA: "CATEGORY_EDIT_DATA",
  UPLOAD_CANCELLED_CHEQUE: "UPLOAD_CANCELLED_CHEQUE",
  UPLOAD_CANCELLED_CHEQUE_SUCCESS: "UPLOAD_CANCELLED_CHEQUE_SUCCESS",
  UPLOAD_KYC_DOCUMENT: "UPLOAD_KYC_DOCUMENT",
  UPLOAD_KYC_DOCUMENT_SUCCESS: "UPLOAD_KYC_DOCUMENT_SUCCESS",
  SOS_REQUEST: "SOS_REQUEST",
  SOS_REQUEST_SUCCESS: "SOS_REQUEST_SUCCESS",
  SOS_UPDATE_REQUEST: "SOS_UPDATE_REQUEST",
  SOS_UPDATE_REQUEST_SUCCESS: "SOS_UPDATE_REQUEST_SUCCESS",
  ORDERS_DATA: "ORDERS_DATA",
  ORDERS_DATA_SUCCESS: "ORDERS_DATA_SUCCESS",
  APPROVE_ORDER: "APPROVE_ORDER",
  APPROVE_ORDER_SUCCESS: "APPROVE_ORDER_SUCCESS",
  ORDER_VIEW_DATA: "ORDER_VIEW_DATA",
  ORDER_VIEW_DATA_SUCCESS: "ORDER_VIEW_DATA_SUCCESS",
  REPEAT_ORDER: " REPEAT_ORDER",
  REPEAT_ORDER_SUCCESS: "REPEAT_ORDER_SUCCESS",
  ORDER_CANCEL: "ORDER_CANCEL",
  BUYER_SEARCH: "BUYER_SEARCH",
  ORDER_UPDATE: "ORDER_UPDATE",
  ADD_TRANSPORT: "ADD_TRANSPORT",
  ISSUE_PAYMENT_LINK: "ISSUE_PAYMENT_LINK",
  INTIATE_REFUND: "INTIATE_REFUND",
  RESEND_PAYMENT: "RESEND_PAYMENT",
  ENUM: "ENUM",
  ENUM_SUCCESS: "ENUM_SUCCESS",

  DELIVERY_SLOT_LIST_DATA: "DELIVERY_SLOT_LIST_DATA",
  DELIVERY_SLOT_LIST_DATA_SUCCESS: "DELIVERY_SLOT_LIST_DATA_SUCCESS",

  REMOVE_DELIVERY_SLOT_LIST_DATA: "REMOVE_DELIVERY_SLOT_LIST_DATA",
  REMOVE_DELIVERY_SLOT_LIST_DATA_SUCCESS:
    "REMOVE_DELIVERY_SLOT_LIST_DATA_SUCCESS",

  ENUM_EDIT: "ENUM_EDIT",
  ENUM__EDIT_SUCCESS: "ENUM_EDIT_SUCCESS",
  ORDER_DISCOUNT: "ORDER_DISCOUNT",
  ORDER_DISCOUNT_SUCCESS: "ORDER_DISCOUNT_SUCCESS",
  CUSTOMER_VERIFICATION: "CUSTOMER_VERIFICATION",
  CUSTOMER_VERIFICATION_SUCCESS: "CUSTOMER_VERIFICATION_SUCCESS",
  GET_SOS_TYPES: "GET_SOS_TYPES",
  GET_SOS_TYPES_SUCCESS: "GET_SOS_TYPES_SUCCESS",
  CREATE_SOS: "CREATE_SOS",
  CREATE_SOS_SUCCESS: "CREATE_SOS_SUCCESS",
  GET_BUYER_INFO: "GET_BUYER_INFO",
  // GET_BUYER_INFO_SUCCESS: "GET_BUYER_INFO_SUCCESS",
  SEND_OTP_LOGIN: "SEND_OTP_LOGIN",
  // SEND_OTP_LOGIN_SUCCESS: "SEND_OTP_LOGIN_SUCCESS",
  VERIFY_OTP_LOGIN: "VERIFY_OTP_LOGIN",
  // VERIFY_OTP_LOGIN_SUCCESS: "VERIFY_OTP_LOGIN_SUCCESS"

  ADMIN_ALL_DATA_SUCCESS: "ADMIN_ALL_DATA_SUCCESS",
  ADMIN_ALL_DATA: "ADMIN_ALL_DATA",
  ADMIN_DELETE_DATA: "ADMIN_DELETE_DATA",
  ADMIN_SORT_DATA: "ADMIN_SORT_DATA",
  ADMIN_CREATE_DATA: "ADMIN_CREATE_DATA",

  // get admin data
  ADMIN_VIEW_DATA: "ADMIN_VIEW_DATA",
  ADMIN_VIEW_DATA_SUCCESS: "ADMIN_VIEW_DATA_SUCCESS",
  ADMIN_EDIT_DATA: "ADMIN_EDIT_DATA",
  CREATE_CART: "CREATE_CART",
  GET_CART: "GET_CART",
  DELETE_CART: "DELETE_CART",
  PLACE_ORDER: "PLACE_ORDER",
  GET_APPROVAL: "GET_APPROVAL",
  ACKNOWLEDGE_APPROVAL: "ACKNOWLEDGE_APPROVAL",
  GET_PAYMENTS: "GET_PAYMENTS",
  SELLER_COUNT_DATA: "SELLER_COUNT_DATA",
  SELLER_COUNT_DATA_SUCCESS: "SELLER_COUNT_DATA_SUCCESS",
  PRODUCT_COUNT_DATA: "PRODUCT_COUNT_DATA",
  PRODUCT_COUNT_DATA_SUCCESS: "PRODUCT_COUNT_DATA_SUCCESS",
  SELLER_BY_CATEGORY_DATA: "SELLER_BY_CATEGORY_DATA",
  SELLER_ASSIGNED_BY_CATEGORY: "SELLER_ASSIGNED_BY_CATEGORY",
  SELLER_ASSIGNED_BY_CATEGORY_SUCCESS: "SELLER_ASSIGNED_BY_CATEGORY_SUCCESS",
  MULTLI_SELLER_MAPPED: "MULTLI_SELLER_MAPPED",
  MULTLI_SELLER_MAPPED_CATEGORY: "MULTLI_SELLER_MAPPED_CATEGORY",
  UPDATE_LIMRA_RATE:"UPDATE_LIMRA_RATE"
};
