import React, { Component } from "react";
import removeIcon from "../../../images/remove.png";
import { Col, Row } from "reactstrap";
export default class OrderSlotsNotification extends Component {
  render() {
    return (
      <>
        <Col xs={12} sm={12} md={12} className="mb-15" id="slotIds">
          <div className=" notification-bottom">
            <Row>
              <Col xs={12} sm={12} md={10}>
                <div className="sameSlotText">Order Dates are Not in Line</div>
              </Col>
              <Col xs={12} sm={12} md={2}>
                <div class="notification-close">
                  <img
                    src={removeIcon}
                    alt="close"
                    onClick={this.props?.removesameSLotDiv}
                  ></img>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </>
    );
  }
}
