import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import { Grid } from "@material-ui/core";
import deleteIcons from "../../../images/Trash icon.svg";

export default class DeliverySlot extends Component {
  render() {
    return (
        <>
        <Row>
          <Col md="12">
            <FormGroup
              className=""
              style={{
                textAlign: "left",
                width: "100%",
              }}
            >
              <Label className="" for="Value">
                Value{" "}
              </Label>

              <Input
                id="enumvalue"
                name="enumvalue"
                type="text"
                placeholder="Type..."
                // value={this.state.EnumValue}
                // invalid= {(NUMBER_REGEX.test(this.state.EnumValue) || this.state.EnumValue?.length == 0) ? false : true}
                onChange={(e) =>
                  this.props.onAddEnumChange("addEnum", e.target.value)
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          {this.props.EnumValue?.map((t) => (
            <Col
              md={2}
              xs={12}
              sm={12}
              // className="text-center"
            >
              <Card>
                <CardBody style={{ padding: "6px" }}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                  >
                    <Grid item>{t ? t : ""}</Grid>
                    <Grid item>
                      <div>
                        <img
                          src={deleteIcons}
                          alt="image loading"
                          className=""
                          disabled={true}
                          height="80%"
                          width="80%"
                          onClick={() => {
                            this.props.deleteEnumSlot(t);
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
              <br></br>
            </Col>
          ))}
        </Row>
      </>
    )
  }
}
