const TenMbInBytes = 10000000;
const MaximumFileSizeLimitExceeded =
  "Maximum file size (10 MB) limit exceeded!";
const ClickHereToDownload = "Click to download and view your";
const regularExpressionNumber = /^[0-9]*$/;
export {
  TenMbInBytes,
  MaximumFileSizeLimitExceeded,
  ClickHereToDownload,
  regularExpressionNumber,
};
export const EnumTypesObject = {
  PackagingCost: "PackagingCost",
  TransportCost: "TransportCost",
  Commission: "Commission",
  kycForOrders: "kycForOrders",
  Error_Found: "Error_Found",
};
export const ProductStatus = {
  PriceUpdated: "PriceUpdated",
  DeliveryDateNotMatch: "DeliveryDateNotMatch",
  false: "false",
};

export const errorMessage = {
  "deliveryDate must be a valid ISO 8601 date string":
    "Please Select Delivery Date",
};
export const isVinculumSuccess="is in sync with vinculum"
export const isVinculumError="is in not sync with vinculum"
export const isSellerError="Seller already mapped with this category"
//user account type
export const UserTypeSeller = "seller";
export const UserTypeBuyer = "buyer";
export const UserTypeLogistic = "logistic";
export const UserTypeCustomer = "customer";
export const UserTypeAdmin = "admin";
export const UserTypeMaker = "maker";
export const UserTypeChecker = "checker";
export const UserTypeSales = "sales";
export const moqUnitsList = [
  { label: "Kgs", value: "Kgs" },
  { label: "Pcs", value: "Pcs" },
];
let timeout;
export const debouncing = (callback) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  timeout = setTimeout(() => {
    callback();
  }, 650);
};
