import React, { Component, createRef } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Container,
  Link,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  InputGroupText,
} from "reactstrap";

import DatePickerDiver from "./DatePickerDelivery";
import MainHeader from "../../../components/common/MainHeader";
import searchIcon from "../../../images/search.png";
import editIcon from "../../../images/EditIcon.png";
import { Grid } from "@material-ui/core";
import plusicon from "../../../images/plusicon.svg";
import deleteIcons from "../../../images/Trash icon.svg";
import orderbuyerdetailimg from "../../../images/orderbuyerdetailimg.png";
import { ProductStatus } from "../../../common/constants";
import DateDisable from "../../DateDisable";
import SwitchToggleSample from "./SwitchToggleSample";
import RepeatDialogue from "./RepeatDialogue";
import { EXTRACT_NUMBER_REGEX } from "../../../common/components/common/Rejex";
import OrderSlotsNotification from "./OrderSlotsNotification";
import { MDBDataTable } from "mdbreact";
import SelectComponent from "../../Product/common/SelectComponent";
import {
  getOrdersData,
  buyerSearch,
  fetchproductSortData,
  createCart,
  getCart,
  placeOrder,
  deleteCart,
  fetchEnum,
} from "../../../store/actions";
import { connect } from "react-redux";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import { NotificationManager } from "react-notifications";
import VoucherCode from "./VoucherCode";
// import Loder
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import imagePending from "../../../images/image-pending.jpg";
import {
  assignUnsupportedFileFormat,
  handleErrorMsg,
  percentageCommission,
} from "../../../common/components/common/GlobalFunctions";
import { SKU_NAME_REGEX } from "../../../common/components/common/Rejex";
import SwitchButton from "../../../common/components/common/switchButton";
import Limra from "../../../images/Limra.png";
let debounceThis;
let timeOut;
// let inputFileRef = createRef(null);
const statusOption = [
  { label: "All", value: false },
  { label: "Approved", value: true },
];
class OrderBuyerDetail extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      buyerDataList: [],
      buyerName: "",
      selectedBuyer: "",
      statusApprove: "",
      deliverySlotDate: "",
      deliveryRepeatDate: "",
      monthDate: "",
      sampleOrder: "",
      couponName: "",
      couponAmount: "",
      switchText: "",
      searchBuyerName: "",
      currentPage: 1,
      limit: 5,
      moqIncDec: {},
      selectedBuyerCart: {},
      orderProof: { 0: "", 1: "", 2: "", 3: "" },
      orderProofFormData: { 0: "", 1: "", 2: "", 3: "" },
      selectedProductToDeleted: "",
      dataLoading: false,
      horeca: false,
      skuName: "",
      sameSlots: "",
      enumTypeCoupon: "",
      repeatToggle: "",
      dateToggle: false,
      existingUser: "",
      monthDate: new Date(),
      dateFlag: false,
      accordianForLogs: undefined,
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      skuName: "",
      statusApprove: "",
    });
  };
  handleMonthChange = (date) => {
    this.setState({ monthDate: date, dateFlag: true });
  };
  componentDidMount() {
    this.fetchBuyerSearch();
    this.fetchEnumCouponCode();
    // this.confirmRepeatToggle();
    this.repeatBuyerSelected();
    this.checkRepeatToggle();
  }

  accordionToggle = (id) => {
    if (this.state.accordianForLogs == id) {
      this.setState({
        accordianForLogs: undefined,
      });
    } else {
      this.setState({
        accordianForLogs: id,
      });
    }
  };

  repeatBuyerSelected = () => {
    // console.log(this.props.history.location.state, "histoory");
    if (this.props.history?.location?.state) {
      let repeatBuyer = this.props.history?.location?.state?.buyer?.buyerId;
      let repeatBuyerObj = {
        label:
          repeatBuyer?.fullName && repeatBuyer?.fullName != "null"
            ? repeatBuyer?.fullName
            : "Null",
        value: {
          id: repeatBuyer._id,
          isBuyerHoreca: repeatBuyer?.Horeca,
          fullName: repeatBuyer?.fullName,
          address: repeatBuyer?.address,
          pincode: repeatBuyer?.pincode,
          phone: repeatBuyer?.phone,
        },
      };
      this.setState(
        {
          selectedBuyer: repeatBuyerObj,
        },
        () => {
          this.getCart();
        }
      );
    }
  };

  fetchEnumCouponCode = () => {
    this.props.fetchEnum((enumData) => {
      if (enumData) {
        this.setState({ enumTypeCoupon: enumData });
      }
    });
  };
  confirmRepeatToggle = () => {
    this.setState({ repeatToggle: !this.state.repeatToggle }, () => {});
  };

  //date Toogle
  openDateToggle = () => {
    this.setState(
      { repeatToggle: false, monthDate: new Date(), dateFlag: false },
      () => {
        this.setState({ dateToggle: !this.state.dateToggle }, () => {});
      }
    );
  };
  checkRepeatToggle = (res) => {
    if (
      res?.value?.productList &&
      !res?.value?.finalDeliverySlot?.lastDeliverySlot &&
      this.props.history.location.state
    ) {
      this.setState({ repeatToggle: true }, () => {});
    } else {
      this.setState({ repeatToggle: false }, () => {});
    }
  };

  handleChangePage = (event, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.fetchproductSortData(offset);
  };

  fetchOrdersData = () => {
    let DataToSent = {
      buyerName: this?.state?.buyerName?.value
        ? this?.state?.buyerName?.value
        : "",
    };
    this.props.getOrdersData(DataToSent, (data) => {
      // console.log("DATA", data);
      this.setState({ OrderData: data });
    });
  };
  percentageCommission = (commisionName) => {
    let commisionValue = "x";
    if (commisionName && this.state.selectedBuyerCart?.rates?.length > 0) {
      const filtercommisionValue = this.state.selectedBuyerCart?.rates.filter(
        (ele) => ele?.EnumType === commisionName
      )[0]?.EnumValue;

      commisionValue = filtercommisionValue ? filtercommisionValue : "x";
    }
    // console.log(commisionValue);
    return commisionValue;
  };

  fetchBuyerSearch = () => {
    // this.setState({
    //   dataLoading: true,
    // });
    this.props.buyerSearch(
      0,
      10,
      this.state.searchBuyerName ? this.state.searchBuyerName : "",
      (res) => {
        if (res) {
          this.setState({
            buyerDataList: res?.docs.map((ele) => {
              return {
                label:
                  ele?.fullName && ele?.fullName != "null"
                    ? ele?.fullName
                    : "Null",
                value: {
                  id: ele._id,
                  isBuyerHoreca: ele?.Horeca,
                  fullName: ele?.fullName,
                  address: ele?.address,
                  pincode: ele?.pincode,
                  phone: ele?.phone,
                },
              };
            }),
          });
        }
        // this.setState({
        //   dataLoading: false,
        // });
      }
    );
  };

  fetchproductSortData = (offset = 0) => {
    this.setState({
      dataLoading: true,
    });
    this.props.fetchproductSortData(
      offset,
      this.state.limit,
      this.state?.category?.minprice,
      this.state?.category?.maxprice,
      this.state?.category?.value,
      this.state?.size?.value,
      this.state?.seller?.value,
      this.state?.SelectedSku?.value,
      true,  // preApproved as true
      this.state.deliverySlotDate,
      this.state?.selectedBuyer?.value?.id,
      this.state.skuName,
      "",
      "",
      () => {
        this.setState({
          dataLoading: false,
        });
      }
    );
  };

  getCart = () => {
    this.setState({
      dataLoading: true,
    });
    this.props.getCart(
      {
        buyerId: this.state.selectedBuyer?.value?.id,
      },
      (res) => {
        this.setState(
          {
            selectedBuyerCart: res?.value,
            sameSlots: res?.value?.finalDeliverySlot?.sameSlots,
            dataLoading: false,
            existingUser: res?.value?.existingUser,
            falseProduct: res?.value?.falseProduct,
            deliverySlotDate: res.value?.deliveryDate
              ? moment(res?.value?.deliveryDate).format("YYYY-MM-DD")
              : "",
          },
          () => {
            this.checkRepeatToggle(res);
            let couponVal = this.state.enumTypeCoupon?.allEnums?.filter(
              (ele) => ele?.EnumType === "CouponCode"
            )[0]?.EnumValue;
            this.setState(
              { sampleOrder: this.state.selectedBuyerCart?.sampleCart },
              () => {
                if (this.state.sampleOrder === true) {
                  this.setState(
                    {
                      switchText: "Sample",
                      couponName: couponVal ? couponVal[0]?.couponName : "",
                      // couponAmount: couponVal ? couponVal[0]?.couponAmount : "",
                    },
                    () => {}
                  );
                } else if (this.state.sampleOrder === false) {
                  this.setState(
                    { switchText: "Original", couponName: "", couponVal: "" },
                    () => {}
                  );
                }
              }
            );

            if (this.state.sameSlots === false) {
              clearTimeout(timeOut);
            }
            timeOut = setTimeout(() => {
              this.setState({ sameSlots: "" }, () => {
                this.removesameSLotDiv();
              });
            }, 8000);
          }
        );
      }
    );
  };

  deleteCart = (e) => {
    this.deletetoggle();
    this.setState({
      dataLoading: true,
    });
    e.preventDefault();
    this.props.deleteCart(
      {
        productId: this.state.selectedProductToDeleted,
        buyerId: this.state.selectedBuyer?.value?.id,
      },
      (res) => {
        if (
          res?.message === "Product has been removed from cart" ||
          res?.status === 200 ||
          res["message"] === "Cart has been deleted"
        ) {
          NotificationManager.success(res?.message, "Success");
          this.getCart();
        } else {
          // NotificationManager.error(
          //   Array.isArray(res?.message) ? res?.message[0] : res?.message,
          //   "Error"
          // );
          handleErrorMsg(res);
        }
        this.setState({
          dataLoading: false,
        });
      }
    );
  };

  selectFile = (index, file) => {
    file = file[0];
    // to send
    let formObj = {};
    formObj[index] = file;

    //to view
    let bloburl = window?.URL?.createObjectURL(file);
    let obj = {};
    obj[index] = bloburl;

    this.setState({
      orderProof: { ...this.state.orderProof, ...obj },
      orderProofFormData: { ...this.state.orderProofFormData, ...formObj },
    });
  };

  removeFile = (index) => {
    let orderProof = this.state.orderProof;
    orderProof[index] = "";

    let orderProofFormData = this.state.orderProofFormData;
    orderProofFormData[index] = "";

    this.setState({
      orderProof: orderProof,
      orderProofFormData: orderProofFormData,
    });
  };
  componentWillUnmount = () => {
    this.setState({ selectedBuyer: "" });
  };
  createCart = (e, ele) => {
    e.preventDefault();
    let notInCart = this.state.selectedBuyerCart?.productList?.every((a) => {
      // console.log(a?.productId?._id, ele?._id);
      return a?.productId?._id != ele?._id;
    });
    let data = {
      buyerId: this.state.selectedBuyer?.value?.id,
      productId: ele?._id,
      quantity: this.state.moqIncDec[ele?._id],
      sampleCart: JSON.stringify(this.state.sampleOrder),
      // deliveryDate: this.state.deliveryRepeatDate,
    };
    let cartId = !notInCart ? this.state.selectedBuyerCart?._id : "";
    this.setState({
      dataLoading: true,
    });
    this.props.createCart(data, this.state.deliverySlotDate, cartId, (res) => {
      if (
        res &&
        ["Cart has been updated", "Cart added successfully"].includes(
          res?.message
        )
      ) {
        NotificationManager.success(res?.message, "Success");
        this.getCart();
      } else {
        // NotificationManager.error(
        //   Array.isArray(res?.message) ? res?.message[0] : res?.message,
        //   "Error"
        // );
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
  };
  createSampleCart = (text) => {
    if (this.state.selectedBuyerCart?.productList?.length > 0) {
      let data = {
        buyerId: this.state.selectedBuyer?.value?.id,
        sampleCart: JSON.stringify(this.state.sampleOrder),
      };
      let cartId = this.state.selectedBuyerCart
        ? this.state.selectedBuyerCart?._id
        : 0;
      this.setState({
        dataLoading: true,
      });
      this.props.createCart(
        data,
        this.state.deliverySlotDate,
        cartId,
        (res) => {
          if (res && ["Cart has been updated"].includes(res?.message)) {
            NotificationManager.success(res?.message, "Success");

            this.getCart();
          } else {
            handleErrorMsg(res);

            if (text === "Sample") {
              this.setState({ switchText: "Original", sampleOrder: false });
            } else if (text === "Original") {
              let couponVal = this.state.enumTypeCoupon?.allEnums?.filter(
                (ele) => ele?.EnumType === "CouponCode"
              )[0]?.EnumValue;
              this.setState({
                switchText: "Sample",
                sampleOrder: true,
                couponName: couponVal ? couponVal[0]?.couponName : "",
              });
            }
          }
          this.setState({
            dataLoading: false,
          });
        }
      );
    }
  };

  switchSample = (text) => {
    this.setState({ switchText: text }, () => {
      if (this.state.switchText === "Sample" && !this.state.sampleOrder) {
        let couponVal = this.state.enumTypeCoupon?.allEnums?.filter(
          (ele) => ele?.EnumType === "CouponCode"
        )[0]?.EnumValue;
        this.setState(
          {
            sampleOrder: true,
            couponName: couponVal ? couponVal[0]?.couponName : "",
            // couponAmount: couponVal ? couponVal[0]?.couponAmount : "",
          },
          () => {
            this.createSampleCart(text);
          }
        );
      } else if (
        this.state.switchText === "Original" &&
        this.state.sampleOrder
      ) {
        this.setState(
          { sampleOrder: false, couponName: "", couponAmount: "" },
          () => {
            this.createSampleCart(text);
          }
        );
      }
    });
  };

  placeOrder = (e) => {
    this.setState({
      dataLoading: true,
    });
    e.preventDefault();
    // console.log(this.state.selectedBuyerCart, this.state.selectedBuyer);
    let form = new FormData();
    form.append("cartId", this.state.selectedBuyerCart?._id);
    form.append("buyerId", this.state.selectedBuyer?.value?.id);
    if (this.state.sampleOrder) {
      form.append("couponName", this.state.couponName);
    }
    Object.values(this.state.orderProofFormData).forEach((a) => {
      if (a) form.append("orderConsentDocuments", a);
    });

    // for (var key of form.entries()) {
    //    console.log(key[0] + ', ' + key[1]);
    // }
    this.props.placeOrder(form, (res) => {
      if (res && res.message === "Order has been created successfully") {
        NotificationManager.success(res?.message, "Success");
        this.props?.history?.push("/Orders");
      }
      // NotificationManager.error(
      //   Array.isArray(res?.message) ? res?.message[0] : res?.message,
      //   "Error"
      // );
      else handleErrorMsg(res);
      this.setState({
        dataLoading: false,
      });
    });
  };
  deletetoggle = () => {
    this.setState({
      deletemodal: !this.state.deletemodal,
    });
  };
  updateMoqIncDec = (id, action, value) => {
    let keyValue = {};
    if (action === "add") keyValue[id] = parseInt(value) ? parseInt(value) : 1;
    else {
      keyValue[id] =
        action === "dec"
          ? this.state.moqIncDec[id] - 15
          : action === "inc"
          ? this.state.moqIncDec[id] + 15
          : this.state.moqIncDec[id];
    }
    if (keyValue[id] < 1) keyValue[id] = 1;

    // console.log(keyValue);
    this.setState({ moqIncDec: { ...this.state.moqIncDec, ...keyValue } });
  };

  onChange(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }

  debounceValue = (val, stateName) => {
    this.setState(
      {
        skuName: val,
        currentPage: 1,
      },
      () => {
        if (timeOut) {
          clearTimeout(timeOut);
        }
        timeOut = setTimeout(() => {
          this.fetchproductSortData();
        }, 1000);
      }
    );
  };
  herocaBuyerStates = (status) => {
    if (status === true) {
      return (
        <span style={{ color: "#56D35E" }} className="p-2">
          Approved
        </span>
      );
    } else if (status === false) {
      return (
        <span style={{ color: "#EE404C" }} className="p-2">
          Non-Approved
        </span>
      );
    } else {
      return <span>N/A</span>;
    }
  };

  handleSelectStatus = (name, status) => {
    this.setState({ statusApprove: status, currentPage: 1 }, () => {
      this.fetchproductSortData();
    });
  };
  deliveryDateFunc = (val) => {
    this.setState({ deliverySlotDate: val, currentPage: 1 }, () => {
      this.fetchproductSortData();
    });
  };
  deliveryDateRepeat = () => {
    this.setState({ deliveryRepeatDate: this.state.monthDate }, () => {
      let dateNew = moment(this.state.monthDate).format(
        "YYYY-MM-DDTHH:mm:ss.SSS"
      );
      let data = {
        buyerId: this.state.selectedBuyer?.value?.id,
        deliveryDate: dateNew,
      };
      this.setState({
        dataLoading: true,
      });
      this.props.createCart(
        data,
        "",
        this.state.selectedBuyerCart?._id,
        (res) => {
          if (
            res &&
            ["Cart has been updated", "Cart added successfully"].includes(
              res?.message
            )
          ) {
            NotificationManager.success(res?.message, "Success");
            this.openDateToggle();
            this.getCart();
          } else {
            handleErrorMsg(res);
          }
          this.setState({
            dataLoading: false,
            dateFlag: false,
          });
        }
      );
    });
  };

  removesameSLotDiv = () => {
    let ele = document.getElementById("slotIds");
    if (ele) {
      ele.style.display = "none";
    }
  };

  render() {
    // console.log(this.state.limit, "limit");
    const totalPages = Math.ceil(
      this.props?.listOfProductData?.length / this.state.limit
    );
    var data = {};
    var dataLandScape ={}
    data = {
      columns: [
        {
          label: <span>SKU</span>,
          field: "sku",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Category</span>,
          field: "category",
          sort: "asc",
          width: 300,
        },
        this.state?.selectedBuyer?.value?.isBuyerHoreca
          ? {
              label: <span>Status</span>,
              field: "status",
              sort: "asc",
              width: 300,
            }
          : {},

        {
          label: <span>Size/Count</span>,
          field: "count",
          sort: "asc",
          width: 300,
        },

        {
          label: <span>Delivery In</span>,
          field: "deliveryIn",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>MOQ</span>,
          field: "moq",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>MOQ Units</span>,
          field: "moqUnits",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Price</span>,
          field: "price",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Action</span>,
          field: "action",
          sort: "asc",
          width: 50,
        },
      ],
      rows: [],
    };
    dataLandScape ={
      columns: [
        {
          label: <span>SKU</span>,
          field: "sku",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>MOQ</span>,
          field: "moq",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Price</span>,
          field: "price",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Action</span>,
          field: "action",
          sort: "asc",
          width: 50,
        },
      ],
      rows: [],
    }
    const debouncing = (e, stateName) => {
      if (debounceThis) {
        clearTimeout(debounceThis);
        debounceThis = null;
      }
      debounceThis = setTimeout(() => {
        if (stateName === "orderId") {
          this.setState({
            [`${stateName}`]: e.target.value.trim(),
            currentPage: 1,
          });
        } else {
          this.setState({ [`${stateName}`]: e }, () => this.fetchBuyerSearch());
        }
      }, 1000);
    };

    //products data
    if (
      this.props.listOfProductData?.docs &&
      this.props.listOfProductData?.docs?.length > 0
    ) {
      this.props?.listOfProductData?.docs?.forEach((ele) => {
        let keyValue = {};
        keyValue[ele?._id] = ele?.moq;
        if (
          !this.state.moqIncDec[ele?._id] &&
          this.state.moqIncDec[ele?._id] != 0
        ) {
          this.setState({
            moqIncDec: { ...this.state.moqIncDec, ...keyValue },
          });
        }
        data.rows.push({
          sku: (
            <div className="d-flex align-items-center skuAlign">
              <div className="d-flex align-items-center skuImage">
                <img
                  src={
                    ele?.images[0]
                      ? ele?.images[0]
                      : ele?.sku?.skuImage[0]
                      ? ele?.sku?.skuImage[0]
                      : imagePending
                  }
                  alt="productimg"
                  className="w-100"
                ></img>
              </div>
              &nbsp;&nbsp;&nbsp;
              {ele?.preApproved === true &&
              this.state?.selectedBuyer?.value?.isBuyerHoreca ? (
                <p className="mb-0">
                  {ele?.sku?.skuName ? (
                    <span style={{ color: "#0DA6C8" }}>{ele.sku?.skuName}</span>
                  ) : (
                    "N/A"
                  )}
                </p>
              ) : ele?.preApproved === false &&
                this.state?.selectedBuyer?.value?.isBuyerHoreca ? (
                <p className="mb-0">
                  {ele?.sku?.skuName ? (
                    <span style={{ color: "#980033" }}>{ele.sku?.skuName}</span>
                  ) : (
                    "N/A"
                  )}
                </p>
              ) : (
                <p className="mb-0">
                  <span>{ele.sku?.skuName}</span>
                </p>
              )}
            </div>
          ),
          category: ele.category?.categoryName
            ? ele.category?.categoryName
            : "N/A",
          count: <div className="size-count">{ele.size}</div>,
          price: (
            <div className="d-flex align-itemms-center">
              &nbsp;
              {ele?.preApproved === true &&
              this.state?.selectedBuyer?.value?.isBuyerHoreca ? (
                <span> {"₹" + ele?.fixedRate}</span>
              ) : ele?.preApproved === false &&
                this.state?.selectedBuyer?.value?.isBuyerHoreca ? (
                <span>{` ₹ ${ele?.minPrice} - ₹ ${ele?.maxPrice}`}</span>
              ) : (
                <span>{"₹" + ele?.rate}</span>
              )}
            </div>
          ),
          status: this.herocaBuyerStates(ele?.preApproved),
          deliveryIn:
            ele?.deliverySlot && ele?.deliverySlot != null ? (
              <p className="mb-0">
                In {ele?.deliverySlot?.match(EXTRACT_NUMBER_REGEX)} Days{" "}
              </p>
            ) : (
              "N/A"
            ),
          rating: (
            <p>
              <div className={` ratingStartDiv ratingStarDiv${ele?.rating}`}>
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 19 18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                </svg>{" "}
                &nbsp;
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 19 18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                </svg>
                &nbsp;
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 19 18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                </svg>
                &nbsp;
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 19 18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                </svg>
                &nbsp;
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 19 18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                </svg>
                &nbsp;
                <span className="fw-400 fs-12">
                  {" "}
                  ({ele?.rating ? ele.rating : ""})
                </span>
              </div>
            </p>
          ),
          moq: (
            <div className="orderbuyerdetailflex">
              <span
                disable={!ele?.moq}
                onClick={() => this.updateMoqIncDec(ele?._id, "dec", "")}
                className="plusicon"
                style={{ cursor: "pointer" }}
              >
                -
              </span>
              {/* <p>{ele?.moq}</p> */}
              {/* <p>{moqIncDec[ele?._id]}</p> */}
              <input
                className="form-control buyerdetailmodalinput"
                value={this.state.moqIncDec[ele?._id]}
                onChange={(e) =>
                  this.updateMoqIncDec(ele?._id, "add", e?.target?.value)
                }
              />
              <span
                disable={!ele?.moq}
                onClick={() => this.updateMoqIncDec(ele?._id, "inc", "")}
                className="minusicon"
                style={{ cursor: "pointer" }}
              >
                +
              </span>
            </div>
          ),
          moqUnits: <div>{ele?.moqUnits ?? ""}</div>,
          date: ele?.deliveryDate ? (
            <div>{moment(ele.deliveryDate).format("DD/MM/YYYY")}</div>
          ) : (
            "N/A"
          ),
          action: (
            <div className="d-flex align-items-center tableBtns ">
              <Button
                className="btn lightBtn"
                onClick={(e) => {
                  this.createCart(e, ele);
                }}
              >
                Add
              </Button>
              &nbsp;&nbsp;
            </div>
          ),
        });
        dataLandScape.rows.push({
          sku: (
            <div className="d-flex text-center align-items-center skuAlign">
              {/* <div className="d-flex align-items-center skuImage">
                <img
                  src={
                    ele?.images[0]
                      ? ele?.images[0]
                      : ele?.sku?.skuImage[0]
                      ? ele?.sku?.skuImage[0]
                      : imagePending
                  }
                  alt="productimg"
                  className="w-100"
                ></img>
              </div>
              &nbsp;&nbsp;&nbsp; */}
              {ele?.preApproved === true &&
              this.state?.selectedBuyer?.value?.isBuyerHoreca ? (
                <p className="mb-0">
                  {ele?.sku?.skuName ? (
                    <span style={{ color: "#0DA6C8" }}>{ele.sku?.skuName}</span>
                  ) : (
                    "N/A"
                  )}
                </p>
              ) : ele?.preApproved === false &&
                this.state?.selectedBuyer?.value?.isBuyerHoreca ? (
                <p className="mb-0">
                  {ele?.sku?.skuName ? (
                    <span style={{ color: "#980033" }}>{ele.sku?.skuName}</span>
                  ) : (
                    "N/A"
                  )}
                </p>
              ) : (
                <p className="mb-0">
                  <span>{ele.sku?.skuName}</span>
                </p>
              )}
            </div>
          ),
          price: (
            <div className="d-flex align-itemms-center">
              &nbsp;
              {ele?.preApproved === true &&
              this.state?.selectedBuyer?.value?.isBuyerHoreca ? (
                <span> {"₹" + ele?.fixedRate}</span>
              ) : ele?.preApproved === false &&
                this.state?.selectedBuyer?.value?.isBuyerHoreca ? (
                <span>{` ₹ ${ele?.minPrice} - ₹ ${ele?.maxPrice}`}</span>
              ) : (
                <span>{"₹" + ele?.rate}</span>
              )}
            </div>
          ),
          moq: (
            <div className="orderbuyerdetailflex">
              <button
                disable={!ele?.moq}
                onClick={() => this.updateMoqIncDec(ele?._id, "dec", "")}
                className="plusicon"
              >
                -
              </button>
              {/* <p>{ele?.moq}</p> */}
              {/* <p>{moqIncDec[ele?._id]}</p> */}
              <InputGroup className="">
              <input
                className="form-control  productPriceInput priceinputSize"
                value={this.state.moqIncDec[ele?._id]}
                style={{ color:'#000'}}
                type='number'
                inputMode='numeric'
                onChange={(e) =>
                  this.updateMoqIncDec(ele?._id, "add", e?.target?.value)
                }
              />
              <InputGroupText className="productPriceInput">
                {ele?.moqUnits ?? "Kgs"}
                </InputGroupText>
              </InputGroup>
              <button
                disable={!ele?.moq}
                onClick={() => this.updateMoqIncDec(ele?._id, "inc", "")}
                className="minusicon"
              >
                +
              </button>
            </div>
          ),
          action: (
            <div className="d-flex align-items-center tableBtns ">
              <Button
                className="btn lightBtn"
                onClick={(e) => {
                  this.createCart(e, ele);
                }}
              >
                Add
              </Button>
              &nbsp;&nbsp;
            </div>
          ),
        });
      });
    }
    // console.log(this.state.selectedBuyerCart);
    // console.log(this.state.selectedBuyerCart?.productList);
    return (
      <>
        <MainHeader pageTitle="Create Order" />
        {this.state.dataLoading ? (
          <div className="ViewPagesLoder1">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="mainParentSectionDiv">
            <div className="dashInnerMainDiv">
              <>
                <Modal
                scrollable
                  funk={true}
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                >
                  <ModalHeader toggle={this.toggle} >
                              <h5 className="align-items-center">Add Product</h5>
                              <div style={{width:'200px', paddingRight:'20px'}}>
                              <DateDisable
                                  deliveryDateFunc={this.deliveryDateFunc}
                                  deliveryDateVal={this.state.deliverySlotDate}
                                />
                              </div>
                      {/* <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv debounceInput">
                        <Input
                          name="search"
                          placeholder="SkuName"
                          type="text"
                          className="mb-0"
                          value={this.state.skuName}
                          autoComplete="off"
                          onChange={(e) => {
                            this.debounceValue(e.target.value, "skuName");
                          }}
                          // invalid={
                          //   SKU_NAME_REGEX.test(this.state.skuName) ||
                          //   this.state.skuName?.length == 0
                          //     ? false
                          //     : true
                          // }
                        />
                      </FormGroup> */}
                  </ModalHeader>
                  <ModalBody>
                    <Container fluid className="p-0">
                      <Row>
                        <Col xs={12} className="">
                          <Grid
                            className=""
                            container
                            spacing={2}
                            direction="row"
                            alignItems="center"
                          >
                            {/* <Grid item xs={6} sm={6} md={2}></Grid>
                            <Grid item xs={6} sm={6} md={3}>
                              {this.state?.selectedBuyer?.value
                                ?.isBuyerHoreca ? (
                                <>
                                  <div className="mt-2 text-start LandScapeDisplayNone">
                                  <SelectComponent
                                    options={statusOption}
                                    onChange={(val) => {
                                      this.handleSelectStatus(
                                        "statusApprove",
                                        val
                                      );
                                    }}
                                    value={this.state.statusApprove}
                                    isClearable
                                  />
                                </div>
                                <div className="mt-2 LandScapeDisplay">
                                    <Row>
                                      <Col className="text-end">
                                        <br />
                                        <p >Is Approved</p>
                                      </Col>
                                      <Col className="text-start">
                                        <SwitchButton
                                          marginStyle='0'
                                          onChange={(val) => {
                                            this.state.statusApprove.value?
                                            this.handleSelectStatus(
                                              "statusApprove",{
                                                label:"Approved",
                                                value:false
                                              }
                                            ):this.handleSelectStatus(
                                              "statusApprove",{
                                                label:"Approved",
                                                value:true
                                              }
                                            )
                                          }}
                                          value={this.state.statusApprove.value}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </Grid>
                             */}

                            {/* <Grid item xs={6} sm={6} md={4}>
                            <div className="input-border orderAdd">
                                <InputGroup>
                                  <Input
                                    className="form-control border-end-0 border mb-0"
                                    type="search"
                                    id="example-search-input"
                                    name="search"
                                    placeholder="SkuName"
                                    value={this.state.skuName}
                                    autoComplete="off"
                                    onChange={(e) => {
                                      this.debounceValue(
                                        e.target.value,
                                        "skuName"
                                      );
                                    }}
                                    // invalid={
                                    //   SKU_NAME_REGEX.test(this.state.skuName) ||
                                    //   this.state.skuName?.length == 0
                                    //     ? false
                                    //     : true
                                    // }
                                    aria-label="Search"
                                    icon="fas fa-times-circle"
                                  />
                                  <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                                  <span class="input-group-append">
                                    <button
                                      class="btn btn-outline border-left-0 border"
                                      type="button"
                                    >
                                      <img
                                        className="pl-1"
                                        src={searchIcon}
                                        alt=""
                                      ></img>
                                    </button>
                                  </span>
                                </InputGroup>
                              </div>
                            </Grid> */}
                          </Grid>
                          <div className="addProductList LandScapeDisplayNone">
                            <MDBDataTable
                              className="text-center text-capitalize addProductstyle"
                              responsive
                              hover
                              data={data}
                              paging={false}
                              searching={false}
                            />
                          </div>
                          <div className="addProductList LandScapeDisplay">
                            <MDBDataTable
                              className="text-center text-capitalize addProductstyle"
                              responsive
                              hover
                              data={dataLandScape}
                              paging={false}
                              searching={false}
                            />
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <p className="mb-0">
                                Showing{" "}
                                {this.props?.listOfProductData?.docs?.length
                                  ? (this.state.currentPage - 1) *
                                      this.state.limit +
                                    1
                                  : (this.state.currentPage - 1) *
                                    this.state.limit}{" "}
                                to{" "}
                                {(this.state.currentPage - 1) *
                                  this.state.limit +
                                  (this.props?.listOfProductData?.docs?.length
                                    ? this.props?.listOfProductData?.docs
                                        ?.length
                                    : "")}{" "}
                                {} entries of{" "}
                                {this.props?.listOfProductData?.length}
                              </p>
                            </div>
                            <div className="custPaginationDiv">
                              <Pagination
                                shape="rounded"
                                count={totalPages}
                                page={this.state.currentPage}
                                color="primary"
                                onChange={this.handleChangePage}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </ModalBody>
                </Modal>
              </>

              <Modal
                funk={true}
                isOpen={this.state.deletemodal}
                toggle={this.deletetoggle}
              >
                <ModalHeader toggle={this.deletetoggle}>Confirm</ModalHeader>
                <ModalBody>Are you sure you want to delete?</ModalBody>
                <ModalFooter className="footermodal">
                  <Button onClick={(e) => this.deleteCart(e)}>Delete</Button>
                  <Button
                    color="secondary"
                    onClick={this.deletetoggle}
                    className="offlinepaymentbtn"
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>

              <>
                {/* <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv ">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <h4>Select Buyer</h4>
                    <div></div>
                  </div>
                </div> */}

                <div>
                  <Row className="mobCustRow">
                    <Col
                      xs={12}
                      sm={12}
                      md={
                        this.state?.selectedBuyer?.value?.isBuyerHoreca &&
                        this.state.selectedBuyerCart
                          ? 4
                          : 6
                      }
                      className="mb-15"
                    >
                      <FormGroup>
                        <Label>
                          Select Buyer<span style={{ color: "red" }}>*</span>
                        </Label>
                        <SelectComponent
                          options={this.state.buyerDataList}
                          value={
                            this.state.buyerName
                              ? this.state.buyerName
                              : this.state.selectedBuyer
                          }
                          onChange={(e) => {
                            this.setState(
                              {
                                buyerName: e,
                                selectedBuyer: e,
                                currentPage: 1,
                              },
                              () => {
                                this.getCart();
                              }
                            );
                          }}
                          onInputChange={(e) => {
                            debouncing(e.trimStart(), "searchBuyerName");
                          }}
                          placeholder="Buyer"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={5} className="mb-15">
                      <FormGroup>
                        <Label for="product">
                          Select Product<span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          id="product"
                          name="product"
                          placeholder="Select"
                          style={{cursor:'pointer'}}
                          className="form-control"
                          readOnly
                          onClick={() => {
                            if (this.state.selectedBuyer) {
                              this.setState({ moqIncDec: {} });
                              this.toggle();
                              this.fetchproductSortData();
                            }
                          }}
                        ></Input>
                        <div
                          className="inputplus"
                          onClick={() => {
                            if (this.state.selectedBuyer) {
                              this.setState({ moqIncDec: {} });
                              this.toggle();
                              this.fetchproductSortData();
                            }
                          }}
                        >
                          +
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={12} md={3} className="mb-15">
                      <FormGroup>
                        {this.state?.selectedBuyer?.value?.isBuyerHoreca &&
                        this.state.selectedBuyerCart ? (
                          <>
                            <Label for="">
                              <span className="mr-4">Order</span>
                            </Label>
                            <SwitchToggleSample
                              switchSample={this.switchSample}
                              switchText={this.state.switchText}
                              existingUser={this.state.existingUser}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                    </Col>
                    <div>
                      <Accordion 
                        open={this.state.accordianForLogs}
                        toggle={(newIndex) => {
                          this.accordionToggle(newIndex);
                        }}
                      >
                        <AccordionItem>
                          <AccordionHeader targetId="1">Order Proof</AccordionHeader>
                          <AccordionBody accordionId="1">
                            <Row>
                        {Object.values(this.state.orderProof).map(
                          (value, index) => {
                            return value ? (
                              <Col
                                xl={3}
                                xs={12}
                                sm={4}
                                md={6}
                                className="mb-15"
                              >
                                <div className="buyerdetailbox">
                                  <div>
                                    <span
                                      onClick={() => this.removeFile(index)}
                                      className="buyerdetailicon"
                                    >
                                      X
                                    </span>
                                  </div>
                                  <img
                                    style={{ width: "100%", height: "100%" }}
                                    src={value}
                                    onError={(e) =>
                                      assignUnsupportedFileFormat(e)
                                    }
                                  />
                                </div>
                              </Col>
                            ) : (
                              <Col
                                onClick={() =>
                                  document.getElementById(index)?.click()
                                }
                                xl={3}
                                xs={12}
                                sm={4}
                                md={6}
                                className="mb-15"
                              >
                                <div className="buyerdetailbox">
                                  <div className="plusiconsvg">
                                    <input
                                      id={index}
                                      onChange={(e) =>
                                        this.selectFile(index, e.target?.files)
                                      }
                                      hidden={true}
                                      type="file"
                                    />
                                    <img src={plusicon} />
                                  </div>
                                </div>
                              </Col>
                            );
                          }
                        )}

                        {/* <Col xl={3} xs={6} sm={6} md={6} className="mb-15">
                      <div className="buyerdetailbox">
                        <div className="plusiconsvg">
                          <img src={plusicon} onClick={() => this.selectFile(1)} />
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} xs={6} sm={6} md={6} className="mb-15">
                      <div className="buyerdetailbox">
                        <div className="plusiconsvg">
                          <img src={plusicon} onClick={() => inputFileRef.current.click()} />
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} xs={6} sm={6} md={6} className="mb-15">
                      <div className="buyerdetailbox">
                        <div className="plusiconsvg">
                          <img src={plusicon} onClick={() => inputFileRef.current.click()} />
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} xs={6} sm={6} md={6} className="mb-15">
                      <div className="buyerdetailbox">
                        <div className="plusiconsvg">
                          <img src={plusicon} onClick={() => inputFileRef.current.click()} />
                        </div>
                      </div>
                    </Col> */}
                      </Row>
                      </AccordionBody>
                        </AccordionItem>
 
                      </Accordion>
                      {/* <div className="placeorder">
                        <button
                          disabled={
                            !this.state.selectedBuyerCart?.productList
                              ?.length ||
                            Object.values(this.state.orderProofFormData).every(
                              (a) => a == ""
                            )
                          }
                          onClick={this.placeOrder}
                          className="btn darkBtn"
                        >
                          Place Order
                        </button>
                      </div> */}
                    </div>
                  </Row>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-4 dashInnerHeadDiv">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <h4>Buyer Information</h4>
                    <div></div>
                  </div>
                </div>
                <div>
                  <Row>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Input
                          id="name"
                          name="name"
                          placeholder="Name"
                          type="text"
                          value={this.state?.selectedBuyer?.value?.fullName}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="phone">Phone</Label>
                        <Input
                          id="phone"
                          name="phone"
                          placeholder="phone"
                          type="text"
                          value={this.state?.selectedBuyer?.value?.phone}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="name">Address</Label>
                        <Input
                          id="address"
                          name="address"
                          placeholder="Address"
                          type="text"
                          value={this.state?.selectedBuyer?.value?.address}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="mb-15">
                      <FormGroup>
                        <Label for="name">Pin Code</Label>
                        <Input
                          id="pincode"
                          name="pincode"
                          placeholder="Pin"
                          type="text"
                          value={this.state?.selectedBuyer?.value?.pincode}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col>

                    {this.state.selectedBuyerCart ? (
                      <Col xs={12} sm={4} md={4} className="mb-15">
                        <FormGroup className="mb-4">
                          <Label>Delivery In</Label>
                          <div className="input-border orderAdd">
                            <InputGroup>
                              <Input
                                className="form-control border-end-0 border mb-0"
                                type="text"
                                id="example-text-input"
                                name="Delivery Date"
                                placeholder="deliveryDate"
                                value={
                                  this.state.selectedBuyerCart?.deliveryDate
                                    ? moment(
                                        this.state.selectedBuyerCart
                                          ?.deliveryDate
                                      ).format("DD/MM/YYYY")
                                    : ""
                                }
                                disabled={true}
                                autoComplete="off"
                                aria-label="edit"
                              />
                              <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                              <span class="input-group-append">
                                <button
                                  class="btn btn-outline border-left-0 border"
                                  type="button"
                                  onClick={this.openDateToggle}
                                >
                                  <img
                                    className="pl-1"
                                    src={editIcon}
                                    alt=""
                                  ></img>
                                </button>
                              </span>
                            </InputGroup>
                          </div>
                        </FormGroup>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Form>
                    {this.state.selectedBuyerCart?.productList?.length > 0
                      ? this.state.selectedBuyerCart?.productList?.map(
                          (ele) => {
                            return (
                              <>
                                {" "}
                                <Row>
                                  {" "}
                                  {ele?.limraRateType === 'limra' && (
                                    <span>
                                      <img
                                        src={Limra}
                                        alt="image loading"
                                        className="limraIcons limraOrderIcons"
                                      />
                                    </span>
                                  )}
                                  <Col
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    className="mb-15 d-grid"
                                  >
                                    <div
                                      className={`${
                                        ele?.status ===
                                        ProductStatus.DeliveryDateNotMatch
                                          ? "repeatProduct align-items-center justify-content-start colBorder resbuyer"
                                          : "align-items-center justify-content-start colBorder resbuyer"
                                      }`}
                                    >
                                      <div className=" align-items-center justify-content-start resbuyer">
                                        <div className="orderPaddingImg">
                                          <img
                                            src={
                                              ele?.productId?.images?.length > 0
                                                ? ele?.productId?.images[0]
                                                : ele?.productId?.sku
                                                    ?.skuImage[0]
                                            }
                                            alt="image loading"
                                            className="orderImg"
                                          />
                                        </div>

                                        <div class="orderLeftColStyle">
                                          <h4 class="mb-0">{ele?.skuName}</h4>
                                          <p class="mb-0">
                                            Product ID:#
                                            {ele?.productId?.productId}
                                          </p>
                                          <h4 class="mb-0">
                                            Rs {ele?.rate}/{" "}
                                            {ele?.productId?.moqUnits ?? ""}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="delecticondivs">
                                        <img
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              deletemodal: true,
                                              selectedProductToDeleted:
                                                ele?.productId?._id,
                                            });
                                          }}
                                          src={deleteIcons}
                                          alt="image loading"
                                          className="orderDelete"
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col
                                    xs={12}
                                    sm={8}
                                    md={8}
                                    className={`${
                                      ele?.status === "DeliveryDateNotMatch"
                                        ? "repeatProduct mb-15 d-grid"
                                        : "mb-15 d-grid"
                                    }`}
                                  >
                                    <div className="d-flex align-items-center justify-content-between colOrder">
                                      <h6>Particular</h6>
                                      <p>Cart Details</p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between colOrder">
                                      <h6>
                                        Weight( {ele?.productId?.moqUnits ?? ""}
                                        ):
                                      </h6>
                                      <div className="innerOrderContentDiv">
                                        <div className="d-flex align-items-center justify-content-end orderColInnerDivStyle">
                                          {/* <p>{ele?.quantity}</p> */}
                                          <input
                                            type="number"
                                            value={ele?.quantity}
                                            disabled={true}
                                          />
                                          {/* <img src={vector} alt="image loading" /> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between colOrder">
                                      <h6>Price (Rs):</h6>
                                      <div className="innerOrderContentDiv">
                                        <div className="d-flex align-items-center justify-content-end orderColInnerDivStyle">
                                          {/* <p>4500 Rs @{ele.rate}/kg</p> */}
                                          <input
                                            type="number"
                                            value={ele?.rate}
                                            disabled={true}
                                          />
                                          {/* <img src={vector} alt="image loading" /> */}
                                        </div>
                                      </div>
                                    </div>
                                    {ele?.status ===
                                    ProductStatus.DeliveryDateNotMatch ? (
                                      <div className="d-flex align-items-center justify-content-between colOrder">
                                        <h6>Deliver:</h6>
                                        <p
                                          className={`${
                                            ele?.status ===
                                            ProductStatus.DeliveryDateNotMatch
                                              ? "repeatDelivery"
                                              : ""
                                          }`}
                                        >
                                          {ele?.productId &&
                                          ele?.productId?.deliverySlot != null
                                            ? `In ${ele?.productId?.deliverySlot?.match(
                                                EXTRACT_NUMBER_REGEX
                                              )} Days`
                                            : "N/A"}{" "}
                                        </p>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <div className="d-flex align-items-center justify-content-between colOrder colLastOrder">
                                      <h6>Product Total</h6>
                                      <p>
                                        {Number(
                                          ele?.rate * ele?.quantity
                                        ).toFixed(2)}{" "}
                                        Rs
                                      </p>
                                    </div>
                                    {ele.status !== ProductStatus.false &&
                                    ele?.status !==
                                      ProductStatus.PriceUpdated &&
                                    ele?.status !==
                                      ProductStatus.DeliveryDateNotMatch ? (
                                      <div className="outofstock">
                                        <h6 className="outofstocktext">
                                          Sorry, this product is no longer
                                          available.
                                        </h6>
                                      </div>
                                    ) : null}
                                    <div> </div>
                                  </Col>
                                </Row>
                              </>
                            );
                          }
                        )
                      : ""}
                  </Form>

                  <Row
                    hidden={
                      !(
                        Object.values(this.state.selectedBuyerCart || {})
                          ?.length > 0
                      )
                    }
                  >
                    {!this.state.selectedBuyerCart?.productList?.[0]?.productId
                      ?.Horeca && (
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <div className="colordercostDiv">
                          <div className="d-flex justify-content-between align-items-center ">
                            <div>Packaging Cost :</div>
                            <p className="mb-0">
                              {this.state.selectedBuyerCart?.packagingCost
                                ? this.state.selectedBuyerCart?.packagingCost
                                : 0}{" "}
                              Rs
                            </p>
                          </div>
                          <p className="commision">
                            (@ Rs {this.percentageCommission("PackagingCost")}{" "}
                            per Kg)
                          </p>
                        </div>
                      </Col>
                    )}
                    <Col xs={12} sm={12} md={6} lg={4} className="mb-15 ">
                      <div className="colordercostDiv">
                        <div className="d-flex  justify-content-between align-items-center">
                          <div>Transport Cost :</div>
                          <p className="mb-0">
                            {this.state.selectedBuyerCart?.transportCost
                              ? this.state.selectedBuyerCart?.transportCost
                              : 0}{" "}
                            Rs
                          </p>
                        </div>
                        <p className="commision">
                          (@ Rs {this.percentageCommission("TransportCost")} per
                          km)
                        </p>
                      </div>
                    </Col>
                    {!this.state.selectedBuyerCart?.productList?.[0]?.productId
                      ?.Horeca && (
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15 ">
                        <div className="colordercostDiv">
                          <div className="d-flex  justify-content-between align-items-center">
                            <div>Freshr Commission :</div>
                            <p className="mb-0">
                              {this.state?.selectedBuyerCart?.commission
                                ? this.state?.selectedBuyerCart?.commission
                                : 0}{" "}
                              Rs
                            </p>
                          </div>
                          <p className="commision">
                            (@ {this.percentageCommission("Commission")} %)
                          </p>
                        </div>
                      </Col>
                    )}

                    <Col xs={8} sm={9} md={9} className="mb-15 ">
                      <div className="d-flex  justify-content-between align-items-center colcostLastOrder">
                        <div>Total</div>
                        <p className="mb-0">
                          {this.state.selectedBuyerCart?.netPayable
                            ? this.state.selectedBuyerCart?.netPayable
                            : ""}{" "}
                          Rs
                        </p>
                      </div>
                    </Col>

                    <Col xs={8} sm={3} md={3} className="mb-15 ">
                      <div className="placeorder">
                        <button
                          disabled={
                            !this.state.selectedBuyerCart?.productList
                              ?.length ||
                            this.state.falseProduct 
                            // Object.values(this.state.orderProofFormData).every(
                            //   (a) => a == ""
                            // )
                          }
                          onClick={this.placeOrder}
                          className="btn darkBtn"
                        >
                          Place Order
                        </button>
                      </div>
                    </Col>

                    {this.state.sampleOrder &&
                    this.state?.selectedBuyer?.value?.isBuyerHoreca ? (
                      <VoucherCode
                        TotalAmount={
                          this.state.selectedBuyerCart?.netPayable
                            ? this.state.selectedBuyerCart?.netPayable
                            : ""
                        }
                        couponName={this.state.couponName}
                      />
                    ) : (
                      ""
                    )}

                    {/* {this.state.sameSlots === false ? (
                      <OrderSlotsNotification
                        sameSlots={this.state.sameSlots}
                        removesameSLotDiv={this.removesameSLotDiv}
                      />
                    ) : (
                      ""
                    )} */}

                    <RepeatDialogue
                      toggle={this.confirmRepeatToggle}
                      modal={this.state.repeatToggle}
                      className=""
                      body="All items  added to the cart"
                      action="Edit"
                      actionFunc={this.openDateToggle}
                    />
                    <DatePickerDiver
                      toggle={this.openDateToggle}
                      modal={this.state.dateToggle}
                      action="ok"
                      monthDate={this.state.monthDate}
                      dateFlag={this.state.dateFlag}
                      handleMonthChange={this.handleMonthChange}
                      actionFunc={this.deliveryDateRepeat}
                    />
                  </Row>
                </div>
              </>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  orderData: state.rfo.orderData,
  listOfProductData: state.rfu.productdata,
  // SosDeleteRes: state.rfso.sosDelete
});
export default connect(mapStateToProps, {
  getOrdersData,
  buyerSearch,
  fetchproductSortData,
  createCart,
  getCart,
  placeOrder,
  deleteCart,
  fetchEnum,
})(OrderBuyerDetail);
