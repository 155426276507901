import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { HistoryPreApprovedProduct } from "../../../store/actions";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import HistoryTable from "./HistoryTable";
import moment from "moment";
import imagePending from "../../../images/image-pending.jpg";
import PreApprovedTable from "./PreApprovedTable";
var row;
var preApproveRow;
class HistoryModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      preApprovedId: "",
      productId: "",
      preApprovedHistory: "",
      dataLoading: false,
    };
  }

  getAllData = () => {
    const { preApprovedId, productId } = this.state;
    this.props.HistoryPreApprovedProduct(preApprovedId, productId, (res) => {
      this.setState({ preApprovedHistory: res?.data, dataLoading: false });
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.currentHistory !== this.props.currentHistory) {
      this.setState(
        {
          data: this.props.currentHistory,
          preApprovedId: this.props.preApproveProdId,
          productId: this.props.productId,
          dataLoading: true,
        },
        () => {
          this.getAllData();
        }
      );
    }
  };
  render() {
    row = [];
    preApproveRow = [];
    const { data, preApprovedHistory } = this.state;
    if (data) {
      let productId = data?.productId;
      let skuImage = data?.sku ? data?.sku?.skuImage[0] : "";
      let skuName = data?.sku?data?.sku?.skuName:"";
      let categoryName = data?.category? data?.category?.categoryName:"";
      row.push({
        sku: (
          <div className="d-flex align-items-center skuAlign">
            <div className="d-flex align-items-center skuImage">
              <img
                src={skuImage ? skuImage : imagePending}
                alt="productimg"
                className="w-100"
              ></img>
            </div>
            &nbsp;&nbsp;&nbsp;
            <p className="mb-0">{skuName ? skuName : "---"}</p>
          </div>
        ),
        category: categoryName ? categoryName : "N/A",
        size: (
          <div className="d-flex align-itemms-center ml-2">
            &nbsp;
            <span>{data?.size ? data?.size : "N/A"} kg</span>
          </div>
        ),
        moq: (
          <div className="d-flex align-itemms-center">
            &nbsp;
            <span>{data?.moq ? data?.moq : "N/A"} kgs</span>
          </div>
        ),
        range: `₹ (${data?.maxPrice ? data?.maxPrice : "N/A"} - ${
          data?.minPrice ? data?.minPrice : "N/A"
        })`,
      });
    }

    if (preApprovedHistory?.length) {
      preApprovedHistory.forEach((element) => {
        if (element) {
          preApproveRow.push({
            editor: element?.createdBy?.fullName,
            existingPrice: `₹ ${element?.existingPrice} /kg`,
            updatedPrice: `₹ ${element?.updatedPrice} /kg`,
            date: moment(element?.createdAt).format("MM/DD/YYYY"),
            time: moment(element?.createdAt).format("HH:mm A"),
          });
        }
      });
    }

    return (
      <div>
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className="historyModel modal-dialog modal-dialog-scrollable"
        >
          <ModalHeader toggle={this.props.toggle}>History</ModalHeader>
          <ModalBody>
            <HistoryTable rows={row ? row : []} />
            {this.state.dataLoading ? (
              <div className="ViewPagesLoder">
                <LoadingSpinner />
              </div>
            ) : (
              <PreApprovedTable rows={preApproveRow ? preApproveRow : []} />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default connect(null, { HistoryPreApprovedProduct })(HistoryModel);
