export const NAME_REGEX = /^[a-zA-Z\s\.]{1,100}$/;
export const ADDRESS_REGEX = /^[a-zA-Z][a-zA-Z0-999999\-\/\s\,\.\(\)]{8,100}$/;
export const NUMBER_REGEX = /^[0-9]{0,10}$/;
export const EXTRACT_NUMBER_REGEX = /\d+/g;
export const PHONE_NUMBER_ERROR_REGEX = /^[0-9]{10,10}$/;
export const EMAIL_REGEX =
  /^[a-z0-9\.]{3,30}@[a-z0-9]{3,15}[.](com|co.in|in|org)$/;
export const IFSC_CODE = /^[A-Z]{4}0[A-Z0-9]{6}$/;

export const ACCOUNT_NUMBER = /[0-9]{9,18}$/;

export const IMAGES_DOCS =
  /^[A-Za-z0-9]{3,}[.](jpg|jpeg|png|JPG|JPEG|PNG|mp4|webm|MP4|WEBM|pdf|doc|docx|dotx|dotm|xml|wps|rtf|odt|htm|html)$/;

export const ORDER_SELLERDATA_REGEX =
  /[A-Za-z0-9]{3,}.(jpg|jpeg|png|JPG|JPEG|PNG|mp4|webm|MP4|WEBM)$/;
export const SKU_NAME_REGEX=
/^[a-zA-Z\s]{1,30}[a-zA-Z(-1)-9\-\/\s\,\(\)\>\<\&]{3,30}$/;
export const REMARK_REGEX =
  /^[a-zA-Z(-1)-9\-\/\s\,\(\)\>\<\%\-\+\@\!\*]{2,350}$/;
export const TRANSACTION_NUMBER = /^[a-zA-Z0-9]{0,50}$/;
export const FOUR_DIGIT_REGEX = /^[0-9]{0,4}$/;
export const PINCODE_REGEX = /^[0-9]{0,6}$/;
export const PINCODE_ERROR_REGEX = /^[0-9]{6,6}$/;
export const ONLY_NUMBER_REGEX = /^[0-9]{0,7}$/;
// ^[a-zA-Z(-1)-9\-\/\s\,\(\)\>\<]{3,150}$
export const QUANTITY_PRICE_REGEX = /^[1-9]{1}[0-9]{0,6}[\.]{0,1}[0-9]{0,2}$/;
export const ORDER_QUANTIY_REGEX = /^[0-9]{0,7}[0-9.]{0,3}$/
export const ORDER_PRICE_REGEX = /^[0-9]{0,7}$/
export const CREDIT_CYCLE_REGEX = /^([0-9]|[0-5][0-9]|60|)$/


