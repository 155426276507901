import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
} from "reactstrap";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { Grid } from "@material-ui/core";
import { fetchproductSortData, orderUpdate } from "../../../store/actions";
import { EXTRACT_NUMBER_REGEX } from "../../../common/components/common/Rejex";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import searchIcon from "../../../images/search.png";
import SelectComponent from "../../Product/common/SelectComponent";
import { NotificationManager } from "react-notifications";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import imagePending from "../../../images/image-pending.jpg";
import { handleErrorMsg } from "../../../common/components/common/GlobalFunctions";
import { SKU_NAME_REGEX } from "../../../common/components/common/Rejex";
import DateDisable from "../../DateDisable";
let timeOut;
const statusOption = [
  { label: "All", value: false },
  { label: "Approved", value: true },
];
class AddProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      limit: 5,
      dataLoading: false,
      skuName: "",
      statusApprove: "",
      deliveryDate: "",
      moqIncDec: {},
      isHoreca: "",
    };
  }

  componentDidMount() {
    this.setState({ isHoreca: this.props?.isHorecaApproveStatus });
    this.listOfProductList();
  }
  listOfProductList = (offset = 0) => {
    this.props?.fetchproductSortData(
      offset,
      this.state.limit,
      "",
      "",
      "",
      "",
      "",
      "",
      true, // default true approved products
      this.state.deliveryDate,
      this.props.buyerId,
      this.state.skuName,
      "",
      "",
    );
  };
  handleChangePage = (event, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.listOfProductList(offset);
  };
  addProducts = (id, quantity, ele) => {
    let orderActions = "adminUpdatesOrder";
    let data = {
      addedProducts: [
        {
          productId: id,
          quantity: this.state.moqIncDec[ele?._id],
        },
      ],
      deletedProducts: [],
      modifiedProducts: [],
    };
    // this.setState({
    //   dataLoading:true
    // })
    this.props?.toggleSpinner(true);
    this.props?.orderUpdate(data, this.props.orderId, orderActions, (res) => {
      if (res && res.message === "Order has been updated") {
        NotificationManager.success(res.message, "Order Updated");
        this.props.OrderInfo();
        this.props.onToggle();
      } else {
        // NotificationManager.error(res.message, "Error");
        handleErrorMsg(res);
      }
      // this.setState({
      //   dataLoading:false
      // })
      this.props?.toggleSpinner(false);
    });
  };
  onChange(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }

  debounceValue = (val, stateName) => {
    // this.onChange(stateName, val);
    this.setState(
      {
        skuName: val,
        currentPage: 1,
      },
      () => {
        if (timeOut) {
          clearTimeout(timeOut);
        }
        timeOut = setTimeout(() => {
          this.listOfProductList();
        }, 1000);
      }
    );
  };
  updateMoqIncDec = (id, action, value) => {
    let keyValue = {};
    if (action === "add") keyValue[id] = parseInt(value) ? parseInt(value) : 1;
    else {
      // console.log("dec");
      keyValue[id] =
        action === "dec"
          ? this.state.moqIncDec[id] - 15
          : action === "inc"
          ? this.state.moqIncDec[id] + 15
          : this.state.moqIncDec[id];
    }
    if (keyValue[id] < 1) keyValue[id] = 1;

    // console.log(keyValue);
    this.setState({ moqIncDec: { ...this.state.moqIncDec, ...keyValue } });
  };
  herocaBuyerStates = (status) => {
    if (status === true) {
      return (
        <span style={{ color: "#56D35E" }} className="p-2">
          Approved
        </span>
      );
    } else if (status === false) {
      return (
        <span style={{ color: "#EE404C" }} className="p-2">
          Non-Approved
        </span>
      );
    } else {
      return <span>N/A</span>;
    }
  };
  handleSelectStatus = (name, status) => {
    this.setState({ statusApprove: status, currentPage: 1 }, () => {
      this.listOfProductList();
    });
  };
  deliveryDateFunc = (val) => {
    this.setState({ deliveryDate: val, currentPage: 1 }, () => {
      this.listOfProductList();
    });
  };

  render() {
    const totPages = Math.ceil(
      this.props?.listOfProductData?.length / this.state.limit
    );
    var data = {};
    var dataLandScape ={}
    data = {
      columns: [
        {
          label: <span>SKU</span>,
          field: "sku",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Category</span>,
          field: "category",
          sort: "asc",
          width: 300,
        },
        this.state.isHoreca
          ? {
              label: <span>Status</span>,
              field: "status",
              sort: "asc",
              width: 300,
            }
          : {},

        {
          label: <span>Size/Count</span>,
          field: "count",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Delivery In</span>,
          field: "deliveryIn",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Price</span>,
          field: "price",
          sort: "asc",
          width: 700,
        },
        {
          label: <span>MOQ</span>,
          field: "moq",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>MOQ Units</span>,
          field: "moqUnits",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Date</span>,
          field: "date",
          sort: "asc",
          width: 50,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    dataLandScape ={
      columns: [
        {
          label: <span>SKU</span>,
          field: "sku",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Price</span>,
          field: "price",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>MOQ</span>,
          field: "moq",
          sort: "asc",
          width: 50,
        },
        {
          label: <span>Action</span>,
          field: "actions",
          sort: "asc",
          width: 50,
        },
      ],
      rows: [],
    }
    if (
      this.props?.listOfProductData.docs &&
      this.props?.listOfProductData.docs.length > 0
    ) {
      this.props?.listOfProductData?.docs.forEach((ele) => {
        let keyValue = {};
        keyValue[ele?._id] = ele?.moq;
        if (
          !this.state.moqIncDec[ele?._id] &&
          this.state.moqIncDec[ele?._id] != 0
        ) {
          this.setState({
            moqIncDec: { ...this.state.moqIncDec, ...keyValue },
          });
        }
        data.rows.push({
          sku: (
            <div className="d-flex align-items-center skuAlign">
              <div className="d-flex align-items-center skuImage">
                <img
                  src={
                    ele?.images[0]
                      ? ele?.images[0]
                      : ele?.sku?.skuImage[0]
                      ? ele?.sku?.skuImage[0]
                      : imagePending
                  }
                  alt="productimg"
                  className="w-100"
                ></img>
              </div>
              &nbsp;&nbsp;&nbsp;
              {ele?.preApproved === true && this.state.isHoreca ? (
                <p className="mb-0">
                  {ele?.sku?.skuName ? (
                    <span style={{ color: "#0DA6C8" }}>{ele.sku?.skuName}</span>
                  ) : (
                    "N/A"
                  )}
                </p>
              ) : ele?.preApproved === false && this.state.isHoreca ? (
                <p className="mb-0">
                  {ele?.sku?.skuName ? (
                    <span style={{ color: "#980033" }}>{ele.sku?.skuName}</span>
                  ) : (
                    "N/A"
                  )}
                </p>
              ) : (
                <p className="mb-0">
                  <span>{ele.sku?.skuName}</span>
                </p>
              )}
            </div>
          ),
          category: ele?.category?.categoryName
            ? ele?.category?.categoryName
            : "N/A",
          count: <div className="size-count">{ele?.size}</div>,
          deliveryIn:
            ele?.deliverySlot && ele?.deliverySlot != null ? (
              <p className="mb-0">
                In {ele?.deliverySlot?.match(EXTRACT_NUMBER_REGEX)} Days{" "}
              </p>
            ) : (
              "N/A"
            ),
          price: (
            <div className="d-flex align-itemms-center">
              &nbsp;
              {ele?.preApproved === true && this.state.isHoreca ? (
                <span> {"₹" + ele?.fixedRate}</span>
              ) : ele?.preApproved === false && this.state.isHoreca ? (
                <span>{` ₹ ${ele?.minPrice} - ₹ ${ele?.maxPrice}`}</span>
              ) : (
                <span>{"₹" + ele?.rate}</span>
              )}
            </div>
          ),
          status: this.herocaBuyerStates(ele?.preApproved),
          moq: (
            // ele?.moq ? ele?.moq : "N/A"
            <div className="orderbuyerdetailflex moq">
              <span
                disable={!ele?.moq}
                onClick={() => this.updateMoqIncDec(ele?._id, "dec", "")}
                className="plusicon"
                style={{ cursor: "pointer" }}
              >
                -
              </span>
              {/* <p>{ele?.moq}</p> */}
              {/* <p>{moqIncDec[ele?._id]}</p> */}
              <input
                className="form-control buyerdetailmodalinput"
                value={this.state.moqIncDec[ele?._id]}
                type="number"
                onChange={(e) =>
                  this.updateMoqIncDec(ele?._id, "add", e?.target?.value)
                }
              />
              <span
                disable={!ele?.moq}
                onClick={() => this.updateMoqIncDec(ele?._id, "inc", "")}
                className="minusicon"
                style={{ cursor: "pointer" }}
              >
                +
              </span>
            </div>
          ),
          moqUnits: <div>{ele?.moqUnits??""}</div>,

          date: ele?.deliveryDate ? (
            <div>{moment(ele?.deliveryDate).format("DD/MM/YYYY")}</div>
          ) : (
            "N/A"
          ),
          actions: (
            <div className="d-flex align-items-center tableBtns ">
              <Link>
                <Button
                  className="btn lightBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    this.addProducts(ele?._id, ele?.moq, ele);
                  }}
                >
                  Add
                </Button>
              </Link>
              &nbsp;&nbsp;
            </div>
          ),
        });
        dataLandScape.rows.push({
          sku: (
            <div className="d-flex text-center align-items-center skuAlign">
              {/* <div className="d-flex align-items-center skuImage">
                <img
                  src={
                    ele?.images[0]
                      ? ele?.images[0]
                      : ele?.sku?.skuImage[0]
                      ? ele?.sku?.skuImage[0]
                      : imagePending
                  }
                  alt="productimg"
                  className="w-100"
                ></img>
              </div>
              &nbsp;&nbsp;&nbsp; */}
              {ele?.preApproved === true && this.state.isHoreca ? (
                <p className="mb-0">
                  {ele?.sku?.skuName ? (
                    <span style={{ color: "#0DA6C8" }}>{ele.sku?.skuName}</span>
                  ) : (
                    "N/A"
                  )}
                </p>
              ) : ele?.preApproved === false && this.state.isHoreca ? (
                <p className="mb-0">
                  {ele?.sku?.skuName ? (
                    <span style={{ color: "#980033" }}>{ele.sku?.skuName}</span>
                  ) : (
                    "N/A"
                  )}
                </p>
              ) : (
                <p className="mb-0">
                  <span>{ele.sku?.skuName}</span>
                </p>
              )}
            </div>
          ),
          deliveryIn:
            ele?.deliverySlot && ele?.deliverySlot != null ? (
              <p className="mb-0">
                In {ele?.deliverySlot?.match(EXTRACT_NUMBER_REGEX)} Days{" "}
              </p>
            ) : (
              "N/A"
            ),
          price: (
            <div className="d-flex align-itemms-center">
              &nbsp;
              {ele?.preApproved === true && this.state.isHoreca ? (
                <span> {"₹" + ele?.fixedRate}</span>
              ) : ele?.preApproved === false && this.state.isHoreca ? (
                <span>{` ₹ ${ele?.minPrice} - ₹ ${ele?.maxPrice}`}</span>
              ) : (
                <span>{"₹" + ele?.rate}</span>
              )}
            </div>
          ),
          moq: (
            // ele?.moq ? ele?.moq : "N/A"
            <div className="orderbuyerdetailflex moq">
              <span
                disable={!ele?.moq}
                onClick={() => this.updateMoqIncDec(ele?._id, "dec", "")}
                className="plusicon"
                style={{ cursor: "pointer" }}
              >
                -
              </span>
              {/* <p>{ele?.moq}</p> */}
              {/* <p>{moqIncDec[ele?._id]}</p> */}
              <input
                className="form-control buyerdetailmodalinput"
                value={this.state.moqIncDec[ele?._id]}
                type="number"
                onChange={(e) =>
                  this.updateMoqIncDec(ele?._id, "add", e?.target?.value)
                }
              />
              <span
                disable={!ele?.moq}
                onClick={() => this.updateMoqIncDec(ele?._id, "inc", "")}
                className="minusicon"
                style={{ cursor: "pointer" }}
              >
                +
              </span>
            </div>
          ),
          actions: (
            <div className="d-flex align-items-center tableBtns ">
              <Link>
                <Button
                  className="btn lightBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    this.addProducts(ele?._id, ele?.moq, ele);
                  }}
                >
                  Add
                </Button>
              </Link>
              &nbsp;&nbsp;
            </div>
          ),
        });
      });
    }
    // console.log((this.state.currentPage - 1) * this.state.limit + 1 , "hi");
    return (
      <>
        {/* {
        this.state.dataLoading ?
        <LoadingSpinner />
        :<> */}
        <div className="mainParentSectionDiv">
          <div className="addProductModal">
            <Modal
              scrollable
              isOpen={this.props.orderModal}
              toggle={() => {
                this.setState(
                  {
                    skuName: "",
                    statusApprove: "",
                    deliveryDate: "",
                  },
                  () => {
                    this.props.onToggle();
                  }
                );
              }}
              className={""}
              size="lg"
            >
              <ModalHeader
                toggle={() => {
                  this.setState({ skuName: "" }, () => {
                    this.props.onToggle();
                  });
                }}
              >
                <h5 className="align-items-center">Add Product</h5>
                <div style={{ width: '200px', paddingRight: '20px' }}>
                  <DateDisable
                    deliveryDateFunc={this.deliveryDateFunc}
                    deliveryDateVal={this.state.deliveryDate}
                  />
                </div>
              </ModalHeader>
              <ModalBody>
                <Container fluid className="p-0">
                  <Row>
                    <Col xs={12} className="">
                      {/* <Grid
                        className=""
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Grid item xs={6} sm={6} md={2}></Grid>
                        <Grid item xs={6} sm={6} md={3}>
                          {this.state.isHoreca ? (
                            <div className="mt-2 text-start">
                              <SelectComponent
                                options={statusOption}
                                onChange={(val) => {
                                  this.handleSelectStatus("statusApprove", val);
                                }}
                                value={this.state.statusApprove}
                                isClearable
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                          <div className="mt-2">
                            <DateDisable
                              deliveryDateFunc={this.deliveryDateFunc}
                              deliveryDateVal={this.state.deliveryDate}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={6} sm={6} md={4}>
                          <div className="input-border mt-2 orderAdd">
                            <InputGroup>
                              <Input
                                className="form-control border-end-0 border"
                                type="search"
                                id="example-search-input"
                                name="search"
                                placeholder="SkuName"
                                value={this.state.skuName}
                                autoComplete="off"
                                onChange={(e) => {
                                  this.debounceValue(e.target.value, "skuName");
                                }}
                                // invalid={
                                //   SKU_NAME_REGEX.test(this.state.skuName) ||
                                //   this.state.skuName?.length == 0
                                //     ? false
                                //     : true
                                // }
                                aria-label="Search"
                                icon="fas fa-times-circle"
                              />
                              <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                              <span class="input-group-append">
                                <button
                                  class="btn btn-outline border-left-0 border"
                                  type="button"
                                >
                                  <img
                                    className="pl-1"
                                    src={searchIcon}
                                    alt=""
                                  ></img>
                                </button>
                              </span>
                            </InputGroup>
                          </div>
                        </Grid>
                      </Grid> */}
                      <div className="dataTableStyles addProductList LandScapeDisplayNone">
                        <MDBDataTable
                          className="text-center text-capitalize addProductstyle"
                          responsive
                          hover
                          data={data}
                          paging={false}
                          searching={false}
                        />
                      </div>
                      <div className="dataTableStyles addProductList LandScapeDisplay">
                            <MDBDataTable
                              className="text-center text-capitalize addProductstyle"
                              responsive
                              hover
                              data={dataLandScape}
                              paging={false}
                              searching={false}
                            />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <p className="mb-0">
                            {/* Showing{" "}
                    {this.props?.listOfProductData?.length != 0
                      ? (this.state.currentPage - 1) * this.state.limit + 1
                      : (this.state.currentPage - 1) * this.state.limit}{" "}
                    to{" "}
                    {(this.state.currentPage - 1) * this.state.limit +
                      (this.props?.listOfProductData?.docs?.length
                        ? this.props?.listOfProductData?.docs?.length
                        : "")}{" "}
                    {} entries of {this.props.listOfProductData?.length} */}
                            Showing{" "}
                            {this.props.listOfProductData?.length != 0
                              ? (this.state.currentPage - 1) *
                                  this.state.limit +
                                1
                              : (this.state.currentPage - 1) *
                                this.state.limit}{" "}
                            to{" "}
                            {(this.state.currentPage - 1) * this.state.limit +
                              (this.props.listOfProductData?.docs?.length
                                ? this.props.listOfProductData?.docs?.length
                                : "")}{" "}
                            {} entries of{" "}
                            {this.props?.listOfProductData?.length}
                          </p>
                        </div>
                        <div className="custPaginationDiv">
                          <Pagination
                            shape="rounded"
                            count={totPages}
                            page={this.state.currentPage}
                            color="primary"
                            onChange={this.handleChangePage}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </Modal>
          </div>
        </div>
        {/* </>
      } */}
      </>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    listOfProductData: state.rfu.productdata,
  };
};
export default connect(mapStateToProps, {
  fetchproductSortData,
  orderUpdate,
})(AddProducts);
