import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroup,
  Container,
  Accordion,
  AccordionHeader,
  AccordionBody,
  AccordionItem,
  ModalFooter,
  Button,
} from "reactstrap";
import { Grid } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import searchIcon from "../../../images/search.png";
import productImage from "../../../images/fresh-butterfish 7.svg";
import ProductModal from "./ProductModal";
import {
  fetchSellerAssignedByCategory,
  addMultipleSeller,
} from "../../../store/actions";
import { handleErrorMsg } from "../../../common/components/common/GlobalFunctions";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import { NotificationManager } from "react-notifications";
let perPageCount = 8;
let debounceThis;
let sellerDebounce;
class MultipleSeller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accordianForLogs: undefined,
      currentPage: 1,
      productModal: false,
      dataLoading: false,
      mappedSeller: [],
      offset: 0,
      limit: 8,
      sellerName: "",
      dataLoading: false,
      assignProductSeller: [],
    };
  }
  componentDidMount() {
    this.mappedSeller();
  }
  mappedSeller = () => {
    this.setState({
      dataLoading: true,
    });
    let sellerArray = this.props.selectedSeller.map((data) => data.id);
    if (this.props.queryParams && sellerArray.length > 0) {
      this.props.fetchSellerAssignedByCategory(
        this.props.queryParams,
        this.state.offset,
        this.state.limit,
        this.state.sellerName,
        sellerArray.length > 0 ? sellerArray : [],
        (res) => {
          let mappedSellerBycategory = [];
          if (res && res?.data?.sellerWithProducts?.length > 0) {
            res.data?.sellerWithProducts.forEach((element) => {
              element.products.forEach((productItem) => {
                element.start = 0;
                element.end = 8;
                element.currentPage = 1;
                element.search = "";
                element.searchProducts = [];
                productItem.isPreActive = productItem.isActive;
              });
              mappedSellerBycategory.push(element);
            });
            this.setState({
              mappedSeller: mappedSellerBycategory,
            });
          }
        }
      );
    }
    this.setState({
      dataLoading: false,
    });
  };
  accordionToggle = (id) => {
    if (this.state.accordianForLogs == id) {
      this.setState({
        accordianForLogs: undefined,
      });
    } else {
      this.setState({
        accordianForLogs: id,
      });
    }
  };
  productToggle = () => {
    this.setState({
      productModal: !this.state.productModal,
    });
  };
  handleChangeProduct = (event, val, index) => {
    let tranformMappedSeller = [...this.state.mappedSeller];
    tranformMappedSeller[index].start = val * perPageCount - perPageCount;
    tranformMappedSeller[index].end = val * perPageCount;
    tranformMappedSeller[index].currentPage = val;
    this.setState({
      mappedSeller: tranformMappedSeller,
    });
  };
  containsObject = (obj, list) => {
    try {
      for (var i = 0; i < list.length; i++) {
        if (list[i].productId === obj.productId) {
          list[i].sellers = [...list[i].sellers, ...obj.sellers];
          return true;
        }
      }
    } catch (e) {
      return false;
    }
  };
  updateMultiSeller = (e, paginateUpdate = false) => {
    e.preventDefault();
    let multiSellerPayload = {
      mapData: [],
    };
    this.state.mappedSeller.forEach((data) => {
      data?.products.forEach((item) => {
        if (item?.isActive != item?.isPreActive) {
          let productPayload = {
            productId: item?.product?._id,
            sellers: [
              {
                seller: item?.product?.sellerWithRate?.seller,
                isActive: item?.isActive,
              },
            ],
            rate: data?.rate,
          };
          let isProductExist = this.containsObject(
            productPayload,
            multiSellerPayload?.mapData
          );
          if (!isProductExist) multiSellerPayload?.mapData.push(productPayload);
        }
      });
    });

    if (
      multiSellerPayload?.mapData &&
      multiSellerPayload?.mapData?.length > 0
    ) {
      this.setState({
        dataLoading: true,
      });
      this.props.addMultipleSeller(
        this.props.queryParams,
        multiSellerPayload,
        (res) => {
          if (res && res.message === "Category has been successfully updated") {
            NotificationManager.success(res.message, "Category Updated!!");
            if (!paginateUpdate) this.props.sellerToggle();
          } else {
            handleErrorMsg(res);
          }
          this.setState({
            dataLoading: false,
          });
        }
      );
    } else if (!paginateUpdate) {
      handleErrorMsg({
        message: "Please select at least one product to proceed ",
      });
    }
  };
  updateMultiSellerProduct = (data, id) => {
    this.state.mappedSeller.forEach((item) => {
      if (item._id === id) {
        item.products = [...data, ...item.products];
      }
    });
    if (id && data?.length > 0) {
      NotificationManager.success("Product has been added successfully");
      this.productToggle();
    } else {
      handleErrorMsg({
        message: "Please select at least one product",
      });
    }
  };
  selectAllProduct = (id) => {
    this.state.mappedSeller.forEach((item) => {
      if (item._id === id) {
        let isCheckedAll = this.checkedProduct(id);
        item.products.forEach((data) => {
          if (!data.isActive) {
            data.isActive = !data.isActive;
          } else {
            if (data.isActive) {
              if (!isCheckedAll) {
                data.isActive = true;
              } else {
                data.isActive = false;
              }
            }
          }
        });
      }
    });
    this.setState({
      mappedSeller: this.state.mappedSeller,
    });
  };
  checkedProduct = (id) => {
    let isActiveAll = this.state.mappedSeller
      .filter((item) => item._id === id)[0]
      ?.products.every((product) => product.isActive);
    return isActiveAll;
  };
  searchProduct = (id, e) => {
    e.preventDefault();
    if (debounceThis) {
      clearTimeout(debounceThis);
      debounceThis = null;
    }
    debounceThis = setTimeout(() => {
      let searchValue = e.target?.value?.trimStart();
      let searchForProducts = this.state.mappedSeller.map((item) => {
        if (item._id === id) {
          const products = item?.products?.filter(
            (productItem) =>
              productItem?.product?.skuName &&
              productItem?.product?.skuName
                ?.toLowerCase()
                .includes(searchValue.toLowerCase())
          );
          return {
            ...item,
            searchProducts: products,
            search: searchValue,
            currentPage: 1,
            start: 0,
            end: 8,
          };
        } else {
          return {
            ...item,
            searchProducts: [],
            search: "",
            currentPage: 1,
            start: 0,
            end: 8,
          };
        }
      });
      this.setState({
        mappedSeller: searchForProducts,
      });
    }, 700);
  };
  checkSingleProduct = (sellerId, productId) => {
    for (let data of this.state.mappedSeller) {
      if (data._id === sellerId)
        for (let productItem of data.products) {
          if (productItem?.product?._id === productId) {
            productItem.isActive = !productItem.isActive;
            break;
          }
        }
    }

    this.setState({
      mappedSeller: this.state.mappedSeller,
    });
  };
  handleChangePage = (event, val) => {
    this.updateMultiSeller(event, true);
    this.setState(
      {
        currentPage: val,
        offset: (val - 1) * this.state.limit,
      },
      () => {
        this.mappedSeller();
      }
    );
  };
  handleChangeSeller = (e) => {
    this.setState(
      {
        sellerName: e.target.value,
      },
      () => {
        if (sellerDebounce) {
          clearTimeout(sellerDebounce);
          sellerDebounce = null;
        }
        sellerDebounce = setTimeout(() => {
          this.mappedSeller();
        }, 700);
      }
    );
  };
  render() {
    return (
      <>
        {this.state.productModal && (
          <ProductModal
            productModal={this.state.productModal}
            productToggle={this.productToggle}
            sellerId={this.state.sellerId}
            queryParams={this.props.queryParams}
            assignProductSeller={this.state.assignProductSeller}
            updateMultiSellerProduct={(data, id) => {
              this.updateMultiSellerProduct(data, id);
            }}
          />
        )}
        <Modal
          isOpen={this.props.sellerModal}
          toggle={this.props.sellerToggle}
          className="editProductQuantity modal-dialog modal-dialog-scrollable"
          size="md"
        >
          <ModalHeader toggle={this.props.sellerToggle}>
            Seller ({this.props?.mappedSellerData?.data?.count ?? 0})
          </ModalHeader>
          <ModalBody>
            <Container fluid className="p-0">
              <Row>
                <Col xs={12} className="">
                  <Grid
                    className=""
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={6} sm={6} md={8}></Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <div className="input-border mt-2">
                        <InputGroup>
                          <Input
                            className="form-control border-end-0 border"
                            type="text"
                            name="sku"
                            id="example-search-input"
                            value={this.state.sellerName}
                            autoComplete="off"
                            onChange={(e) => this.handleChangeSeller(e)}
                            placeholder="Search for Seller..."
                            aria-label="Search"
                            icon="fas fa-times-circle"
                          />
                          <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                          <span class="input-group-append">
                            <button
                              class="btn btn-outline border-left-0 border"
                              type="button"
                            >
                              <img
                                className="pl-1"
                                src={searchIcon}
                                alt=""
                              ></img>
                            </button>
                          </span>
                        </InputGroup>
                      </div>
                    </Grid>
                  </Grid>
                  {this.state.dataLoading ? (
                    <div className="ViewPagesLoder">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <>
                      <br />
                      <Accordion
                        open={this.state.accordianForLogs}
                        toggle={(newIndex) => {
                          this.accordionToggle(newIndex);
                        }}
                      >
                        {this.state?.mappedSeller.map((data, index) => {
                          let resultingProducts = data?.search
                            ? data?.searchProducts
                            : data?.products;
                          return (
                            <div
                              className="d-flex align-items-center justify-content-between"
                              key={data._id}
                            >
                              <div className="accordionWidth">
                                <AccordionItem>
                                  <AccordionHeader
                                    targetId={index.toString()}
                                    className="accordionSeller"
                                  >
                                    {data?.seller?.fullName}
                                  </AccordionHeader>
                                  <AccordionBody accordionId={index.toString()}>
                                    <Row>
                                      <Col
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={3}
                                        className="mb-15"
                                      >
                                        <div className="d-flex">
                                          <div
                                            className="inputRedplus"
                                            onClick={() => {
                                              this.setState({
                                                productModal: true,
                                                sellerId: data.seller._id,
                                                assignProductSeller: [
                                                  ...new Set(
                                                    data?.products.map(
                                                      (item) =>
                                                        item?.product?._id
                                                    )
                                                  ),
                                                ],
                                              });
                                            }}
                                          >
                                            +
                                          </div>
                                          <div className="addNewProduct fontRoboto154">
                                            Add new products
                                          </div>
                                        </div>
                                      </Col>

                                      <Col
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={3}
                                        className="mb-15"
                                      ></Col>
                                      <Col
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        className="mb-15"
                                      >
                                        <div className="input-border mt-2">
                                          <InputGroup>
                                            <Input
                                              className="form-control border-end-0 border"
                                              type="search"
                                              name="sku"
                                              id="example-search-input"
                                              // value={data?.search}
                                              autoComplete="off"
                                              onChange={(e) =>
                                                this.searchProduct(data._id, e)
                                              }
                                              placeholder="Search for Product..."
                                              aria-label="Search"
                                              icon="fas fa-times-circle"
                                            />
                                            <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                                            <span class="input-group-append">
                                              <button
                                                class="btn btn-outline border-left-0 border"
                                                type="button"
                                              >
                                                <img
                                                  className="pl-1"
                                                  src={searchIcon}
                                                  alt=""
                                                ></img>
                                              </button>
                                            </span>
                                          </InputGroup>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="multiplSellerFont">
                                      {resultingProducts
                                        ?.slice(data.start, data.end)
                                        ?.map((productItems) => {
                                          return (
                                            <Col
                                              xs={6}
                                              sm={6}
                                              md={6}
                                              lg={6}
                                              className="mb-15"
                                            >
                                              <div className="d-flex align-items-center justify-content-between">
                                                <div className="productItemsCheckbox">
                                                  <Input
                                                    type="checkbox"
                                                    className="verticalCenter"
                                                    checked={
                                                      productItems?.isActive
                                                    }
                                                    onClick={() =>
                                                      this.checkSingleProduct(
                                                        data._id,
                                                        productItems?.product
                                                          ?._id
                                                      )
                                                    }
                                                  />

                                                  <img
                                                    src={
                                                      productItems?.product
                                                        ?.images?.length > 0
                                                        ? productItems?.product
                                                            ?.images[0]
                                                        : productImage
                                                    }
                                                    className="accordionImg skuImage"
                                                    alt="image loading..."
                                                  />
                                                  <span className="accordionSpan">
                                                    <div className="accordionSpanFont">
                                                      {
                                                        productItems?.product
                                                          ?.sku?.skuName
                                                      }
                                                    </div>
                                                    <div className="mt-2">
                                                      Product ID:
                                                      {
                                                        productItems?.product
                                                          ?.productId
                                                      }
                                                    </div>
                                                  </span>
                                                </div>
                                                <div>
                                                  <p className="sellerSize sellerProductCount">
                                                    {" "}
                                                    Size/Count
                                                  </p>
                                                  <span>
                                                    ({" "}
                                                    {
                                                      productItems?.product
                                                        ?.size
                                                    }
                                                    )
                                                  </span>
                                                </div>
                                              </div>
                                            </Col>
                                          );
                                        })}
                                    </Row>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div>
                                        <p className="mb-0"></p>
                                      </div>
                                      <div className="custPaginationDiv">
                                        <Pagination
                                          shape="rounded"
                                          count={Math.ceil(
                                            resultingProducts?.length / 8
                                          )}
                                          page={data?.currentPage}
                                          color="primary"
                                          onChange={(event, val) =>
                                            this.handleChangeProduct(
                                              event,
                                              val,
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </AccordionBody>
                                </AccordionItem>
                              </div>
                              <div className="multiSellerAll">
                                <Input
                                  type="checkbox"
                                  checked={this.checkedProduct(data._id)}
                                  onClick={() => {
                                    this.selectAllProduct(data._id);
                                  }}
                                />
                                <span>Select All</span>
                              </div>
                            </div>
                          );
                        })}
                      </Accordion>
                    </>
                  )}
                   <hr />
                  <div>
                    <div className="paginations">
                      <p className="mb-0">
                        Showing{" "}
                        {this.props?.mappedSellerData?.data?.count
                          ? (this.state.currentPage - 1) * this.state.limit + 1
                          : (this.state.currentPage - 1) * this.state.limit}{" "}
                        to{" "}
                        {(this.state.currentPage - 1) * this.state.limit +
                          (this.props?.mappedSellerData.data?.sellerWithProducts?.length
                            ? this.props?.mappedSellerData.data?.sellerWithProducts
                              ?.length
                            : "")}{" "}
                        entries of {""}
                        {this.props?.mappedSellerData?.data?.count ?? 0}
                      </p>

                      <div className="custPaginationDiv noRightBorderPagination">
                        <Pagination
                          shape="rounded"
                          count={Math.ceil(
                            this.props?.mappedSellerData?.data?.count / perPageCount
                          )}
                          page={this.state.currentPage}
                          color="primary"
                          onChange={this.handleChangePage}
                        />
                      </div>
                    </div>
                    <div className="productBntFit">
                      <Button
                        color="secondary"
                        onClick={(e) => this.updateMultiSeller(e)}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    mappedSellerData: state.rfs.mappedSeller,
  };
};
export default connect(mapStateToProps, {
  fetchSellerAssignedByCategory,
  addMultipleSeller,
})(MultipleSeller);
