import React, { Component } from "react";
import Slider from "react-slick";
import { CardImg,Container,CardBody,CardText,Card,Row } from "reactstrap";

export default class Carouselcomponent extends Component {
    constructor(props){
        super(props);
        // this.state={
          //   items : [
          //       {
          //           caption: 'Trending now',captionDesc:'‘Big head Carp’ Chilika',src: 
          //           'https://teams.cloudesign.com/files/nw7pfawkepnfig3yhw1ok7ar8h/public?h=mlI93a8ftorB6_CP36u4v12pYaHFg9PgFlKX22IiMUo',
          //           altText: 'Slide One'
          //       },
          //   ]
        // }
    }
    render() {
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };
        return (
          <Container fluid>
            <Row className="sliderDiv">
              <Slider {...settings}>
                  {/* <Col xs={12} sm={6} md={4}> */}
                  {this.props?.items?.map((item,index)=>{
                    return <Card key={index} className="d-flex align-items-stretch">
                      <div className="slideImgDiv">
                        <CardImg variant="top" src={item.src} />
                      </div>
                      <CardBody>
                        <p>{item.caption}</p>
                        <CardText>
                        {item.captionDesc}
                        </CardText>
                      </CardBody>
                  </Card>
                  })}
                  {/* </Col> */}
              </Slider>
            </Row>
          </Container>
        );
      }
}