import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./styleSku.css";
class SkuToogle extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let mode = this.props.switchButton;
    let bgcolor, clr;
    if (this.props.switchButton === "Yes") {
      clr = "#FFFFFF";
      bgcolor = "#02C8F4";
    } else {
      clr = "#FFFFFF";
      bgcolor = "#6c757d";
    }

    return (
      <>
        <Row>
          <Col>
            <div
              className="button-coverSku"
              style={{
                padding: "2px",
              }}
            >
              <span className="">
                <div className="btn-group">
                  <div className="skuToogle">
                    <button
                      type="button"
                      className="btn fontRobotoYes"
                      size="sm"
                      disabled={this.props.disabled}
                      style={{
                        backgroundColor: mode === "Yes" ? `${bgcolor}` : "",
                        color: mode === "Yes" ? `${clr}` : "#05284E",
                        padding: "5px",
                      }}
                      onClick={() => this.props.handleClick("On")}
                    >
                      Yes
                    </button>
                  </div>
                  <div className="skuToogle">
                    <button
                      type="button"
                      className="btn fontRobotoNo"
                      disabled={this.props.disabled}
                      size="sm"
                      style={{
                        backgroundColor: mode === "No" ? `${bgcolor}` : "",
                        color: mode === "No" ? `${clr}` : "#05284E",
                        padding: "5px",
                      }}
                      onClick={() => this.props.handleClick("No")}
                    >
                      No
                    </button>
                  </div>
                </div>
              </span>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default SkuToogle;
