import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  FormGroup,
  Input,
  InputGroup,
  Label,
} from "reactstrap";
import { Grid } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import {
  categoryViewData,
  categoryEditData,
  fetchSellerData,
  fetchSellerByCategory,
  addMultipleSellerCategory,
} from "../../../store/actions";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import { regularExpressionNumber } from "../../../common/constants";
import { NAME_REGEX } from "../../../common/components/common/Rejex";
import {
  assignUnsupportedFileFormat,
  handleErrorMsg,
} from "../../../common/components/common/GlobalFunctions";
import { isSellerError } from "../../../common/constants";
import SelectComponent from "../../Product/common/SelectComponent";
import deleteIcons from "../../../images/Trash icon.svg";
import searchIcon from "../../../images/search.png";
import productImage from "../../../images/fresh-butterfish 7.svg";
import MultipleSeller from "./MultipleSeller";
var queryParams;

class SkuInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      id: "",
      trackNumber: 0,
      categoryName: "",
      categoryRating: "",
      loading: false,
      categoryImage: "",
      oldCatImage: "",
      listOfSeller: [],
      seller: "",
      selectedSeller: [],
      selectedSellerPayload: [],
      price: "",
      modal: true,
      accordianForLogs: false,
      sellerModal: false,
    };
  }

  componentDidMount() {
    this.categoryViewDataEdit();
    this.listOfSeller();
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  categoryViewDataEdit = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("isEditMode");
    this.setState({
      isEditMode: myParam,
      loading: true,
    });
    queryParams = this.props?.computedMatch?.params?.catid;
    if (queryParams) {
      this.props?.categoryViewData(queryParams, (res) => {
        this.setState({
          categoryRating:
            res?.categoryRating && res?.categoryRating != "NaN"
              ? res?.categoryRating
              : "",
          id: res?._id,
          categoryName: res?.categoryName ? res?.categoryName : "",
          loading: false,
          oldCatImage: res?.categoryImage[0],
        });
      });
      this.props.fetchSellerByCategory(queryParams, (res) => {
        if (res && res?.sellers?.length > 0)
          this.setState({
            selectedSeller: res?.sellers?.map((ele) => {
              return {
                label: ele?.seller?.fullName ? ele?.seller?.fullName : "Null",
                value: ele?.price ? ele?.price : 0,
                id: ele?.seller?._id ? ele?.seller?._id : "",
              };
            }),
          });
      });
    }
  };
  categoryEditData = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    let formData = new FormData();
    formData.append("categoryName", this.state.categoryName);
    formData.append("categoryRating", this.state.categoryRating);
    if (this.state.categoryImage)
      formData.append("categoryImage", this.state.categoryImage);
    let payload = formData;
    this.props?.categoryEditData(
      this.props?.computedMatch?.params?.catid,
      payload,
      (res) => {
        if (res && res.message === "Category has been successfully updated") {
          NotificationManager.success(res.message, "Category Updated!!");
          this.props.history.push("/Category");
        } else {
          // NotificationManager.error(res.message, "Error");
          handleErrorMsg(res);
        }
        this.setState({
          loading: false,
        });
      }
    );
  };

  onHandleChange(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }
  listOfSeller = () => {
    this.setState({
      dataLoading: true,
    });
    this.props?.fetchSellerData((res) => {
      if (res.sellerList && res.sellerList.length > 0) {
        this.setState({
          listOfSeller: res.sellerList.map((ele) => {
            return {
              label: ele.fullName ? ele.fullName : "Null",
              value: ele._id,
            };
          }),

          dataLoading: false,
        });
      }
    });
  };
  isExistSeller = (obj) => {
    let sellerPresent = this.state.selectedSeller.filter(
      (sellerItem) => sellerItem.id === obj.id
    );
    if (sellerPresent && sellerPresent.length > 0) {
      return true;
    }
    return false;
  };
  addSeller = (e) => {
    e.preventDefault();
    let selectedSeller = [];

    if (this.state?.seller?.label && this.state?.seller?.value) {
      let sellerData = {
        label: this.state?.seller?.label,
        value: this.state?.price ? this.state?.price : 0,
        id: this.state?.seller?.value,
      };
      let isChecked = this.isExistSeller(sellerData);
      if (!isChecked) selectedSeller.push(sellerData);
      else handleErrorMsg({ message: isSellerError });
    }
    this.setState({
      selectedSeller: [...this.state.selectedSeller, ...selectedSeller],
      selectedSellerPayload: [
        ...this.state.selectedSellerPayload,
        ...selectedSeller,
      ],
      seller: "",
      price: "",
    });
  };
  sellerToggle = () => {
    this.setState({
      sellerModal: !this.state.sellerModal,
    });
  };
  addMultipleSellerCategory = () => {
    let sellerIdMapped = this.state.selectedSellerPayload.map(
      (data) => data.id
    );
    let payload = {
      sellerIds: sellerIdMapped,
    };
    if (sellerIdMapped.length > 0) {
      this.props.addMultipleSellerCategory(queryParams, payload, (res) => {
        if (res && res.message === "Seller mapped successfully") {
          this.setState({
            selectedSellerPayload: [],
          });
          NotificationManager.success(res.message, "Seller Updated!!");
        } else {
          handleErrorMsg(res);
        }
      });
    }
    this.setState({
      sellerModal: true,
    });
  };
  render() {
    return (
      <>
        <div className="mainParentSectionDiv">
          <div className="pd-15 bg-white borderedDiv">
            <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
              <h4>Category Information</h4>
              <div>&nbsp;</div>
            </div>
            <div>
              {this.state.loading ? (
                <div className="ViewPagesLoder1">
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  {this.state.sellerModal && (
                    <MultipleSeller
                      sellerModal={this.state.sellerModal}
                      sellerToggle={this.sellerToggle}
                      queryParams={queryParams}
                      selectedSeller={this.state.selectedSeller}
                    />
                  )}
                  <Row>
                    <Col xs={12} sm={12} md={12} className="pd-15">
                      <div className="formDivCss">
                        <Form>
                          <Row>
                            <Col xs={12} sm={12} md={4}>
                              <Row
                                hidden={
                                  !this.state.categoryImage &&
                                  !this.state.oldCatImage
                                }
                              >
                                {
                                  <Col
                                    xs={12}
                                    sm={11}
                                    md={11}
                                    className="mb-15"
                                  >
                                    {!this.state.oldCatImage && (
                                      <a
                                        class="remove-image"
                                        onClick={() =>
                                          this.setState({ categoryImage: "" })
                                        }
                                        style={{
                                          display: "inline",
                                          cursor: "pointer",
                                          float: "right",
                                        }}
                                      >
                                        X
                                      </a>
                                    )}
                                    <div className="productImageDivStyle">
                                      <img
                                        src={
                                          (this.state.categoryImage &&
                                            URL.createObjectURL(
                                              this.state.categoryImage
                                            )) ||
                                          this.state.oldCatImage
                                        }
                                        alt="productImage"
                                      />
                                    </div>
                                  </Col>
                                }
                              </Row>
                            </Col>
                            <Col xs={12} sm={12} md={8}>
                              <Row>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  className="mb-15 ml-4"
                                >
                                  <FormGroup>
                                    <Label for="category">Category Image</Label>
                                    <Input
                                      id="category"
                                      name="category"
                                      placeholder="#4 of 10 in FRESH WATER"
                                      type="file"
                                      accept=".jpg, .jpeg, .png"
                                      multiple={false}
                                      disabled={
                                        this.state.isEditMode === "false"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        if (e.target && e.target.files[0]) {
                                          this.setState({
                                            oldCatImage: "",
                                            categoryImage: e.target.files[0],
                                          });
                                        }
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} sm={6} md={6} className="mb-15">
                                  <FormGroup>
                                    <Label for="sku">Category Name</Label>
                                    <Input
                                      id="sku"
                                      name="sku"
                                      placeholder="#4 of 10 in ROHU"
                                      type="text"
                                      value={this.state.categoryName}
                                      invalid={
                                        NAME_REGEX.test(
                                          this.state.categoryName
                                        ) ||
                                        this.state.categoryName?.length == 0
                                          ? false
                                          : true
                                      }
                                      disabled={
                                        this.state.isEditMode === "false"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        // let regularExpressionNumber = /^[a-zA-Z]+$/;
                                        // if (
                                        //   regularExpressionNumber.test(e.target.value)
                                        // ) {
                                        this.setState({
                                          categoryName: e.target.value,
                                        });
                                        // } else {
                                        //   this.setState({
                                        //     categoryName: "",
                                        //   });
                                        // }
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={6} sm={6} md={6} className="mb-15">
                                  <FormGroup>
                                    <Label for="sku">Category Rating</Label>
                                    <Input
                                      id="sku"
                                      name="sku"
                                      placeholder="#4 of 10 in ROHU"
                                      type="text"
                                      maxLength={1}
                                      onChange={(e) => {
                                        regularExpressionNumber.test(
                                          e?.target?.value
                                        ) &&
                                          this.setState({
                                            categoryRating:
                                              parseInt(e?.target?.value) || "",
                                          });
                                      }}
                                      value={this.state.categoryRating}
                                      disabled={
                                        this.state.isEditMode === "false"
                                          ? true
                                          : false
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center justify-content-between">
                    <div></div>
                    <div>
                      <button
                        className="btn darkBtn categoryEditBtn"
                        onClick={(e) => this.categoryEditData(e)}
                        disabled={
                          this.state.isEditMode === "false" ? true : false
                        }
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <br />
                  <div className="pd-15 bg-white borderedDiv">
                    <div className="d-flex align-items-center justify-content-between ">
                      <h4>List Under Seller</h4>
                      <div>&nbsp;</div>
                    </div>
                    <div className="formDivCss">
                      <Form>
                        <Row>
                          <Col xs={5} sm={5} md={5} lg={5} className="mb-15">
                            <FormGroup>
                              <Label for="state">Select Seller</Label>
                              <span style={{ color: "red" }}>*</span>

                              <SelectComponent
                                options={this.state.listOfSeller}
                                disabled={
                                  this.state.isEditMode == "false"
                                    ? true
                                    : false
                                }
                                onChange={(val) => {
                                  this.onHandleChange("seller", val);
                                }}
                                value={
                                  this.state.seller ? this.state.seller : ""
                                }
                              />
                            </FormGroup>
                          </Col>

                          <Col xs={4} sm={4} md={4} lg={4} className="mb-15">
                            <FormGroup>
                              <Label for="member">Seller Price</Label>
                              <Input
                                id="member"
                                name="member"
                                value={this.state.price ? this.state.price : 0}
                                type="text"
                                onChange={(e) => {
                                  this.onHandleChange("price", e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            className=" d-flex align-items-center justify-content-between"
                          >
                            <div>
                              <button
                                className="btn darkBtn deleteIconsCategory"
                                onClick={(e) => {
                                  this.addSeller(e);
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </Col>
                          {this.state?.selectedSeller.length > 0 ? (
                            <div className="scrollSelectedSeller">
                              {this.state?.selectedSeller.length > 0 &&
                                this.state?.selectedSeller?.map(
                                  (data, index) => (
                                    <>
                                      <div className="sellerBorder" key={index}>
                                        <div className="sellerPriceDiv text-capitalize">
                                          {data?.label}
                                        </div>
                                        <div className="sellerPriceDiv">
                                          <FormGroup>
                                            <Label for="member">
                                              Seller Price
                                            </Label>
                                            <Input
                                              id="member"
                                              name="member"
                                              type="text"
                                              value={data?.value}
                                              onChange={(e) => {}}
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )}
                            </div>
                          ) : (
                            ""
                          )}
                        </Row>
                        <div className="d-flex align-items-center justify-content-between">
                          <button
                            className="btn lightBtn"
                            onClick={() => this.props.history.push("/Category")}
                          >
                            Back
                          </button>
                          <div>
                            <button
                              className="btn darkBtn"
                              onClick={(e) => {
                                // this.categoryEditData(e);
                                e.preventDefault();

                                this.addMultipleSellerCategory();
                              }}
                              disabled={
                                this.state.isEditMode === "false"
                                  ? true
                                  : !this.state.selectedSeller?.length > 0
                              }
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    categoryData: state.rfs.categoryView,
  };
};
export default connect(mapStateToProps, {
  categoryViewData,
  categoryEditData,
  fetchSellerData,
  fetchSellerByCategory,
  addMultipleSellerCategory,
})(SkuInformation);
