import React, { Component } from 'react'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {TailSpin} from "react-loader-spinner";
// export class LoadingSpinner extends Component {
//     render() {
//         return (
//             <div>
                
//             </div>
//         )
//     }
// }

// export default 

export default class LoadingSpinner extends React.Component {
  //other logic
  render() {
    return (
      <div className='loaderDiv d-flex flex-row justify-content-center'>
    <TailSpin
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        // timeout={3000} //3 secs
      />
      </div>
     
    );
  }
}