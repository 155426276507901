import React, { Component } from 'react'
import { Container, Row, Col } from "reactstrap";
import MainHeader from '../../components/common/MainHeader'
import Setting from './common/Setting';

export default class Settings extends Component {
  render() {
    return (
      <div>
        <MainHeader pageTitle={"Settings"} />
        <div className="mainParentSectionDiv">
          <Container fluid className="pd-15">
            <Row className="d-flex align-items-stretch">
              <Col xs={12} sm={12} md={12} className="pd-0">
                <Setting />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
