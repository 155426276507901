import React, { Component } from "react";
import MainHeader from "../../components/common/MainHeader";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Input,
  Label,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  getAllPayments,
  acknowledgeApproval,
  fetchAllAdminData,
} from "../../store/actions";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import moment from "moment";
import SelectComponent from "../Product/common/SelectComponent";
import AccordionComponent from "../Product/common/AccordionComponent";
import Pagination from "@material-ui/lab/Pagination";
import LoadingSpinner from "../../common/components/common/LoadingSpinner";
import {
  assignUnsupportedFileFormat,
  handleErrorMsg,
} from "../../common/components/common/GlobalFunctions";
import {
  NUMBER_REGEX,
  REMARK_REGEX,
} from "../../common/components/common/Rejex";
import Carouselcomponent from "../../Carouselcomponent";
let debounceThis;
const findUser = JSON.parse(localStorage.getItem("authUser"));
class OfflinePayment extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      paymentMedium: "",
      mode: "offline",
      paymentStatus: "",
      allPayments: [],
      remarks: "",
      action: "",
      paymentId: "",
      orderId: "",
      createdBy: "",
      offset: 0,
      limit: 10,
      currentPage: 1,
      length: "",
      allAdminDataPhone: "",
      allAdminDataName: "",
      sort: "",
      userType: "",
      userId: "",
      fullName: "",
      makerDataList: [],
      selectedMakerId: { value: "" },
      sortKey: "createdAt",
      sortValue: "",
      loading: false,
      offlinePaymentImages: [],
    };
  }

  componentDidMount = () => {
    this.getAllPayments();
    this.getAllAdminData();
  };

  getAllAdminData() {
    // console.log(this.state.selectedMakerId);

    this.props.fetchAllAdminData(
      this.state.offset,
      this.state.limit,
      this.state.allAdminDataPhone,
      this.state.fullName,
      this.state.sort,
      this.state.userId,
      this.state.userType,
      (res) => {
        this.setState({
          makerDataList: res?.docs.map((ele) => {
            return { label: ele.fullName, value: ele._id };
          }),
        });
      }
    );
  }

  getAllPayments() {
    // console.log(this.state.selectedMakerId, this.state.fullName);
    this.setState({
      loading: true,
    });

    this.props?.getAllPayments(
      {
        offset: this.state.offset,
        limit: this.state.limit,
        paymentMedium: this.state.paymentMedium,
        mode: this.state.mode,
        paymentStatus: this.state.paymentStatus,
        createdBy: findUser.userType === "maker" ? findUser._id : this.state.selectedMakerId?.value || "",
        orderId: this.state.orderId,
        sortKey: this.state.sortKey,
        sortValue: this.state.sortValue,
      },
      (res) => {
        if (res && res?.message === "All PAYMENTS") {
          let makerData = res?.value?.data?.filter((ele) => {
            return (
              ele?.createdBy?._id ===
              JSON.parse(localStorage.getItem("authUser"))?._id
            );
          });
          if (
            JSON.parse(localStorage.getItem("authUser"))?.userType ===
              "maker" &&
            makerData
          ) {
            this.setState({
              allPayments: makerData,
              length: res?.value?.limit,
            });
          } else {
            this.setState({
              allPayments: res?.value?.data,
              length: res?.value?.limit,
            });
          }
        } else {
          handleErrorMsg(res);
        }
        this.setState({
          loading: false,
        });
      }
    );
  }

  acknowledgeApprovalOfPayments = () => {
    this.setState({
      loading: true,
    });
    this.props.acknowledgeApproval(
      {
        remarks: this.state.remarks,
        status: this.state.action,
      },
      this.state.paymentId,
      (res) => {
        if (!res?.statusCode) {
          NotificationManager.success(res?.message, "Success");
          this.getAllPayments();
          this.state.action === "approved"
            ? this.toggle()
            : this.rejecttoggle();
        } else {
          handleErrorMsg(res);
        }
        this.setState({
          loading: false,
          remarks: "",
        });
      }
    );
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  showPoofToggle = () => {
    this.setState({
      showProofModal: !this.state.showProofModal,
    });
  };

  rejecttoggle = () => {
    this.setState({
      rejectmodal: !this.state.rejectmodal,
    });
  };

  toggleFilter = () => {
    this.setState({
      toggleDiv: !this.state.toggleDiv,
    });
  };

  handleChangePage = (event, val) => {
    this.setState(
      {
        currentPage: val,
        offset: (val - 1) * this.state.limit,
      },
      () => {
        this.getAllPayments();
      }
    );
  };
  showImages(array) {
    // console.log(array);

    this.setState({
      offlinePaymentImages: array,
    });
  }

  render() {
    const totPages = Math.ceil(this.state.length / this.state.limit);

    var data = {};
    data = {
      columns: [
        {
          label: <span>Order Id</span>,
          field: "orderid",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Placed By</span>,
          field: "placedby",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Amount</span>,
          field: "amt",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Date</span>,
          field: "date",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Mode</span>,
          field: "mode",
          sort: "asc",
          width: 50,
        },
        {
          label: "Transaction No",
          field: "transactionno",
          sort: "asc",
          width: 100,
        },
        {
          label: "Proof",
          field: "proof",
          width: 100,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };

    if (findUser?.userType != "maker") {
      data?.columns?.push({
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 100,
      });
    }

    {
      this.state.allPayments?.forEach((a) => {
        data?.rows?.push({
          orderid: a?.orderId?.orderId || "Null",
          placedby: a?.createdBy?.fullName || "Null",
          amt:
            (
              <div className="d-flex align-items-center">
                <p className="mb-0">{a?.razorPayPaymentsRecord?.amount}</p>
              </div>
            ) || "Null",
          date: moment(a?.createdAt)?.format("L") || "Null",
          mode: a?.razorPayPaymentsRecord?.paymentMedium || "Null",
          transactionno: a?.razorPayPaymentsRecord?.TransactionRefNo || "Null",
          status: a?.razorPayPaymentsRecord?.status || "Null",
          proof:
            (
              <Button
                className="btn lightBtn py-1"
                onClick={(e) => {
                  e.preventDefault();
                  this.showImages(a?.razorPayPaymentsRecord.paymentProof);
                  this.setState({ showProofModal: true });
                }}
              >
                Show
              </Button>
            ) || "Null",
          actions: (
            <div className="d-flex align-items-center tableBtns">
              {findUser?.userType === "maker" ? (
                "Null"
              ) : (
                <>
                  &nbsp;&nbsp;
                  <Button
                    className="btn lightBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        modal: true,
                        action: "approved",
                        paymentId: a?._id,
                      });
                    }}
                    disabled={a?.razorPayPaymentsRecord?.status !== "pending"}
                  >
                    Accept
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="btn lightBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        rejectmodal: true,
                        action: "rejected",
                        paymentId: a?._id,
                      });
                    }}
                    disabled={a?.razorPayPaymentsRecord?.status !== "pending"}
                  >
                    Reject
                  </Button>
                </>
              )}
            </div>
          ),
        });
      });
    }

    const debouncing = (e, stateName) => {
      if (debounceThis) {
        clearTimeout(debounceThis);
        debounceThis = null;
      }
      debounceThis = setTimeout(() => {
        this.setState({ [`${stateName}`]: e, offset: 0 }, () =>
          this.getAllAdminData()
        );
      }, 1000);
    };
// console.log(this.state.selectedMakerId);
    return (
      <>
        <MainHeader pageTitle={"Offline Payment"} />

        <div className="mainParentSectionDiv">
          <div className="dashInnerMainDiv custCreationDivSec sosDiv">
            <div className="d-flex align-items-center justify-content-between w-100 dashInnerHeadDiv">
              <h4>Payment Requests</h4>
              <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                <div className="pos-rel">
                  <button
                    className="btn lightBtn"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.toggleFilter();
                    }}
                  >
                    Show Filters
                  </button>
                  <div
                    className={`logoutDropdown productMainFilterDropdown ${
                      this.state.toggleDiv ? "displayBlock" : "displayNone"
                    }`}
                  >
                    <div class="arrow-up"></div>

                    <AccordionComponent AccordTitle={"Order Id"}>
                      <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv debounceInput ">
                        <Input
                          name="search"
                          placeholder="Order ID"
                          type="text"
                          maxLength={4}
                          className="mb-0 orderidfix"
                          autoComplete="off"
                          value={this.state.orderId}
                          onChange={(e) => {
                            if (NUMBER_REGEX.test(e.target.value)) {
                              this.setState({ orderId: e?.target?.value });
                            } else {
                              this.setState({ orderId: "" });
                            }
                          }}
                        />
                        {/* <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.6391 12.192L14.8511 15.418L11.6391 12.192ZM1.03907 7.35996C1.0444 6.20746 1.39103 5.08238 2.0352 4.12671C2.67938 3.17103 3.59222 2.42761 4.65852 1.99026C5.72482 1.55291 6.89678 1.44124 8.02649 1.66935C9.1562 1.89746 10.193 2.45511 11.0061 3.27194C11.8191 4.08876 12.372 5.12814 12.5949 6.25889C12.8178 7.38964 12.7007 8.56107 12.2584 9.62534C11.8162 10.6896 11.0685 11.599 10.1099 12.2388C9.15125 12.8785 8.02457 13.2199 6.87207 13.22C5.32201 13.2147 3.83735 12.5947 2.74382 11.4961C1.65029 10.3975 1.0372 8.91002 1.03907 7.35996V7.35996Z"
                            stroke="#5E5E5E"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg> */}
                      </FormGroup>
                    </AccordionComponent>
                    {findUser?.userType != "maker" && (
                      <AccordionComponent AccordTitle={"Placed By"}>
                        <SelectComponent
                          options={this.state.makerDataList}
                          value={
                            this.state.selectedMakerId
                              ? this.state.selectedMakerId
                              : ""
                          }
                          onChange={(e) => {
                            // console.log(e);
                            if (e) {
                              this.setState({
                                selectedMakerId: e,
                                currentPage: 1,
                              });
                            } else {
                              this.setState({
                                selectedMakerId: e,
                                currentPage: 1,
                              });
                            }
                          }}
                          onInputChange={(e) => {
                            // console.log(2);
                            debouncing(e.trimStart(), "fullName");
                          }}
                          placeholder="Buyer"
                        />
                      </AccordionComponent>
                    )}
                    <AccordionComponent AccordTitle={" Payment Medium"}>
                      <Input
                        value={this.state.paymentMedium}
                        onChange={(e) =>
                          this.setState({ paymentMedium: e.target.value })
                        }
                        id="exampleSelect"
                        name="select"
                        type="select"
                      >
                        <option value="">All</option>
                        <option value="cash">Cash</option>
                        <option value="upi">UPI</option>
                        <option value="netbanking">Net Banking</option>
                        <option value="wallet">Wallet</option>
                        <option value="card">Card</option>
                      </Input>
                    </AccordionComponent>
                    <AccordionComponent AccordTitle={" Payment Status"}>
                      <Input
                        value={this.state.paymentStatus}
                        onChange={(e) =>
                          this.setState({ paymentStatus: e.target.value })
                        }
                        id="exampleSelect"
                        name="select"
                        type="select"
                      >
                        <option value="">All</option>
                        <option value="pending">Pending</option>
                        <option value="approved">approved</option>
                        <option value="rejected">rejected</option>
                      </Input>
                    </AccordionComponent>

                    <AccordionComponent AccordTitle={"Sort by"}>
                      <Input
                        value={this.state.sortValue}
                        onChange={(e) =>
                          this.setState({ sortValue: e.target.value })
                        }
                        name="select"
                        type="select"
                      >
                        <option value={"DESC"}>Date Descending</option>
                        <option value={1}>Date Ascending</option>
                      </Input>
                    </AccordionComponent>

                    <Form>
                      <div>
                        <br />
                      </div>
                      <div className="d-flex align-items-center">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState(
                              {
                                paymentStatus: "",
                                createdBy: "",
                                sortValue: "",
                                orderId: "",
                                selectedMakerId: "",
                                paymentMedium: "",
                              },
                              () => {
                                this.getAllPayments();
                                this.toggleFilter();
                              }
                            );
                          }}
                          outline
                        >
                          Clear all
                        </Button>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.getAllPayments();
                            this.toggleFilter();
                          }}
                          outline
                          className="buttonDivStyle"
                        >
                          Apply Filter
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>

                <div className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo mb-3">
                  <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                    <Label>Show</Label>&nbsp;&nbsp;
                    <Input
                      onChange={(e) => {
                        this.setState(
                          {
                            limit: e.target.value,
                            currentPage: 1,
                          },
                          () => {
                            this.getAllPayments();
                          }
                        );
                      }}
                      name="select"
                      type="select"
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                    </Input>
                  </FormGroup>
                </div>
              </Form>
            </div>
            {this.state.loading ? (
              <LoadingSpinner />
            ) : (
              <>
                <div>
                  <div className="cancelOrderwarning">
                    <Modal
                      funk={true}
                      isOpen={this.state.modal}
                      toggle={this.toggle}
                    >
                      <ModalHeader toggle={this.toggle}>
                        Accept Payment
                      </ModalHeader>
                      <ModalBody>
                        <textarea
                          id="w3review"
                          name="w3review"
                          value={this.state.remarks}
                          rows="7"
                          cols="50"
                          style={{ resize: "none", backgroundColor: "#F9F9F9" }}
                          placeholder="Type"
                          onChange={(e) =>
                            this.setState({ remarks: e?.target?.value })
                          }
                        ></textarea>
                      </ModalBody>
                      <ModalFooter className="footermodal">
                        <Button
                          disabled={!REMARK_REGEX.test(this.state.remarks)}
                          onClick={this.acknowledgeApprovalOfPayments}
                        >
                          Accept
                        </Button>
                        <Button
                          color="secondary"
                          onClick={this.toggle}
                          className="offlinepaymentbtn"
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                  <div className="cancelOrderwarning">
                    <Modal
                      funk={true}
                      isOpen={this.state.rejectmodal}
                      toggle={this.rejecttoggle}
                    >
                      <ModalHeader toggle={this.rejecttoggle}>
                        Reject Payment
                      </ModalHeader>
                      <ModalBody>
                        <textarea
                          id="w3review"
                          name="w3review"
                          rows="7"
                          cols="50"
                          style={{ resize: "none", backgroundColor: "#F9F9F9" }}
                          placeholder="Type"
                          onChange={(e) =>
                            this.setState({ remarks: e?.target?.value })
                          }
                        ></textarea>
                      </ModalBody>
                      <ModalFooter className="footermodal">
                        <Button
                          disabled={!REMARK_REGEX.test(this.state.remarks)}
                          onClick={this.acknowledgeApprovalOfPayments}
                        >
                          Reject
                        </Button>
                        <Button
                          color="secondary"
                          onClick={this.rejecttoggle}
                          className="offlinepaymentbtn"
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                  <div className="cancelOrderwarning">
                    <Modal
                      funk={true}
                      isOpen={this.state.showProofModal}
                      toggle={this.showPoofToggle}
                    >
                      <ModalHeader toggle={this.showPoofToggle}>
                        Payment Proof
                      </ModalHeader>
                      <ModalBody>
                        <div
                          className="paymentParent"
                          hidden={this.state.offlinePaymentImages?.length < 1}
                        >
                          {this.state.offlinePaymentImages?.map((image) => {
                            return (
                              <>
                                <Col>
                                  <div className="imagedwld">
                                    <a
                                      download
                                      href={image}
                                      className="fa downloadButton"
                                    >
                                      &#xf019;
                                    </a>
                                  </div>
                                  <img
                                    className="paymentProofImages"
                                    width={200}
                                    height={200}
                                    src={image}
                                    type="*/img"
                                    onError={(e) =>
                                      assignUnsupportedFileFormat(e)
                                    }
                                  ></img>
                                </Col>
                              </>
                            );
                          })}

                          {/* <Carouselcomponent items={this.state.carouselData} /> */}
                        </div>
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
                <div>
                  <div className="dataTableStyles">
                    <MDBDataTable
                      className="text-center text-capitalize"
                      responsive
                      hover
                      data={data}
                      paging={false}
                      searching={false}
                    />
                  </div>

                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <p className="mb-0">
                        Showing{" "}
                        {this.state.allPayments?.length != 0
                          ? (this.state.currentPage - 1) * this.state.limit + 1
                          : (this.state.currentPage - 1) *
                            this.state.limit}{" "}
                        to{" "}
                        {(this.state.currentPage - 1) * this.state.limit +
                          (this.state.allPayments?.length
                            ? this.state.allPayments?.length
                            : "")}{" "}
                        {} entries of {this.state.length}
                      </p>
                    </div>
                    <div className="custPaginationDiv noRightBorderPagination">
                      <Pagination
                        shape="rounded"
                        count={totPages}
                        page={this.state.currentPage}
                        color="primary"
                        onChange={this.handleChangePage}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return { allMakerData: state.rfa.allAdminData };
};
export default connect(mapStateToProps, {
  getAllPayments,
  acknowledgeApproval,
  fetchAllAdminData,
})(OfflinePayment);
