import { NotificationManager } from "react-notifications";
import unsupportedFileFormat from "../../../images/unsupportedFileFormat.png";
import {
  errorMessage,
  isVinculumError,
  isVinculumSuccess,
} from "../../constants";
export const handleKeyPress = (evt) => {
  if ((evt.which != 8 && evt.which != 0 && evt.which < 48) || evt.which > 57) {
    evt.preventDefault();
  }
};
export const handleKeyPressDotValid = (evt) => {
  if (
    (evt.which != 8 && evt.which != 0 && evt.which !== 46 && evt.which < 48) ||
    evt.which > 57
  ) {
    evt.preventDefault();
  }
};
export const isVinculum = (res, msg) => {
  if (res === "true") {
    NotificationManager.success(`${msg} ${isVinculumSuccess}`, "");
  } else {
    NotificationManager.error(`${msg} ${isVinculumError}`, "Error");
  }
};
export const handleErrorMsg = (res) => {
  Array.isArray(res?.message)
    ? res.message?.forEach((a) => {
        NotificationManager.error(a, "Error");
      })
    : Array.isArray(res?.response?.message)
    ? res.response.message?.forEach((a) => {
        if (Object.keys(errorMessage).some((key) => key === a)) {
          return NotificationManager.error(errorMessage[a], "Error");
        } else {
          NotificationManager.error(a, "Error");
        }
      })
    : NotificationManager.error(
        res?.response?.message && typeof res?.response?.message === "string"
          ? res?.response?.message
          : res?.response?.error?.response?.message &&
            typeof res?.response?.error?.response?.message === "string"
          ? res?.response?.error?.response?.message
          : res?.message?.message && typeof res?.message?.message === "string"
          ? res?.message?.message
          : res?.response?.message?.message &&
            typeof res?.response?.message?.message === "string"
          ? res?.response?.message?.message
          : res?.message && typeof res?.message === "string"
          ? res?.message
          : res?.error && typeof res?.error === "string"
          ? res?.error
          : "",
        "Error"
      );
};

export const assignUnsupportedFileFormat = (e) => {
  e.target.src = unsupportedFileFormat;
};
