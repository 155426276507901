import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button, Accordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap";
import { connect } from "react-redux";
import PreApprovedProducts from "../../PreApprovedProducts/index";
import moment from "moment";
import {
  customerViewData,
  customerEditData,
  verifyBankAccount,
  triggerKycForm,
  uploadCancelledCheque,
  uploadKycDocuments,
  fetchAllAdminData,
} from "../../../store/actions";
import { NotificationManager } from "react-notifications";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SelectComponent from "../../Product/common/SelectComponent";
import { stateList } from "../../../common/StateList";
import { userTypeList } from "../../../common/userTypeList";
import SwitchButton from "../../../common/components/common/switchButton";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import {
  debouncing,
  MaximumFileSizeLimitExceeded,
  TenMbInBytes,
  UserTypeBuyer,
  UserTypeSales,
} from "../../../common/constants";
import {
  NUMBER_REGEX,
  EMAIL_REGEX,
  NAME_REGEX,
  ACCOUNT_NUMBER,
  IFSC_CODE,
  ADDRESS_REGEX,
  PHONE_NUMBER_ERROR_REGEX,
  PINCODE_ERROR_REGEX,
  PINCODE_REGEX,
  CREDIT_CYCLE_REGEX,
} from "../../../common/components/common/Rejex";
import {
  assignUnsupportedFileFormat,
  handleErrorMsg,
  isVinculum,
} from "../../../common/components/common/GlobalFunctions";
import walleticon1 from "../../../images/walleticon1.jpg";
import { AccountInformationContent, BasicInformationContent, CustomerInformationContent, KycInformationContent } from "./CustomerInfoContent";
var userType_localStorage;
class Customerdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEditMode: "false",
      name: "",
      natureOfBusinness: "",
      email: "",
      mobNo: "",
      addrs: "",
      state: "",
      pin: "",
      src: "",
      memberSince: "",
      accNo: "",
      IFSC: "",
      sendVerifyModal: false,
      bankDetailsVerified: {},
      trackNumber: 0,
      userType: "",
      businessType: [
        { label: "Private limited company", value: "Private limited company" },
        { label: "Public limited company", value: "Public limited company" },
        { label: "Sole proprierter", value: "Sole proprierter" },
        { label: "Partnership", value: "Partnership" },
      ],
      displayImage: "",
      chequeImage: "",
      chequeImageName: "",
      kycDocumentType: "",
      kycDisplayImage: [],
      bankVerificationDetails: null,
      userName: "",
      bankName: "",
      bankBranch: "",
      availableBalance: "",
      micr: "",
      submitKycDisable: true,
      accountVerified: "",
      nameEdit: undefined,
      natureOfBusinnessEdit: undefined,
      emailEdit: undefined,
      mobNoEdit: undefined,
      userTypeEdit: undefined,
      addrsEdit: undefined,
      stateEdit: undefined,
      pinEdit: undefined,
      addressProofImage: [],
      formDataIdentity: [],
      formDataAddress: [],
      Horeca: undefined,
      identityProof_images: {},
      identityProof_images_added: [],
      identityProof_images_formData: [],
      addressProof_images: {},
      addressProof_images_added: [],
      addressProof_images_formData: [],
      addressDocType: "",
      dataLoading: false,
      imageToBePoppedUp: "",
      availableBalance: "",
      salesData: [],
      selectedSalesInfo: { label: "", value: "" },
      offset: 0,
      limit: 10,
      salesNumber: "",
      salesName: "",
      sort: -1,
      id: "",
      userType: UserTypeSales,
      creditCycle: "",
      accordianForLogs: undefined,
    };
  }
  onChange = (name, val) => {
    this.setState({
      [name]: val,
    });
  }
  keyInfoOnChange = (e, val, states) => {
    Object.values(e?.target?.files).every(
      (eachFile) => eachFile?.size < TenMbInBytes
    )
      ? this.setState(
        {
          [states]: [
            ...this.state
              .identityProof_images_formData,
            ...Object.values(e.target.files),
          ],
        },
        () =>
          this.getKycDocuments(
            e.target.files,
            val
          )
      )
      : NotificationManager.error(
        MaximumFileSizeLimitExceeded,
        "Error"
      );
  }
  componentDidMount() {
    this.customerViewDataEdit();
  }
  customerViewDataEdit = () => {
    // console.log(this.props?.customerviewData?.addressProof , "this.props?.customerviewData?.addressProof");
    this.setState({
      dataLoading: true,
    });
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("isEditMode");
    var queryParams = this.props?.computedMatch?.params?.customerid;
    if (queryParams) {
      this.props?.customerViewData(queryParams, (res) => {
        const identityProof_images =
          this.props?.customerviewData?.identityProof;
        const addressProof_images = this.props?.customerviewData?.addressProof;

        // const blankArrayImage = [];
        // const blankArrayImageAddress = [];
        // const panImageArray = [];
        // const imageArray =
        //   this.props?.customerviewData?.identityProof?.aadharCard.length > 0
        //     ? this.props?.customerviewData?.identityProof?.aadharCard
        //     : [];
        // const panImage =
        //   this.props?.customerviewData?.identityProof?.panCard.length > 0
        //     ? this.props?.customerviewData?.identityProof?.panCard
        //     : [];
        // const ImageAddressArray = this.props?.customerviewData?.addressProof.aadharCard;
        // if (imageArray?.length > 0) {
        //   imageArray.forEach((ele) => {
        //     blankArrayImage.push({
        //       disable: true,
        //       selectedFile: ele,
        //     });
        //   });
        // }
        // if (panImage.length > 0) {
        //   panImage.forEach((ele) => {
        //     panImageArray.push({
        //       disable: true,
        //       selectedFile: ele,
        //     });
        //   });
        // }
        // if (ImageAddressArray.length > 0) {
        //   ImageAddressArray.forEach((ele) => {
        //     blankArrayImageAddress.push({
        //       disable: true,
        //       selectedFile: ele,
        //     });
        //   });
        // }

        let DataToUpdate = {
          Horeca: this.props?.customerviewData?.Horeca,
          id: this.props?.customerviewData?._id,
          isEditMode: myParam ? myParam : "false",
          // kycDisplayImage: [...panImageArray, ...blankArrayImage],
          // addressProofImage: blankArrayImageAddress,
          identityProof_images: identityProof_images,
          addressProof_images: addressProof_images,
          name: this.props?.customerviewData?.fullName,
          email: this.props?.customerviewData?.email
            ? this.props?.customerviewData?.email
            : "",
          mobNo: this.props?.customerviewData?.phone
            ? this.props?.customerviewData?.phone
            : "",
          addrs: this.props?.customerviewData?.address
            ? this.props?.customerviewData?.address
            : "",
          state: this.props?.customerviewData?.state
            ? {
              value: this.props?.customerviewData?.state,
              label: this.props?.customerviewData?.state,
            }
            : "",
          pin: this.props?.customerviewData?.pincode
            ? this.props?.customerviewData?.pincode
            : "",
          src: this.props?.customerviewData?.userAccountSource,
          memberSince: this.props?.customerviewData?.memberSince
            ? moment(this.props?.customerviewData?.memberSince).format(
              "DD/MM/YYYY"
            )
            : "",
          userType: this.props?.customerviewData?.userType
            ? {
              value: this.props?.customerviewData?.userType,
              label: this.props?.customerviewData?.userType,
            }
            : "",
          creditCycle: this.props?.customerviewData?.creditDays
            ? this.props?.customerviewData?.creditDays
            : 0,
          accNo: this.props?.customerviewData.bank?.accountNo?.replace(
            /[0-9](?=([0-9]{4}))/g,
            "X"
          ),
          IFSC: this.props?.customerviewData.bank?.ifsc,
          natureOfBusinness: this.props?.customerviewData?.natureOfBusiness
            ? {
              value: this.props?.customerviewData?.natureOfBusiness,
              label: this.props?.customerviewData?.natureOfBusiness,
            }
            : "",
          displayImage:
            this.props?.customerviewData?.cancelledCheque?.length > 0
              ? this.props?.customerviewData?.cancelledCheque[0]
              : "",
          // ? this.props?.customerviewData?.cancelledCheck[0]
          // : "",
          bankVerificationDetails: this.props?.customerviewData?.bank
            ? this.props?.customerviewData?.bank
            : null,
          userName: this.props?.customerviewData?.bank?.username
            ? this.props?.customerviewData?.bank?.username
            : "",
          bankName: this.props?.customerviewData?.bank?.bankName
            ? this.props?.customerviewData?.bank?.bankName
            : "",
          availableBalance: this.props?.customerviewData?.bank?.availableBalance
            ? this.props?.customerviewData?.bank?.availableBalance
            : "",
          bankBranch: this.props?.customerviewData?.bank?.city
            ? this.props?.customerviewData?.bank?.city
            : "",
          micr: this.props?.customerviewData?.bank?.micr
            ? this.props?.customerviewData?.bank?.micr
            : "",
          submitKycDisable: !(
            this.props?.customerviewData?.bankDetails?.type &&
            this.props?.customerviewData?.bankDetails?.images[0]
          ),
          accountVerified: this.props?.customerviewData?.bank
            ?.accountVerificationStatus
            ? this.props?.customerviewData?.bank?.accountVerificationStatus
            : "",
          nameEdit: undefined,
          natureOfBusinnessEdit: undefined,
          emailEdit: undefined,
          mobNoEdit: undefined,
          userTypeEdit: undefined,
          addrsEdit: undefined,
          stateEdit: undefined,
          pinEdit: undefined,
          dataLoading: false,
        };

        let createdBy = this.props?.customerviewData?.createdBy;
        if (createdBy?.userType === UserTypeSales) {
          DataToUpdate["selectedSalesInfo"] = {
            label: createdBy?.fullName,
            value: createdBy?._id,
          };
        }
        this.setState(DataToUpdate);
      });
    }
  };
  onChangeEdit(e) {
    const {
      name,
      natureOfBusinness,
      email,
      mobNo,
      userType,
      addrs,
      state,
      pin,
      Horeca,
      selectedSalesInfo,
      creditCycle,
    } = this.state;
    e.preventDefault();

    let payload = {
      fullName: name ? name : "",
      phone: mobNo ? `${mobNo}` : "",
      address: addrs ? addrs : "",
      state: state?.value ? state?.value : "",
      email: email ? email : "",
      natureOfBusiness: natureOfBusinness?.value
        ? natureOfBusinness?.value
        : "",
      pincode: pin ? pin : "",
      userType: userType?.value ? userType?.value : "",
      creditDays: creditCycle ? Number(creditCycle) : 0,
    };
    if (selectedSalesInfo?.value && userType_localStorage !== "sales")
      payload["createdBy"] = selectedSalesInfo?.value;
    // let payload = {
    //   fullName: nameEdit ? nameEdit : "",
    //   phone: mobNoEdit ? mobNoEdit : "",
    //   address: addrsEdit ? addrsEdit : "",
    //   state: stateEdit?.value ? stateEdit?.value : "",
    //   email: emailEdit ? emailEdit : "",
    //   natureOfBusiness: natureOfBusinnessEdit?.value
    //     ? natureOfBusinnessEdit?.value
    //     : "",
    //   pincode: pinEdit ? pinEdit : "",
    //   userType: userTypeEdit?.value ? userTypeEdit?.value : "",
    // };
    // for (const [key, value] of Object.entries(payload)) {
    //   if (!value) {
    //     delete payload[key];
    //   }
    // }
    if (userType?.value !== "buyer") {
      delete payload.creditDays;
    }

    payload["Horeca"] = Horeca;
    this.setState({
      dataLoading: true,
    });
    this.props?.customerEditData(
      payload,
      this.props?.customerviewData?._id,
      (res) => {
        if (
          res &&
          res?.message === "Customer Details have been updated successfully"
        ) {
          NotificationManager.success(res?.message, "Customer Updated!!");
          this.customerViewDataEdit();
        } else {
          // NotificationManager.error(
          //   res?.value?.message || res?.message,
          //   "Error"
          // );
          handleErrorMsg(res);
        }
        if (userType?.value === "seller") {
          isVinculum(res?.value?.isVinculum, "Seller");
        }
        this.setState({
          dataLoading: false,
        });
      }
    );
  }

  componentDidUpdate() {
    if (
      this.props?.customerviewData?._id !== this.state.id &&
      this.state.trackNumber < 1
    ) {
      this.setState({ trackNumber: 1 }, () => {
        this.customerViewDataEdit();
      });
    }
  }
  accordionToggle = (id) => {
    if (this.state.accordianForLogs == id) {
      this.setState({
        accordianForLogs: undefined,
      });
    } else {
      this.setState({
        accordianForLogs: id,
      });
    }
  };
  componentWillUnmount = () => {
    this.setState({
      id: "",
      name: "",
      natureOfBusinness: "",
      email: "",
      mobNo: "",
      addrs: "",
      state: "",
      pin: "",
      src: "",
      membSince: "",
      accNo: "",
      IFSC: "",
    });
  };

  verify = () => {
    let data = {
      userId: this.state.id,
      bankAccount: this.state.accNo,
      ifsc: this.state.IFSC,
    };
    let responseDataStore = {};
    this.setState({
      dataLoading: true,
    });
    this.props?.verifyBankAccount(data, (res) => {
      if (res.value) {
        NotificationManager.success(res?.message, "Bank Account Verified");
        responseDataStore.accountNo = res.value.accountNo;
        responseDataStore.accountVerificationStatus =
          res.value.accountVerificationStatus;
        responseDataStore.bankName = res.value.bankName;
        responseDataStore.availableBalance = res.value.availableBalance;
        responseDataStore.branch = res.value.branch;
        responseDataStore.city = res.value.city;
        responseDataStore.micr = res.value.micr;
        responseDataStore.refId = res.value.refId;
        responseDataStore.username = res.value.username;
        responseDataStore.utr = res.value.utr;
        responseDataStore.createdAt = res.value.createdAt;
        this.setState({ bankDetailsVerified: responseDataStore }, () => {
          this.setState(
            { sendVerifyModal: !this.state.sendVerifyModal },
            () => {
              this.customerViewDataEdit();
            }
          );
        });
      } else {
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
  };
  toggleSendVerifyModal = () => {
    this.setState({
      sendVerifyModal: !this.state.sendVerifyModal,
    });
  };

  triggerKycFormSms = () => {
    let data = {
      userid: this.state.id,
    };
    this.setState({
      dataLoading: true,
    });
    this.props?.triggerKycForm(data, (res) => {
      if (res && res?.value?.result?.type === "success") {
        NotificationManager.success(res?.message, "KYC SMS sent to user");
        this.props.history.push("/Customers");
      } else {
        // NotificationManager.error(res?.message, "Error");
        handleErrorMsg(res);
        this.setState({
          addressProof_images_formData: [],
          addressProof_images_added: [],
          identityProof_images_formData: [],
          identityProof_images_added: [],
          kycDocumentType: "",
          addressDocType: "",
        });
        this.customerViewDataEdit();
      }
      this.setState({
        dataLoading: false,
      });
    });
  };

  displayImageConverter = (file, callback) => {
    let reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        callback(reader.result);
      };
    }
    reader.onerror = function (error) {
      // console.log("Error: ", error);
    };

    return (
      <div className="productPopupStyleImg">
        <img
          style={{ overflow: "hidden" }}
          src={this.state.displayImage}
          alt="Cancelled cheque"
        />
      </div>
    );
  };
  submitKyc = () => {
    let formData = new FormData();
    // this.state.formDataIdentity.forEach((ele) => {
    //   formData.append(this.state.kycDocumentType, ele);
    // });
    // this.state.formDataAddress.forEach((ele) => {
    //   formData.append("addressProof", ele);
    // });
    // console.log(this.state.identityProof_images_formData, this.state.addressProof_images_formData);
    this.state.identityProof_images_formData?.forEach((ele) => {
      formData.append(`identityProof.${this.state.kycDocumentType}`, ele);
    });
    this.state.addressProof_images_formData?.forEach((ele) => {
      formData.append(`addressProof.${this.state.addressDocType}`, ele);
    });
    this.setState({
      dataLoading: true,
    });
    this.props?.uploadKycDocuments(formData, this.state.id, (res) => {
      if (
        res &&
        res?.message === "Customer documents have been uploaded successfully"
      ) {
        NotificationManager.success(res?.message, "success");
        this.triggerKycFormSms();
      } else {
        handleErrorMsg(res);
        // NotificationManager.error(res?.message, "Error");
      }
      this.setState({
        dataLoading: false,
      });
    });
  };
  toBase64(file) {
    // console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({ name: file.name, selectedFile: reader.result });
      reader.onerror = (error) => reject(error);
    });
  }

  getKycDocuments = async (files, name) => {
    const filePathsPromises = [];
    Object.values(files).forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    const mappedFiles = filePaths.map((base64File) => ({
      selectedFile: base64File.selectedFile,
      name: base64File.name,
    }));
    if (name === "identityProof") {
      this.setState({
        identityProof_images_added: [
          ...this.state.identityProof_images_added,
          ...mappedFiles,
        ],
        // kycDisplayImage: [...this.state.kycDisplayImage, ...mappedFiles],
      });
    } else {
      this.setState({
        addressProof_images_added: [
          ...this.state.addressProof_images_added,
          ...mappedFiles,
        ],
        // addressProofImage: [...this.state.addressProofImage, ...mappedFiles],
      });
    }
    // console.log(this.state.addressProof_images_added, this.state.addressProof_images_formData);
  };
  removeImage = (index) => {
    // let filteredDisplayImage = this.state.kycDisplayImage
    //   ? this.state.kycDisplayImage
    //   : [];
    // let formDataFilterIdentity = this.state.formDataIdentity
    //   ? this.state.formDataIdentity
    //   : [];
    // filteredDisplayImage.splice(index, 1);
    // formDataFilterIdentity.splice(index, 1);
    // this.setState({
    //   kycDisplayImage: filteredDisplayImage,
    //   formDataIdentity: formDataFilterIdentity,
    // });
    let filteredDisplayImage = this.state.identityProof_images_added;
    let formDataFilterIdentity = this.state.identityProof_images_formData;

    filteredDisplayImage.splice(index, 1);
    formDataFilterIdentity.splice(index, 1);

    this.setState({
      identityProof_images_added: filteredDisplayImage,
      identityProof_images_formData: formDataFilterIdentity,
    });
  };
  removeImageAddress = (index) => {
    // let filteredDisplayImageAddress = this.state.addressProofImage
    //   ? this.state.addressProofImage
    //   : [];
    // let formDataFilterAddress = this.state.formDataAddress
    //   ? this.state.formDataAddress
    //   : [];
    // filteredDisplayImageAddress.splice(index, 1);
    // formDataFilterAddress.splice(index, 1);
    // this.setState({
    //   addressProofImage: filteredDisplayImageAddress,
    //   formDataAddress: formDataFilterAddress,
    // });

    let filteredDisplayImageAddress = this.state.addressProof_images_added;
    let formDataFilterAddress = this.state.addressProof_images_formData;

    filteredDisplayImageAddress.splice(index, 1);
    formDataFilterAddress.splice(index, 1);

    this.setState({
      addressProof_images_added: filteredDisplayImageAddress,
      addressProof_images_formData: formDataFilterAddress,
    });
  };
  downloadNow(url) {
    let node = document?.createElement("a");
    node.href = url;
    node?.setAttribute("download", "");
    node?.click();
  }
  render() {
    let { businessType } = this.state;
    userType_localStorage = JSON.parse(
      localStorage?.getItem("authUser")
    )?.userType;
    let cancelledChequeImage = React.createRef(null);
    const getFIle = (file) => {
      this.setState({ chequeImage: file[0] }, () => {
        this.displayImageConverter(this.state.chequeImage, (img) => {
          this.setState({ displayImage: img });
        });
        let formData = new FormData();
        formData.append("cancelledCheque", this.state.chequeImage);
        this.props?.uploadCancelledCheque(formData, this.state.id, (res) => { });
      });
    };
    // console.log(this.props?.customerviewData?.bank?.availableBalance , "this.props?.customerviewData");

    const callingSales = () => {
      this.props?.fetchAllAdminData(
        this.state.offset,
        this.state.limit,
        this.state.salesNumber,
        this.state.salesName,
        this.state.sort,
        this.state.id,
        UserTypeSales,
        (res) => {
          let salesData = [];
          res?.docs?.forEach((item) => {
            salesData.push({
              label: item.fullName ? item.fullName : "Null",
              value: item?._id,
            });
          });
          this.setState({ salesData: salesData, dataLoading: false });
        }
      );
    };
    this.componentDidMount = () => {
      callingSales();
    };

    return (
      <div className="mainParentSectionDiv">
        <Modal
          isOpen={this.state.imageToBePoppedUp[0]}
          toggle={() => {
            this.setState({ imageToBePoppedUp: false });
          }}
          size="md"
        >
          {/* <ModalHeader>Image</ModalHeader> */}
          <ModalBody>
            <img
              width={700}
              src={this.state.imageToBePoppedUp}
              onError={(e) => {
                this.downloadNow(e?.target?.currentSrc);
                this.setState({ imageToBePoppedUp: false });
              }}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.sendVerifyModal}
          toggle={this.toggleSendVerifyModal}
          className={this.props.className}
          size="md"
        >
          <ModalHeader toggle={this.toggleSendVerifyModal}>Details</ModalHeader>
          <ModalBody>
            <Row>
              <Col>Account Number :</Col>
              <Col>{this.state.bankDetailsVerified.accountNo}</Col>
            </Row>
            <Row>
              <Col>Status :</Col>
              <Col>
                {this.state.bankDetailsVerified.accountVerificationStatus}
              </Col>
            </Row>
            <Row>
              <Col>Bank Name :</Col>
              <Col>{this.state.bankDetailsVerified.bankName}</Col>
            </Row>
            <Row>
              <Col>City :</Col>
              <Col>{this.state.bankDetailsVerified.branch}</Col>
            </Row>
            <Row>
              <Col>MICR :</Col>
              <Col>{this.state.bankDetailsVerified.micr}</Col>
            </Row>
            <Row>
              <Col>Ref ID :</Col>
              <Col>{this.state.bankDetailsVerified.refId}</Col>
            </Row>
            <Row>
              <Col>User Name :</Col>
              <Col>{this.state.bankDetailsVerified.username}</Col>
            </Row>
            <Row>
              <Col>UTR :</Col>
              <Col>{this.state.bankDetailsVerified.utr}</Col>
            </Row>
            <Row>
              <Col>Created At :</Col>
              <Col>
                {moment(this.state.bankDetailsVerified.createdAt).format(
                  "DD-MM-YYYY"
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}>
            <div>
              <Button color="secondary" onClick={this.toggleSendVerifyModal}>
                OK
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        {this.state.dataLoading ? (
          <div className="ViewPagesLoder">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="pd-15 bg-white borderedDiv">
              <div className="LandScapeDisplayNone">
                <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
                  <h4>Customer Information</h4>
                  <div>&nbsp;</div>
                </div>
              </div>
              <div className="formDivCss">
                <Accordion
                  open={this.state.accordianForLogs}
                  toggle={(newIndex) => {
                    this.accordionToggle(newIndex);
                  }}
                  className="LandScapeDisplay"
                >
                  <AccordionItem>
                    <AccordionHeader targetId="1"><h6>Customer Information</h6></AccordionHeader>
                    <AccordionBody accordionId="1">
                      <CustomerInformationContent
                        isEditMode={this.state.isEditMode}
                        name={this.state.name}
                        onChange={this.onChange}
                        natureOfBusinness={this.state.natureOfBusinness}
                        businessType={businessType}
                        email={this.state.email}
                        mobNo={this.state.mobNo}
                        userType={this.state.userType}
                        userType_localStorage={userType_localStorage}
                        Horeca={this.state.Horeca}
                        creditCycle={this.state.creditCycle}
                      />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
                <div className="LandScapeDisplayNone">
                  <CustomerInformationContent
                    isEditMode={this.state.isEditMode}
                    name={this.state.name}
                    onChange={this.onChange}
                    natureOfBusinness={this.state.natureOfBusinness}
                    businessType={businessType}
                    email={this.state.email}
                    mobNo={this.state.mobNo}
                    userType={this.state.userType}
                    userType_localStorage={userType_localStorage}
                    Horeca={this.state.Horeca}
                    creditCycle={this.state.creditCycle}
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="pd-15 bg-white borderedDiv">
              <div className="LandScapeDisplayNone">
                <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
                  <h4>Basic Information</h4>
                  <div>&nbsp;</div>
                </div>
              </div>
              <div className="formDivCss">
                <Accordion
                  open={this.state.accordianForLogs}
                  toggle={(newIndex) => {
                    this.accordionToggle(newIndex);
                  }}
                  className="LandScapeDisplay"
                >
                  <AccordionItem>
                    <AccordionHeader targetId="2"><h6>Basic Information</h6></AccordionHeader>
                    <AccordionBody accordionId="2">
                      <BasicInformationContent
                        isEditMode={this.state.isEditMode}
                        addrs={this.state.addrs}
                        onChange={this.onChange}
                        state={this.state.state}
                        pin={this.state.pin}
                        src={this.state.src}
                        memberSince={this.state.memberSince}
                        userType={this.state.userType}
                        selectedSalesInfo={this.state.selectedSalesInfo}
                        callingSales={callingSales}
                        salesData={this.state.salesData}
                        name={this.state.name}
                        email={this.state.email}
                        mobNo={this.state.mobNo}
                        onChangeEdit={this.onChangeEdit}
                      />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
                <div className="LandScapeDisplayNone">
                  <BasicInformationContent
                    isEditMode={this.state.isEditMode}
                    addrs={this.state.addrs}
                    onChange={this.onChange}
                    state={this.state.state}
                    pin={this.state.pin}
                    src={this.state.src}
                    memberSince={this.state.memberSince}
                    userType={this.state.userType}
                    selectedSalesInfo={this.state.selectedSalesInfo}
                    callingSales={callingSales}
                    salesData={this.state.salesData}
                    name={this.state.name}
                    email={this.state.email}
                    mobNo={this.state.mobNo}
                    onChangeEdit={this.onChangeEdit}
                  />
                </div>
                <div>
                  <div className="basicDetailBtn">
                    <div className="mt-8 d-flex align-items-center justify-content-between">
                      <button
                        className="btn lightBtn"
                        onClick={() => this.props.history.push("/Customers")}
                      >
                        Back
                      </button>
                      <button
                        className="btn darkBtn"
                        disabled={
                          this.state.isEditMode === "false"
                            ? true
                            : !(
                              (NAME_REGEX.test(this.state.name) ||
                                this.state.name?.length == 0) &&
                              (EMAIL_REGEX.test(this.state.email) ||
                                this.state.email?.length == 0) &&
                              (ADDRESS_REGEX.test(this.state.addrs) ||
                                this.state.addrs?.length == 0) &&
                              (PHONE_NUMBER_ERROR_REGEX.test(
                                this.state.mobNo
                              ) ||
                                this.state.mobNo) &&
                              (this.state.pin?.length == 6 ||
                                this.state.pin?.length == 0 ||
                                this.state.pin?.length == undefined)
                            )
                        }
                        onClick={(e) => {
                          this.onChangeEdit(e);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pd-15 bg-white borderedDiv mt-3">
              <div className="LandScapeDisplayNone">
                <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
                  <h4>Account Information</h4>
                  <div>&nbsp;</div>
                </div>
              </div>
              <div className="formDivCss">
                <Accordion
                  open={this.state.accordianForLogs}
                  toggle={(newIndex) => {
                    this.accordionToggle(newIndex);
                  }}
                  className="LandScapeDisplay"
                >
                  <AccordionItem>
                    <AccordionHeader targetId="3"><h6>Account Information</h6></AccordionHeader>
                    <AccordionBody accordionId="3">
                      <AccountInformationContent
                        customerviewData={this.props.customerviewData}
                        isEditMode={this.state.isEditMode}
                        accNo={this.state.accNo}
                        onChange={this.onChange}
                        IFSC={this.state.IFSC}
                        accountVerified={this.state.accountVerified}
                        userName={this.state.userName}
                        bankName={this.state.bankName}
                        bankBranch={this.state.bankBranch}
                        micr={this.state.micr}
                        bankVerificationDetails={this.state.bankVerificationDetails}
                        verify={this.verify}
                        chequeImageName={this.state.chequeImageName}
                        cancelledChequeImage={cancelledChequeImage}
                        getFIle={getFIle}
                        displayImage={this.state.displayImage}
                      />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
                <div className="LandScapeDisplayNone">
                  <AccountInformationContent
                    customerviewData={this.props.customerviewData}
                    isEditMode={this.state.isEditMode}
                    accNo={this.state.accNo}
                    onChange={this.onChange}
                    IFSC={this.state.IFSC}
                    accountVerified={this.state.accountVerified}
                    userName={this.state.userName}
                    bankName={this.state.bankName}
                    bankBranch={this.state.bankBranch}
                    micr={this.state.micr}
                    bankVerificationDetails={this.state.bankVerificationDetails}
                    verify={this.verify}
                    chequeImageName={this.state.chequeImageName}
                    cancelledChequeImage={cancelledChequeImage}
                    getFIle={getFIle}
                    displayImage={this.state.displayImage}
                  />
                </div>
              </div>
            </div>
            <div className="pd-15 bg-white borderedDiv mt-3">
              <div className="LandScapeDisplayNone">
                <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
                  <h4>Kyc Document Upload & Verification</h4>
                  <div>&nbsp;</div>
                </div>
              </div>
              <div className="formDivCss">
                <Accordion
                  open={this.state.accordianForLogs}
                  toggle={(newIndex) => {
                    this.accordionToggle(newIndex);
                  }}
                  className="LandScapeDisplay"
                >
                  <AccordionItem>
                    <AccordionHeader targetId="4"><h6>Kyc Document Upload & Verification</h6></AccordionHeader>
                    <AccordionBody accordionId="4">
                      <KycInformationContent
                        isEditMode={this.state.isEditMode}
                        kycDocumentType={this.state.kycDocumentType}
                        onChange={this.onChange}
                        keyInfoOnChange={this.keyInfoOnChange}
                        identityProof_images={this.state.identityProof_images}
                        identityProof_images_added={this.state.identityProof_images_added}
                        removeImage={this.removeImage}
                        addressDocType={this.state.addressDocType}
                        addressProof_images={this.state.addressProof_images}
                        addressProof_images_added={this.state.addressProof_images_added}
                        removeImageAddress={this.state.removeImageAddress}
                        customerviewData={this.props.customerviewData}
                        submitKyc={this.submitKyc}
                        triggerKycFormSms={this.triggerKycFormSms}
                      />
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
                <div className="LandScapeDisplayNone">
                  <KycInformationContent
                    isEditMode={this.state.isEditMode}
                    kycDocumentType={this.state.kycDocumentType}
                    onChange={this.onChange}
                    keyInfoOnChange={this.keyInfoOnChange}
                    identityProof_images={this.state.identityProof_images}
                    identityProof_images_added={this.state.identityProof_images_added}
                    removeImage={this.removeImage}
                    addressDocType={this.state.addressDocType}
                    addressProof_images={this.state.addressProof_images}
                    addressProof_images_added={this.state.addressProof_images_added}
                    removeImageAddress={this.state.removeImageAddress}
                    customerviewData={this.props.customerviewData}
                    submitKyc={this.submitKyc}
                    triggerKycFormSms={this.triggerKycFormSms}
                  />
                </div>
              </div>
            </div>
            {this.state.userType?.value === "buyer" &&
              this.state.Horeca != undefined &&
              this.state.Horeca === true ? (
              <PreApprovedProducts
                custId={this.state.id}
                isEditMode={this.state.isEditMode}
              />
            ) : (
              ""
            )}
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customerviewData: state.rfc.customerView,
  };
};
export default connect(mapStateToProps, {
  customerViewData,
  customerEditData,
  verifyBankAccount,
  triggerKycForm,
  uploadCancelledCheque,
  uploadKycDocuments,
  fetchAllAdminData,
})(Customerdetails);
