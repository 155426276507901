import React, { Component } from 'react'
import Limra from "../../../images/Limra.png";
import detailsslidesmallimage from "../../../images/detailsslidesmallimage.png";
import { handleKeyPress, handleKeyPressDotValid } from '../../../common/components/common/GlobalFunctions';
import { EXTRACT_NUMBER_REGEX, ORDER_PRICE_REGEX, ORDER_QUANTIY_REGEX } from '../../../common/components/common/Rejex';
import vector from "../../../images/Edit icon.svg";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Row, Table } from 'reactstrap';
export default class OrderedProducts extends Component {
    constructor(props){
        super(props);
        this.state={
            accordianForLogs1: undefined,
            indx:0,
        }
    }
    componentDidMount(){
        this.setState({indx:this.props?.i})
    }
    accordionToggle = (id) => {
        if (this.state.accordianForLogs1 == id) {
          this.setState({
            accordianForLogs1: undefined,
          });
        } else {
          this.setState({
            accordianForLogs1: id,
          });
        }
      };
    render() {
        const ele = this.props?.ele
        const i = !this.state?.indx ? 0 : this.state?.indx
        let orderStatus =this.props?.orderStatus
        return (
            <>
                <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    className="mb-15 d-grid"
                >
                    <div className="d-flex align-items-center justify-content-start colBorder">
                        <div className="d-flex align-items-center">
                            <div className="orderPaddingImg">
                                <img
                                    src={
                                        ele?.productId?.images[0]
                                            ? ele?.productId?.images[0]
                                            : ele?.productId?.images[1]
                                                ? ele?.productId?.images[1]
                                                : ele?.productId?.sku?.skuImage
                                                    ? ele?.productId?.sku?.skuImage[0]
                                                    : detailsslidesmallimage
                                    }
                                    alt="image loading"
                                    className="orderImg"
                                />
                            </div>

                            <div className="orderLeftColStyle">
                                <h4 className="mb-0">
                                    {ele?.skuName
                                        ? ele?.skuName
                                        : ele?.productId?.sku?.skuName
                                            ? ele?.productId?.sku?.skuName
                                            : "N/A"}
                                </h4>
                                <p className="mb-0">
                                    Product Id:{" "}
                                    {ele?.productId?.productId
                                        ? ele?.productId?.productId
                                        : "null"}
                                </p>
                                <h4 className="mb-0">
                                    Rs {ele.rate}/
                                    {ele?.productId?.moqUnits ?? ""}
                                </h4>
                            </div>
                        </div>
                        <div
                            onClick={
                                () => {
                                    if (
                                        this.props.isEditMode !== "false" &&
                                        this.props.orderedProductList.length !== 1
                                    ) {
                                        if (
                                            this.props.orderStatus === "Placed" ||
                                            this.props.orderStatus === "Updated"
                                        ) {
                                            this.props.productDelete(ele?.productId?._id)
                                        }
                                    }
                                }
                            }
                        >
                        </div>
                        <div className='limraOrderDetailIcons'>
                            {ele?.limraRateType === 'limra' && (
                                <img
                                    src={Limra}
                                    alt="Limra logo"
                                    className="limraIcons"
                                />
                            )}
                        </div>
                    </div>
                </Col>

                <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={8}
                    className="mb-15 d-grid paddingGrid"
                >
                    <div className="d-flex align-items-center justify-content-between colOrder">
                        <h6>Particular</h6>
                        <p>Order Details</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between colOrder">
                        <h6>Weight({ele?.productId?.moqUnits ?? ""}):</h6>
                        <div className="innerOrderContentDiv">
                            <div className="d-flex align-items-center justify-content-end orderColInnerDivStyle">
                                {/* <p>100</p> */}
                                <input
                                    type="text"
                                    maxLength="7"
                                    value={
                                        ele.quantity >= 0 ? ele.quantity : ""
                                    }
                                    disabled={
                                        this.props.isEditMode === "false" ||
                                            orderStatus === "Cancelled"
                                            ? true
                                            : ele["weight" + i]
                                    }
                                    onKeyPress={(evt) => {
                                        handleKeyPressDotValid(evt);
                                    }}
                                    onChange={(e) => {
                                        if (
                                            ORDER_QUANTIY_REGEX.test(e.target.value)
                                        ) {
                                            this.props.orderUpdate(
                                                i,
                                                "quantity",
                                                e.target.value
                                            );
                                        } else {
                                            this.props.orderUpdate(i, "quantity", "");
                                        }
                                    }}
                                />
                                <img
                                    src={vector}
                                    alt="image loading"
                                    onClick={(e) => {
                                        this.props.editPrice(i, "weight");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between colOrder">
                        <h6>Price (Rs):</h6>
                        <div className="innerOrderContentDiv">
                            <div className="d-flex align-items-center justify-content-end orderColInnerDivStyle">
                                {/* <p>4500 Rs @{ele.rate}/kg</p> */}
                                <input
                                    type="text"
                                    maxLength="7"
                                    value={ele.rate >= 0 ? ele.rate : ""}
                                    disabled={
                                        this.props.isEditMode === "false" ||
                                            orderStatus === "Cancelled"
                                            ? true
                                            : ele["rate" + i]
                                    }
                                    // onChange={(e) => {
                                    //   this.orderUpdate(i, "rate", e.target.value);
                                    // }}
                                    onKeyPress={(evt) => {
                                        handleKeyPress(evt);
                                    }}
                                    onChange={(e) => {
                                        if (
                                            ORDER_PRICE_REGEX.test(e.target.value)
                                        ) {
                                            this.props.orderUpdate(
                                                i,
                                                "rate",
                                                e.target.value
                                            );
                                        } else {
                                            this.props.orderUpdate(i, "rate", "");
                                        }
                                    }}
                                />
                                <img
                                    src={vector}
                                    alt="image loading"
                                    onClick={(e) => {
                                        this.props.editPrice(i, "rate");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between colOrder">
                        <h6>Deliver:</h6>
                        <p>
                            {ele?.productId &&
                                ele?.productId?.deliverySlot != null
                                ? `In ${ele?.productId?.deliverySlot?.match(
                                    EXTRACT_NUMBER_REGEX
                                )} Days`
                                : "N/A"}{" "}
                        </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between colOrder colLastOrder">
                        <h6>Product Total</h6>
                        <p>
                            {(ele.quantity * ele.rate).toFixed(2) || 0} Rs
                        </p>
                    </div>
                    {ele.status !== "false" &&
                        ele?.status !== "PriceUpdated" ? (
                        <div className="outofstock">
                            <h6 className="outofstocktext">
                                Sorry, this product is no longer available.
                            </h6>
                        </div>
                    ) : null}
                </Col>
                <Row className="mb-25">
                    <Accordion
                        open={this.state.accordianForLogs1}
                        toggle={(newIndex) => {
                          this.accordionToggle(newIndex);
                        }}
                    >
                        <AccordionItem>
                            <AccordionHeader targetId={i}>
                                Product edit history
                            </AccordionHeader>
                            <AccordionBody accordionId={i}>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <th>State</th>
                                            <th>UpdatedBy</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Weight</th>
                                            <th>Price</th>
                                        </tr>
                                        <tr>
                                            <th>Original</th>
                                            <td>
                                                {
                                                    this.props?.orderViewData
                                                        ?.singleOrder?.buyerName
                                                }
                                            </td>
                                            <td>
                                                {new Date(
                                                    this.props?.orderViewData?.singleOrder?.createdAt
                                                )?.toLocaleDateString()}
                                            </td>
                                            <td>
                                                {new Date(
                                                    this.props?.orderViewData?.singleOrder?.createdAt
                                                )?.toLocaleTimeString()}
                                            </td>
                                            <td>
                                                {
                                                    this.props
                                                        .mappedOriginalProductDetails[
                                                        ele?.productId?._id
                                                    ]?.quantity
                                                }
                                            </td>
                                            <td>
                                                {
                                                    this.props
                                                        .mappedOriginalProductDetails[
                                                        ele?.productId?._id
                                                    ]?.rate
                                                }
                                            </td>
                                        </tr>
                                        {this.props?.orderLogsObjForUpdated && this.props?.orderLogsObjForUpdated[
                                            ele?.productId?._id
                                        ]?.map((a) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <th>updated</th>
                                                        <td>{a?.updatedBy?.fullName}</td>
                                                        <td>
                                                            {new Date(
                                                                a?.createdAt
                                                            )?.toLocaleDateString()}
                                                        </td>
                                                        <td>
                                                            {new Date(
                                                                a?.createdAt
                                                            )?.toLocaleTimeString()}
                                                        </td>
                                                        <td>{a?.quantity}</td>
                                                        <td>{a?.rate}</td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </Row>
                <br />
            </>
        )
    }
}
