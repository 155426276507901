import React, { Component } from 'react'
import {Container,Row,Col,Button,FormGroup,Form,Input,Label} from 'reactstrap'
import LightLogo from '../../images/freshrfooterlogo.png'
import DarkLogo from '../../logo.PNG'

export default class SignupPage extends Component {
    render() {
        return (
            <div>
                <Container fluid>
                    <Row className="justify-content-md-center loginDivCss signupDivCss">
                        <Col xs={12} sm={4} md={4} className="loginLogRight">
                                <img src={DarkLogo} alt="darkLogoImage"/>
                                <hr/>
                                <p>Sign Up with us</p>
                                <div>
                                    <Form>
                                        <FormGroup>
                                            <Input
                                            name="email"
                                            placeholder="Email Address"
                                            type="email"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                            id="exampleNumber"
                                            name="password"
                                            placeholder="Password"
                                            type="password"
                                        />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                            id="exampleNumber"
                                            name="password"
                                            placeholder="Confirm Password"
                                            type="password"
                                        />
                                        </FormGroup>
                                        <div>
                                            <FormGroup
                                                check
                                                inline
                                            >
                                                <Input type="checkbox" />
                                                <Label check>
                                                Agree to the terms and conditions
                                                </Label>
                                            </FormGroup>
                                        </div>
                                        <br/>
                                        <Button outline className="buttonDivStyle">
                                            Sign Up
                                        </Button>
                                        <p className="signinpara">
                                        Already have account yet?<br/> Please <a href="#">Sign in</a> here.
                                        </p>
                                    </Form>
                                </div>
                        </Col> 
                        <Col xs={12} sm={12} md={12}>
                            <div className="d-flex align-items-center justify-content-between footDivStyle">
                                <p>© FreshR. All Rights Reserved.</p>
                                <ul className="d-flex align-items-center">
                                    <li>Terms of Use</li>
                                    <li>Privacy Policy</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
