import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import ProductCarousel from "./ProductCarousel";

export default class ProductsModal extends Component {
  constructor(props) {
    super(props);
  }
  render(props) {
    return (
      <Modal
        className="productModalDiv"
        toggle={() => this.props.toggle()}
        isOpen={this.props.isOpen}
        size="xs"
      >
        <ModalBody>
          <div className="loginModalDiv">
            <ProductCarousel images={this.props.images} />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
