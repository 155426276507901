import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import verifiedImage from "../../../images/verifiedImage.png";
// import { connect } from "react-redux";
// import moment from "moment";
// import {
//   customerViewData,
//   customerEditData,
//   verifyBankAccount,
//   triggerOtp,
//   VerifyOtp,
// } from "../../../store/actions";
// import { NotificationManager } from "react-notifications";

class VerificationSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <div className="verifiedCustomer">
              <div className="text-center">
                <img src={verifiedImage} alt="verifiedImage" width="25%" />
                {/* <h2>Verified successfully</h2> */}
                <h2>Thank you for your confirmation, your account has been succesfully verified! You can now log-in to our mobile app and place an order!</h2>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
export default VerificationSuccess;
