import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label, Row, Col } from "reactstrap";
import TableProfilePic from "../../../images/tableprofilepic.png";
import TableProfilePicOne from "../../../images/joey.png";
import { connect } from "react-redux";
import {
  getOrdersData,
  buyerSearch,
  repeatOrder,
  orderUpdate,
  approveOrder,
} from "../../../store/actions";
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
import SelectComponent from "../../Product/common/SelectComponent";
import AccordionComponent from "../../Product/common/AccordionComponent";
import ConfirmDialogue from "../../../common/components/common/ConfirmDialogue";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import { withRouter } from "react-router-dom";
import {
  NUMBER_REGEX,
  FOUR_DIGIT_REGEX,
} from "../../../common/components/common/Rejex";
import moment from "moment";
let debounceThis;
var userLocalType;
class OrdersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: "",
      currentOrderId: "",
      orderDetail: "",
      buyer: "",
      OrderData: [],
      limit: 10,
      sortKey: "orderId",
      sortValue: "",
      currentPage: 1,
      limit: 10,
      orderId: "",
      paymentStatus: "",
      orderStatus: "",
      buyerName: [],
      searchBuyerName: "",
      buyerDataList: [],
      selectedBuyer: "",
      toggleDiv: false,
      repeatToggle: false,
      confirmToggle: false,
      approveToggle: false,
      paymentMode: "",
      buyerType: "",
      dataLoading: false,
      from: "",
      to: "",
    };
  }
  fetchOrdersData = (offset = 0) => {
    this.setState({
      dataLoading: true,
    });
    let DataToSent = {
      offset: offset,
      limit: this.state.limit,
      sortKey: this.state.sortKey,
      sortValue: this.state.sortValue,
      orderId: this.state.orderId ? this.state.orderId : "",
      buyerName: this.state.buyerName.value ? this.state.buyerName.value : "",
      paymentStatus: this.state.paymentStatus ? this.state.paymentStatus : "",
      orderStatus: this.state.orderStatus ? this.state.orderStatus : "",
      paymentMode: this.state.paymentMode ? this.state.paymentMode : "",
      buyerType: this.state.buyerType ? this.state.buyerType : "",
      from: this.state?.from ? this.state?.from : "",
      to: this.state?.to ? this.state?.to : "",
    };
    this.props?.getOrdersData(DataToSent, (data) => {
      this.setState({ OrderData: data }, () => {
        this.setState({
          dataLoading: false,
        });
      });
    });
  };
  componentDidMount() {
    this.fetchOrdersData();
    this.fetchBuyerSearch();
  }

  handleChangePage = (event, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.fetchOrdersData(offset);
  };
  fetchBuyerSearch = () => {
    // this.setState({
    //   dataLoading: true,
    // });
    this.props?.buyerSearch(
      0,
      10,
      this.state.searchBuyerName ? this.state.searchBuyerName : "",
      (res) => {
        if (res) {
          this.setState({
            buyerDataList: res?.docs.map((ele) => {
              return {
                label:
                  ele?.fullName && ele?.fullName != "null"
                    ? ele?.fullName
                    : "Null",
                value: ele._id,
              };
            }),
          });
        }
      }
    );
    // this.setState({
    //   dataLoading: true,
    // });
  };

  filterOrdertData() {
    this.setState({
      toggleDiv: false,
    });
    this.fetchOrdersData();
  }

  toggleFilter = () => {
    this.setState({
      toggleDiv: !this.state.toggleDiv,
    });
  };
  //repeatToggle
  confirmRepeatToggle = (orderId, data) => {
    this.setState({ repeatToggle: !this.state.repeatToggle }, () => {
      this.setState({ currentOrderId: orderId, orderDetail: data });
    });
  };
  //confirmOrder Toggle
  confirmOrderToggle = (orderId) => {
    this.setState({ confirmToggle: !this.state.confirmToggle }, () => {
      this.setState({ currentOrderId: orderId });
    });
  };
  //approveOrder toogle
  confirmApproveToggle = (orderId) => {
    this.setState({ approveToggle: !this.state.approveToggle }, () => {
      this.setState({ currentOrderId: orderId });
    });
  };
  confirmOrderFunc = (orderId) => {
    let orderActions = "userApproveOrder";
    let data = {
      addedProducts: [],
      deletedProducts: [],
      modifiedProducts: [],
    };
    this.setState({ dataLoading: true });
    this.props?.orderUpdate(data, orderId, orderActions, (res) => {
      if (res && res?.message === "Order has been updated") {
        NotificationManager.success(res?.message, "Order Updated");
        this.setState(
          {
            dataLoading: false,
            confirmToggle: false,
          },
          () => {
            this.fetchOrdersData();
          }
        );
      } else {
        handleErrorMsg(res);
        this.setState({ dataLoading: false });
      }
    });
  };

  //repeatorder action
  actionFunc = (id, data) => {
    this.setState({ dataLoading: true });
    this.props.repeatOrder(id, (res) => {
      if (res["message"] === "Products added to cart") {
        NotificationManager.success(res["message"], "Success");
        this.setState({ dataLoading: false }, () => {
          this.confirmRepeatToggle();
          this.props.history.push({
            pathname: "/OrderBuyerDetail",
            state: data,
          });
        });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({ dataLoading: false });
      }
    });
  };
  approveOrderFunc = (id) => {
    this.setState({ dataLoading: true });
    this.props.approveOrder(id, (res) => {
      if (res?.message === "Order has been created successfully") {
        NotificationManager.success(res["message"], "Success");
        this.setState({ dataLoading: false, approveToggle: false }, () => {
          this.fetchOrdersData();
        });
      } else {
        NotificationManager.error(res?.message, "Error");
        this.setState({ dataLoading: false });
      }
    });
  };
  render() {
    userLocalType = JSON.parse(localStorage.getItem("authUser")).userType;
    const totalPages = Math.ceil(
      this.props?.orderData?.allOrders?.length / this.state.limit
    );
    var data = {};
    data = {
      columns: [
        {
          label: <span>Order Id</span>,
          field: "orderId",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Date</span>,
          field: "date",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Created By</span>,
          field: "createdBy",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Buyer</span>,
          field: "buyer",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Order Status</span>,
          field: "orderStatus",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Payment Status</span>,
          field: "payment",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Actions</span>,
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };

    this.state.OrderData?.allOrders?.docs?.map((el) => {
      data.rows.push({
        orderId: (
          <div className="">
            &nbsp;<span>{el.orderId ? el.orderId : ""}</span>
          </div>
        ),
        date: <div>{moment(el?.createdAt).format("DD/MM/YYYY")}</div>,
        createdBy: <div>{el?.createdBy?.fullName ?? ""}</div>,
        buyer: (
          <div className="">
            &nbsp;<span>{el?.buyer?.buyerId?.fullName ?? "null"}</span>
          </div>
        ),
        orderStatus: (
          <div className="">
            &nbsp;<span>{el.orderStatus ? el.orderStatus : "null"}</span>
          </div>
        ),
        payment: (
          <div className="">
            &nbsp;<span>{el.paymentStatus ? el.paymentStatus : "null"}</span>
          </div>
        ),

        actions: (
          <div className="tableBtns">
            <Link to={`/order/${el._id}?isEditMode=${false}`}>
              <Button className="btn lightBtn">View</Button>
            </Link>
            &nbsp;&nbsp;
            <Link to={`/order/${el._id}?isEditMode=${true}`}>
              <Button className="btn lightBtn">Edit</Button>
            </Link>
            &nbsp;&nbsp;
            {el?.orderStatus === "Delivered" ? (
              <Button
                className="btn lightBtn"
                onClick={(e) => {
                  e.preventDefault();
                  this.confirmRepeatToggle(el?._id, el);
                }}
              >
                Repeat
              </Button>
            ) : (
              ""
            )}
            {userLocalType!=="sales" && el?.orderStatus === "ApprovePending" ? (
              <Button
                className="mr-4 btn lightBtn"
                outline
                onClick={(e) => {
                  e.preventDefault();
                  this.confirmOrderToggle(el?._id);
                }}
              >
                Confirm
              </Button>
            ) : (
              ""
            )}
            {userLocalType!=="sales" && ((el?.orderStatus === "Placed" &&
              (el?.isVinculum === "false" || el?.isVinculum === "pending")) ||
            (el?.orderStatus === "Updated" &&
              (el?.isVinculum === "false" || el?.isVinculum === "pending"))) ? (
              <Button
                className="mr-4 btn lightBtn"
                outline
                onClick={(e) => {
                  e.preventDefault();
                  this.confirmApproveToggle(el?._id);
                }}
              >
                Approve
              </Button>
            ) : (
              ""
            )}
          </div>
        ),
      });
    });

    const debouncing = (e, stateName) => {
      if (debounceThis) {
        clearTimeout(debounceThis);
        debounceThis = null;
      }
      debounceThis = setTimeout(() => {
        if (stateName === "orderId") {
          this.setState(
            { [`${stateName}`]: e.target.value, currentPage: 1 },
            () => {
              // this.fetchOrdersData()
            }
          );
        } else {
          this.setState({ [`${stateName}`]: e }, () => this.fetchBuyerSearch());
        }
      }, 500);
    };

    let { buyerDataList, orderId, orderStatus } = this.state;
    return (
      <div className="dashInnerMainDiv custCreationDivSec sosDiv">
        <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
          <div
            className="d-flex align-items-center justify-content-between w-100"
            onClick={() => {
              this.setState({
                toggleDiv: false,
              });
            }}
          >
            <h4>Orders List</h4>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                <div className="createorderbtn">
                  <Link to="/OrderBuyerDetail">
                    <button className="btn darkBtn">Create Order</button>
                  </Link>
                </div>
                <div className="pos-rel">
                  <button
                    className="btn lightBtn"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.toggleFilter();
                    }}
                  >
                    Show Filters
                  </button>
                  <div>
                    <div
                      className={`logoutDropdown productMainFilterDropdown ${
                        this.state.toggleDiv ? "displayBlock" : "displayNone"
                      }`}
                    >
                      <div class="arrow-up"></div>
                      <AccordionComponent AccordTitle={"Buyer"}>
                        <SelectComponent
                          options={buyerDataList}
                          value={
                            this.state.buyerName ? this.state.buyerName : "Null"
                          }
                          onChange={(e) => {
                            if (e) {
                              this.setState(
                                { buyerName: e, currentPage: 1 },
                                () => {
                                  // this.fetchOrdersData();
                                }
                              );
                            } else {
                              this.setState(
                                {
                                  buyerName: e,
                                  currentPage: 1,
                                },
                                () => {
                                  // this.fetchOrdersData();
                                }
                              );
                            }
                          }}
                          onInputChange={(e) => {
                            debouncing(e.trimStart(), "searchBuyerName");
                          }}
                          placeholder="Buyer"
                        />
                      </AccordionComponent>
                      <AccordionComponent AccordTitle={"Order Id"}>
                        <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv debounceInput ">
                          <Input
                            name="search"
                            value={this.state.orderId ? this.state.orderId : ""}
                            placeholder="Order ID"
                            type="number"
                            maxLength={4}
                            className="mb-0 orderidfix"
                            // onChange={(e) => debouncing(e, "orderId")}
                            onChange={(e) => {
                              if (FOUR_DIGIT_REGEX.test(e.target.value)) {
                                this.setState({ orderId: e?.target?.value });
                              }
                              // else {
                              //   this.setState({ orderId: "" });
                              // }
                            }}
                            autoComplete="off"
                          />
                          {/* <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.6391 12.192L14.8511 15.418L11.6391 12.192ZM1.03907 7.35996C1.0444 6.20746 1.39103 5.08238 2.0352 4.12671C2.67938 3.17103 3.59222 2.42761 4.65852 1.99026C5.72482 1.55291 6.89678 1.44124 8.02649 1.66935C9.1562 1.89746 10.193 2.45511 11.0061 3.27194C11.8191 4.08876 12.372 5.12814 12.5949 6.25889C12.8178 7.38964 12.7007 8.56107 12.2584 9.62534C11.8162 10.6896 11.0685 11.599 10.1099 12.2388C9.15125 12.8785 8.02457 13.2199 6.87207 13.22C5.32201 13.2147 3.83735 12.5947 2.74382 11.4961C1.65029 10.3975 1.0372 8.91002 1.03907 7.35996V7.35996Z"
                            stroke="#5E5E5E"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg> */}
                        </FormGroup>
                      </AccordionComponent>

                      <AccordionComponent AccordTitle={"Order Date"}>
                        <Form>
                          <div>
                            <div className="d-flex align-items-center inputDiv">
                              <Label for="exampleInput">From</Label>
                              &nbsp;&nbsp;
                              <Input
                                name="search"
                                value={this.state.from ? this.state.from : ""}
                                placeholder="From"
                                type="date"
                                // maxLength={4}
                                className="mb-0 orderidfix dateinput fromandtodate"
                                // onChange={(e) => debouncing(e, "orderId")}
                                onChange={(e) => {
                                  // if (FOUR_DIGIT_REGEX.test(e.target.value)) {
                                  this.setState({ from: e?.target?.value });
                                  // }
                                  // else {
                                  //   this.setState({ orderId: "" });
                                  // }
                                }}
                                autoComplete="off"
                              />
                            </div>
                            <br />
                            <div className="d-flex align-items-center inputDiv">
                              <Label for="exampleInput1">To</Label>
                              &nbsp;&nbsp;
                              <Input
                                name="search"
                                value={this.state.to ? this.state.to : ""}
                                placeholder="To"
                                type="date"
                                // maxLength={4}
                                className="mb-0 orderidfix dateinput fromandtodate"
                                // onChange={(e) => debouncing(e, "orderId")}
                                onChange={(e) => {
                                  // if (FOUR_DIGIT_REGEX.test(e.target.value)) {
                                  this.setState({ to: e?.target?.value });
                                  // }
                                  // else {
                                  //   this.setState({ orderId: "" });
                                  // }
                                }}
                                autoComplete="off"
                              />
                            </div>
                            <br />
                          </div>
                        </Form>
                      </AccordionComponent>

                      <AccordionComponent AccordTitle={"Mode"}>
                        <Input
                          value={this.state.paymentMode}
                          onChange={(e) =>
                            this.setState({ paymentMode: e?.target?.value })
                          }
                          id="exampleSelect"
                          name="select"
                          type="select"
                        >
                          <option value="">All</option>
                          <option value="online">Online</option>
                          <option value="offline">Offline</option>
                        </Input>
                      </AccordionComponent>

                      {/* <AccordionComponent AccordTitle={" Payment Status"}>
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onChange={(e) => {
                          let value;
                          if (e.target.value === "Pending") value = "Pending";
                          else if (e.target.value === "UnSuccessfull")
                            value = "UnSuccessfull";
                          else if (e.target.value === "Cancelled")
                            value = "Cancelled";
                          else if (e.target.value === "All") value = "";
                          else if (e.target.value === "Partially Paid")
                            value = "Partially Paid";
                          else if (e.target.value === "Fully Paid")
                            value = "Fully Paid";
                          else if (e.target.value === "Issued")
                            value = "Issued";
                          else if (e.target.value === "Expired") */}
                      {/* value = "Expired"; */}

                      <AccordionComponent AccordTitle={" Order Status"}>
                        <Input
                          value={orderStatus}
                          id="exampleSelect"
                          name="select"
                          type="select"
                          onChange={(e) => {
                            let value;
                            if (e.target.value === "Placed") value = "Placed";
                            else if (e.target.value === "Updated")
                              value = "Updated";
                            else if (e.target.value === "Cancelled")
                              value = "Cancelled";
                            else if (e.target.value === "All") value = "";
                            else if (e.target.value === "Finalized")
                              value = "Finalized";
                            else if (e.target.value === "In Transit")
                              value = "In Transit";
                            else if (e.target.value === "Delivered")
                              value = "Delivered";
                            this.setState(
                              {
                                orderStatus: value,
                                currentPage: 1,
                              },
                              () => {
                                // this.fetchOrdersData();
                              }
                            );
                          }}
                        >
                          <option>All</option>
                          <option>Placed</option>
                          <option>Updated</option>
                          <option>Cancelled</option>
                          <option>Finalized</option>
                          <option>In Transit</option>
                          <option>Delivered</option>/
                        </Input>
                      </AccordionComponent>

                      <AccordionComponent AccordTitle={" Payment Status"}>
                        <Input
                          id="exampleSelect"
                          value={
                            this.state.paymentStatus
                              ? this.state.paymentStatus
                              : ""
                          }
                          name="select"
                          type="select"
                          onChange={(e) => {
                            let value;
                            if (e.target.value === "Pending") value = "Pending";
                            else if (e.target.value === "UnSuccessfull")
                              value = "UnSuccessfull";
                            else if (e.target.value === "Cancelled")
                              value = "Cancelled";
                            else if (e.target.value === "All") value = "";
                            else if (e.target.value === "Partially Paid")
                              value = "Partially Paid";
                            else if (e.target.value === "Fully Paid")
                              value = "Fully Paid";
                            else if (e.target.value === "Issued")
                              value = "Issued";
                            else if (e.target.value === "Expired")
                              value = "Expired";

                            this.setState(
                              {
                                paymentStatus: value,
                                currentPage: 1,
                              },
                              () => {
                                // this.fetchOrdersData();
                              }
                            );
                          }}
                        >
                          <option>All</option>
                          <option>Pending</option>
                          <option>UnSuccessfull</option>
                          <option>Partially Paid</option>
                          <option>Fully Paid</option>
                          <option>Cancelled</option>
                          <option>Issued</option>
                          <option>Expired</option>
                        </Input>
                      </AccordionComponent>

                      <AccordionComponent AccordTitle={"Sort by"}>
                        <Input
                          onChange={(e) =>
                            this.setState(
                              { sortValue: e.target.value, currentPage: 1 },
                              () => {
                                // console.log(this.state.sort);
                              }
                            )
                          }
                          name="select"
                          type="select"
                          value={this.state.sortValue}
                        >
                          <option value=""></option>
                          <option value="desc">Order Id Descending</option>
                          <option value="asc">Order Id Ascending</option>
                        </Input>
                      </AccordionComponent>
                      <AccordionComponent AccordTitle={"Buyer Type"}>
                        <Input
                          value={this.state.buyerType}
                          onChange={(e) =>
                            this.setState({ buyerType: e?.target?.value })
                          }
                          id="exampleSelect"
                          name="select"
                          type="select"
                        >
                          <option value="">All</option>
                          <option value="true">Horeca</option>
                          <option value="false">GT</option>
                        </Input>
                      </AccordionComponent>
                      <Form>
                        <div>
                          <br />
                        </div>
                        <div className="d-flex align-items-center">
                          <Button
                            outline
                            onClick={() => {
                              this.setState(
                                {
                                  buyer: "",
                                  orderId: "",
                                  paymentStatus: "",
                                  orderStatus: "",
                                  buyerName: [],
                                  sortValue: "",
                                  paymentMode: "",
                                  buyerType: "",
                                  from: "",
                                  to: "",
                                },
                                () => {
                                  this.setState({ toggleDiv: false }, () => {
                                    this.fetchOrdersData();
                                  });
                                }
                              );
                            }}
                          >
                            Clear all
                          </Button>
                          <Button
                            outline
                            className="buttonDivStyle"
                            onClick={() => {
                              this.setState({ currentPage: 1 }, () => {
                                this.filterOrdertData();
                              });
                            }}
                          >
                            Apply Filter
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo mb-3">
                  <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                    <Label>Show</Label>&nbsp;&nbsp;
                    <Input
                      onChange={(e) => {
                        this.setState(
                          {
                            limit: e.target.value,
                            currentPage: 1,
                          },
                          () => {
                            this.fetchOrdersData();
                          }
                        );
                      }}
                      name="select"
                      type="select"
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                    </Input>
                  </FormGroup>
                </div>
              </Form>
            </div>
          </div>
        </div>
        {this.state.dataLoading ? (
          <LoadingSpinner></LoadingSpinner>
        ) : (
          <>
            <div className="dataTableStyles">
              <MDBDataTable
                className="text-center text-capitalize"
                responsive
                hover
                data={data}
                paging={false}
                searching={false}
                displayEntries={false}
              />
            </div>

            <div className="d-flex align-items-center justify-content-between paginationCss">
              <div>
                <p className="mb-0">
                  Showing{" "}
                  {this.props?.orderData?.allOrders?.length
                    ? (this.state.currentPage - 1) * this.state.limit + 1
                    : (this.state.currentPage - 1) * this.state.limit}{" "}
                  to{" "}
                  {(this.state.currentPage - 1) * this.state.limit +
                    (this.props?.orderData?.allOrders?.docs?.length
                      ? this.props?.orderData?.allOrders?.docs?.length
                      : "")}{" "}
                  {} entries of {this.props?.orderData?.allOrders?.length}
                </p>
              </div>
              <div className="custPaginationDiv">
                <Pagination
                  shape="rounded"
                  count={totalPages}
                  page={this.state.currentPage}
                  color="primary"
                  onChange={this.handleChangePage}
                />
              </div>
            </div>
          </>
        )}

        {/* repeat popup */}
        <ConfirmDialogue
          toggle={this.confirmRepeatToggle}
          modal={this.state.repeatToggle}
          className=""
          orderId={this.state.currentOrderId}
          orderDetail={this.state.orderDetail}
          heading="Confirm"
          body="Do You Like to Repeat Order"
          action="Repeat"
          actionFunc={this.actionFunc}
        />
        {/* confirmOrder popup */}
        <ConfirmDialogue
          toggle={this.confirmOrderToggle}
          modal={this.state.confirmToggle}
          orderDetail=""
          className=""
          orderId={this.state.currentOrderId}
          heading="Confirm"
          body="Do You Like to Confirm Order"
          action="Confirm"
          actionFunc={this.confirmOrderFunc}
        />
        {/* approveOrder popup */}
        <ConfirmDialogue
          toggle={this.confirmApproveToggle}
          modal={this.state.approveToggle}
          className=""
          orderId={this.state.currentOrderId}
          orderDetail=""
          heading="Approve"
          body="Do You Like to Approve Order"
          action="Approve"
          actionFunc={this.approveOrderFunc}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...state,
    ...props,
    orderData: state.rfo.orderData,
  };
};
export default connect(mapStateToProps, {
  getOrdersData,
  buyerSearch,
  repeatOrder,
  orderUpdate,
  approveOrder,
})(withRouter(OrdersPage));
