import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  Label,
} from "reactstrap";
import DarkLogo from "../../logo.PNG";
import newLogo from "../../images/Freshr Logo Blue.png";
import { userLogin } from "../../store/actions";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../common/components/common/LoadingSpinner";
import {
  handleErrorMsg,
  handleKeyPress,
} from "../../common/components/common/GlobalFunctions";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      phone: "",
      password: "",
      userType: "admin",
      dataLoading: false,
    };
  }

  valueStore = (type, e) => {
    let phoneCheck = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let emailCheck =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (type === "username") {
      if (e.target.value.match(phoneCheck)) {
        this.setState({ phone: e.target.value, email: "" });
      } else if (e.target.value.match(emailCheck)) {
        this.setState({ email: e.target.value, phone: "" });
      } else {
        const checkInteger = /^[0-9]+$/;
        if (checkInteger.test(e.target.value))
          this.setState({ email: "", phone: e.target.value });
        else this.setState({ email: e.target.value, phone: "" });
      }
    }
    let obj = {};
    obj[type] = e?.target?.value;
    this.setState({ ...obj });
  };

  loginAction = () => {
    this.setState({
      dataLoading: true,
    });
    let data = {};
    if (this.state.phone) {
      data = { phone: this.state.phone, password: this.state.password };
    } else {
      data = { email: this.state.email, password: this.state.password };
    }
    this.props.userLogin(
      data,
      this.state.userType,
      this.props.history,
      (res) => {
        // console.log(res);
        this.setState({ dataLoading: false });
        if (res.message === "Login successfull!!") {
          NotificationManager.success("User Logged In", "LogIn Successful");
          localStorage.setItem("authUser", JSON.stringify(res.user));
          localStorage.setItem("token", JSON.stringify(res.token));
          // this.props?.history?.push("/Dashboard");
          if (res.user.userType === "sales") {
            window.location.href = "/Customers";
          } else {
            window.location.href = "/Dashboard";
          }
        } else {
          // NotificationManager.error(res.message, "Error");
          handleErrorMsg(res);
        }
      }
    );
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (
        (this.state.email !== "" && this.state.phone !== "") ||
        this.state.password !== ""
      ) {
        this.loginAction();
      }
    }
  };

  render() {
    return (
      <>
        <div>
          {this.state.dataLoading ? (
            <LoadingSpinner />
          ) : (
            <Container fluid>
              <Row className="justify-content-md-center loginDivCss">
                <Col
                  xxl={4}
                  xl={4}
                  md={6}
                  lg={4}
                  sm={8}
                  xs={9}
                  className="loginLogRight"
                >
                  <div>
                    <img
                      src={newLogo}
                      alt="darkLogoImage"
                      className="newLogos"
                    />
                    <hr />
                    <h2>Welcome</h2>
                    <p>Please Sign in</p>
                    <div>
                      <Form>
                        <FormGroup>
                          <Input
                            name="email"
                            placeholder="Email / Phone"
                            type="text"
                            onKeyPress={this.handleKeyDown}
                            onChange={(e) => {
                              this.valueStore("username", e);
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Input
                            id="exampleNumber"
                            name="password"
                            placeholder="Password"
                            type="password"
                            onChange={(e) => {
                              this.valueStore("password", e);
                            }}
                            onKeyPress={this.handleKeyDown}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Input
                            name="userType"
                            placeholder="userType"
                            type="select"
                            onKeyPress={this.handleKeyDown}
                            onChange={(e) => {
                              this.valueStore("userType", e);
                            }}
                          >
                            <option value="admin">Admin</option>
                            <option value="checker">Checker</option>
                            <option value="maker">Maker</option>
                            <option value="sales">Sales</option>
                          </Input>
                        </FormGroup>
                        <div className="d-flex align-items-center justify-content-between checkDivCss"></div>
                        <Button
                          onClick={() => {
                            this.loginAction();
                          }}
                          outline
                          className="buttonDivStyle"
                        >
                          Sign In
                        </Button>
                      </Form>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <div className="d-flex align-items-center justify-content-between footDivStyle">
                    <p className="textcenter">© FreshR. All Rights Reserved.</p>
                    <ul className="d-flex align-items-center">
                      <Link to="/TermsAndConditions">
                        <li className="textcenter">
                          Terms of Use and Privacy Policy
                        </li>
                      </Link>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    loggedInUserData: state.rfu.loggedInUser,
  };
};

export default connect(mapStateToProps, { userLogin })(LoginPage);
