import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import MainHeader from "../../components/common/MainHeader";
import UserRequests from "./common/UserRequests";
import NewUserRequests from "./common/NewUserRequests";
// import Customerdetails from './common/Customerdetails'
// import Userinformation from './common/UserInformation'
import Buyerform from './common/Buyerform'

export default class Customers extends Component {
  render() {
    return (
      <div>
        <MainHeader pageTitle={"Customers"} />
        <div className="mainParentSectionDiv">
          <Container fluid className="pd-15">
            <Row className="d-flex align-items-stretch">
              <Col xs={12} sm={12} md={12} className="pd-0">
                {/* <Buyerform /> */}
                <NewUserRequests/>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
