import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label } from "reactstrap";
import userprofile from "../../../images/userprofile.png";
import { connect } from "react-redux";
import { getSkuList, skuDeleteData } from "../../../store/actions";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import { handleErrorMsg } from "../../../common/components/common/GlobalFunctions";
import imagepending from "../../../images/image-pending.jpg";
class Sku extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skuToDeleteId: "",
      deleteConfirmModal: false,
      offset : 0,
      limit: 10,
      currentPage: 1,
      dataLoading: false,
    };
  }
  componentDidMount() {
    this.getAllSkuData();
  }
  getAllSkuData = (offset = 0) => {
    this.setState({ dataLoading: true });
    this.props?.getSkuList(offset, this.state.limit, () => {
      this.setState({ dataLoading: false });
    });
  };
  onSkuDelete(id) {
    this.setState({
      dataLoading: true,
    });
    this.props?.skuDeleteData(id, (res) => {
      if (res) {
        if (res.message === "SKU has been deleted") {
          this.getAllSkuData((this.state.currentPage - 1) * this.state.limit);
          NotificationManager.success(res.message, "Delete Successful");
        } else {
          // NotificationManager.error(res.message, "Error");
          handleErrorMsg(res);
        }
      }
      this.setState({
        dataLoading: false,
      });
    });
  }
  deleteConfirmModalToggle = () => {
    this.setState(
      {
        deleteConfirmModal: !this.state.deleteConfirmModal,
      },
      () => {}
    );
  };
  handleChangePage = (name, val) => {
    this.setState({
      currentPage: val,
      offset: (val - 1) * this.state.limit,
    });
    const offset = (val - 1) * this.state.limit;
    this.getAllSkuData(offset);
  };
  render() {
    const totPages = Math.ceil(
      this.props?.skuDataList?.length / this.state.limit
    );
    var data = {};
    data = {
      columns: [
        {
          label: <span>Sr.No</span>,
          field: "srNo",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>SKU Name</span>,
          field: "skuName",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Date</span>,
          field: "date",
          sort: "asc",
          width: 300,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    if (
      this.props?.skuDataList?.docs &&
      this.props?.skuDataList?.docs?.length > 0
    ) {
      this.props?.skuDataList?.docs.forEach((ele, index) => {
        // console.log(this.state.offset , index , "hi");
        data.rows.push({
          srNo: this.state.offset + index + 1,
          skuName: (
            <div className="d-flex align-items-center ">
              <img className="skuImage" src={ele?.skuImage[0] ? ele?.skuImage[0] : imagepending}></img>&nbsp;&nbsp;&nbsp;
              <p className="mb-0">{ele.skuName}</p>
            </div>
          ),
          date: <div>{moment(ele.createdAt).format("DD/MM/YYYY")}</div>,
          actions: (
            <div className="d-flex align-items-center tableBtns">
              <Link to={`/SkuInfo/${ele._id}?isEditMode=${false}`}>
                <Button className="btn lightBtn">View</Button>
              </Link>
              &nbsp;&nbsp;
              <Link to={`/SkuInfo/${ele._id}?isEditMode=${true}`}>
                <Button className="btn lightBtn">Edit</Button>
              </Link>
              &nbsp;&nbsp;
              <Button
                className="btn lightBtn"
                onClick={() => {
                  this.setState({ skuToDeleteId: ele._id }, () => {
                    this.deleteConfirmModalToggle();
                  });
                }}
              >
                Delete
              </Button>
            </div>
          ),
        });
      });
    }
    
    return (
      <>
        <div className="dashInnerMainDiv custCreationDivSec sosDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <div className="d-flex align-items-center justify-content-between w-100">
              <h4>SKU List</h4>
              <div>
                <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                  <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                    <Label for="exampleSelect">Show</Label>&nbsp;&nbsp;
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      onChange={(e) => {
                        this.setState(
                          {
                            limit: e.target.value,
                            currentPage: 1,
                          },
                          () => {
                            this.getAllSkuData();
                          }
                        );
                        // setTimeout(() => {
                        //   this.getAllSkuData();
                        // }, 500);
                      }}
                    >
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                    </Input>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
          {this.state.dataLoading ? (
            <div className="ViewPagesLoder1">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <Modal
                isOpen={this.state.deleteConfirmModal}
                toggle={this.deleteConfirmModalToggle}
                className={this.props.className}
                size="md"
              >
                <ModalHeader toggle={this.deleteConfirmModalToggle}>
                  Confirm
                </ModalHeader>
                <ModalBody>Are you sure you want to delete the SKU?</ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    onClick={() => {
                      this.onSkuDelete(this.state.skuToDeleteId);
                      this.deleteConfirmModalToggle();
                    }}
                  >
                    Delete
                  </Button>{" "}
                  <Button
                    color="secondary"
                    onClick={this.deleteConfirmModalToggle}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>

              <div className="dataTableStyles">
                <MDBDataTable
                  className="text-center text-capitalize pl-4"
                  responsive
                  hover
                  data={data}
                  paging={false}
                  searching={false}
                  displayEntries={false}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p className="mb-0">
                    Showing{" "}
                    {this.props?.skuDataList?.length != 0
                      ? (this.state.currentPage - 1) * this.state.limit + 1
                      : (this.state.currentPage - 1) * this.state.limit}{" "}
                    to{" "}
                    {(this.state.currentPage - 1) * this.state.limit +
                      (this.props?.skuDataList?.docs?.length
                        ? this.props?.skuDataList?.docs?.length
                        : "")}{" "}
                    {} entries of {this.props?.skuDataList?.length}
                  </p>
                </div>
                <div className="custPaginationDiv">
                  <Pagination
                    shape="rounded"
                    count={totPages}
                    page={this.state.currentPage}
                    color="primary"
                    onChange={this.handleChangePage}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    skuDataList: state.rfs.skuList,
  };
};
export default connect(mapStateToProps, {
  getSkuList,
  skuDeleteData,
})(Sku);
