import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  ModalHeader,
  FormGroup,
  Input,
  Label,
  Container,
} from "reactstrap";
import detailsslidesmallimage from "../../../images/detailsslidesmallimage.png";
import { connect } from "react-redux";
import {
  fetchOrderViewData,
  orderUpdate,
  getSosTypes,
  createSos,
  getBuyerPhoneInfo,
  sendOtpLogin,
  verifyOtpLogin,
} from "../../../store/actions";
import { NotificationManager } from "react-notifications";
import bagIcons from "../../../images/Order bag icon.svg";
import moment, { duration } from "moment";
import { ClockTimerComponent } from "../../../common/components/common/countdown-circle-timer";
import Butterfish from "../../../images/fresh-butterfish 13.png";
import Papplet from "../../../images/papplet.svg";
import Pappletv from "../../../images/pappletv.svg";
import Ordericon from "../../../images/order id icon.svg";
import vector from "../../../images/Vector 2.png";
import { handleErrorMsg } from "../../../common/components/common/GlobalFunctions";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import Slider from "react-slick";
import ProductsModal from "../../Product/common/ProductModal";
import ProductDetailImage from "../../Product/common/ProductDetailImage";
const oneDayInMilliseconds = 86400000;

export class confirmOrderDelivery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      deliveryDate: "",
      createdAt: "",
      orderedProductList: [],
      totalOrderedCost: "",
      totalProductValue: "",
      commission: "",
      totalCost: "",
      packagingCost: "",
      orderId: "",
      orderStatus: "",
      paymentStatus: "",
      netDifference: "",
      payLinkId: "",
      rates: [],
      amount: 0,
      amountPaid: 0,
      amountRefund: 0,
      discount: 0,
      transportCost: 0,
      ratesOBJ: {},
      buttonsDisable: true,
      allSosTypes: [],
      sosSelected: "",
      query: "",
      otpVerified: false,
      sosModal: false,
      otpModal: true,
      userPhone: 0,
      typeOtp: 0,
      orderIdFromUrlPath: "",
      otpSent: false,
      errorSendingOtp: false,
      minutesLeft: "false",
      updatedAt: 0,
      orderConfirmationExpiryTime: 0,
      orderLogs: [],
      sosPending: "",
      orderPlacedDate: "",
      // Commission: {},
      // PackagingCost: {},
      // TransportCost: {}
      dataLoading: false,
      horeca: false,
      ProductModal: false,
    };
  }
  updateOrderData(res) {
    this.setState({
      orderStatus: res?.singleOrder?.orderStatus,
      id: res?.singleOrder?._id,
      deliveryDate: res?.singleOrder?.deliveryDate,
      createdAt: res?.singleOrder?.createdAt,
      orderedProductList: res?.singleOrder?.orderedProductList,
      totalProductValue: res?.singleOrder?.totalProductValue,
      commission: res?.singleOrder?.commission,
      totalCost: res?.singleOrder?.totalCost,
      packagingCost: res?.singleOrder?.packagingCost,
      orderId: res?.singleOrder?.orderId,
      paymentStatus: res?.singleOrder?.paymentStatus,
      netDifference: res?.singleOrder?.netDifference,
      payLinkId: res?.singleOrder?.payLinkId,
      rates: res?.singleOrder?.rates,
      amount: res?.singleOrder?.originalCost,
      amountPaid: res?.singleOrder?.amountPaid,
      amountRefund: res?.singleOrder?.amountRefund,
      discount: res?.singleOrder?.discount,
      transportCost: res?.singleOrder?.transportCost,
      updatedAt: res?.singleOrder?.updatedAt,
      orderConfirmationExpiryTime:
        res?.singleOrder?.orderConfirmationExpiryTime,
      orderLogs: res?.singleOrder?.orderLogs,
      sosPending: res?.singleOrder?.sosPending,
      horeca: res?.singleOrder?.orderedProductList[0]?.productId?.Horeca
        ? res?.singleOrder?.orderedProductList[0]?.productId?.Horeca
        : false,
      ratesOBJ: {},
    });
    this.state.rates?.filter((a) => {
      let obj = {};
      obj[a.EnumType] = a;
      this.setState({ ratesOBJ: { ...this.state.ratesOBJ, ...obj } });
    });
    if (
      this.state.orderStatus != "In Review" ||
      this.state.sosPending == true
    ) {
      this.setState({
        buttonsDisable: true,
        minutesLeft: this.state.orderConfirmationExpiryTime,
      });
      this.updateTiming();
    } else {
      this.setState({
        buttonsDisable: false,
        minutesLeft: this.state.orderConfirmationExpiryTime,
      });
      this.updateTiming();
    }
  }

  getOrderData(updateOrder = "all") {
    this.setState({
      dataLoading: true,
    });
    this.props?.fetchOrderViewData(this.state.orderIdFromUrlPath, (res) => {
      this.updateOrderData(res);
      if (updateOrder === "all") {
        this.updateOrderToInReview(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
  }
  updateTiming() {
    // let minutes = (new Date().getTime() - new Date(this.state.updatedAt).getTime()) / 1000 / 60;
    //orderConfirmationExpiryTime
    // let minutes = new Date().getTime() <= new Date(this.state?.orderConfirmationExpiryTime).getTime();
    let minutes =
      (new Date(this.state?.orderConfirmationExpiryTime).getTime() -
        new Date().getTime()) /
      1000 /
      60;
    let secondsLeft = minutes / 60;

    // console.log(minutes , "mins");
    // console.log(
    //   new Date(this.state?.orderConfirmationExpiryTime).getTime(),
    //   "orderConfirmationExpiryTime"
    // );
    // console.log(new Date().getTime(), "time");
    if (minutes > 0) this.setState({ minutesLeft: parseInt(minutes) });
    else this.setState({ minutesLeft: "false" });
  }

  sendOtpForLogin() {
    this.setState({ otpSent: false, errorSendingOtp: false });
    this.props?.sendOtpLogin(
      { phone: this.state.userPhone?.toString() },
      (res) => {
        if (res && res?.user?.phone) {
          this.setState({ otpSent: true, errorSendingOtp: false });
          NotificationManager.success(res.message, "otp generated");
        } else {
          this.setState({ otpSent: false, errorSendingOtp: true });
          handleErrorMsg(res);
          // NotificationManager.error(res.message, "error sending otp");
        }
      }
    );
  }

  componentDidMount() {
    this.setState(
      { orderIdFromUrlPath: this.props?.computedMatch?.params?.orderid },
      async (res) => {
        // let search = new URLSearchParams(window?.location?.search);
        // let receivedToken = search.get("token");
        // if (receivedToken) { // android
        //     localStorage.setItem("token", JSON.stringify({ "token": receivedToken, "date": new Date().getTime() }))
        // } else { // web
        let tokenSaved = await JSON?.parse(localStorage?.getItem("token"));
        let dateSaved = await tokenSaved?.date;

        let OneDayPassed =
          new Date().getTime() < dateSaved + oneDayInMilliseconds;

        if (tokenSaved && tokenSaved?.token && dateSaved && OneDayPassed) {
          this.setState({ otpVerified: true, otpModal: false });
          this.getOrderData();
        } else {
          let info = await new Promise((resolve, reject) => {
            this.props?.getBuyerPhoneInfo(
              { orderId: this.state.orderIdFromUrlPath },
              (res) => {
                this.setState({ userPhone: res?.data?.buyer?.buyerId?.phone }); //userPhone: "ds" })//res?.data?.buyer?.buyerId?.phone })
                resolve(res);
              }
            );
          });

          if (info && info?.data?.buyer?.buyerId?.phone) {
            this.sendOtpForLogin();
          }
        }

        // }

        this.props?.getSosTypes((res) => {
          this.setState({ allSosTypes: res?.sosEnumTypes });
        });
      }
    );
  }

  updateOrderToInReview(res) {
    let data = {
      addedProducts: [],
      deletedProducts: [],
      modifiedProducts: [],
    };
    if (
      res?.singleOrder?.orderStatus === "In Transit" ||
      res?.singleOrder?.orderStatus === "Finalized"
    ) {
      this.props?.orderUpdate(
        data,
        this?.state?.orderIdFromUrlPath,
        "initiateReview",
        (res) => {
          if (res && res?.updatedOrder) {
            NotificationManager.success(res.message, "Order Updated");
          } else {
            handleErrorMsg(res);
            // NotificationManager.error(
            //   "order can not be updated to In Review",
            //   "Error"
            // );
            this.setState({ buttonsDisable: true });
          }
          this.getOrderData("");
        }
      );
    }
  }

  toggleProductsModal = () => {
    this.setState({
      ProductModal: !this.state.ProductModal,
    });
  };

  async otpVerification() {
    // console.log(this.state.typeOtp);
    this.props?.verifyOtpLogin(
      { phone: this.state.userPhone?.toString(), otp: this.state.typeOtp },
      async (res) => {
        // console.log(res);
        if (res?.status && res?.status === 400) handleErrorMsg(res);
        // NotificationManager.error(res.message, "Error Occured");
        else {
          NotificationManager.success(res.message, "Otp Verified");
          let receivedToken = res?.token;
          localStorage.setItem(
            "token",
            JSON.stringify({ token: receivedToken, date: new Date().getTime() })
          );
          this.setState({ otpVerified: true, otpModal: false });
          // this.updateOrderToInReview();
          this.getOrderData();
        }
      }
    );
  }
  percentageCommission = (commisionName) => {
    let commisionValue = "x";
    if (commisionName && this.state.rates?.length > 0) {
      const filtercommisionValue = this.state.rates.filter(
        (ele) => ele.EnumType === commisionName
      )[0]?.EnumValue;

      commisionValue = filtercommisionValue ? filtercommisionValue : "x";
    }
    return commisionValue;
  };
  render() {
    const confirmOrder = (e) => {
      e.preventDefault();
      let data = {
        addedProducts: [],
        deletedProducts: [],
        modifiedProducts: [],
      };
      this.setState({
        dataLoading: true,
      });
      this.props?.orderUpdate(
        data,
        this.state?.id,
        "confirmDelivery",
        (res) => {
          this.setState({ sosModal: false });

          // console.log(res);
          if (res && res?.updatedOrder) {
            this.setState({ buttonsDisable: true });
            this.getOrderData();
            NotificationManager.success(res.message, "Order Confirmed");
          } else {
            // NotificationManager.error(res.message, "Error");
            handleErrorMsg(res);
          }
          this.setState({
            dataLoading: false,
          });
        }
      );
    };

    const submitQuery = (e) => {
      e.preventDefault();
      let dataToSubmit = {
        query: this.state.query,
        orderId: this.state.id,
        sosType: this.state.sosSelected,
      };
      // console.log(dataToSubmit);
      this.setState({
        dataLoading: true,
        sosModal: false,
      });
      this.props?.createSos(dataToSubmit, (res) => {
        this.setState({ sosModal: false, dataLoading: false });
        // console.log(res);
        if (
          res.statusCode === 200 ||
          res.statusCode === 201
        ) {
          this.setState({ buttonsDisable: true });
          NotificationManager.success(res.message, "Sos Raised");
          this.setState({
            dataLoading: false,
          });
          this.getOrderData()
        } else {
          // NotificationManager.error(res.message, "Error");
          handleErrorMsg(res);
        }
      });
    };
    // console.log(this.state?.minutesLeft, "hi");
    // console.log(this.state.deliveryDate);
    // console.log(this.state.packagingCost);
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "slides",
    };
    // console.log(this.state.orderStatus, "hi");
    return (
      <>
        <div hidden={this.state.otpVerified} className="mainParentSectionDiv">
          <div className="modalDriver">
            <Modal
              isOpen={this.state.otpModal}
              className={"modalDriverInner"}
              size="md"
            >
              <ModalHeader>
                <h4>OTP Verification</h4>
              </ModalHeader>
              <div className="popupFormParentDivCss">
                <div className="popupFormDivsCss">
                  {this.state.otpSent ? (
                    <p style={{ color: "green" }}>
                      otp has been sent to number ending with ***
                      {this.state.userPhone?.toString().slice(6, 10)}
                    </p>
                  ) : this.state.errorSendingOtp ? (
                    <p style={{ color: "red" }}>error sending otp</p>
                  ) : (
                    <p style={{ color: "red" }}>sending otp ....</p>
                  )}
                  {this.state.errorSendingOtp && (
                    <Button onClick={() => this.sendOtpForLogin()}>
                      resend otp
                    </Button>
                  )}
                  <label>OTP</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    className="otpinput"
                    type="text"
                    pattern="[0-9]{4}"
                    required={true}
                    onChange={(a) => {
                      this.setState({ typeOtp: parseInt(a?.target?.value) });
                    }}
                  />
                </div>
              </div>
              <div>
                <Button
                  className="btn darkBtn locationbtn"
                  disabled={!this.state.otpSent}
                  onClick={() => this.otpVerification()}
                >
                  Submit
                </Button>{" "}
              </div>
            </Modal>
          </div>
        </div>
        {/* {console.log(this.state.minutesLeft)}  */}
        <div hidden={!this.state.otpVerified} className="mainParentSectionDiv">
          <div className="pd-15 bg-white ">
            {this.state.orderStatus != "Delivered" && this.state.minutesLeft != "false" && !this.state.sosPending && (
              <div className="d-flex align-items-center justify-content-center  dashInnerHeadDiv">
                {/* <h4>Please take action with in : {this.state.minutesLeft} minutes</h4> */}
                <h4 className="">Please take action with in : </h4> &nbsp;&nbsp;
                {
                  <ClockTimerComponent
                    duration={this.state.minutesLeft}
                    size={100}
                  />
                }
                &nbsp;&nbsp;<h4>minutes</h4>
              </div>
            )}

            <div className="modalDriver">
              <Modal
                isOpen={this.state.sosModal}
                toggle={() =>
                  this.setState({
                    sosModal: !this.state.sosModal,
                  })
                }
                className={"modalDriverInner verifymodal"}
                size="md"
              >
                <ModalHeader
                  toggle={() =>
                    this.setState({
                      sosModal: !this.state.sosModal,
                    })
                  }
                >
                  Add Query
                </ModalHeader>
                <div className="popupFormParentDivCss">
                  <div className="popupFormDivsCss">
                    <div className="mb-2">
                      {/* <legend className="col-form-label col-sm-2 w-100">
                                            sos Types
                                        </legend> */}
                      {this.state.allSosTypes.map((a) => {
                        return (
                          <Col>
                            <div className="d-flex w-100">
                              <label className="w-100 my-1" check>
                                {a}
                              </label>{" "}
                              <input
                                name="sos"
                                type="radio"
                                value={a}
                                className="w-25 my-1"
                                onChange={(a) =>
                                  this.setState({
                                    sosSelected: a?.target?.value,
                                  })
                                }
                              />
                            </div>
                          </Col>
                        );
                      })}
                    </div>
                  </div>
                  <div className="popupFormDivsCss">
                    <label>Query</label>
                    {/* &nbsp; <span>*</span> */}
                    <input
                      type="text"
                      value={this.state?.query}
                      disabled={this.state.buttonsDisable}
                      onChange={(a) =>
                        this.setState({ query: a?.target?.value })
                      }
                    />
                  </div>
                </div>
                <div>
                  <Button
                    className="btn darkBtn locationbtn"
                    disabled={!this.state?.sosSelected}
                    onClick={(e) => submitQuery(e)}
                  >
                    Submit
                  </Button>{" "}
                </div>
              </Modal>
            </div>

            <div className="formDivCss">
              <Form>
                <Container>
                  <br />
                  <hr />
                  <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
                    <div className="d-flex align-items-center justify-content-between ">
                      <img src={Ordericon} alt="image loading" />
                      &nbsp;
                      <div>
                        <h4 className="fontRoboto157">
                          Order ID:
                          {this.state.orderId ? this.state.orderId : ""}
                        </h4>
                      </div>
                    </div>
                    <div className="fontRoboto154">
                      {this.state.deliveryDate
                        ? moment(this.state.deliveryDate).format("ll")
                        : ""}
                    </div>
                  </div>

                  {this.state.dataLoading ? (
                    <div className="ViewPagesLoder1">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <>
                      {/* <Row className="mb-5"> */}
                      <div className="mb-5">
                        {this.state?.orderedProductList?.length > 0 &&
                          this.state?.orderedProductList?.map((ele) => (
                            <>
                              <Row>
                                <Col xs={12}>
                                  <Row className="mobilePaddingDiv20">
                                    <Col xs={12} md={6} xl={4}>
                                      <img
                                        src={ele?.productId?.sku?.skuImage[0]}
                                        height="224px"
                                        alt="image loading"
                                        className="confirmOrderImg"
                                      />
                                    </Col>
                                    {/* <Col xs={12} md={1}></Col> */}
                                    <Col xs={12} md={6} xl={8}>
                                      <Row className="rowRohuDiv">
                                        <Col lg={6} md={8} xs={8}>
                                          <span className="fontRoboto157 colorSkunameDiv">
                                            {" "}
                                            {ele?.skuName || "N/A"}
                                          </span>
                                        </Col>
                                        <Col lg={6} md={4} xs={4}>
                                          <span className="fontRoboto157">
                                            {" "}
                                            Rs {ele?.rate ?? ""}/kg
                                          </span>
                                        </Col>
                                      </Row>
                                      <Row className="rowCategoryDiv">
                                        <Col lg={6} md={8} xs={8}>
                                          <span className="fontRoboto157">
                                            {" "}
                                            Category :
                                          </span>
                                          <span className="fontRoboto154">
                                            {" "}
                                            {ele?.productId?.category
                                              ?.categoryName ?? "N/A"}{" "}
                                          </span>
                                        </Col>
                                        <Col lg={6} md={4} xs={4}>
                                          <span className="fontRoboto157">
                                            {" "}
                                            Quatity:
                                          </span>
                                          <span className="fontRoboto154">
                                            {" "}
                                            {ele?.quantity ?? ""}{" "}
                                          </span>
                                        </Col>
                                      </Row>
                                      <Row className="rowRatingDiv">
                                        <Col lg={6} md={8} xs={8}>
                                          {" "}
                                          <div className="d-flex flex-row">
                                            <div className="fontRoboto157 pr-2">
                                              {" "}
                                              Rating :
                                            </div>

                                            <div
                                              className={` ratingStartDiv fontRoboto154 ratingStarDiv${
                                                ele?.productId?.rating ?? ""
                                              }`}
                                            >
                                              <svg
                                                width="13"
                                                height="12"
                                                viewBox="0 0 19 18"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                              </svg>{" "}
                                              &nbsp;
                                              <svg
                                                width="13"
                                                height="12"
                                                viewBox="0 0 19 18"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                              </svg>
                                              &nbsp;
                                              <svg
                                                width="13"
                                                height="12"
                                                viewBox="0 0 19 18"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                              </svg>
                                              &nbsp;
                                              <svg
                                                width="13"
                                                height="12"
                                                viewBox="0 0 19 18"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                              </svg>
                                              &nbsp;
                                              <svg
                                                width="13"
                                                height="12"
                                                viewBox="0 0 19 18"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M9.64697 0.642975L6.86719 6.496L0.647949 7.08109L4.96387 11.325L4.01318 17.763L9.64697 14.544L14.915 17.763L14.0371 11.325L18.646 7.08109L12.208 6.13004L9.64697 0.642975Z" />
                                              </svg>
                                              &nbsp;
                                              <span className="fw-400 fs-12">
                                                {" "}
                                                ({ele?.productId?.rating ?? ""})
                                              </span>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col lg={6} md={4} xs={4}>
                                          {" "}
                                          <span className="fontRoboto157">
                                            Size:
                                          </span>
                                          <span className="fontRoboto154">
                                            {" "}
                                            {ele?.productId?.size ?? "N/A"}
                                          </span>
                                        </Col>
                                      </Row>
                                      <Row
                                        style={{
                                          position: "relative",
                                          top: "15%",
                                        }}
                                      >
                                        <Col
                                          xs={12}
                                          className="marginBottomDelivery"
                                        >
                                          <hr />
                                        </Col>
                                        <Col xs={12} className=" ">
                                          <span className="productdetails">
                                            Product Details
                                          </span>
                                        </Col>
                                        <Col
                                          xs={12}
                                          className="marginBottomDelivery"
                                        >
                                          <div className="fontRoboto154">
                                            Date & Time of Catch :{" "}
                                            {
                                              ele?.sellerData?.packaging
                                              // ? new Date(
                                              //     ele?.sellerData?.packaging
                                              //   )?.toLocaleDateString() +
                                              //   " " +
                                              //   new Date(
                                              //     ele?.sellerData?.packaging
                                              //   )?.toLocaleTimeString()
                                              // :  "NaN"
                                            }
                                          </div>
                                        </Col>
                                        {/* <Col xs={12}>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            disabled
                            className="fontRoboto154"
                            value={
                              ele?.sellerData?.packaging ?
                                new Date(ele?.sellerData?.packaging).toLocaleDateString() + new Date(ele?.sellerData?.packaging).toLocaleTimeString() : " NaN"
                            }
                          />
                        </Col> */}
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} className="mobileProductImage">
                                  <Row style={{ paddingTop: "15px" }}>
                                    <Col xs={12} md={7}>
                                      <div className="mobilePadding-7 fontRoboto154 productvideoheading">
                                        Product Image
                                      </div>
                                      <Slider {...settings}>
                                        {ele?.sellerData?.images?.map(
                                          (ele, index) => {
                                            if (index < 3) {
                                              return (
                                                <Col
                                                  xs={12}
                                                  sm={4}
                                                  md={3}
                                                  lg={3}
                                                  xl={3}
                                                  className="md-top imagecenter"
                                                >
                                                  <img
                                                    src={ele}
                                                    alt="img loading"
                                                    className="imgWidthDelivery"
                                                    onClick={() =>
                                                      this.toggleProductsModal()
                                                    }
                                                  />
                                                  <a
                                                    download
                                                    href={ele}
                                                    className="fa downloadbtn"
                                                  >
                                                    &#xf019;
                                                  </a>
                                                </Col>
                                              );
                                            }
                                          }
                                        )}
                                      </Slider>
                                      {/* <ProductDetailImage
                                        images={ele?.sellerData?.images}
                                      /> */}
                                      <ProductsModal
                                        images={ele?.sellerData?.images}
                                        isOpen={this.state.ProductModal}
                                        toggle={this.toggleProductsModal}
                                      />
                                      {/* <Row className="rowProductImage">
                                        {ele?.sellerData?.images?.map(
                                          (ele, index) => {
                                            if (index < 3) {
                                              return (
                                                <Col
                                                  xs={12}
                                                  sm={4}
                                                  md={3}
                                                  lg={3}
                                                  xl={3}
                                                  className="md-top imagecenter"
                                                >
                                                  <img
                                                    src={ele}
                                                    alt="img loading"
                                                    className="imgWidthDelivery"
                                                  />
                                                  <a
                                                    download
                                                    href={ele}
                                                    className="fa downloadbtn"
                                                  >
                                                    &#xf019;
                                                  </a>
                                                </Col>
                                              );
                                            }
                                          }
                                        )}
                                      </Row> */}
                                    </Col>
                                    <Col xs={12} md={1}></Col>
                                    <Col xs={12} md={4}>
                                      <Row>
                                        <Col xs={12}>
                                          <div className="mobilePadding-7 fontRoboto154 productvideoheading">
                                            Product Video
                                          </div>
                                        </Col>
                                        {ele?.sellerData?.videos?.map(
                                          (ele, index) => {
                                            if (index < 1) {
                                              return (
                                                <Col
                                                  xs={12}
                                                  sm={12}
                                                  xl={12}
                                                  md={12}
                                                  lg={12}
                                                >
                                                  <div>
                                                    <video
                                                      controls
                                                      className="videodiv"
                                                    >
                                                      <source
                                                        src={ele}
                                                        type="video/mp4"
                                                        width="100%"
                                                        height="100%"
                                                        className="imgWidthDelivery productVideosImgDIv "
                                                      />
                                                      Your browser does not
                                                      support the html video
                                                      tag.
                                                    </video>
                                                  </div>
                                                </Col>
                                              );
                                            }
                                          }
                                        )}
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          ))}
                        {/* </Row> */}
                        {/* <Col xs={12}> */}
                        <Row className="innerDivContent paddingInnerContent mobilePaddingDiv25">
                          <Col
                            xs={12}
                            md={5}
                            className="colordercostDiv innerDivPosition mb-2"
                          >
                            <div className="dotedLineDiv"></div>
                           

                            <div className="coloumn-reverse">
                              {this.state.orderLogs?.map((a, index) => {
                                return a?.type != "Updated" ? (
                                  <>
                                    <Row
                                      className="OrderFlow"
                                      style={{
                                        padding: "22px",
                                      }}
                                      // className={`${a?.type}`}
                                    >
                                      <Col xs={6} lg={6} md={7} xxl={9}>
                                        {" "}
                                        <div className="d-flex flex-row">
                                          <div className="orderPlaceMarginRight">
                                            <div className="orderPlacedDiv">
                                              <img
                                                src={vector}
                                                className="colorInnerDiv"
                                              />
                                            </div>
                                          </div>
                                          <div className="fontRoboto154">
                                            {" "}
                                            {a?.type}{" "}
                                            {new Date(
                                              a?.createdAt
                                            ).toLocaleDateString()}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={6}
                                        lg={6}
                                        md={5}
                                        xxl={3}
                                        className="fontRoboto154"
                                      >
                                        {" "}
                                        {new Date(
                                          a?.createdAt
                                        ).toLocaleTimeString()}
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  ""
                                );
                              })}
                            </div>
                            {/* <Row className="arrivedDiv">
                  <Col xs={8}>
                    {" "}
                    <div className="d-flex flex-row ">
                      <div className="orderPlaceMarginRight">
                        <div className="orderPlacedDiv">
                          <img
                            src={vector}
                            className="colorInnerDiv"
                          />
                        </div>
                      </div>
                      <div className="fontRoboto154">
                        {" "}
                        Arrived by Mon 25,March 2022
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} className="fontRoboto154">
                    {" "}
                    11:00 AM
                  </Col>
                </Row> */}
                            {/* <Row className="intransitDivContent">
                  <Col xs={8}>
                    {" "}
                    <div className="d-flex flex-row">
                      <div className="orderPlaceMarginRight">
                        <div className="orderPlacedDiv">
                          <img
                            src={vector}
                            className="colorInnerDiv"
                          />
                        </div>
                      </div>
                      <div className="fontRoboto154">
                        {" "}
                        In-transit Sun 26,March 2022
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} className="fontRoboto154">
                    {" "}
                    12:00 AM
                  </Col>
                </Row> */}
                            <Row className="orderPlacedContent ">
                              <Col xs={6} md={7} lg={8} xl={6} xxl={9}>
                                <div className="d-flex flex-row">
                                  <div className="orderPlaceMarginRight">
                                    <div className="orderPlacedDiv">
                                      <img
                                        src={vector}
                                        className="colorInnerDiv"
                                      />
                                    </div>
                                  </div>
                                  <div className="fontRoboto154">
                                    {" "}
                                    Order Placed
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xs={6}
                                lg={4}
                                md={4}
                                xl={5}
                                xxl={3}
                                className="fontRoboto154"
                              >
                                {" "}
                                {new Date(
                                  this.state?.createdAt
                                ).toLocaleTimeString()}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} md={1}></Col>

                          <Col xs={12} md={6} className="mobilePackaging">
                            {!this.state.horeca && (
                              <Row className="colordercostDiv">
                                <>
                                  <Col xs={8} sm={10}>
                                    <div className="fontRoboto154">
                                      Packaging Cost :
                                    </div>
                                    <p className="commision fontRoboto154 text-nowrap">
                                      (@{" "}
                                      {this.percentageCommission(
                                        "PackagingCost"
                                      )}{" "}
                                      %)
                                    </p>
                                  </Col>
                                  <Col xs={4} sm={2}>
                                    <p className="mb-0 fontRoboto154 text-nowrap">
                                      Rs{" "}
                                      {this.state.packagingCost
                                        ? this.state.packagingCost
                                        : 0}
                                    </p>
                                  </Col>
                                </>
                              </Row>
                            )}

                            <br></br>
                            <Row className="colordercostDiv">
                              <Col xs={8} sm={10}>
                                <div className="fontRoboto154">
                                  Transport Cost :
                                </div>
                                <p className="commision fontRoboto154 text-nowrap">
                                  (@ Rs{" "}
                                  {this.percentageCommission("TransportCost")}{" "}
                                  per km)
                                </p>
                              </Col>
                              <Col xs={4} sm={2}>
                                <p className="mb-0 fontRoboto154 text-nowrap">
                                  Rs{" "}
                                  {this.state.transportCost
                                    ? this.state.transportCost
                                    : 0}
                                </p>
                              </Col>
                            </Row>
                            <br />

                            {!this.state.horeca && (
                              <>
                                <Row className="colordercostDiv">
                                  <Col xs={8} sm={9}>
                                    <div className="fontRoboto154">
                                      Freshr Commission :
                                    </div>
                                    <p className="commision fontRoboto154 text-nowrap">
                                      (@{" "}
                                      {this.percentageCommission("Commission")}{" "}
                                      %)
                                    </p>
                                  </Col>
                                  <Col xs={4} sm={3} className="commission">
                                    <p className="commision fontRoboto154 text-nowrap">
                                      Rs{" "}
                                      {this.state.commission
                                        ? this.state.commission
                                        : 0}
                                    </p>
                                  </Col>
                                </Row>
                              </>
                            )}

                            <br></br>
                            <Row className="colordercostDiv">
                              <Col xs={8} sm={10}>
                                <div className="fontRoboto154">Discount :</div>
                                <p className="commision fontRoboto154 text-nowrap"></p>
                              </Col>
                              <Col xs={4} sm={2}>
                                <p className="commision fontRoboto154 text-nowrap">
                                  Rs{" "}
                                  {this.state.discount
                                    ? this.state.discount
                                    : 0}
                                </p>
                              </Col>
                            </Row>
                            <br />
                            <Row className="colordercostDiv">
                              <Col xs={8} sm={10}>
                                <div className="fontRoboto154">
                                  Amount Paid :
                                </div>
                                <p className="commision fontRoboto154 text-nowrap"></p>
                              </Col>
                              <Col xs={4} sm={2}>
                                <p className="commision fontRoboto154 text-nowrap">
                                  Rs{" "}
                                  {this.state.amountPaid
                                    ? this.state.amountPaid
                                    : 0}
                                </p>
                              </Col>
                            </Row>
                            <br />
                            <Row className="colordercostDiv">
                              <Col xs={8} sm={10}>
                                <div className="fontRoboto154">
                                  Amount Refunded :
                                </div>
                                <p className="commision fontRoboto154 text-nowrap"></p>
                              </Col>
                              <Col xs={4} sm={2}>
                                <p className="commision fontRoboto154 text-nowrap">
                                  Rs{" "}
                                  {this.state.amountRefund
                                    ? this.state.amountRefund
                                    : 0}
                                </p>
                              </Col>
                            </Row>
                            <br />
                            <Row className="colcostLastOrder">
                              <Col xs={8} sm={9}>
                                <div className="fontRoboto154">Total:</div>
                              </Col>
                              <Col xs={4} sm={2}>
                                <p className="mb-0 fontRoboto154 text-nowrap">
                                  {this.state.totalCost
                                    ? this.state.totalCost
                                    : ""}{" "}
                                  Rs
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* </Col> */}
                        <Row>
                          {/* <Col xs={12} className="raiseSosDiv">
                            <div className="centerdiv">
                              <div className="align-items-center paddingtopdiv divbtns d-flex flex-row">
                                <button
                                  className="btn darkBtn btn-right raiseButtonWidth buttonSos margin-right"
                                  disabled={this.state.buttonsDisable}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      sosModal: true,
                                    });
                                  }}
                                >
                                  Raise sos
                                </button>
                                <button
                                  className="btn darkBtn raiseButtonWidth buttonSos margin-top-10"
                                  onClick={(e) => confirmOrder(e)}
                                  disabled={this.state.buttonsDisable}
                                >
                                  Confirm Order
                                </button>
                              </div>
                            </div>
                          </Col> */}
                        </Row>
                      </div>
                    </>
                  )}
                </Container>
              </Form>
            </div>
          </div>
          <Col xs={12} className="raiseSosDiv">
            <div className="centerdiv">
              <div className="align-items-center paddingtopdiv divbtns d-flex flex-row">
                <button
                  className="btn darkBtn btn-right raiseButtonWidth buttonSos margin-right"
                  disabled={this.state.buttonsDisable}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      sosModal: true,
                    });
                  }}
                >
                  Raise sos
                </button>
                <button
                  className="btn darkBtn raiseButtonWidth buttonSos margin-top-10"
                  onClick={(e) => confirmOrder(e)}
                  disabled={this.state.buttonsDisable}
                >
                  Confirm Order
                </button>
              </div>
            </div>
          </Col>
        </div>
      </>
    );
  }
}

export default connect(null, {
  fetchOrderViewData,
  orderUpdate,
  getSosTypes,
  createSos,
  getBuyerPhoneInfo,
  sendOtpLogin,
  verifyOtpLogin,
})(confirmOrderDelivery);
