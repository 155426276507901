import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import TableProfilePic from "../../../images/tableprofilepic.png";
import { connect } from "react-redux";
import {
  fetchAllAdminData,
  adminDeleteData,
  addAdmins,
} from "../../../store/actions";
import { NotificationManager } from "react-notifications";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import { debounce, throttle } from "lodash";
import MainHeader from "../../../components/common/MainHeader";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import {
  NUMBER_REGEX,
  NAME_REGEX,
  EMAIL_REGEX,
  PHONE_NUMBER_ERROR_REGEX,
} from "../../../common/components/common/Rejex";
import {
  handleErrorMsg,
  handleKeyPress,
} from "../../../common/components/common/GlobalFunctions";
class AdminMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empid: "",
      name: "",
      rollType: "maker",
      conno: "",
      emailid: "",
      password: "",
      limit: 10,
      adminToDeleteId: "",
      offset: 0,
      sort: "",
      searchAdminByPhone: "",
      searchAdminByName: "",
      currentPage: 1,
      userId: "",
      userType: "",
      loading: false,
      userAccountStatus: "KYC Verified",
      loading1: false,
    };
  }

  componentDidMount = () => {
    this.getAllData();
  };
  getAllData = () => {
    this.setState({
      loading: true,
    });
    this.props.fetchAllAdminData(
      this.state.offset,
      this.state.limit,
      this.state.searchAdminByPhone,
      this.state.searchAdminByName,
      this.state.sort,
      this.state.userId,
      this.state.userType,
      (res) => {
        this.setState({
          loading: false
        });
      }
    );
  };

  onChangeEvent(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }

  generatePassword = () => {
    var length = 10,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  onDeleteAdminRequest(id) {
    this.setState({
      loading: true,
    });
    this.props.adminDeleteData(id, (res) => {
      if (res) {
        if (
          res &&
          res?.message === "user has been deleted successfully!"
        ) {
          NotificationManager.success(res?.message, "Delete Successful");
          this.getAllData();
        } else {
          // NotificationManager.error(res?.message, "Error");
          handleErrorMsg(res);
        }
        this.setState({
          loading: false,
        });
      }
    });
  }

  delayedHandleChange = debounce(() => {
    this.getAllData();
  }, 1000);

  getadminSearch(name, val) {
    this.setState(
      {
        [`${name}`]: val,
        currentPage: 1,
        offset: 0
      },
      () => {
        this.delayedHandleChange();
      }
    );
  }

  handleChangePage = (event, val) => {
    this.setState(
      {
        currentPage: val,
        offset: (val - 1) * this.state.limit,
      },
      () => {
        this.getAllData();
      }
    );
  };

  createTheAdmin = (e) => {
    e.preventDefault();
    let { empid, name, rollType, conno, emailid, password, userAccountStatus } =
      this.state;
    let obj = {
      loginId: empid,
      fullName: name,
      email: emailid,
      phone: conno,
      userType: rollType,
      password: password,
      userAccountStatus: userAccountStatus,
    };
    this.setState({
      loading1: true,
    });
    this.props.addAdmins(obj, (res) => {
      if (res && res?.statusCode === 200) {
        NotificationManager.success(
          res?.message,
          "Admin has been created successfully"
        );
        this.setState({
          empid: "",
          name: "",
          emailid: "",
          conno: "",
          rollType: "maker",
          password: "",
        });
        this.getAllData();
      } else {
        handleErrorMsg(res);
      }
      this.setState({
        loading1: false,
      });
    });
  };

  render() {
    const totPages = Math.ceil(
      this.props.allAdminDatas.length / this.state.limit
    );
    var data = {};
    data = {
      columns: [
        {
          label: <span>ID</span>,
          field: "id",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Name</span>,
          field: "name",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Email ID</span>,
          field: "emailid",
          sort: "asc",
          width: 500,
        },
        {
          label: <span>Contact</span>,
          field: "contact",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Status</span>,
          field: "status",
          sort: "asc",
          width: 50,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [
        // {
        //   id: "#2654",
        //   name: (
        //     <div className="d-flex align-items-center">
        //       <img src={TableProfilePic}></img>&nbsp;&nbsp;&nbsp;
        //       <p className="mb-0">Ashish Saini</p>
        //     </div>
        //   ),
        //   emailid: "abcd@gmail.com",
        //   contact: "+91 73678 63456",
        //   status: "Completed",
        //   actions: (
        //     <div className="d-flex align-items-center tableBtns">
        //       <Link to={"/AdminuserInfo"}>
        //         <Button className="btn lightBtn">View</Button>
        //       </Link>
        //       &nbsp;&nbsp;<Button className="btn lightBtn">Edit</Button>
        //       &nbsp;&nbsp;<Button className="btn lightBtn">Delete</Button>
        //     </div>
        //   ),
        // },
        // 1,2,3,4,5
      ],
    };

    this?.props?.allAdminDatas?.docs?.map((el, i) => {
      data.rows.push({
        id: this.state.offset + i + 1,
        name: (
            <p className="mb-0 textCapitalize">{el?.fullName ? el?.fullName : "Null"}</p>
        ),
        emailid: <p>{el?.email ? el?.email : "Null"}</p>,
        contact: <p>{el?.phone ? el?.phone : "Null"}</p>,
        status: <p>{el?.userAccountStatus ? el?.userAccountStatus : "Null"}</p>,
        actions: (
          <div className="d-flex align-items-center tableBtns">
            <Link to={`/AdminuserInfo/${el?._id}?isEditMode=${false}`}>
              <Button className="btn lightBtn">View</Button>
            </Link>
            &nbsp;&nbsp;
            <Link to={`/AdminuserInfo/${el?._id}?isEditMode=${true}`}>
              <Button className="btn lightBtn">Edit</Button>
            </Link>
            &nbsp;&nbsp;
            <Button
              className="btn lightBtn"
              onClick={() => {
                this.setState({ modal: true, adminToDeleteId: el?._id });
              }}
            >
              Delete
            </Button>
          </div>
        ),
      });
    });
    return (
      <>
        {/* <LoadingSpinner /> */}
        <MainHeader pageTitle={"Admin"} />
        <div className="mainParentSectionDiv">
          <div className="dashInnerMainDiv custCreationDivSec sosDiv">
            <div>
              <div className="d-flex align-items-center justify-content-between w-100 dashInnerHeadDiv">
                <h4>Create New User</h4>
              </div>
              <div>
                {this.state.loading1 ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <Row>
                      <Col xs={12} sm={2} md={4} className="mb-15">
                        <FormGroup>
                          <Label for="empid">Employee ID</Label>
                          <Input
                            id="empid"
                            name="empid"
                            value={this.state.empid}
                            pattern="[0-9]{0,5}"
                            maxLength={6}
                            placeholder="Type..."
                            type="text"
                            onChange={(e) => {
                              let regularExpressionNumber = /^[0-9]*$/;
                              if (
                                regularExpressionNumber.test(e.target.value)
                              ) {
                                this.onChangeEvent("empid", e.target.value);
                              } else {
                                this.onChangeEvent("empid", "");
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={5} md={4} className="mb-15">
                        <FormGroup>
                          <Label for="name">
                            User name
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            id="name"
                            name="name"
                            placeholder="Type..."
                            value={this.state.name}
                            invalid={
                              NAME_REGEX.test(this.state.name) ||
                                this.state.name?.length == 0
                                ? false
                                : true
                            }
                            type="text"
                            onChange={(e) => {
                              this.onChangeEvent("name", e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col xs={12} sm={5} md={5} className="mb-15">
                            <FormGroup>
                              <Label for="dept">
                                Department<span style={{color:"red"}}>*</span>
                              </Label>
                              <Input
                                id="name"
                                name="name"
                                placeholder="Type..."
                                type="text"
                              />
                            </FormGroup>
                          </Col> */}
                      <Col xs={12} sm={5} md={4} className="mb-15">
                        <FormGroup>
                          <Label for="roll">
                            Roll<span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            id="rollType"
                            name="rollType"
                            placeholder="Type..."
                            type="select"
                            value={this.state.rollType}
                            onChange={(e) =>
                              this.onChangeEvent("rollType", e.target.value)
                            }
                          >
                            <option value={"maker"}>Maker</option>
                            <option value={"checker"}>Checker</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={5} md={4} className="mb-15">
                        <FormGroup>
                          <Label for="conno">
                            Contact no<span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            id="conno"
                            name="conno"
                            placeholder="Type..."
                            type="text"
                            value={this.state.conno}
                            // pattern="[0-9]{0,5}"
                            maxLength="10"
                            onChange={(e) => {
                              let regularExpressionNumber = /^[0-9]*$/;
                              if (
                                regularExpressionNumber.test(e.target.value)
                              ) {
                                this.onChangeEvent("conno", e.target.value);
                              } else {
                                this.onChangeEvent("conno", "");
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={5} md={4} className="mb-15">
                        <FormGroup>
                          <Label for="exampleEmail">
                            Email
                            {/* <span
                              hidden={
                                EMAIL_REGEX.test(this.state.emailid) ||
                                this.state.emailid?.length == 0
                              }
                              style={{
                                color: "red",
                                marginLeft: "20px",
                                fontSize: "14px",
                              }}
                            >
                              Invalid Email
                            </span> */}
                          </Label>
                          <Input
                            id="emailid"
                            name="emailid"
                            placeholder="Type..."
                            value={this.state.emailid}
                            invalid={
                              EMAIL_REGEX.test(this.state.emailid) ||
                                this.state.emailid?.length == 0
                                ? false
                                : true
                            }
                            type="text"
                            // pattern="/^[a-zA-Z]+ [a-zA-Z]+$/"
                            // maxLength={20}
                            onChange={(e) =>
                              this.onChangeEvent("emailid", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={5} md={4} className="mb-15">
                        <FormGroup>
                          <Label for="password">
                            Password<span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            id="password"
                            name="password"
                            placeholder="Type..."
                            type="text"
                            value={this.state.password}
                            onChange={(e) =>
                              this.onChangeEvent("password", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={5} md={4} className="mb-15">
                        <FormGroup>
                          <Label for="UserAccountStatus">
                            User Account Status
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            id="password"
                            name="UserAccountStatus"
                            placeholder="Type..."
                            type="select"
                            disabled={true}
                            value={this.state.userAccountStatus}
                            onChange={(e) =>
                              this.onChangeEvent(
                                "userAccountStatus",
                                e.target.value
                              )
                            }
                          >
                            <option value={"New"}>New</option>
                            <option value={"KYC Verified"}>KYC Verified</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        sm={2}
                        md={4}
                        className="mb-15 d-flex align-items-center justify-content-between"
                      >
                        <div className="pdt-15">
                          <button
                            className="btn darkBtn"
                            onClick={() => {
                              const val = this.generatePassword();
                              this.onChangeEvent("password", val);
                            }}
                          >
                            Generate Password
                          </button>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        sm={2}
                        md={4}
                        className="mb-15 d-flex align-items-center justify-content-between"
                      >
                        <div className="pdt-15">
                          <button
                            className="btn darkBtn"
                            onClick={this.createTheAdmin}
                            disabled={
                              !(
                                this.state.empid &&
                                this.state.name &&
                                this.state.rollType &&
                                this.state.conno.length === 10 &&
                                this.state.emailid &&
                                this.state.password &&
                                (NUMBER_REGEX.test(this.state.empid) ||
                                  this.state.empid?.length == 0) &&
                                (NAME_REGEX.test(this.state.name) ||
                                  this.state.name?.length == 0) &&
                                (EMAIL_REGEX.test(this.state.emailid) ||
                                  this.state.emailid?.length == 0)
                              )
                            }
                          >
                            Create
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
              <div className="d-flex align-items-center justify-content-between w-100">
                <h4>Admin Users</h4>
                <div>
                  <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                    <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv">
                      <Input
                        name="search"
                        placeholder="Search By Phone"
                        type="text"
                        maxLength={10}
                        className="mb-0 searchPhoneInput"
                        value={this.state.adminSearch}
                        // invalid={
                        //   PHONE_NUMBER_ERROR_REGEX.test(
                        //     this.state.searchAdminByPhone
                        //   ) || this.state.searchAdminByPhone?.length == 0
                        //     ? false
                        //     : true
                        // }
                        onChange={(e) => {
                          if (
                            NUMBER_REGEX.test(e.target.value) &&
                            e.target.value.length <= 10
                          ) {
                            this.getadminSearch(
                              "searchAdminByPhone",
                              e.target.value.trim()
                            );
                          }
                        }}
                        onKeyPress={(evt) => {
                          handleKeyPress(evt);
                        }}
                        // onChange={(e) => {
                        //   if (
                        //     NUMBER_REGEX.test(e.target.value) &&
                        //     e.target.value <= 10
                        //   ) {
                        //     this.getadminSearch(
                        //       "searchAdminByPhone",
                        //       e.target.value.trim()
                        //     );
                        //   } else {
                        //     this.getadminSearch("searchAdminByPhone", "");
                        //   }
                        // }}
                        autoComplete="off"
                      />
                    </FormGroup>

                    <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv">
                      <Input
                        name="search"
                        placeholder="Search By Name"
                        type="text"
                        className="mb-0 searchPhoneInput"
                        value={this.state.adminSearch}
                        // invalid={
                        //   NAME_REGEX.test(this.state.searchAdminByName) ||
                        //     this.state.searchAdminByName?.length == 0
                        //     ? false
                        //     : true
                        // }
                        onChange={(e) => {
                          this.getadminSearch(
                            "searchAdminByName",
                            e.target.value.trim()
                          );
                        }}
                        autoComplete="off"
                      />
                      {/* <span
                        hidden={
                          NAME_REGEX.test(this.state.searchAdminByName) ||
                          this.state.searchAdminByName?.length == 0
                        }
                        style={{
                          color: "red",
                          marginLeft: "20px",
                          fontSize: "15px",
                        }}
                      >
                        Invalid Name
                      </span> */}
                    </FormGroup>

                    <FormGroup className="d-flex align-items-center tableHeaderSelectDiv">
                      <Label for="exampleSelect">Sort by</Label>&nbsp;&nbsp;
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onChange={(e) => {
                          this.setState(
                            {
                              sort: e.target.value,
                            },
                            () => {
                              this.getAllData();
                            }
                          );
                        }}
                      >
                        <option>Latest</option>
                        <option>Oldest</option>
                      </Input>
                    </FormGroup>
                    <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                      <Label for="exampleSelect">Show</Label>&nbsp;&nbsp;
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        onChange={(e) => {
                          this.setState(
                            {
                              limit: e.target.value,
                            },
                            () => {
                              this.getAllData();
                            }
                          );
                        }}
                      >
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>40</option>
                      </Input>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
            {this.state.loading ? (
              <LoadingSpinner />
            ) : (
              <div>
                <Modal
                  funk={true}
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                >
                  <ModalHeader toggle={this.toggle}>Confirm</ModalHeader>
                  <ModalBody>
                    Are you sure you want to delete the Admin?
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="danger"
                      onClick={() => {
                        this.onDeleteAdminRequest(this.state.adminToDeleteId);
                        this.toggle();
                      }}
                    >
                      Delete
                    </Button>{" "}
                    <Button color="secondary" onClick={this.toggle}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
                <div className="dataTableStyles">
                  <MDBDataTable
                    className="text-center"
                    responsive
                    hover
                    data={data}
                    paging={false}
                    searching={false}
                  />
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <p className="mb-0">
                      Showing{" "}
                      {this.props.allAdminDatas?.length != 0
                        ? (this.state.currentPage - 1) * this.state.limit + 1
                        : (this.state.currentPage - 1) * this.state.limit}{" "}
                      to{" "}
                      {(this.state.currentPage - 1) * this.state.limit +
                        (this.props.allAdminDatas?.docs?.length
                          ? this.props.allAdminDatas?.docs?.length
                          : "")}{" "}
                      { } entries of {this.props?.allAdminDatas?.length}
                    </p>
                  </div>
                  <div className="custPaginationDiv noRightBorderPagination">
                    <Pagination
                      shape="rounded"
                      count={totPages}
                      page={this.state.currentPage}
                      color="primary"
                      onChange={this.handleChangePage}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, props) => {
  return { allAdminDatas: state.rfa.allAdminData };
};
export default connect(mapStateToProps, {
  fetchAllAdminData,
  adminDeleteData,
  addAdmins,
})(AdminMain);
