import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  InputGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default class DatePickerDelivery extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="repeatModal">
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.props.toggle}></ModalHeader>
          <ModalBody>
            <FormGroup className="mb-4">
              <Label>Select Delivery Date</Label>
              <InputGroup>
                <DatePicker
                  selected={this.props.monthDate}
                  className="form-control"
                  minDate={new Date()}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  // showTimeInput
                  onChange={this.props.handleMonthChange}
                  showMonthDropdown
                  inline
                />
              </InputGroup>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button outline onClick={this.props.toggle}>
              Cancel
            </Button>
            <Button
              outline
              onClick={() => {
                this.props.actionFunc();
              }}
              disabled={!this.props.monthDate || !this.props.dateFlag}
            >
              {this.props.action}
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
