import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label } from "reactstrap";
import userprofile from "../../../images/userprofile.png";
import TableProfilePicOne from "../../../images/joey.png";
import { connect } from "react-redux";
import {
  userRequestData,
  userRequestDeleteData,
  // userFilterData,
} from "../../../store/actions";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import files loader
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import { handleErrorMsg } from "../../../common/components/common/GlobalFunctions";

class UserRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleDiv: false,
      userAccountSource: "",
      limit: 10,
      currentPage: 1,
      userToDeleteId: "",
      deleteConfirmModal: false,
      dataLoading: false,
    };
  }

  toggleFilter = () => {
    this.setState({
      toggleDiv: !this.state.toggleDiv,
    });
  };
  deleteConfirmModalToggle = () => {
    this.setState({
      deleteConfirmModal: !this.state.deleteConfirmModal,
    });
  };
  onDeleteUserRequest(id) {
    this.props?.userRequestDeleteData(id, (res) => {
      if (
        res &&
        res.message === "Service request has been deleted successfully updated"
      ) {
        NotificationManager.success(res.message, "Delete Successful");
        this.getAllData((this.state.currentPage - 1) * this.state.limit);
      } else {
        // NotificationManager.error(res.message, "Error");
        handleErrorMsg(res);
      }
    });
  }
  componentDidMount() {
    if (this.props?.location?.state?.userAccountSource === "mobile")
      this.setState({ userAccountSource: "mobile", currentPage: 1 }, () => {
        this.getAllData();
      });
    else if (this.props?.location?.state?.userAccountSource === "web")
      this.setState({ userAccountSource: "web", currentPage: 1 }, () => {
        this.getAllData();
      });
    else
      this.setState({ userAccountSource: "", currentPage: 1 }, () => {
        this.getAllData();
      });
  }
  getAllData = (offset = 0) => {
    this.setState({
      dataLoading: true,
    });
    this.props?.userRequestData(
      offset,
      this.state.limit,
      this.state.userAccountSource,
      () => {
        this.setState({
          dataLoading: false,
        });
      }
    );
  };

  onChange(name, val) {
    this.setState(
      {
        [`${name}`]: val,
        currentPage: 1,
        toggleDiv: !this.state.toggleDiv,
      },
      () => {
        this.getAllData();
      }
    );
  }
  handleChangePage = (event, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.getAllData(offset);
  };
  render() {
    const totPages = Math.ceil(
      this.props?.listOfUserRequest?.length / this.state.limit
    );
    var data = {};
    data = {
      columns: [
        {
          label: <span>Name</span>,
          field: "name",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Contact</span>,
          field: "contact",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Member Since</span>,
          field: "member",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Source of Interest</span>,
          field: "source",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Status</span>,
          field: "status",
          sort: "asc",
          width: 50,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [
        // {name:<div className="d-flex align-items-center"><img src={TableProfilePic}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Ashish Saini</p></div>,contact:'+91 73678 63456',date:<div>21/07/21<br/> 2:00 PM</div>,source:'Website',status:'Completed',actions:<div className="d-flex align-items-center tableBtns"><Button className="btn lightBtn">View</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Edit</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Delete</Button></div>},
        // {name:<div className="d-flex align-items-center"><img src={TableProfilePicOne}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Joey Lobo</p></div>,contact:'+91 73678 63456',date:<div>21/07/21<br/> 2:00 PM</div>,source:'Website',status:'Canceled',actions:<div className="d-flex align-items-center tableBtns"><Button className="btn lightBtn">View</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Edit</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Delete</Button></div>},
        // {name:<div className="d-flex align-items-center"><img src={TableProfilePic}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Manoj Tiwari</p></div>,contact:'+91 73678 63456',date:<div>21/07/21<br/> 2:00 PM</div>,source:'Website',status:'Dispatch',actions:<div className="d-flex align-items-center tableBtns"><Button className="btn lightBtn">View</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Edit</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Delete</Button></div>},
        // {name:<div className="d-flex align-items-center"><img src={TableProfilePicOne}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Disha Kelkar</p></div>,contact:'+91 73678 63456',date:<div>21/07/21<br/> 2:00 PM</div>,source:'Website',status:'Completed',actions:<div className="d-flex align-items-center tableBtns"><Button className="btn lightBtn">View</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Edit</Button>&nbsp;&nbsp;<Button className="btn lightBtn">Delete</Button></div>}
      ],
    };
    if (
      this.props?.listOfUserRequest?.docs &&
      this.props?.listOfUserRequest?.docs?.length > 0
    ) {
      this.props?.listOfUserRequest?.docs.forEach((ele) => {
        data.rows.push({
          name: (
            <div className="d-flex align-items-center">
              <img src={userprofile}></img>&nbsp;&nbsp;&nbsp;
              <p className="mb-0">{ele.fullName ? ele.fullName : "null"}</p>
            </div>
          ),
          contact: ele.phone,
          // contact: ele.phone,
          member: (
            <div>
              {moment(ele.createdAt).format("DD/MM/YYYY")} <br />{" "}
              {moment(ele.createdAt).format("LT")}
            </div>
          ),
          source: (
            <span className="text-capitalize text-center">
              {ele.userAccountSource}
            </span>
          ),
          status: (
            <span className="text-capitalize text-center">
              {ele.userAccountStatus}
            </span>
          ),
          actions: (
            <div className="d-flex align-items-center tableBtns">
              <Link
                to={`/Customerdetails/${ele._id}?isEditMode=${false}`}
                className="flexforres"
              >
                <Button className="btn lightBtn ">View</Button>&nbsp;&nbsp;
              </Link>
              <Link
                to={`/Customerdetails/${ele._id}?isEditMode=${true}`}
                className="flexforres"
              >
                <Button className="btn lightBtn">Edit</Button>&nbsp;&nbsp;
              </Link>
              <Button
                className="btn lightBtn flexforres"
                onClick={() => {
                  this.setState({ userToDeleteId: ele._id }, () => {
                    // this.onDeleteUserRequest(ele._id);
                    this.deleteConfirmModalToggle();
                  });
                }}
              >
                Delete
              </Button>
            </div>
          ),
        });
      });
    }

    return (
      <div className="mainParentSectionDiv1">
        <Modal
          isOpen={this.state.deleteConfirmModal}
          toggle={this.deleteConfirmModalToggle}
          className={this.props.className}
          size="md"
        >
          <ModalHeader toggle={this.deleteConfirmModalToggle}>
            Confirm
          </ModalHeader>
          <ModalBody>Are you sure you want to delete the User?</ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                this.onDeleteUserRequest(this.state.userToDeleteId);
                this.deleteConfirmModalToggle();
              }}
            >
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.deleteConfirmModalToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div className="dashInnerMainDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <div
              className="d-flex align-items-center justify-content-between w-100"
              onClick={() => {
                this.setState({
                  toggleDiv: !this.state.toggleDiv,
                });
              }}
            >
              <h4>User Requests....</h4>
              <div>
                <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                  <div className="d-flex flex-row">
                    <Link
                      to={{
                        pathname: "/Buyerform",
                        state: { userType: "buyer" },
                      }}
                    >
                      <Button className="btn darkBtn addcustomerresponsive">
                        Add New Customer
                      </Button>
                    </Link>

                    <div className="pos-rel">
                      <Button
                        className="btn lightBtn"
                        onClick={() => this.toggleFilter()}
                      >
                        Source
                      </Button>
                      <div
                        className={`logoutDropdown userRquestsFilterDropdown ${
                          this.state.toggleDiv ? "displayBlock" : "displayNone"
                        }`}
                      >
                        <div class="arrow-up"></div>
                        <ul className="mb-0">
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              this.onChange("userAccountSource", "web");
                            }}
                          >
                            <li>
                              <span>Website</span>
                            </li>
                          </a>
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              this.onChange("userAccountSource", "mobile");
                            }}
                          >
                            <li>
                              <span>Mobile app</span>
                            </li>
                          </a>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <FormGroup className="d-flex align-items-center tableHeaderSelectDiv">
                  <Label for="exampleSelect">Sort by</Label>&nbsp;&nbsp;
                  <Input id="exampleSelect" name="select" type="select">
                    <option>Latest</option>
                    <option>option2</option>
                    <option>option3</option>
                    <option>option4</option>
                    <option>option5</option>
                  </Input>
                </FormGroup> */}
                </Form>
              </div>
            </div>
          </div>
          {this.state.dataLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="dataTableStyles">
                <MDBDataTable
                  className="text-center  text-capitalize"
                  responsive
                  hover
                  data={data}
                  paging={false}
                  searching={false}
                  displayEntries={false}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p className="mb-0">
                    Showing{" "}
                    {this.props?.listOfUserRequest?.length != 0
                      ? (this.state.currentPage - 1) * 10 + 1
                      : (this.state.currentPage - 1) * 10}{" "}
                    to{" "}
                    {(this.state.currentPage - 1) * 10 +
                      (this.props?.listOfUserRequest?.docs?.length
                        ? this.props?.listOfUserRequest?.docs?.length
                        : "")}{" "}
                    {} entries of {this.props?.listOfUserRequest?.length}
                  </p>
                </div>
                <div className="custPaginationDiv">
                  <Pagination
                    shape="rounded"
                    count={totPages}
                    page={this.state.currentPage}
                    color="primary"
                    onChange={this.handleChangePage}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    listOfUserRequest: state.rfu.userRequestData,
  };
};
export default connect(mapStateToProps, {
  userRequestData,
  userRequestDeleteData,
  // userFilterData,
})(UserRequests);
