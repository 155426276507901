import React, { Component } from "react";
import { Row, Col, Progress, Button } from "reactstrap";
import CustomerCreation from "./Customercreation";
import Buyericon from "../../../images/buyerIconOne.png";
import Suppliericon from "../../../images/supplierIconTwo.png";
import UserIconNew from "../../../images/newUserCustomer.png";
import Logisticsicon from "../../../images/logisticsIconThree.png";
import { Link } from "react-router-dom";
import Store from "../../../store/index";
var userLocalType;
export default class NewUserRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerCount: {},
    };
  }
  componentDidMount() {
    if (Store.getState().rfu.userCount.user) {
      this.setState({
        customerCount: Store.getState().rfu.userCount.user,
      });
    }
  }
  render() {
    userLocalType = JSON.parse(localStorage.getItem("authUser")).userType;

    return (
      <div className="pd-15 bg-white borderedDiv">
        <div className="newRequestSummaryDiv">
          <Row>
            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
              <div className="d-flex align-items-center noBorderedDiv">
                {/* <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <rect width="82" height="82" fill="url(#pattern0)"/>
                                <defs>
                                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                <use xlink:href="#image0_101_1216" transform="scale(0.0121951)"/>
                                </pattern>
                                <image id="image0_101_1216" width="82" height="82" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFIAAABSCAYAAADHLIObAAAABHNCSVQICAgIfAhkiAAABCpJREFUeF7tnUtIVFEYx/93kYswNFxpQWqghEXmiLSqkRI3jhiUSAQq1JgEQkHQInBc9Fgk2Mq0RRakEIWiBq2a0UUJ+eqtLrICNTBpRFdubpw73Jind+6938w92HdgNnPO+d/v+/E/z81RAKAbZTWAckOBWgIo2eI/LkYE1KAK5TOg3m3F9KjSA1edCgwadeP6xAQU4IzSjbK3CpTjDMo6ARXqhPIAriCALOsy3BPAugCpMgr7BBikfYaaAoNkkEQEiGTYkQySiACRDDuSQRIRIJJhRzJIIgJEMuxIBklEgEiGHckgiQgQybAjGSQRASIZqR25t6QQ9Z+eaan6G9ux8OQlUdr0MgySiCmDZJBEBIhkHHFkuc+LD1392ApubpuGlTkyWW0ifv9k0g6yss+HosYarM3OY7iyZVuYZkGa0d4xIEUiRjB37dmN/acqtJxXp+ew+fNXwvx1iJru+wUMu72GjqeEmXZHiuAjkk7CmUYJOw1RxOcIyGiYK2NTGHa3GPGKWy/mRFe7V6tzwol6UI6B1GHm17m1Ybg2u2AJZEZ2JmoDvVrfdA/n8IAdBSkC2ZOfi43vK5Yg6p0ETFGMdgG2PmLQ2XGQqUwundrSgsxzu7RtknCs7rbFoQAWHo+mk0/S35IOpBim1YP3kOcuj5uE2DL5mzssz6lJkzHZUCqQ2sLh70FOafG2aWwFN/C0wOPonBgdoFQgw7cyRoZYDkxipPKyUbO01acU5MH6KhTWV8Uks7G4hInr92P+bw4GkJEVWoGTKf0FnpgVv6T1LPadDp2Gwsvq5FfM3HmUjKylNikF6Wq/hHJf7Eb798wcXpRdiAhYLC4ef4+pJN5c7cTHroGIPice3sShi3UxOj9GxvGq9popfTONUwrySFsDDrc1xMTz58u3mKSsgJzq6MWkL7QZ10vF7SsQIyG6LL1+h3HvLTNsTLVNKUgzkVCBNPNNyrbSgBRJmZ0jnx87L802SCqQ4bc4Rm6xc9FhpG2lXiqQYh95bnYAmQdCp5lEZWt909ZFhxVQRn2kAimCFUfC6qFO5Bwtihu7jBBFoNKB1OkVN9WguMmD3JMu7S8xlMVZe75vRKoTjR6vtCCNhpJs9VKBzCktMnWyWRmbloanFCDNnLGjyYmhLk44Tl7qSjFH2oGoQ5XhAsNxR5rdhCcay05vzh0FaeVYmAhkvAuMdE6gOwZkvAsMBmmBAIM0eQeZiDGDZJAWxl9UF8rFhh3JjmRHhhPg7Y99P2gKDJJBRhL4rxcbcRsuLm8pynJgCuLnVHF0aDuVdCq+yyCJqDJIBklEgEiGHckgiQgQybAjGSQRASIZdiSDJCJAJMOOZJBEBIhk2JEMkogAkQw7kkESESCSEY7kJwbsw1znRy/sQ4T26AU/w2KfpPYMi5Dhh4GswIx8GOgvrPEho2sVlaIAAAAASUVORK5CYII="/>
                                </defs>
                                </svg> */}
                <img src={UserIconNew} alt="customernewImage" />
                <h4 className="mb-0 newuserres">
                  &nbsp;&nbsp;New User Requests
                </h4>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
              <div className="d-flex align-items-center justify-content-between dashInnerDivs dashNewRequestsInnerDiv h-100">
                <div className="progressDiv">
                  <p className="mb-0">Mobile App</p>
                  <Progress value={50} />
                  <p className="mb-0">
                    {this.state.customerCount?.countMobile}
                  </p>
                </div>
                <Link
                  to={{
                    pathname: "/UserRequests",
                    state: { userAccountSource: "mobile" },
                  }}
                >
                  <Button>View all</Button>
                </Link>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
              <div className="d-flex align-items-center justify-content-between dashInnerDivs dashNewRequestsInnerDiv h-100">
                <div className="progressDiv">
                  <p className="mb-0">Website</p>
                  <Progress value={80} className="bg-new" />
                  <p className="mb-0">{this.state.customerCount?.countWeb}</p>
                </div>
                <Link
                  to={{
                    pathname: "/UserRequests",
                    state: { userAccountSource: "web" },
                  }}
                >
                  <Button>View all</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <br />
        <div>
          <div className="mobInnerHeadDiv">
            <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
              <h4>Customers</h4>
              <div>&nbsp;</div>
            </div>
            <Row className="mobCustRow">
              <Col xxl={4} xl={4} xs={12} sm={4} lg={6} md={4}>
                <div className="d-flex align-items-center custCreationDiv custCreationBuyerDiv margin">
                  <img src={Buyericon} alt="buyerIcon" />
                  <div>
                    <p>Buyer</p>
                    <Link
                      to={{
                        pathname: "/Buyerform",
                        state: { userType: "buyer" },
                      }}
                    >
                      <button>Add New</button>
                    </Link>
                  </div>
                </div>
              </Col>
             {userLocalType!=="sales" && <>
              <Col xxl={4} xl={4} xs={12} sm={4} lg={6} md={4}>
                <div className="d-flex align-items-center custCreationDiv custCreationSupplierDiv margin">
                  <img src={Suppliericon} alt="supplierIcon" />
                  <div disabled={true}>
                    <p>Supplier</p>
                   
                    <Link
                      to={{
                        pathname: "/Buyerform",
                        state: { userType: "seller" },
                      }}
                    >
                      <button>Add New</button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xxl={4} xl={4} xs={12} sm={4} lg={6} md={4}>
                <div className="d-flex align-items-center custCreationDiv custCreationLogisticsDiv lg-margin margin">
                  <img src={Logisticsicon} alt="logisticsIcon" />
                  <div>
                    <p>Logistic</p>
                    <Link
                      to={{
                        pathname: "/Buyerform",
                        state: { userType: "logistic" },
                      }}
                    >
                      <button>Add New</button>
                    </Link>
                  </div>
                </div>
              </Col>
              </>}
            </Row>
          </div>
        </div>
        <br />
        <br />
        <div>
          <Row>
            <Col>
              <CustomerCreation />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
