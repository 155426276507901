import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody,Button,ModalFooter } from "reactstrap";

export default class ConfirmEnumModel extends Component {
  render() {
    return (
      <div className="DeleteModal">
      
        <Modal
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.props.toggle}>Confirm</ModalHeader>
          <ModalBody>
          Are you sure you want to delete the Slot?
          </ModalBody>
          <ModalFooter>
            <Button className="btn-danger" onClick={this.props.deleteEnumValue}>
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.props.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
