import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroup,
  ModalFooter,
  Container,
  Accordion,
  AccordionHeader,
  AccordionBody,
  AccordionItem,
  Button,
} from "reactstrap";
import Pagination from "@material-ui/lab/Pagination";
import { Grid } from "@material-ui/core";
import productImage from "../../../images/fresh-butterfish 7.svg";
import searchIcon from "../../../images/search.png";
import { connect } from "react-redux";
import { fetchproductSortData } from "../../../store/actions";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
let debounceThis;
class ProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      skuName: "",
      currentPage: 1,
      limit: 10,
      offset: 0,
      selectedProduct: [],
      selectedProductById: [],
    };
  }
  componentDidMount() {
    this.listOfProductList();
  }

  listOfProductList = () => {
    this.setState({
      dataLoading: true,
    });
    this.props?.fetchproductSortData(
      this.state.offset,
      this.state.limit,
      "",
      "",
      this.props.queryParams,
      "",
      "",
      "",
      "",
      "",
      "",
      this.state.skuName,
      true,
      "",
      () => {
        this.setState({
          dataLoading: false,
        });
      }
    );
  };
  addProductSeller = (e) => {
    e.preventDefault();
    this.props.updateMultiSellerProduct(
      this.state.selectedProduct,
      this.props.sellerId
    );
    this.setState({
      selectedProduct: [],
      selectedProductById: [],
    });
  };
  handleChangePage = (event, val) => {
    this.setState(
      {
        currentPage: val,
        offset: (val - 1) * this.state.limit,
      },
      () => {
        this.listOfProductList();
      }
    );
  };

  handleChangeSku = (e) => {
    this.setState(
      {
        skuName: e.target.value,
      },
      () => {
        if (debounceThis) {
          clearTimeout(debounceThis);
          debounceThis = null;
        }
        debounceThis = setTimeout(() => {
          this.listOfProductList();
        }, 700);
      }
    );
  };
  handleSelectedProduct = (data, index) => {
    if (data && !this.state.selectedProductById.includes(data._id)) {
      let productObj = [data].map((item) => {
        return {
          product: {
            _id: item?._id,
            sku: item?.sku?._id,
            productId: item?.productId,
            category: item?.category?._id,
            skuCode: item?.skuCode,
            isVinculum: [`${item?.isVinculum}`],
            images:
              item.images?.length > 0
                ? item?.images
                : item?.sku?.skuImage?.length > 0
                ? item?.sku?.skuImage
                : [],
            sellerWithRate: {
              seller: this.props.sellerId,
              rate: 0,
              isActive: true,
            },
            sku: { skuName: item?.sku?.skuName },
            size: item?.size,
          },
          isActive: true,
        };
      });
      this.setState({
        selectedProduct: [...this.state.selectedProduct, ...productObj],
        selectedProductById: [...this.state.selectedProductById, data._id],
      });
    } else {
      let removeSelectedProductById = this.state.selectedProductById.filter(
        (product) => product !== data._id
      );
      let removeSelectedProduct = this.state.selectedProduct.filter(
        (productItem) => productItem?.product?._id !== data._id
      );
      this.setState({
        selectedProductById: removeSelectedProductById,
        selectedProduct: removeSelectedProduct,
      });
    }
  };
  render() {
    const totPages = Math.ceil(
      this.props?.listOfProductData?.length / this.state.limit
    );
    return (
      <Modal
        isOpen={this.props.productModal}
        className="selectionProduct editProductQuantity modal-dialog modal-dialog-scrollable"
        size="md"
      >
        <ModalHeader
          toggle={() => {
            this.setState({
              selectedProduct: [],
              selectedProductById: [],
              skuName: "",
            });
            this.props.productToggle();
          }}
        >
          Add New Product For The Seller{" "}
        </ModalHeader>
        <ModalBody>
          <Container fluid className="p-0">
            <Row>
              <Col xs={12} className="">
                <Grid
                  className=""
                  container
                  spacing={2}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={6} sm={6} md={8}>
                    <h4 className="suggestedProduct">Suggested Products</h4>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <div className="input-border mt-2">
                      <InputGroup>
                        <Input
                          className="form-control border-end-0 border suggestedProductInput"
                          type="text"
                          name="sku"
                          id="example-search-input"
                          value={this.state.skuName}
                          autoComplete="off"
                          onChange={(e) => {
                            this.handleChangeSku(e);
                          }}
                          placeholder="Search for Product..."
                          aria-label="Search"
                          icon="fas fa-times-circle"
                        />
                        <span className="pt-2 pb-2 input-line pl-1 ml-1"></span>
                        <span class="input-group-append">
                          <button
                            class="btn btn-outline border-left-0 border"
                            type="button"
                          >
                            <img className="pl-1" src={searchIcon} alt=""></img>
                          </button>
                        </span>
                      </InputGroup>
                    </div>
                  </Grid>
                </Grid>
                {this.state.dataLoading ? (
                  <div className="ViewPagesLoder">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <>
                    <br />

                    <div className="d-flex align-items-center justify-content-between">
                      <div className="accordionWidth">
                        {this.props?.listOfProductData?.docs?.length > 0 ? (
                          <Row>
                            {this.props?.listOfProductData?.docs?.map(
                              (data, index) => (
                                <Col
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                  className="mb-15"
                                  key={data?._id}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="productDataCheckbox">
                                      <Input
                                        type="checkbox"
                                        className="verticalCenter"
                                        checked={this.state.selectedProductById.includes(
                                          data._id
                                        )}
                                        disabled={this.props.assignProductSeller.includes(
                                          data._id
                                        )}
                                        onClick={() => {
                                          this.handleSelectedProduct(
                                            data,
                                            index
                                          );
                                        }}
                                      />

                                      <img
                                        src={
                                          data?.images[0]
                                            ? data?.images[0]
                                            : data?.sku?.skuImage[0]
                                            ? data?.sku?.skuImage[0]
                                            : productImage
                                        }
                                        className="accordionImg skuImage"
                                        alt="image loading..."
                                      />
                                      <span className="accordionSpan">
                                        <div className="accordionSpanDark">
                                          {data?.sku?.skuName}
                                        </div>
                                        <div className="mt-2 accordionDarkId">
                                          Product ID:{data?.productId}
                                        </div>
                                      </span>
                                    </div>
                                    <div>
                                      <p className="sellerSize"> Size/Count</p>
                                      <span className="sizeInnerContent">
                                        ({data?.size})
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                              )
                            )}
                          </Row>
                        ) : (
                          <div className="NoProductAvail">
                            <h3>No products available</h3>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <div>
            <p className="mb-0">
              Showing{" "}
              {this.props?.listOfProductData?.length != 0
                ? (this.state.currentPage - 1) * this.state.limit + 1
                : (this.state.currentPage - 1) * this.state.limit}{" "}
              to{" "}
              {(this.state.currentPage - 1) * this.state.limit +
                (this.props?.listOfProductData.docs?.length
                  ? this.props?.listOfProductData.docs.length
                  : "")}{" "}
              {} entries of {this.props?.listOfProductData?.length}
            </p>
          </div>
          <div className="custPaginationDiv noRightBorderPagination">
            <Pagination
              shape="rounded"
              count={totPages}
              page={this.state.currentPage}
              color="primary"
              onChange={this.handleChangePage}
            />
          </div>
        </ModalFooter>
        <Button
          color="secondary"
          onClick={(e) => this.addProductSeller(e)}
          className="productBntFit"
        >
          Update
        </Button>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    listOfProductData: state.rfu.productdata,
  };
};
export default connect(mapStateToProps, { fetchproductSortData })(ProductModal);
