import React, { Component } from 'react'
import { MDBDataTable } from "mdbreact";
import {FormGroup,Input,Button} from 'reactstrap'
import TableProfilePic from '../../../images/tableprofilepic.png'
import TableProfilePicOne from '../../../images/joey.png'
import UserRequest from '../../Customers/common/UserRequests'
export default class ServiceRequests extends Component {
    
    render() {
        var data={}
        data = {
            columns: [
                {
                label: <span>SR ID</span>,
                field: "srId",
                sort: "asc",
                width: 300,
                },
                {
                label: <span>Order ID</span>,
                field: "orderId",
                sort: "asc",
                width: 300,
                },
                {
                label: <span>Name</span>,
                field: "name",
                sort: "asc",
                width: 300,
                },
                {
                label: <span>Email ID</span>,
                field: "emailId",
                sort: "asc",
                width: 300,
                },
                {
                label: <span>Contact</span>,
                field: "contact",
                sort: "asc",
                width: 50,
                },
                {
                label: <span>Status</span>,
                field: "status",
                sort: "asc",
                width: 100,
                },
                {
                label: "Actions",
                field: "actions",
                sort: "asc",
                width: 100,
                },
            ],
            rows: [
                {srId:'#2654',orderId:'#100',name:<div className="d-flex align-items-center"><img src={TableProfilePic}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Ashish Saini</p></div>,emailId:'ashish62@fishgmail.com',contact:'+91 73678 63456',status:'Completed',actions:'Troubleshoot'},
                {srId:'#2655',orderId:'#101',name:<div className="d-flex align-items-center"><img src={TableProfilePicOne}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Joey Lobo</p></div>,emailId:'joeyR99@fishgmail.com',contact:'+91 73678 63456',status:'Canceled',actions:'Troubleshoot'},
                {srId:'#2656',orderId:'#102',name:<div className="d-flex align-items-center"><img src={TableProfilePic}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Manoj Tiwari</p></div>,emailId:'manoj@fishgmail.com',contact:'+91 73678 63456',status:'Dispatch',actions:'Troubleshoot'},
                {srId:'#2657',orderId:'#103',name:<div className="d-flex align-items-center"><img src={TableProfilePicOne}></img>&nbsp;&nbsp;&nbsp;<p className="mb-0">Disha Kelkar</p></div>,emailId:'dindustry@fish.gmail.com',contact:'+91 73678 63456',status:'Completed',actions:'Troubleshoot'}
            ]
        }
        return (
            // <div className="dashInnerMainDiv">
            //     <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            //         <div className="d-flex align-items-center justify-content-between w-100">
            //             <h4>Service Requests</h4>
            //             <div className="d-flex align-items-center justify-content-end tableHeaderDivCss">
            //                 <form>
            //                     <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv">
            //                         <Input
            //                         name="search"
            //                         placeholder="search placeholder"
            //                         type="search"
            //                         className="mb-0"
            //                         />
            //                             <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            //                             <path d="M11.6391 12.192L14.8511 15.418L11.6391 12.192ZM1.03907 7.35996C1.0444 6.20746 1.39103 5.08238 2.0352 4.12671C2.67938 3.17103 3.59222 2.42761 4.65852 1.99026C5.72482 1.55291 6.89678 1.44124 8.02649 1.66935C9.1562 1.89746 10.193 2.45511 11.0061 3.27194C11.8191 4.08876 12.372 5.12814 12.5949 6.25889C12.8178 7.38964 12.7007 8.56107 12.2584 9.62534C11.8162 10.6896 11.0685 11.599 10.1099 12.2388C9.15125 12.8785 8.02457 13.2199 6.87207 13.22C5.32201 13.2147 3.83735 12.5947 2.74382 11.4961C1.65029 10.3975 1.0372 8.91002 1.03907 7.35996V7.35996Z" stroke="#5E5E5E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            //                             </svg>

            //                     </FormGroup>
            //                 </form>
            //                     <Button className="btn lightBtn"
            //                     >
            //                         View all
            //                     </Button>
            //             </div>
            //         </div>
            //     </div>
            //     <div className="dataTableStyles">
            //         <MDBDataTable
            //             className="text-center text-capitalize"
            //             responsive
            //             hover
            //             data={data}
            //             paging={false}
            //             searching={false}
            //         />
            //     </div>  
            // </div>
            <div>
                <UserRequest/>
            </div>
        )
    }
}
