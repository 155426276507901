import React, { Component } from "react";
import MainHeader from "../../components/common/MainHeader";
import { Container, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import Myprofiledp from "../../images/myprofiledp.png";
import { fetchprofiledata, userEditData } from "../../store/actions";
import { connect } from "react-redux";
import { handleErrorMsg } from "../../common/components/common/GlobalFunctions";
import { NotificationManager } from "react-notifications";

class Profile extends Component {
  componentDidMount = () => {
    this.props.fetchprofiledata();
  };

  state = {
    userId: this.props.userdata?._id,
    empid: { editable: false, data: this.props.userdata?.loginId },
    name: { editable: true, data: this.props.userdata?.fullName },
    department: { editable: false, data: this.props.userdata?.userType },
    contactno: { editable: true, data: this.props.userdata?.phone },
    emailid: { editable: true, data: this.props.userdata?.email },
  };
  componentDidUpdate = (prevprops) => {
    if (prevprops?.userdata?._id != this.props.userdata?._id) {
      this.setState({
        empid: { ...this.state.empid, data: this.props.userdata?.loginId },
        name: { ...this.state.name, data: this.props.userdata?.fullName },
        department: {
          ...this.state.department,
          data: this.props.userdata?.userType,
        },
        contactno: {
          ...this.state.contactno,
          data: this.props.userdata?.phone,
        },
        emailid: { ...this.state.emailid, data: this.props.userdata?.email },
      });
    }
  };

  updateEmpid = (e, state_name) => {
    if (this.state.empid.editable == true) {
      this.setState({
        empid: { ...this.state.empid, data: e.target.value },
      });
    }
  };
  updateName = (e, state_name) => {
    if (this.state.name.editable == true) {
      this.setState({ name: { ...this.state.name, data: e.target.value } });
    }
  };
  updateDepartment = (e, state_name) => {
    if (this.state.department.editable == true) {
      this.setState({
        department: { ...this.state.department, data: e.target.value },
      });
    }
  };
  updateContactno = (e, state_name) => {
    if (this.state.contactno.editable == true) {
      this.setState({
        contactno: { ...this.state.contactno, data: e.target.value },
      });
    }
  };
  updateEmail = (e, state_name) => {
    if (this.state.emailid.editable == true) {
      this.setState({
        emailid: { ...this.state.emailid, data: e.target.value },
      });
    }
  };

  saveUserData = (e) => {
    let dataTosave = {
      fullName: this.state.name.data,
      email: this.state.emailid.data,
      loginId: this.props.userdata?.loginId,
      phone: this.state.contactno.data,
    };
    this.setState({
      dataLoading: true,
    });
    // console.log(this.props.userdata?._id , "this.state.userId");
    this.props.userEditData(dataTosave, this.props.userdata?._id, (res) => {
      if (res?.updateUser) {
        NotificationManager.success(res?.message, "success");
        this.props.history.push("/Dashboard");
      } else {
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
  };

  render() {
    // console.log(this.state.userId, "this.state.userId");
    return (
      <div>
        {/* {console.log(this.state)} */}
        <MainHeader pageTitle={"My Profile"} />
        <br />
        <div className="mainParentSectionDiv">
          <Container fluid className="pd-0">
            <Row className="d-flex align-items-stretch">
              <Col xs={12} sm={12} md={12}>
                <div className="pd-15 bg-white borderedDiv">
                  <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
                    <h4>Edit Information</h4>
                    <div>&nbsp;</div>
                  </div>
                  <div>
                    <Row>
                      <Col xs={12} sm={3} md={3}>
                        <div className="d-flex justify-content-center">
                          <img src={Myprofiledp} alt="Profile Dp" />
                        </div>
                      </Col>
                      <Col xs={12} sm={9} md={9}>
                        <div className="d-flex align-items-center h-100">
                          <div>
                            <p className="mb-0 profileHeading">
                              {this.state?.empid?.data}
                            </p>
                            <div className="d-flex align-items-center profilechangeBtnDiv">
                              <button className="btn darkBtn" disabled={true}>
                                Change Profile
                              </button>{" "}
                              <span>
                                Acceptable formats: jpg, png only. Max file size
                                is 500kb and min size 70kb
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <br />
              </Col>

              <Col xs={12} sm={12} md={12}>
                <div className="pd-15 bg-white borderedDiv">
                  <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
                    <h4>Account Information</h4>
                    <div>&nbsp;</div>
                  </div>
                  <div className="formDivCss">
                    <Form>
                      <Row>
                        <Col xs={12} sm={4} md={4} className="mb-15">
                          <FormGroup>
                            <Label for="empId">Employee ID</Label>
                            <Input
                              id="empId"
                              name="empId"
                              placeholder="#2654"
                              type="text"
                              value={this.state.empid?.data}
                              onChange={(e) => this.updateEmpid(e, "empid")}
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={4} md={4} className="mb-15">
                          <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                              id="name"
                              name="name"
                              placeholder="name"
                              value={this.state.name.data}
                              onChange={(e) => this.updateName(e, "name")}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={4} md={4} className="mb-15">
                          <FormGroup>
                            <Label for="dept">User Type</Label>
                            <Input
                              id="dept"
                              name="dept"
                              placeholder="Department"
                              value={this.state.department.data}
                              onChange={(e) =>
                                this.updateDepartment(e, "department")
                              }
                              type="text"
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={5} md={5} className="mb-15">
                          <FormGroup>
                            <Label for="contno">Contact No</Label>
                            <Input
                              id="contno"
                              name="contno"
                              placeholder="contact"
                              value={this.state.contactno.data}
                              onChange={(e) =>
                                this.updateContactno(e, "contactno")
                              }
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={5} md={5} className="mb-15">
                          <FormGroup>
                            <Label for="emailid">Email ID</Label>
                            <Input
                              id="emailid"
                              name="emailid"
                              placeholder="email"
                              value={this.state.emailid.data}
                              onChange={(e) => this.updateEmail(e, "emailid")}
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={2} md={2} className="mb-15">
                          <div className="d-flex align-items-center h-100 accbtn">
                            <button
                              className="btn darkBtn"
                              onClick={(e) => {
                                e.preventDefault();
                                this.saveUserData();
                              }}
                              disabled={false}
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    userdata: state.rfu.user,
  };
};

// export default Profile;
export default connect(mapStatesToProps, { fetchprofiledata, userEditData })(
  Profile
);
