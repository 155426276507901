import React, { Component } from 'react';
import { Collapse, CardBody, Card, CardHeader} from 'reactstrap';


export default class AccordionComponent extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse:false};
      }
      toggle(e) {
        // let event = e.target.dataset.event;
        // this.setState({ collapse: this.state.collapse === Number(event) ? 0 : Number(event) });
        this.setState({collapse:!this.state.collapse})
      }
    render() {
      let {cards,collapse}=this.state
       return (
            // <div className="container">
                    <Card style={{ marginBottom: '1rem',cursor:'pointer' }} key={this.props.AccordTitle} className={this.state.collapse ? "darkBg":""}>
                      <CardHeader onClick={this.toggle} data-event={this.props.AccordTitle} className="d-flex align-items-center justify-content-between">{this.props.AccordTitle} <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.state.collapse ? "displayNone":""}>
                    <path d="M2.26709 1.50391L9.18506 8.42212L16.103 1.50391" stroke="#212529" stroke-width="3" stroke-linecap="round"/>
                    </svg>
                    <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.state.collapse ? "displayBlock":"displayNone"} style={{transform: 'rotate(180deg)'}}>
                    <path d="M2.26709 1.50391L9.18506 8.42212L16.103 1.50391" stroke="#212529" stroke-width="3" stroke-linecap="round"/>
                    </svg>
                    </CardHeader>
                      <Collapse isOpen={collapse}>
                      <CardBody>{this.props.children}</CardBody>
                      </Collapse>
                    </Card>


              // </div>
          );
    }
}
