import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      } else {
        return (
          <Layout>
            <Component {...props} {...rest} />
          </Layout>
        );
      }
    }}
  />
);

const mapStateToProps = (state) => {};
export default connect(mapStateToProps, null)(AppRoute);
