import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import RotateScreen from "../../pages/RotateScreen/RotateScreen";
// import { withRouter } from "react-router-dom";

import Header from "./Header";
import MiniHeader from "./MiniHeader";
import SubHeader from "./SubHeader";
// import Footer from "./footer";

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarDiv: false,
    };
  }

  toggleSidebarMenu = () => {
    this.setState({
      sidebarDiv: !this.state.sidebarDiv,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>

        <div id="layout-wrapper">
          <div className="rotateScreen" >
              <RotateScreen/>
          </div>
          <Container fluid>
            <Row className="DisplayNoneLand">
              <Col
                xs={12}
                sm={2}
                md={2}
                className={`pd-0 ${
                  this.state.sidebarDiv ? "width-8" : "width25"
                }`}
              >
                <div className="pos-rel headerSidebar ">
                  <div
                    className={
                      this.state.sidebarDiv
                        ? "displayNone DisplayNoneLand"
                        : "displayBlock "
                    }
                  >
                    <Header />
                  </div>
                  <div
                    className={
                      this.state.sidebarDiv
                        ? "displayBlock DisplayNoneLand"
                        : "displayNone"
                    }
                  >
                    <MiniHeader />
                  </div>
                  <div
                    className="svgStyleDiv svgStyleDivLandNone "
                    onClick={() => this.toggleSidebarMenu()}
                  >
                    <svg
                      width="12"
                      height="17"
                      viewBox="0 0 12 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={
                        this.state.sidebarDiv ? "displayBlock" : "displayNone"
                      }
                      style={{ transform: "rotate(180deg)" }}
                    >
                      <path
                        d="M9.71008 1.54095L2.79187 8.45892L9.71008 15.3769"
                        stroke="#980033"
                        stroke-width="3"
                        stroke-linecap="round"
                      />
                    </svg>
                    <svg
                      width="12"
                      height="17"
                      viewBox="0 0 12 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={
                        this.state.sidebarDiv ? "displayNone" : "displayBlock"
                      }
                    >
                      <path
                        d="M9.71008 1.54095L2.79187 8.45892L9.71008 15.3769"
                        stroke="#980033"
                        stroke-width="3"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  {/* <div
                    className="svgStyleDiv  "
                    onClick={() => this.toggleSidebarMenu()}
                  > */}
                  <svg
                    onClick={() => this.toggleSidebarMenu()}
                    className="toggleIcon"
                    width="20"
                    height="30"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2.30036 4.60022C2.09703 4.60022 1.90202 4.68099 1.75824 4.82477C1.61446 4.96855 1.53369 5.16355 1.53369 5.36689C1.53369 5.57022 1.61446 5.76522 1.75824 5.909C1.90202 6.05278 2.09703 6.13355 2.30036 6.13355H20.7004C20.9037 6.13355 21.0987 6.05278 21.2425 5.909C21.3863 5.76522 21.467 5.57022 21.467 5.36689C21.467 5.16355 21.3863 4.96855 21.2425 4.82477C21.0987 4.68099 20.9037 4.60022 20.7004 4.60022H2.30036ZM1.53369 11.5002C1.53369 11.2969 1.61446 11.1019 1.75824 10.9581C1.90202 10.8143 2.09703 10.7336 2.30036 10.7336H20.7004C20.9037 10.7336 21.0987 10.8143 21.2425 10.9581C21.3863 11.1019 21.467 11.2969 21.467 11.5002C21.467 11.7036 21.3863 11.8986 21.2425 12.0423C21.0987 12.1861 20.9037 12.2669 20.7004 12.2669H2.30036C2.09703 12.2669 1.90202 12.1861 1.75824 12.0423C1.61446 11.8986 1.53369 11.7036 1.53369 11.5002ZM1.53369 17.6336C1.53369 17.4302 1.61446 17.2352 1.75824 17.0914C1.90202 16.9477 2.09703 16.8669 2.30036 16.8669H20.7004C20.9037 16.8669 21.0987 16.9477 21.2425 17.0914C21.3863 17.2352 21.467 17.4302 21.467 17.6336C21.467 17.8369 21.3863 18.0319 21.2425 18.1757C21.0987 18.3194 20.9037 18.4002 20.7004 18.4002H2.30036C2.09703 18.4002 1.90202 18.3194 1.75824 18.1757C1.61446 18.0319 1.53369 17.8369 1.53369 17.6336Z"
                      fill="#2E3236"
                    />
                  </svg>
                </div>
                {/* </div> */}
              </Col>
              <Col
                xs={12}
                sm={10}
                md={10}
                className={this.state.sidebarDiv ? "width-92" : ""}
              >
                <div className="main-content">
                  {this.props.children}
                  {/* <Footer /> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                className={`pd-0 width-8
                }`}
              >
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                className={this.state.sidebarDiv ? "width-92" : ""}
              >
               
                <div className="main-content">
                  {this.props.children}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Layout;
