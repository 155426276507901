import React, { Component } from 'react'
import { MDBDataTable } from "mdbreact";
var data = {};

export default class PreApprovedTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
      }
      render() {
        let rows = this.props?.rows;
        data = {
          columns: [
            {
              label: "Editor",
              field: "editor",
              sort: "asc",
              width: 200,
            },
    
            {
              label: "Existing Price",
              field: "existingPrice",
              sort: "asc",
              width: 200,
            },
    
            {
              label: "Updated Price",
              field: "updatedPrice",
              sort: "asc",
              width: 200,
            },
    
            {
              label: "Date",
              field: "date",
              sort: "asc",
              width: 200,
            },
    
            {
              label: "Time",
              field: "time",
              sort: "asc",
              width: 200,
            },
    
          ],
          rows: rows,
        };
        return (
          <div>
            <MDBDataTable
             className="text-center text-capitalize"
             bordered
             responsive
             hover
             sortable
             data={data}
             paging={false}
             searching={false}
             noBottomColumns
            />
          </div>
        );
      }
    }
