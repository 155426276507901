import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import ConfirmEnumModel from "./ConfirmEnumModel";
import CouponCode from "./CouponCode";
import DeliverySlot from "./DeliverySlot";
import {
  enumEditData,
  removeEnumDeliverySlot,
  fetchEnum,
} from "../../../store/actions";
import { NotificationManager } from "react-notifications";
import { lazySlidesOnRight } from "react-slick/lib/utils/innerSliderUtils";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import { handleErrorMsg } from "../../../common/components/common/GlobalFunctions";
import { NUMBER_REGEX } from "../../../common/components/common/Rejex";
import { enumUpdatedSuccessfully } from "../../../common/constantResponseMessage";
class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalProductValue: "",
      commission: "",
      totalCost: "",
      packagingCost: "",
      editModal: false,
      enumToEditId: "",
      enumTypeToEdit: "",
      addEnum: "",
      loading: false,
      EnumType: "",
      deleteModel: false,
      currentDeleteEnumVal: "",
      index: "",
      couponEdit: "",
      couponEditNew: "",
      couponName: "",
      couponAmount: 0,
    };
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
    this.props.fetchEnum((res) => {
      this.setState({
        loading: false,
      });
    });
  }

  //delete modal toogle
  deleteEnum = (t) => {
    this.setState(
      {
        currentDeleteEnumVal: t,
      },
      () => {
        if (this.state.currentDeleteEnumVal) {
          this.setState({ deleteModel: !this.state.deleteModel });
        }
      }
    );
  };

  editModalToggle = () => {
    this.setState(
      {
        editModal: !this.state.editModal,
      },
      () => {
        this.setState({ index: "", couponEdit: "", couponEditNew: "" });
      }
    );
  };
  onChange = (name, val) => {
    this.setState({
      [`${name}`]: val,
    });
  };

  //couponCodeEdit
  onInputChange = (val, name) => {
    this.setState({
      couponEditNew: { ...this.state.couponEditNew, [`${name}`]: val },
    });
  };
  //allEnum Updating
  enumRequestEditData = () => {
    let payload = {
      EnumValue:
        this.state.EnumType === "Error_Found" ||
        this.state.EnumType === "kycForOrders"
          ? this.state.EnumValue
          : parseInt(this.state.EnumValue),
    };
    let couponPaylod = {
      EnumValue: {
        couponName: this.state.couponEditNew?.couponName
          ? this.state.couponEditNew?.couponName
          : "",
        couponAmount: this.state.couponEditNew?.couponAmount
          ? this.state.couponEditNew?.couponAmount
          : "",
      },
      couponId: this.state.couponEditNew?._id
        ? this.state.couponEditNew?._id
        : "",
    };
    let payloadFinal =
      this.state.EnumType === "CouponCode" ? couponPaylod : payload;
    this.setState({
      loading: true,
    });
    this.props.enumEditData(
      payloadFinal,
      this.state.EnumType,
      this.state.enumToEditId,
      "updating",
      (res) => {
        if (res && res.message === enumUpdatedSuccessfully) {
          NotificationManager.success(res.message, "Enum Updated!!");
          this.setState({
            loading: true,
          });
          this.props.fetchEnum((res) => {
            this.setState({
              loading: false,
            });
          });
          this.props.history.push("/Settings");
        } else {
          // NotificationManager.error(res?.error?.message || res?.message, "Error");
          handleErrorMsg(res);
        }
        this.setState({
          loading: false,
        });
      }
    );
    this.editModalToggle();
  };

  //DeliverySlot and CouponCode remove
  removeEnumValue = () => {
    let deleteCoupon = {
      couponName: this.state.currentDeleteEnumVal?.couponName
        ? this.state.currentDeleteEnumVal?.couponName
        : "",
      couponAmount: this.state.currentDeleteEnumVal?.couponAmount
        ? this.state.currentDeleteEnumVal?.couponAmount
        : "",
    };
    let payload = {
      EnumValue:
        this.state.EnumType === "CouponCode"
          ? deleteCoupon
          : this.state.currentDeleteEnumVal,
    };
    this.setState({
      loading: true,
    });
    this.props.removeEnumDeliverySlot(
      payload,
      this.state.enumToEditId,
      this.state.EnumType,
      (res) => {
        if (res && res?.message === enumUpdatedSuccessfully) {
          NotificationManager.success(res?.message, "Enum Updated!!");
          this.setState({
            loading: true,
            deleteModel: false,
            editModal: false,
          });
          this.props.fetchEnum((res) => {
            this.setState({
              loading: false,
            });
          });
          this.props.history.push("/Settings");
        } else {
          // NotificationManager.error(res?.error?.message || res?.message, "Error");
          handleErrorMsg(res);
        }
        this.setState({
          loading: false,
          deleteModel: false,
        });
      }
    );
  };

  //DeliverySlot and CouponCode adding
  enumRequestAddData = () => {
    let payload = {
      EnumValue:
        this.state.EnumType === "CouponCode"
          ? {
              couponName: this.state.couponName,
              couponAmount: this.state.couponAmount,
            }
          : this.state.addEnum,
    };
    this.setState({
      loading: true,
    });
    this.props.enumEditData(
      payload,
      this.state.EnumType,
      this.state.enumToEditId,
      "",
      (res) => {
        if (res && res.message === enumUpdatedSuccessfully) {
          NotificationManager.success(res.message, "Enum Updated!!");
          this.setState({
            loading: true,
          });
          this.props.fetchEnum((res) => {
            this.setState({
              loading: false,
              couponEdit: "",
              couponEditNew: "",
              index: "",
              couponName: "",
              couponAmount: 0,
            });
          });
          this.props.history.push("/Settings");
        } else {
          // NotificationManager.error(res?.error?.message || res?.message, "Error");
          handleErrorMsg(res);
        }
        this.setState({
          loading: false,
        });
      }
    );
    this.editModalToggle();
  };
  contentEditable = (i, t) => {
    this.setState({ index: i, couponEdit: t, couponEditNew: t });
  };

  render() {
    var data = {};
    data = {
      columns: [
        {
          label: <span>Config Name</span>,
          field: "enumname",
          sort: "asc",
          width: 200,
        },
        {
          label: <span>Config Value</span>,
          field: "enumvalue",
          sort: "asc",
          width: 200,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };

    this.props.EnumData?.allEnums?.map((el) => {
      data.rows.push({
        enumname: (
          <div className="d-flex align-itemms-center textCapitalize">
            &nbsp;<span>{el?.EnumName ? el?.EnumName : "Null"}</span>
          </div>
        ),
        enumvalue: (
          <>
            <div className="d-flex align-itemms-center slotEnum">
              {el?.EnumType === "DeliverySlot" ? (
                el?.EnumValue ? (
                  el?.EnumValue?.slice(0, 8)?.map((t) => (
                    <span>
                      {typeof t === "object" ? t?.couponName : t} &nbsp;&nbsp;{" "}
                      <span className="vl"></span>&nbsp;&nbsp;
                    </span>
                  ))
                ) : (
                  "false"
                )
              ) : el?.EnumType === "CouponCode" ||
                el?.EnumType === "TaxCategory" ? (
                el?.EnumValue ? (
                  el?.EnumValue?.slice(0, 3)?.map((t) => (
                    <span>
                      {el?.EnumType === "CouponCode" && typeof t === "object"
                        ? t?.couponName
                        : t
                        ? t?.taxCategory
                        : t}
                      &nbsp;&nbsp; <span className="vl"></span>&nbsp;&nbsp;
                    </span>
                  ))
                ) : (
                  "false"
                )
              ) : (
                <span>{el?.EnumValue !== undefined ? el?.EnumValue : "N/A"}</span>
              )}
              <span className="fs-6">
                {(el?.EnumValue?.length > 8 &&
                  el?.EnumType === "DeliverySlot") ||
                (el?.EnumValue?.length > 2 && el?.EnumType === "CouponCode")
                  ? "......."
                  : ""}
              </span>
            </div>
          </>
        ),
        actions: (
          <div className="d-flex align-items-center tableBtns ">
            <Link>
              <Button
                className="btn lightBtn"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(
                    {
                      enumToEditId: el?._id,
                      enumTypeToEdit: el?.EnumType,
                      loading: true,
                    },
                    () => {
                      this.editModalToggle();
                      this.setState({
                        loading: false,
                      });
                      //this.props.fetchEnum();
                    }
                  );
                  this.setState(
                    {
                      EnumName: el?.EnumName,
                      EnumValue: el?.EnumValue,
                      EnumType: el?.EnumType,
                    },
                    () => {}
                  );
                }}
              >
                Edit
              </Button>
            </Link>
          </div>
        ),
      });
    });

    return (
      <div className="dashInnerMainDiv custCreationDivSec sosDiv">
        <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h4>Additional Cost Settings</h4>
            <div></div>
          </div>
        </div>
        {this.state.loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Modal
              isOpen={this.state.editModal}
              toggle={this.editModalToggle}
              className={this.props?.className}
              size="md"
            >
              <ModalHeader toggle={this.editModalToggle}>
                {this.state.EnumName}
              </ModalHeader>
              <Form className="">
                <ModalBody>
                  {this.state.EnumType === "DeliverySlot" ? (
                    <>
                      <DeliverySlot
                        deleteEnumSlot={this.deleteEnum}
                        onAddEnumChange={this.onChange}
                        EnumValue={this.state.EnumValue}
                      />
                    </>
                  ) : this.state.EnumType === "CouponCode" ? (
                    <>
                      <CouponCode
                        onChange={this.onChange}
                        onInputChange={this.onInputChange}
                        indexSerial={this.state.index}
                        couponEdit={this.state.couponEdit}
                        EnumType={this.state.EnumType}
                        EnumValue={this.state.EnumValue}
                        contentEditable={this.contentEditable}
                        couponUpdateRequest={this.enumRequestEditData}
                        deleteEnum={this.deleteEnum}
                      />
                    </>
                  ) : (
                    <Row>
                      <Col xs={12} sm={12} md={12} className="mt-4 ">
                        <FormGroup
                          className=""
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {this.state.EnumType !== "kycForOrders" &&
                          this.state.EnumType !== "CouponCode" &&
                          this.state.EnumType !== "DeliverySlot" ? (
                            <Input
                              id="enumvalue"
                              name="enumvalue"
                              type="text"
                              value={this.state.EnumValue}
                              // invalid= {(NUMBER_REGEX.test(this.state.EnumValue) || this.state.EnumValue?.length == 0) ? false : true}
                              onChange={(e) =>
                                this.onChange("EnumValue", e.target.value)
                              }
                            />
                          ) : this.state.EnumType !== "CouponCode" &&
                            this.state.EnumType !== "DeliverySlot" ? (
                            <Input
                              type="select"
                              onChange={(e) =>
                                this.onChange("EnumValue", e.target.value)
                              }
                            >
                              <option>Select</option>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </Input>
                          ) : (
                            ""
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </ModalBody>
                {this.state.EnumType !== "DeliverySlot" &&
                this.state.EnumType !== "CouponCode" ? (
                  <ModalFooter>
                    <button
                      className="btn lightBtn"
                      onClick={(e) => {
                        e.preventDefault();
                        this.enumRequestEditData();
                      }}
                      disabled={!this.state.EnumValue}
                    >
                      UPDATE
                    </button>{" "}
                    <Button color="secondary" onClick={this.editModalToggle}>
                      Cancel
                    </Button>
                  </ModalFooter>
                ) : (
                  <ModalFooter style={{ justifyContent: "center" }}>
                    <Button
                      className="btn"
                      color="secondary"
                      size="lg"
                      onClick={(e) => {
                        e.preventDefault();
                        this.enumRequestAddData();
                      }}
                      disabled={!this.state.EnumValue}
                      style={{ marginTop: "31px" }}
                    >
                      ADD
                    </Button>{" "}
                  </ModalFooter>
                )}
              </Form>
            </Modal>
            <div className="dataTableStyles">
              <MDBDataTable
                className="text-center"
                responsive
                hover
                data={data}
                paging={false}
                searching={false}
                displayEntries={false}
              />
            </div>
          </>
        )}
        <ConfirmEnumModel
          toggle={this.deleteEnum}
          modal={this.state.deleteModel}
          deleteEnumValue={this.removeEnumValue}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    EnumData: state.rfe.EnumData,
  };
};
export default connect(mapStateToProps, {
  fetchEnum,
  enumEditData,
  removeEnumDeliverySlot,
})(Setting);
