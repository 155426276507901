import React, { Component } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { Row, Col } from "reactstrap";
export default class PaginateData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        {this.props?.totCnt !== 0 ? (
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <p className="mb-0  font-12px">
                Showing {this.props?.fromVal} to {this.props?.toVal} of{" "}
                {this.props?.totCnt} entries
              </p>
            </div>
            <div className="custPaginationDiv noRightBorderPagination">
              <Pagination
                shape="rounded"
                size="medium"
                defaultPage={1}
                siblingCount={1}
                page={this.props?.offset / 10 + 1}
                count={this.props?.totPages}
                color="primary"
                onChange={this.props?.handleChangePage}
              />
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
