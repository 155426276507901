import React, { Component } from 'react'
import {Container,Row,Col} from 'reactstrap'
import MainHeader from '../../components/common/MainHeader'
import ProductMainPage from './common/ProductMain'
// import ExistingProductCategory from './common/ExistingProductCategory'
// import ProductInformationPage from './common/ProductInformation'
// import AddProductPage from './common/AddProduct'



export default class Product extends Component {
    render() {
        return (
            <div>
                <MainHeader pageTitle={"Products"}/>
                <div className="mainParentSectionDiv">
                    <Container fluid className="pd-15">
                        <Row className="d-flex align-items-stretch">
                            <Col xs={12} sm={12} md={12} className="pd-0">
                                <ProductMainPage />
                            </Col>
                        </Row>
                    </Container>
                </div> 
            </div>
        )
    }
}
