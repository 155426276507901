import { types } from "./actionTypes";

export const userLogin = (data, userType, history, callback) => ({
  type: types.USER_LOGIN,
  payload: { data, userType, history },
  callback,
});
export const logoutUser = () => ({
  type: types.USER_LOGOUT,
});

export const fetchprofiledata = () => ({
  type: types.PROFILE_DATA,
});

export const customerViewData = (id, callback) => {
  return {
    type: types.CUSTOMER_VIEW_DATA,
    id,
    callback,
  };
};
// export const customerSearchdata = (id) => {
//   return {
//     type: types.CUSTOMER_SEARCH_DATA,
//     id,
//   };
// };
export const customerEditData = (payload, id, callback) => {
  return {
    type: types.CUSTOMER_EDIT_DATA,
    payload,
    id,
    callback,
  };
};
export const preApprovedProductData = (buyerId, offset, callback) => {
  return {
    type: types.PRE_APPROVED_PRODUCT_LIST,
    buyerId,
    offset,
    callback,
  };
};
export const DeletePreApprovedProduct = (productId, buyerId, callback) => {
  return {
    type: types.DELETE_PRE_APPROVED_PRODUCT,
    productId,
    buyerId,
    callback,
  };
};
export const HistoryPreApprovedProduct = (
  preApprovedId,
  productId,
  callback
) => {
  return {
    type: types.HISTORY_PRE_APPROVED_PRODUCT,
    preApprovedId,
    productId,
    callback,
  };
};
export const EditPreApprovedProduct = (
  productId,
  buyerId,
  payload,
  callback
) => {
  return {
    type: types.EDIT_PRE_APPROVED_PRODUCT,
    productId,
    buyerId,
    payload,
    callback,
  };
};

export const AddPreApprovedProduct = (payload, callback) => {
  return {
    type: types.ADD_PRE_APPROVED_PRODUCT,
    payload,
    callback,
  };
};

export const userEditData = (payload, id, callback) => {
  return {
    type: types.USER_DATA_UPDATE,
    payload,
    id,
    callback,
  };
};

export const sortCustomerData = (
  offset,
  limit,
  searchCustomer,
  status,
  source,
  userName,
  sort,
  usertype,
  callback
) => {
  return {
    type: types.CUSTOMER_SORT_DATA,
    offset,
    limit,
    searchCustomer,
    status,
    source,
    userName,
    sort,
    usertype,
    callback,
  };
};
export const userRequestData = (offset, limit, userAccountSource, callback) => {
  return {
    type: types.USER_REQUEST_DATA,
    offset,
    limit,
    userAccountSource,
    callback,
  };
};
export const userViewData = (id) => {
  return {
    type: types.USER_VIEW_DATA,
    id,
  };
};
export const userRequestDeleteData = (id, callback) => {
  return {
    type: types.USER_REQUEST_DELETE_DATA,
    id,
    callback,
  };
};
export const userRequestEditData = (payload, id, callback) => {
  return {
    type: types.USER_REQUEST_EDIT_DATA,
    payload,
    id,
    callback,
  };
};
// export const userFilterData = (id) => {
//   return {
//     type: types.USER_REQUEST_FILTER_DATA,
//     id,
//   };
// };
export const fetchDashboardData = () => ({
  type: types.DASHBOARD_DATA,
});

export const fetchproductSortData = (
  offset,
  limit,
  maxPrice,
  minPrice,
  category,
  size,
  seller,
  SelectedSku,
  preApproved,
  deliverySlot,
  buyerId,
  skuName,
  isVinculum,
  limra,
  callback
) => {
  return {
    type: types.PRODUCT_SORT_DATA,
    offset,
    limit,
    maxPrice,
    minPrice,
    category,
    size,
    seller,
    SelectedSku,
    preApproved,
    deliverySlot,
    buyerId,
    skuName,
    isVinculum,
    limra,
    callback,
  };
};
export const fetchProductFilterData = (payload) => {
  return {
    type: types.PRODUCT_FILTER_DATA,
    payload,
  };
};
export const fetchProductFilterSkuData = (id) => {
  return {
    type: types.PRODUCT_FILTER_DATA_BY_SKU,
    id,
  };
};

export const fetchProductViewData = (id, callback) => {
  return {
    type: types.PRODUCT_VIEW_DATA,
    id,
    callback,
  };
};
export const fetchCategoryData = (offset, limit, categoryName, callback) => {
  return {
    type: types.CATEGORY_DATA,
    offset,
    limit,
    categoryName,
    callback,
  };
};
export const fetchSkuData = (skuName, callback) => {
  return {
    type: types.CATEGORY_DATA,
    skuName,
    callback,
  };
};
export const syncAllSku = (callback) => {
  return {
    type: types.SYNC_ALL_SKU,
    callback,
  };
};
export const fetchUserCountData = () => ({
  type: types.USER_COUNT_DATA,
});

export const customerDeleteData = (id, callback) => {
  return {
    type: types.CUSTOMER_DELETE_DATA,
    id,
    callback,
  };
};

export const fetchSellerData = (callback) => {
  return { type: types.SELLER_DATA, callback };
};
export const fetchAllSellerSyncToVinc = (userType, callback) => {
  return { type: types.ALL_SELLER_SYNC_VINCULLUM, userType, callback };
};

export const fetchProductAllFilterData = (
  category,
  seller,
  size,
  minPrice,
  maxPrice
) => {
  return {
    type: types.PRODUCT_ALL_FILTER_DATA,
    category,
    seller,
    size,
    minPrice,
    maxPrice,
  };
};

export const fetchSellerData_ashish = () => ({
  type: types.PRODUCT_SELLER_DATA,
});

export const fetchProductData = (callback) => ({
  type: types.PRODUCT_PRODUCT_CATEGORY,
  callback,
});
export const fetchSkusById = (id) => ({
  type: types.PRODUCT_GET_SKU_BYID,
  value: { categoryId: id },
});

export const AddProductApi = (data, callback) => {
  return {
    type: types.PRODUCT_CREATE,
    data,
    callback,
  };
};

export const Product_addCategory = (data, callback) => ({
  type: types.PRODUCTS_ADD_CATEGORY,
  data,
  callback,
});
export const GetSkusList = (offset, limit, skuName, payload, callback) => ({
  type: types.PRODUCTS_GET_ALL_SKUS,
  offset,
  limit,
  skuName,
  payload,
  callback,
});
export const Product_addSku = (data, callback) => ({
  type: types.PRODUCTS_ADD_SKU,
  data,
  callback,
});
export const addCustomers = (data, callback) => ({
  type: types.CUSTOMER_ADDITION,
  data,
  callback,
});
export const productDeleteData = (id, callback) => {
  return {
    type: types.PRODUCT_DELETE_DATA,
    id,
    callback,
  };
};
export const productEditData = (payload, id, callback) => {
  return {
    type: types.PRODUCT_EDIT_DATA,
    payload,
    id,
    callback,
  };
};

export const verifyBankAccount = (payload, callback) => {
  return {
    type: types.VERIFY_BANK_ACCOUNT,
    payload,
    callback,
  };
};

export const triggerOtp = (payload, callback) => {
  return {
    type: types.TRIGGER_OTP,
    payload,
    callback,
  };
};

export const VerifyOtp = (payload, callback) => {
  return {
    type: types.VERIFY_OTP,
    payload,
    callback,
  };
};

export const triggerKycForm = (payload, callback) => {
  return {
    type: types.TRIGGER_KYC_FORM,
    payload,
    callback,
  };
};

export const getSkuList = (offset, limit, callback) => {
  return {
    type: types.SKU_DATA,
    offset,
    limit,
    callback,
  };
};

export const skuDeleteData = (id, callback) => {
  return {
    type: types.SKU_DELETE_DATA,
    id,
    callback,
  };
};

export const skuEditData = (id, payload, callback) => {
  return {
    type: types.SKU_EDIT_DATA,
    id,
    payload,
    callback,
  };
};
export const skuViewData = (id, callback) => {
  return {
    type: types.SKU_VIEW_DATA,
    id,
    callback,
  };
};

export const categoryBySku = (payload, callback) => {
  return {
    type: types.CATEGORY_BY_SKU_DATA,
    payload,
    callback,
  };
};

export const getCategoryList = (offset, limit, callback) => {
  return {
    type: types.CATEGORYS_DATA,
    offset,
    limit,
    callback,
  };
};

export const categoryDeleteData = (id, callback) => {
  return {
    type: types.CATEGORY_DELETE_DATA,
    id,
    callback,
  };
};

export const categoryViewData = (id, callback) => {
  return {
    type: types.CATEGORY_VIEW_DATA,
    id,
    callback,
  };
};

export const categoryEditData = (id, payload, callback) => {
  return {
    type: types.CATEGORY_EDIT_DATA,
    id,
    payload,
    callback,
  };
};

export const uploadCancelledCheque = (data, userId, callback) => ({
  type: types.UPLOAD_CANCELLED_CHEQUE,
  data,
  userId,
  callback,
});

export const uploadKycDocuments = (data, userId, callback) => ({
  type: types.UPLOAD_KYC_DOCUMENT,
  data,
  userId,
  callback,
});

export const getSosRequestData = (data, callback) => ({
  type: types.SOS_REQUEST,
  data: data,
  callback: callback,
});

export const UpdateSosRequestData = (data, payload, callback) => {
  return { type: types.SOS_UPDATE_REQUEST, data, payload, callback };
};

export const getOrdersData = (data, callback) => ({
  type: types.ORDERS_DATA,
  data: data,
  callback: callback,
});
export const approveOrder = (orderId, callback) => ({
  type: types.APPROVE_ORDER,
  orderId,
  callback,
});

// REPEAT_ORDER
export const repeatOrder = (orderId, callback) => ({
  type: types.REPEAT_ORDER,
  orderId,
  callback: callback,
});

export const fetchOrderViewData = (id, callback) => {
  return {
    type: types.ORDER_VIEW_DATA,
    id,
    callback,
  };
};

export const orderCancel = (id, callback) => {
  return {
    type: types.ORDER_CANCEL,
    id,
    callback,
  };
};

export const buyerSearch = (offset, limit, userName, callback) => {
  return {
    type: types.BUYER_SEARCH,
    offset,
    limit,
    userName,
    callback,
  };
};

export const orderUpdate = (data, orderId, orderActions, callback) => {
  return {
    type: types.ORDER_UPDATE,
    data,
    orderId,
    orderActions,
    callback,
  };
};

export const addTransport = (id, data, callback) => {
  return {
    type: types.ADD_TRANSPORT,
    id,
    data,
    callback,
  };
};

export const issuePaymentLink = (id, callback) => {
  return {
    type: types.ISSUE_PAYMENT_LINK,
    id,
    callback,
  };
};

export const intiateRefund = (payload, callback) => {
  return {
    type: types.INTIATE_REFUND,
    payload,
    callback,
  };
};

export const resendPayment = (payload, callback) => {
  return {
    type: types.RESEND_PAYMENT,
    payload,
    callback,
  };
};

export const fetchEnum = (callback) => {
  return {
    type: types.ENUM,
    callback,
  };
};

export const fetchDeliverySlot = (callback) => {
  return {
    type: types.DELIVERY_SLOT_LIST_DATA,
    callback,
  };
};

export const enumEditData = (payload, enumType, id, status, callback) => {
  return {
    type: types.ENUM_EDIT,
    payload,
    enumType,
    id,
    status,
    callback,
  };
};

export const removeEnumDeliverySlot = (payload, ID, EnumType, callback) => {
  return {
    type: types.REMOVE_DELIVERY_SLOT_LIST_DATA,
    payload,
    ID,
    EnumType,
    callback,
  };
};

export const orderDiscount = (payload, id, callback) => {
  return {
    type: types.ORDER_DISCOUNT,
    payload,
    id,
    callback,
  };
};

export const customerVerify = (id, callback) => {
  return {
    type: types.CUSTOMER_VERIFICATION,
    id,
    callback,
  };
};

export const getSosTypes = (callback) => {
  return {
    type: types.GET_SOS_TYPES,
    callback,
  };
};

export const createSos = (data, callback) => {
  return {
    type: types.CREATE_SOS,
    data,
    callback,
  };
};

export const getBuyerPhoneInfo = (data, callback) => {
  return {
    type: types.GET_BUYER_INFO,
    data,
    callback,
  };
};

export const sendOtpLogin = (data, callback) => {
  return {
    type: types.SEND_OTP_LOGIN,
    data,
    callback,
  };
};

export const verifyOtpLogin = (data, callback) => {
  return {
    type: types.VERIFY_OTP_LOGIN,
    data,
    callback,
  };
};

export const fetchAllAdminData = (
  offset,
  limit,
  searchAdminByPhone,
  searchAdminByName,
  sort,
  id,
  userType,
  callback
) => {
  return {
    type: types.ADMIN_ALL_DATA,
    offset,
    limit,
    searchAdminByPhone,
    searchAdminByName,
    sort,
    id,
    userType,
    callback,
  };
};

export const adminDeleteData = (id, callback) => {
  return {
    type: types.ADMIN_DELETE_DATA,
    id,
    callback,
  };
};

export const createadminData = (id, callback) => {
  return {
    type: types.ADMIN_CREATE_DATA,
    id,
    callback,
  };
};

export const addAdmins = (data, callback) => ({
  type: types.ADMIN_CREATE_DATA,
  data,
  callback,
});

// get admin data for update
export const getAdminData = (id) => {
  return { type: types.ADMIN_VIEW_DATA, id };
};

// edit admin
export const adminEditData = (id, callback) => {
  return {
    type: types.ADMIN_EDIT_DATA,
    id,
    callback,
  };
};

// export const singleAdminData = (
//   offset,
//   limit,
//   searchAdminByPhone,
//   searchAdminByName,
//   sort,
//   id,
//   callback
// ) => {
//   return {
//     type: types.ADMIN_VIEW_DATA,
//     offset,
//     limit,
//     searchAdminByPhone,
//     searchAdminByName,
//     sort,
//     id,
//     callback,
//   };
// };

export const createCart = (data, deliveryDate, cartId, callback) => {
  return {
    type: types.CREATE_CART,
    data,
    deliveryDate,
    cartId,
    callback,
  };
};
export const getCart = (data, callback) => {
  return {
    type: types.GET_CART,
    data,
    callback,
  };
};
export const deleteCart = (data, callback) => {
  return {
    type: types.DELETE_CART,
    data,
    callback,
  };
};
export const placeOrder = (data, callback) => {
  return {
    type: types.PLACE_ORDER,
    data,
    callback,
  };
};

export const getApproval = (data, orderId, callback) => {
  return {
    type: types.GET_APPROVAL,
    data,
    orderId,
    callback,
  };
};
export const acknowledgeApproval = (data, paymentId, callback) => {
  return {
    type: types.ACKNOWLEDGE_APPROVAL,
    data,
    paymentId,
    callback,
  };
};

export const getAllPayments = (
  {
    offset,
    limit,
    paymentMedium,
    mode,
    paymentStatus,
    createdBy,
    orderId,
    sortKey,
    sortValue,
  },
  callback
) => {
  return {
    type: types.GET_PAYMENTS,
    offset,
    limit,
    paymentMedium,
    mode,
    paymentStatus,
    createdBy,
    orderId,
    sortKey,
    sortValue,
    callback,
  };
};
export const getSellerCount = (userType) => {
  return {
    type: types.SELLER_COUNT_DATA,
    userType,
  };
};
export const getProductCount = () => ({
  type: types.PRODUCT_COUNT_DATA,
});
export const fetchSellerByCategory = (id, callback) => ({
  type: types.SELLER_BY_CATEGORY_DATA,
  id,
  callback,
});
export const fetchSellerAssignedByCategory = (
  id,
  offset,
  limit,
  sellerName,
  MultipleSellerId,
  callback
) => ({
  type: types.SELLER_ASSIGNED_BY_CATEGORY,
  id,
  offset,
  limit,
  sellerName,
  MultipleSellerId,
  callback,
});
export const addMultipleSellerCategory = (id, payload, callback) => ({
  type: types.MULTLI_SELLER_MAPPED_CATEGORY,
  id,
  payload,
  callback,
});
export const addMultipleSeller = (id, payload, callback) => ({
  type: types.MULTLI_SELLER_MAPPED,
  id,
  payload,
  callback,
});

export const limraRateUpdate = (payload, callback) => {
  return {
    type: types.UPDATE_LIMRA_RATE,
    payload,
    callback,
  };
};
