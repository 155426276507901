import React, { Component } from "react";
import { FormGroup, Input, Form, Label, Col, Row, Button } from "reactstrap";
import userProfileImage from "../../../images/userProfileOne.png";
import { Link } from "react-router-dom";
import {
  adminDeleteData,
  customerViewData,
  userEditData,
} from "../../../store/actions";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { makerCheckerList } from "../../../common/userTypeList";
import SelectComponent from "../../Product/common/SelectComponent";
// loader
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import {
  NUMBER_REGEX,
  EMAIL_REGEX,
  NAME_REGEX,
  PHONE_NUMBER_ERROR_REGEX,
} from "../../../common/components/common/Rejex";
import {
  handleErrorMsg,
  handleKeyPress,
} from "../../../common/components/common/GlobalFunctions";
class AdminuserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empid: "",
      name: "",
      rollType: "",
      conno: "",
      emailid: "",
      password: "",
      isEditMode: false,
      adminToDeleteId: "",
      editMode: "",
      userId: "",
      isActive: false,
      userAccountStatus: "",
      dataLoading: false,
    };
  }

  onChangeEvent(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("isEditMode");
    this.setState({ editMode: myParam });
    this.getAllData();
  }

  getAllData = () => {
    this.setState({
      dataLoading: true,
    });
    var queryParams = this.props.computedMatch.params.adminId;
    this.props.customerViewData(queryParams, (res) => {
      if (res) {
        this.setState({
          empid: res?.loginId,
          name: res?.fullName,
          rollType: res?.userType
            ? {
              value: res?.userType,
              label: res?.userType,
            }
            : "",
          conno: res?.phone,
          emailid: res?.email,
          userId: queryParams,
          userAccountStatus: res?.userAccountStatus,
          dataLoading: false,
        });
      }
    });
  };
  saveUserData = (e) => {
    e.preventDefault();
    let dataTosave = {
      fullName: this.state.name,
      email: this.state.emailid,
      phone: `${this.state.conno}`,
      userType: this.state.rollType?.value,
      loginId: `${this.state.empid}`,
      userAccountStatus: this.state.userAccountStatus,
    };
    this.setState({
      dataLoading: true,
    });
    this.props.userEditData(dataTosave, this.state.userId, (res) => {
      if (res && res?.message === "user has been successfully updated") {
        // console.log(res);
        NotificationManager.success(res?.message, "success");
        this.props.history.push("/AdminMain");
      } else {
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
  };

  handleDeactivate = () => {
    let dataTosave = {
      isActive: this.state.isActive,
    };
    this.props.userEditData(dataTosave, this.state.userId, (res) => {
      if (res) {
        NotificationManager.success(res?.message, "success");
        this.props.history.push("/AdminMain");
      } else {
        NotificationManager.error(
          Array.isArray(res?.message) ? res?.message[0] : res?.message?.message,
          "Error"
        );
      }
    });
  };

  // toggle = () => {
  //   this.setState({
  //     modal: !this.state.modal,
  //   });
  // };
  // onDeleteAdminRequest(id) {
  //   this.props.adminDeleteData(id, (res) => {
  //     if (res) {
  //       console.log(res.message, "hiiiiii");
  //       // if (
  //       //   res &&
  //       //   res.message === "user has been deleted successfully updated"
  //       // ) {
  //       //   NotificationManager.success(res.message, "Delete Successful");
  //       //   this.props.history.push("/AdminMain");
  //       // } else {
  //       //   NotificationManager.error(res.message, "Error");
  //       // }
  //     }
  //   });
  // }

  render() {
    // console.log(this.state.empid);
    return (
      <div>
        <div className="pd-15 bg-white borderedDiv">
          <Modal funk={true} isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Confirm</ModalHeader>
            <ModalBody>Are you sure you want to delete the Admin?</ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={() => {
                  this.onDeleteAdminRequest(this.state.adminToDeleteId);
                  this.toggle();
                }}
              >
                Delete
              </Button>{" "}
              <Button color="secondary" onClick={this.toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          {this.state.dataLoading ? (
            <div className="ViewPagesLoder1">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
                <h4>User Information</h4>
                <div>&nbsp;</div>
              </div>
              <Row>
                <Col xs={12} sm={3} md={3} className="pd-15 borderRight">
                  <div className="d-flex justify-content-center">
                    <img src={userProfileImage} alt="User Profile Image" />
                  </div>
                </Col>
                <Col xs={12} sm={9} md={9} className="pd-15">
                  <div className="formDivCss">
                    <Form>
                      <Row>
                        <Col xs={12} sm={12} md={12} className="pd-15">
                          <div className="formDivCss">
                            <Form>
                              <Row>
                                <Col xs={12} sm={2} md={2} className="mb-15">
                                  <FormGroup>
                                    <Label for="empid">Employee ID</Label>
                                    <Input
                                      id="empid"
                                      name="empid"
                                      placeholder="Type..."
                                      type="number"
                                      disabled={
                                        // this.state.editMode === "false"
                                        //   ? true
                                        //   : false
                                        true
                                      }
                                      value={this.state.empid}
                                      onChange={(e) =>
                                        this.onChangeEvent(
                                          "empid",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={12} sm={5} md={5} className="mb-15">
                                  <FormGroup>
                                    <Label for="name">Admin user name </Label>
                                    <Input
                                      id="name"
                                      name="name"
                                      placeholder="Type..."
                                      type="text"
                                      value={this.state.name}
                                      invalid={
                                        NAME_REGEX.test(this.state.name) ||
                                          this.state.name?.length == 0
                                          ? false
                                          : true
                                      }
                                      disabled={
                                        this.state.editMode === "false"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.onChangeEvent(
                                          "name",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                {/* <Col xs={12} sm={5} md={5} className="mb-15">
                              <FormGroup>
                                <Label for="dept">Department</Label>
                                <Input
                                  id="name"
                                  name="name"
                                  placeholder="Type..."
                                  type="text"
                                  onChange={(e) =>
                                    this.onChangeEvent("empid", e.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col> */}
                                <Col xs={12} sm={5} md={5} className="mb-15">
                                  <FormGroup>
                                    <Label for="roll">Roll Type</Label>
                                    <SelectComponent
                                      disabled={
                                        this.state.editMode === "false"
                                          ? true
                                          : false
                                      }
                                      value={this.state.rollType}
                                      onChange={(e) =>
                                        this.onChangeEvent("rollType", e)
                                      }
                                      options={makerCheckerList}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={12} sm={4} md={4} className="mb-15">
                                  <FormGroup>
                                    <Label for="roll">
                                      User Account Status
                                    </Label>
                                    <Input
                                      id="rollType"
                                      name="rollType"
                                      placeholder="Type..."
                                      type="select"
                                      value={this.state.userAccountStatus}
                                      disabled={true}
                                      onChange={(e) =>
                                        this.onChangeEvent(
                                          "userAccountStatus",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value={"New"}>New</option>
                                      <option value={"KYC Verified"}>
                                        KYC Verified
                                      </option>
                                      <option
                                        value={"Awaiting User Confirmation"}
                                      >
                                        Awaiting User Confirmation
                                      </option>
                                    </Input>
                                  </FormGroup>
                                </Col>
                                <Col xs={12} sm={4} md={4} className="mb-15">
                                  <FormGroup>
                                    <Label for="conno">Mobile Number</Label>
                                    <Input
                                      id="conno"
                                      name="conno"
                                      placeholder="Type..."
                                      type="text"
                                      maxLength={10}
                                      value={this.state.conno}
                                      disabled={
                                        this.state.editMode === "false"
                                          ? true
                                          : false
                                      }
                                      invalid={
                                        PHONE_NUMBER_ERROR_REGEX.test(
                                          this.state.conno
                                        )
                                          ? false
                                          : true
                                      }
                                      onKeyPress={(evt) => {
                                        handleKeyPress(evt);
                                      }}
                                      onChange={(e) => {
                                        if (
                                          NUMBER_REGEX.test(e.target.value) &&
                                          e.target.value.length <= 10
                                        ) {
                                          this.onChangeEvent(
                                            "conno",
                                            e.target.value
                                          );
                                        } else {
                                          this.onChangeEvent("conno", "");
                                        }
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs={12} sm={4} md={4} className="mb-15">
                                  <FormGroup>
                                    <Label for="exampleEmail">Email</Label>
                                    <Input
                                      id="emailid"
                                      name="emailid"
                                      placeholder="Type..."
                                      type="text"
                                      value={this.state.emailid}
                                      invalid={
                                        EMAIL_REGEX.test(this.state.emailid) ||
                                          this.state.emailid?.length == 0 || this.state.emailid == null
                                          ? false
                                          : true
                                      }
                                      disabled={
                                        this.state.editMode === "false"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        this.onChangeEvent(
                                          "emailid",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <div className="d-flex align-items-center justify-content-between">
                                <Link to={"/AdminMain"}>
                                  <button className="btn lightBtn">Back</button>
                                </Link>
                                <div>
                                  <button
                                    disabled={
                                      this.state.editMode === "false"
                                        ? true
                                        : !(
                                          (NAME_REGEX.test(this.state.name) ||
                                            this.state.name?.length == 0) &&
                                          PHONE_NUMBER_ERROR_REGEX.test(
                                            this.state.conno
                                          ) &&
                                          (EMAIL_REGEX.test(
                                            this.state.emailid
                                          ) ||
                                            this.state.emailid?.length == 0)
                                        )
                                    }
                                    className="btn darkBtn"
                                    onClick={(e) => this.saveUserData(e)}
                                  >
                                    Save
                                  </button>
                                  &nbsp;&nbsp;
                                  {/* <button
                                    disabled={
                                      this.state.editMode === "false"
                                        ? true
                                        : false
                                    }
                                    className="btn lightBtn"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleDeactivate();
                                    }}
                                  >
                                    Deactivate
                                  </button> */}
                                  &nbsp;&nbsp;
                                  {/* <button
                                className="btn lightBtn"
                                disabled={
                                  this.state.editMode === "false" ? true : false
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    modal: true,
                                    adminToDeleteId: this.props.queryParams,
                                  });
                                }}
                              >
                                Delete
                              </button> */}
                                </div>
                              </div>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return { EditallAdminDatas: state.rfa.singleAdminData };
};
export default connect(mapStateToProps, {
  adminDeleteData,
  customerViewData,
  userEditData,
})(AdminuserInfo);
