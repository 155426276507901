import { NotificationManager } from 'react-notifications';
// var QRCode = require('qrcode');
import React from 'react';
import QrCodeWithLogo from "qrcode-with-logos";
// import appLogo from "../../images/logo192.png"
import logo from "../../images/Logo.jpeg"
import { jsPDF } from "jspdf";

export const GenerateQr = ({ disabled, orderId, data, qrWidth, logoSize }) => {

    function GenerateQrCode() {

        let dataToEncode = `${process.env.REACT_APP_BASE_URL}/confirmOrderDelivery/${data}`;

        let qrcode = new QrCodeWithLogo({
            // canvas: document.getElementById("canvas"),
            content: dataToEncode,
            width: qrWidth,
            // download: true,
            // downloadName: "qrCode.pdf",
            logo: { src: logo, logoSize: logoSize }
        });
        qrcode.getCanvas().then(canvas => {
            var doc = new jsPDF();
            doc.text(`Order Id : ${orderId}`, 80, 30);
            doc.setFontSize(24)
            doc.addImage(canvas.toDataURL(), 15, 40, 180, 180)
            doc.save(`QR Code for order ${orderId}`);

        });
    }

    return (
        <>
            <button
                className="btn lightBtn buttonWidth"
                disabled={disabled === 'false' ? true : false}
                onClick={(e) => {
                    e.preventDefault();
                    GenerateQrCode()
                }
                }
            >
                Generate Qr Code
            </button>
        </>
    )
}



