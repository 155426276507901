import React, { Component } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import deleteIcons from "../../../images/Trash icon.svg";

class CouponCode extends Component {
  render() {
    return (
      <>
        <Row>
          <Col md="6">
            <FormGroup
              className=""
              style={{
                textAlign: "left",
                width: "100%",
              }}
            >
              <Label className="" for="enumCouponName">
                Coupon Name{" "}
              </Label>

              <Input
                id="enumCouponName"
                name="couponName"
                type="text"
                placeholder="Type..."
                onChange={(e) =>
                  this.props.onChange("couponName", e.target.value)
                }
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup
              className=""
              style={{
                textAlign: "left",
                width: "100%",
              }}
            >
              <Label className="" for="enumvalue">
                Coupon Amount{" "}
              </Label>

              <Input
                id="enumvalue"
                name="couponAmount"
                type="number"
                placeholder="Type..."
                onChange={(e) =>
                  this.props.onChange("couponAmount", e.target.value)
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <div class="container CouponCode">
            <div class="row">
              <div class="col-md-12">
                <table class="table  table-striped">
                  <colgroup>
                    <col class="col-md-7"></col>
                    <col class="col-md-2"></col>
                    <col class="col-md-1"></col>
                    <col class="col-md-2"></col>
                  </colgroup>
                  <tbody>
                    {this.props.EnumType === "CouponCode" ? (
                      this.props.EnumValue ? (
                        this.props.EnumValue?.map((t) => (
                          <tr>
                            <td>
                              {" "}
                              {typeof t === "object" ? (
                                this.props.indexSerial === t?._id &&
                                this.props.couponEdit?.couponName ===
                                  t?.couponName ? (
                                  <Input
                                    placeholder="Coupon Name"
                                    type="text"
                                    name="couponName"
                                    id="couponName"
                                    defaultValue={t?.couponName}
                                    onChange={(e) => {
                                      this.props.onInputChange(
                                        e.target.value,
                                        "couponName"
                                      );
                                    }}
                                  />
                                ) : (
                                  <div>{t?.couponName}</div>
                                )
                              ) : (
                                "N/A"
                              )}
                              {""}
                            </td>
                            <td>
                              {typeof t === "object" ? (
                                this.props.indexSerial === t?._id &&
                                this.props.couponEdit?.couponAmount ===
                                  t?.couponAmount ? (
                                  <Input
                                    placeholder="Coupon Amount"
                                    type="number"
                                    name="couponAmount"
                                    id="couponAmount"
                                    defaultValue={t?.couponAmount}
                                    onChange={(e) => {
                                      this.props.onInputChange(
                                        e?.target?.value,
                                        "couponAmount"
                                      );
                                    }}
                                  />
                                ) : (
                                  <div>{t?.couponAmount}</div>
                                )
                              ) : (
                                "N/A"
                              )}
                              {""}
                            </td>
                            <td>
                              {this.props.indexSerial === t?._id &&
                              this.props.couponEdit?.couponAmount ===
                                t?.couponAmount ? (
                                <>
                                  <button
                                    className="btn lightBtn btn btn-secondary btn-sm"
                                    type="button"
                                    onClick={() => {
                                      this.props.couponUpdateRequest();
                                    }}
                                  >
                                    Save
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn lightBtn btn btn-secondary btn-sm"
                                    type="button"
                                    onClick={() => {
                                      this.setState(
                                        { couponEditNew: "" },
                                        () => {
                                          this.props.contentEditable(t?._id, t);
                                        }
                                      );
                                    }}
                                    hidden={t?.couponName === "FRESHR_SAMPLE"}
                                  >
                                    Edit
                                  </button>{" "}
                                </>
                              )}
                            </td>
                            <td>
                              <div hidden={t?.couponName === "FRESHR_SAMPLE"}>
                                <img
                                  src={deleteIcons}
                                  alt="image loading"
                                  className=""
                                  disabled={true}
                                  onClick={() => {
                                    this.props.deleteEnum(t);
                                  }}
                                  style={{
                                    maxWidth: " 65%",
                                    height: "auto",
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        "false"
                      )
                    ) : (
                      <span></span>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Row>
      </>
    );
  }
}
export default CouponCode;
