import { isUserAuthenticated, getUserToken } from "./auth_helper";
const getHeaders = () => {
  let headers = {
    "Content-Type": "application/json",
  };
  if (isUserAuthenticated) {
    const token = getUserToken();
    headers["Authorization"] = "Bearer" + " " + token;
  }
  return headers;
};
const getHeadersFormData=()=>{
  let headers = {
  };
  if (isUserAuthenticated) {
    const token = getUserToken();
    headers["Authorization"] = "Bearer" + " " + token;
  }
  return headers;
}
export { getHeaders,getHeadersFormData };
