import React, { Component, createRef} from "react";
import { Button, Input } from "reactstrap";
export default class DateDisable extends Component {
  constructor() {
    super();
    this.state = {
      todayDate: "",
    };
  }
  dateRef = createRef()

  componentDidMount = () => {
    this.updateTodayDate();
  };
  updateTodayDate() {
    if (this.state.todayDate.length < 1) {
      let year = `${new Date().getFullYear()}`;
      let month = `${new Date().getMonth() + 1}`;
      let day = `${new Date().getDate()}`;

      if (day.length < 2) day = `0${day}`;
      if (month.length < 2) month = `0${month}`;

      let dateWithZeroes = `${year}-${month}-${day}`;
      this.setState({
        todayDate: dateWithZeroes,
      });
    }
  }
  datePicker(e){
      e.preventDefault()
      this.dateRef.current?.showPicker()
  }
  render() {
    return (
      <>
        <input
          id="date"
          name="date"
          type="date"
          value={this.props?.deliveryDateVal}
          onChange={(e) => {
            this.props.deliveryDateFunc(e?.target?.value);
          }}
          disabled={this.props?.disabled ? true :false}
          className='inputDate'
          ref={this.dateRef}
          min={this.props.pastDateShow ? "" : this.state.todayDate}
        />
        <div
          onClick={(e)=>this.datePicker(e)} className='datePicker'>
          <Button className="datePickerButton" >
            <>{
            this.props?.deliveryDateVal?.length > 0 ? 
            this.props?.deliveryDateVal : 
            'dd-mm-yy'}</>
            <i class="fa fa-calendar" aria-hidden="true"></i>
            </Button>
        </div>

      </>
    );
  }
}
