import React, { Component } from "react";
import Switch from "react-switch";

class SwitchButton extends Component {
  constructor(props) {
    super();
    this.state = { checked: false, disabled: false };
  }

  componentDidMount() {
    // console.log(this.props);
    this.setState({
      checked: this.props?.value,
      disabled: this.props.disabled,
    });
  }

  handleChange = () => {
    let DataToUpdate = !this.state.checked;
    this.setState({ checked: DataToUpdate });
    this.props?.onChange(DataToUpdate);
  };

  render() {
    return (
      <div>
        <span className="text-center">{this.props?.name}</span>
        <br />
        <div
          style={{
            margin: this.props.marginStyle
              ? this.props.marginStyle
              : "10px 0px 0px 20px",
          }}
        >
          <Switch
            onChange={this.handleChange}
            checked={this.state.checked}
            disabled={this.state.disabled}
          />
        </div>
      </div>
    );
  }
}

export default SwitchButton;
