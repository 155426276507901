import React, { Component } from "react";
import "./brain.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  appRoutePages,
  appRoutePagesForChecker,
  appRoutePagesForMaker,
  appRoutePagesForSales,
  publicPages,
} from "./routes/";
import AppRoute from "./routes/route";
import VerticalLayout from "./components/verticalLayout/";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import NonAuthLayout from "./components/NonAuthLayout";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import store from "./store";
import { Provider } from "react-redux";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allProtectedRoutes: [],
    };
  }
  getLayout = () => {
    let layoutCls = VerticalLayout;
    return layoutCls;
  };
  componentDidMount = () => {
    let localData = window.localStorage.getItem("authUser");
    let currentPath = window.location.pathname;
    if (!localData && currentPath != "/login") {
      if (
        !(
          currentPath.includes("/verification") ||
          currentPath.includes("/confirmOrderDelivery") ||
          currentPath.includes("/VerificationSuccess") ||
          currentPath.includes("/TermsAndConditions") ||
          currentPath.includes("/Verification")
        )
      ) {
        window.location.href = "/login";
      }
    }
    if (localData && localData != "undefined") {
      let userType = JSON.parse(localData)?.userType;
      this.setState({
        allProtectedRoutes: ["admin"].includes(userType)
          ? appRoutePages
          : ["checker"].includes(userType)
          ? appRoutePagesForChecker
          : ["maker"].includes(userType)
          ? [...appRoutePagesForMaker, ...appRoutePages]
          : ["sales"].includes(userType)
          ?[...appRoutePagesForSales]
          :[],
      });
    }
  };
  render() {
    const Layout = this.getLayout();

    return (
      // <div className="App">
      //    <Header/>
      //    <SubHeader/>
      //    {/* <Home/> */}
      //    {/* <Products/> */}
      //    <Productdetails/>
      //    <Footer/>
      // </div>
      <>
        <React.Fragment>
          <Provider store={store}>
            <Router>
              <Switch>
                {publicPages.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                  />
                ))}
                {this.state.allProtectedRoutes?.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                  />
                ))}
              </Switch>
            </Router>
          </Provider>
        </React.Fragment>
        <NotificationContainer />
      </>
    );
  }
}

export default App;
