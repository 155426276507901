import React, { Component } from 'react'
import { Col, Row } from 'reactstrap';
import newLogo from "../../images/Freshr Logo Blue.png";
import RotateSreen from '../../images/RotateScreen.png'
export default class RotateScreen extends Component {
    render() {
        return (
            <div className='rotateScreen'>
                <Row xl={12} md={12} xs={12} lg={12} className='pt-5 text-center'>
                    <Col ><img src={newLogo} alt="sidebarLogo" className="newLogos" /></Col>
                </Row>
                <div className='rotateContent text-center' >
                    <Row xl={12} md={12} xs={12} lg={12} >
                        <Col className='switchRotate'>
                            SWITCH ON THE ROTATE
                        </Col>
                    </Row>
                    <Row xl={12} md={12} xs={12} lg={12}>
                        <Col className='switchRotateDes'>
                            Please rotate your screen in landscape to access the screens.
                        </Col>
                    </Row>
                    <Row xl={12} md={12} xs={12} lg={12} className='text-center'>
                        <Col className='rotate'><img src={RotateSreen} alt="Rotate" /></Col>
                    </Row>
                </div>
            </div>
        )
    }
}
