import React, { Component } from "react";
import { Row, Col, CardBody, Card } from "reactstrap";
import { Grid } from "@material-ui/core";
import style from "./style.css";
import copyIcon from "../../../images/copyIcon.png";
export default class VoucherCode extends Component {
  render() {
    return (
      <>
        <Row className="p-4">
          <Col xl="4" lg="5" md="5" sm="7" xs="5" className="p-0">
            <Card className="voucherCard">
              <CardBody style={{ padding: "4%" }}>
                <Row className="" xl="7" md="7" xs="12" sm="12">
                  <Col xl="5" lg="5" md="5" xs="5" sm="5">
                    <Grid
                      className=""
                      container
                      spacing={0}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={12}>
                        <span className="discountText">
                          FreshR Special Discount
                        </span>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <span className="fontText">Voucher Code:</span>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <span className="fontText">Rs {this.props?.TotalAmount}</span>
                      </Grid>
                    </Grid>
                  </Col>
                  <Col xl="7" lg="7" md="7" xs="7" sm="7">
                    <Card className="codeCard">
                      <CardBody>
                      <div class="flex3">
                        <div class="flex-items">
                          {" "}
                          <span className="fontText">{this.props.couponName}</span>{" "}
                          &nbsp;&nbsp;
                        </div>
                        <div class="flex-items">
                          {" "}
                          <img
                            src={copyIcon}
                            alt="copyIcon"
                            className="ml-4"
                          ></img>
                        </div>
                      </div>
                      </CardBody>
                     
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
