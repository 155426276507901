import React, { Component } from "react";
import { Row, Col } from "reactstrap";

class SwitchToggleSample extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let mode = this.props.switchText;
    let bgcolor, clr;
    if (
      this.props.switchText === "Original" ||
      this.props.switchText === "Sample"
    ) {
      clr = "#FFFFFF";
      bgcolor = "#02C8F4";
    }

    return (
      <>
        <Row>
          <Col>
            <div
              className="button-cover"
              style={{
                padding: "2px",
              }}
            >
              <span className="">
                <div className="btn-group">
                  <div className="">
                    <button
                      type="button"
                      className="btn fontRoboto128"
                      style={{
                        backgroundColor: mode === "Sample" ? `${bgcolor}` : "",
                        color: mode === "Sample" ? `${clr}` : "#05284E",
                        padding: "5px",
                      }}
                      disabled={this.props.existingUser?true:false}
                      onClick={() => {
                        this.props.switchSample("Sample");
                      }}
                    >
                      Sample
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn fontRoboto127"
                      style={{
                        backgroundColor:
                          mode === "Original" ? `${bgcolor}` : "",
                        color: mode === "Original" ? `${clr}` : "#05284E",
                        padding: "5px",
                      }}
                      disabled={this.props.existingUser?true:false}
                      onClick={() => {
                        this.props.switchSample("Original");
                      }}
                    >
                      Original
                    </button>
                  </div>
                </div>
              </span>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default SwitchToggleSample;
