import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Container } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import {
  // customerSearchdata,
  skuEditData,
  skuViewData,
} from "../../../store/actions";
import { checkNavigable } from "react-slick/lib/utils/innerSliderUtils";
import MainHeader from "../../../components/common/MainHeader";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import { SKU_NAME_REGEX } from "../../../common/components/common/Rejex";
import { handleErrorMsg } from "../../../common/components/common/GlobalFunctions";
import SkuToogle from "./SkuToggle";
class SkuInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      categoryId: "",
      id: "",
      trackNumber: 0,
      skuName: "",
      loading: false,
      skuImage: "",
      oldSkuImage: "",
      status: "",
      switchButton: "No",
    };
  }

  componentDidMount() {
    this.skuViewDataEdit();
  }
  skuViewDataEdit = () => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const myParam = urlParams.get("isEditMode");
    this.setState({
      isEditMode: myParam,
      loading: true,
    });
    var queryParams = this.props?.computedMatch?.params?.skuid;
    if (queryParams) {
      this.props.skuViewData(queryParams, (res) => {
        this.setState({
          categoryId: res?.categoryId,
          id: res?._id,
          skuName: res?.skuName,
          loading: false,
          oldSkuImage: res?.skuImage[0],
          status: !res?.isActive ? false : true,
          switchButton: !this.state.status ? "No" : "Yes",
        });
      });
    }
  };
  skuEditData = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("skuName", this.state.skuName);
    formData.append("categoryId", this.state.categoryId);
    formData.append("isActive", this.state.status);
    if (this.state.skuImage) formData.append("skuImage", this.state.skuImage);

    let payload = formData;
    this.props.skuEditData(
      this.props.computedMatch.params.skuid,
      payload,
      (res) => {
        if (res && res.message === "SKU has been successfully updated") {
          NotificationManager.success(res.message, "SKU Updated!!");
          this.props.history.push("/Sku");
        } else {
          // NotificationManager.error(res.message, "Error");
          handleErrorMsg(res);
        }
      }
    );
  };
  refreshskuImage = () => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const myParam = urlParams.get("isEditMode");
    this.setState({
      isEditMode: myParam,
      loading: true,
    });
    var queryParams = this.props?.computedMatch?.params?.skuid;
    if (queryParams) {
      this.props.skuViewData(queryParams, (res) => {
        this.setState({
          loading: false,
          oldSkuImage: res?.skuImage[0],
        });
      });
    }
  };
  handleClick = () => {
    this.setState({
      status: !this.state.status,
      switchButton: !this.state.status ? "Yes" : "No",
    });
  };
  componentDidUpdate() {
    if (
      this.props.skuData._id !== this.state.id &&
      this.state.trackNumber < 1
    ) {
      this.setState({ trackNumber: 1 }, () => {
        this.skuViewDataEdit();
      });
    }
  }
  render() {
    return (
      <>
        <div>
          <MainHeader pageTitle={"SKU Information"} />
          <div className="pd-15 bg-white borderedDiv">
            <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
              <h4>Edit Sku</h4>
              <div>&nbsp;</div>
            </div>
            {this.state.loading ? (
              <div className="ViewPagesLoder1">
                <LoadingSpinner />
              </div>
            ) : (
              <Container fluid={true}>
                <Row>
                  <Col xs={12} sm={12} md={12} className="pd-15">
                    <div className="formDivCss skuInfo">
                      <Form>
                        <Row>
                          <Col xs={12} sm={12} md={4}>
                            <Row
                              hidden={
                                !this.state.skuImage && !this.state.oldSkuImage
                              }
                            >
                              {
                                <Col xs={12} sm={11} md={11} className="">
                                  {!this.state.oldSkuImage && (
                                    <a
                                      class="remove-image"
                                      onClick={() =>
                                        this.setState({ skuImage: "" }, () => {
                                          this.refreshskuImage();
                                        })
                                      }
                                      style={{
                                        display: "inline",
                                        cursor: "pointer",
                                        float: "right",
                                      }}
                                    >
                                      X
                                    </a>
                                  )}
                                  <div className="productImageDivStyle mt-4">
                                    <img
                                      src={
                                        (this.state.skuImage &&
                                          URL.createObjectURL(
                                            this.state.skuImage
                                          )) ||
                                        this.state.oldSkuImage
                                      }
                                      alt="productImage"
                                    />
                                  </div>
                                </Col>
                              }
                            </Row>
                          </Col>
                          <Col xs={12} sm={12} md={8}>
                            <Row>
                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                className="mb-15 ml-4"
                              >
                                <FormGroup>
                                  <Label>Sku Image</Label>
                                  <div className="fileCover">
                                    <Label for="sku">
                                      <p className="AttachedSkuImage">
                                        <span
                                          className="vl"
                                          style={{ color: "#5E5E5E" }}
                                        >
                                          &nbsp;&nbsp; File Attached{" "}
                                        </span>
                                        &nbsp;&nbsp;
                                      </p>
                                    </Label>

                                    <p className="imageNameText">
                                      {this.state.skuImage?.name}
                                    </p>
                                  </div>

                                  <Input
                                    id="sku"
                                    name="sku"
                                    placeholder="#4 of 10 in ROHU"
                                    type="file"
                                    accept=".jpg, .jpeg, .png"
                                    multiple={false}
                                    disabled={
                                      this.state.isEditMode === "false"
                                        ? true
                                        : false
                                    }
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                      if (e.target && e.target.files[0]) {
                                        this.setState({
                                          oldSkuImage: "",
                                          skuImage: e.target.files[0],
                                        });
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} sm={6} md={9} className="mb-15">
                                <FormGroup>
                                  <Label for="sku">SKU Name</Label>
                                  <Input
                                    id="sku"
                                    name="sku"
                                    placeholder="#4 of 10 in ROHU"
                                    type="text"
                                    value={this.state.skuName}
                                    disabled={
                                      this.state.isEditMode === "false"
                                        ? true
                                        : false
                                    }
                                    invalid={
                                      SKU_NAME_REGEX.test(this.state.skuName) ||
                                      this.state.skuName?.length == 0
                                        ? false
                                        : true
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        skuName: e.target.value.trimStart(),
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={6} sm={6} md={3} className="mb-15">
                                <FormGroup>
                                  <Label for="sku">Active</Label>
                                  <SkuToogle
                                    handleClick={this.handleClick}
                                    switchButton={this.state.switchButton}
                                    disabled={
                                      this.state.isEditMode === "false"
                                        ? true
                                        : false
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <div className="d-flex align-items-center justify-content-between">
                            <button
                              className="btn lightBtn"
                              onClick={() => this.props.history.push("/Sku")}
                            >
                              Back
                            </button>
                            <div>
                              <button
                                className="btn darkBtn"
                                onClick={(e) => this.skuEditData(e)}
                                disabled={
                                  this.state.isEditMode === "false"
                                    ? true
                                    : false
                                }
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    skuData: state.rfs.skuView,
  };
};
export default connect(mapStateToProps, {
  skuEditData,
  skuViewData,
})(SkuInformation);
