import React, { Component } from 'react'
import MainHeader from '../../components/common/MainHeader'
import { Container, Row, Col } from "reactstrap";
import OrdersPage from './common/Orders'
export default class Orders extends Component {
    render() {
        return (
            <div>
                <MainHeader pageTitle={"Orders"} />
                <div className="mainParentSectionDiv">
                    <Container fluid className="pd-15">
                        <Row className="d-flex align-items-stretch">
                            <Col xs={12} sm={12} md={12} className="pd-0">
                                <OrdersPage />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}
