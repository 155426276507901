import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label, Row, Col } from "reactstrap";
import TableProfilePic from "../../../images/tableprofilepic.png";
import userprofile from "../../../images/userprofile.png";
import { connect } from "react-redux";
import {
  // customerSearchdata,
  fetchAllSellerSyncToVinc,
  sortCustomerData,
  customerDeleteData,
  getSellerCount,
} from "../../../store/actions";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { debounce, throttle } from "lodash";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import {
  NUMBER_REGEX,
  NAME_REGEX,
  EMAIL_REGEX,
  PHONE_NUMBER_ERROR_REGEX,
} from "../../../common/components/common/Rejex";
import {
  handleErrorMsg,
  handleKeyPress,
} from "../../../common/components/common/GlobalFunctions";
import info from "../../../images/info.svg";
import Tippy from "@tippyjs/react";
// C:\Users\Dell\Documents\new_freshr_project\freshr-dashboard\src\images\info.png
import "tippy.js/dist/tippy.css";
import { UserTypeSales } from "../../../common/constants";
var userLocalType;
class Customercreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchCustomer: "",
      limit: 10,
      currentPage: 1,
      status: "",
      source: "",
      customerToDeleteId: "",
      deleteConfirmModal: false,
      userName: "",
      sort: "",
      usertype: "",
      dataLoading: false,
    };
  }
  onDeleteCustomerRequest(id) {
    this.props?.customerDeleteData(id, (res) => {
      if (res) {
        if (res && res.message === "Customer has been deleted successfully") {
          NotificationManager.success(res.message, "Delete Successful");
        } else {
          // NotificationManager.error(res.message, "Error");
          handleErrorMsg(res);
        }
        if (res) {
          this.getAllData((this.state.currentPage - 1) * this.state.limit);
        }
      }
    });
  }
  componentDidMount() {
    this.getAllData();
    if (userLocalType !== "sales") {
      this.getAllCount();
    }
  }
  getAllCount = () => {
    this.props.getSellerCount(this.state.usertype);
  };
  allVendorSync = () => {
    this.setState({
      dataLoading: true,
    });
    this.props?.fetchAllSellerSyncToVinc(this.state.usertype, (res) => {
      if (
        res?.status === 200 ||
        res?.status === 201 ||
        res?.message === "Some Vendors are sync with vinculum"
      ) {
        NotificationManager.success(res.message, "Successful");
        this.getAllCount();
      } else {
        // NotificationManager.error(res.message, "Error");
        handleErrorMsg(res);
      }
      this.setState({
        dataLoading: false,
      });
    });
  };

  deleteConfirmModalToggle = () => {
    this.setState({
      deleteConfirmModal: !this.state.deleteConfirmModal,
    });
  };

  getAllData = (offset = 0) => {
    this.setState({
      dataLoading: true,
    });
    this.props?.sortCustomerData(
      offset,
      this.state.limit,
      this.state.searchCustomer,
      this.state.status,
      this.state.source,
      this.state.userName,
      this.state.sort,
      this.state.usertype,
      () => {
        this.setState({
          dataLoading: false,
        });
      }
    );
  };
  delayedHandleChange = debounce(() => {
    this.getAllData();
  }, 1000);

  getCustomerSearch(name, val) {
    this.setState({
      [`${name}`]: val,
      currentPage: 1,
    });
    this.delayedHandleChange();
  }

  handleChangePage = (event, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.getAllData(offset);
  };
  render() {
    userLocalType = JSON.parse(localStorage.getItem("authUser")).userType;
    const totPages = Math.ceil(
      this.props?.customerdata?.length / this.state.limit
    );
    var data = {};
    var dataLandScape = {}
    data = {
      columns: [
        {
          label: <span>Name</span>,
          field: "name",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Contact</span>,
          field: "contact",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Vinculum</span>,
          field: "vinculum",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Member Since</span>,
          field: "member",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>User Type</span>,
          field: "type",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Source of Interest</span>,
          field: "source",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Status</span>,
          field: "status",
          sort: "asc",
          width: 50,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    dataLandScape = {
      columns: [
        {
          label: <span>Name</span>,
          field: "name",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>Contact</span>,
          field: "contact",
          sort: "asc",
          width: 300,
        },
     
        {
          label: <span>Member Since</span>,
          field: "member",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>User Type</span>,
          field: "type",
          sort: "asc",
          width: 300,
        },
        
        {
          label: <span>Status</span>,
          field: "status",
          sort: "asc",
          width: 50,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    if (
      this.props?.customerdata?.docs &&
      this.props?.customerdata?.docs?.length > 0
    ) {
      this.props?.customerdata.docs.forEach((ele) => {
        data.rows.push({
          name: (
            <div className="d-flex align-items-center">
              <img src={userprofile}></img>&nbsp;&nbsp;&nbsp;
              <p className="mb-0">{ele.fullName ? ele.fullName : "null"}</p>
            </div>
          ),
          contact: <p className="mb-0">{ele.phone ? ele.phone : "null"}</p>,
          vinculum: ele?.isVinculum ? ele?.isVinculum : "N/A",
          member: (
            <div>
              {moment(ele.memberSince).format("DD/MM/YYYY")}
              <br />
              {moment(ele.memberSince).format("LT")}
            </div>
          ),
          type: <p className="mb-0">{ele.userType ? ele.userType : "null"}</p>,
          source: (
            <p className="mb-0">
              {ele.userAccountSource ? ele.userAccountSource : "null"}
            </p>
          ),
          status: (
            <p className="mb-0">
              {ele.userAccountStatus ? ele.userAccountStatus : "null"}
            </p>
          ),
          actions: (
            <div className="d-flex align-items-center tableBtns">
              <Link to={`/Customerdetails/${ele._id}?isEditMode=${false}`}>
                <Button className="btn lightBtn">View</Button>
              </Link>
              &nbsp;&nbsp;
              <Link to={`/Customerdetails/${ele._id}?isEditMode=${true}`}>
                <Button className="btn lightBtn">Edit</Button>
              </Link>
              &nbsp;&nbsp;
              {userLocalType !== "sales" && (
                <Button
                  className="btn lightBtn"
                  onClick={() => {
                    this.setState({ customerToDeleteId: ele._id }, () => {
                      this.deleteConfirmModalToggle();
                    });
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          ),
        });
        dataLandScape.rows.push({
          name: (
            <div className="d-flex align-items-center">
              <img src={userprofile}></img>&nbsp;&nbsp;&nbsp;
              <p className="mb-0">{ele.fullName ? ele.fullName : "null"}</p>
            </div>
          ),
          contact: <p className="mb-0">{ele.phone ? ele.phone : "null"}</p>,
          member: (
            <div>
              {moment(ele.memberSince).format("DD/MM/YYYY")}
              <br />
              {moment(ele.memberSince).format("LT")}
            </div>
          ),
          type: <p className="mb-0">{ele.userType ? ele.userType : "null"}</p>,
         
          status: (
            <p className="mb-0">
              {ele.userAccountStatus ? ele.userAccountStatus : "null"}
            </p>
          ),
          actions: (
            <div className="d-flex align-items-center tableBtns">
              <Link to={`/Customerdetails/${ele._id}?isEditMode=${false}`}>
                <Button className="btn lightBtn">View</Button>
              </Link>
              &nbsp;&nbsp;
              <Link to={`/Customerdetails/${ele._id}?isEditMode=${true}`}>
                <Button className="btn lightBtn">Edit</Button>
              </Link>
              &nbsp;&nbsp;
              {userLocalType !== "sales" && (
                <Button
                  className="btn lightBtn"
                  onClick={() => {
                    this.setState({ customerToDeleteId: ele._id }, () => {
                      this.deleteConfirmModalToggle();
                    });
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          ),
        });
      });
    }
    return (
      <div className="dashInnerMainDiv noBorderedDiv custCreationDivSec">
        <Modal
          isOpen={this.state.deleteConfirmModal}
          toggle={this.deleteConfirmModalToggle}
          className={this.props.className}
          size="md"
        >
          <ModalHeader toggle={this.deleteConfirmModalToggle}>
            Confirm
          </ModalHeader>
          <ModalBody>Are you sure you want to delete the customer?</ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                this.onDeleteCustomerRequest(this.state.customerToDeleteId);
                this.deleteConfirmModalToggle();
              }}
            >
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.deleteConfirmModalToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
          <div className="d-flex align-items-center justify-content-between w-100">
            <h4>Customer List</h4>
            <div>
              <Form className="d-block align-items-center justify-content-end tableHeaderDivCss text-nowrap">
                <div className="CustomerFilterRow1">
                  <FormGroup className="d-flex align-items-center tableHeaderSelectDiv tableHeaderSelectDiv2 selectDivTwo">
                    <Label for="exampleSelect">User Type</Label>&nbsp;&nbsp;
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      disabled={userLocalType === "sales" ? true : false}
                      onChange={(e) => {
                        let value;
                        if (e.target.value === "Buyer") value = "buyer";
                        else if (e.target.value === "Seller") value = "seller";
                        else if (e.target.value === "Admin") value = "admin";
                        else if (e.target.value === "Sales")
                          value = UserTypeSales;
                        else if (e.target.value === "All") value = "";
                        this.setState(
                          {
                            usertype: value,
                            currentPage: 1,
                          },
                          () => {
                            this.getAllData();
                            this.getAllCount();
                          }
                        );
                      }}
                    >
                      <option>All</option>
                      <option selected={userLocalType == "sales"}>Buyer</option>
                      <option>Seller</option>
                      <option>Sales</option>
                      <option>Admin</option>
                    </Input>
                  </FormGroup>

                  <FormGroup className="d-flex align-items-center tableHeaderSelectDiv2 tableHeaderSelectDiv selectDivTwo">
                    <Label for="exampleSelect">Source</Label>&nbsp;&nbsp;
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      onChange={(e) => {
                        let value;
                        if (e.target.value === "Web") value = "web";
                        else if (e.target.value === "Mobile") value = "mobile";
                        else if (e.target.value === "AddedByAdmin")
                          value = "AddedByAdmin";
                        else if (e.target.value === "All") value = "";
                        this.setState(
                          {
                            source: value,
                            currentPage: 1,
                          },
                          () => {
                            this.getAllData();
                          }
                        );
                      }}
                    >
                      <option>All</option>
                      <option>Web</option>
                      <option>Mobile</option>
                      <option>AddedByAdmin</option>
                    </Input>
                  </FormGroup>

                  <FormGroup className="d-flex align-items-center tableHeaderSelectDiv2 tableHeaderSelectDiv debounceInput customerDowncontent">
                    <Label for="exampleSelect">Sort by</Label>&nbsp;&nbsp;
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      onChange={(e) => {
                        this.setState(
                          {
                            sort: e.target.value,
                            currentPage: 1,
                          },
                          () => {
                            this.getAllData();
                          }
                        );
                      }}
                    >
                      <option>Latest</option>
                      <option>Oldest</option>
                    </Input>
                  </FormGroup>

                  <FormGroup className="d-flex align-items-center  tableHeaderSelectDiv2 tableHeaderSelectDiv selectDivTwo landscapeHide">
                    <Label for="exampleSelect">Status</Label>&nbsp;&nbsp;
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      onChange={(e) => {
                        let value;
                        if (e.target.value === "New") value = "New";
                        else if (
                          e.target.value === "Awaiting User Confirmation"
                        )
                          value = "Awaiting User Confirmation";
                        else if (e.target.value === "KYC Verified")
                          value = "KYC Verified";
                        else if (e.target.value === "All") value = "";
                        this.setState(
                          {
                            status: value,
                            currentPage: 1,
                          },
                          () => {
                            this.getAllData();
                          }
                        );
                      }}
                    >
                      <option>All</option>
                      <option>New</option>
                      <option>Awaiting User Confirmation</option>
                      <option>KYC Verified</option>
                    </Input>
                  </FormGroup>

                  <FormGroup className="d-flex align-items-center tableHeaderSelectDiv2 tableHeaderSelectDiv selectDivTwo landscapeHide">
                    <Label for="exampleSelect">Show</Label>&nbsp;&nbsp;
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      onChange={(e) => {
                        this.setState(
                          {
                            limit: e.target.value,
                            currentPage: 1,
                          },
                          () => {
                            this.getAllData();
                          }
                        );
                        // setTimeout(() => {
                        //   this.getAllData();
                        // }, 500);
                      }}
                    >
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                    </Input>
                  </FormGroup>
                </div>

                <div className="CustomerFilterRow2">
                  {this.state.usertype === "seller" ||
                  this.state.usertype === "buyer" ? (
                    <>
                      <div className="sellerCountIcon ">
                        <Tippy
                          content={
                            <>
                              <div className="text-capitalize">
                                Total {this.state.usertype} &nbsp;:&nbsp;
                                {this.props?.sellersCountData?.count?.length > 0
                                  ? this.props?.sellersCountData?.count[0]
                                      ?.totalCount
                                  : 0}
                              </div>
                              <div>
                                Sync Count &nbsp;:&nbsp;
                                {this.props?.sellersCountData?.count?.length > 0
                                  ? this.props?.sellersCountData?.count[0]
                                      ?.trueCount
                                  : 0}
                              </div>
                              <div>
                                Not Sync Count &nbsp;:&nbsp;
                                {this.props?.sellersCountData?.count?.length > 0
                                  ? this.props?.sellersCountData?.count[0]
                                      ?.falseCount
                                  : 0}
                              </div>
                              <div>
                                Pending Sync &nbsp;:&nbsp;
                                {this.props?.sellersCountData?.count?.length > 0
                                  ? this.props?.sellersCountData?.count[0]
                                      ?.pendingCount
                                  : 0}
                              </div>
                            </>
                          }
                          disabled={false}
                        >
                          <span className="queryToolTip">
                            <img src={info} alt="infoImageIcon"></img>
                          </span>
                        </Tippy>
                      </div>
                      <div className="mb-0 mr-4">
                        <Button
                          size="sm"
                          onClick={this.allVendorSync}
                          className="infoButtonSpacing"
                        >
                          {" "}
                          <span className="btn-label">
                            <i className="fa fa-refresh"></i>
                          </span>
                          <span
                            className="fontRoboto154"
                            style={{ marginLeft: "10px" }}
                          >
                            Sync
                          </span>
                        </Button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv tableHeaderSelectDiv1">
                    <Input
                      name="search"
                      placeholder="Search By Phone"
                      type="text"
                      maxLength="10"
                      className="mb-0 searchPhoneInput"
                      value={this.state.customerSearch}
                      // invalid={
                      //   PHONE_NUMBER_ERROR_REGEX.test(this.state.searchCustomer) || this.state.searchCustomer?.length == 0
                      //     ? false
                      //     : true
                      // }
                      onKeyPress={(evt) => {
                        handleKeyPress(evt);
                      }}
                      onChange={(e) => {
                        if (
                          NUMBER_REGEX.test(e.target.value) &&
                          e.target.value.length <= 10
                        ) {
                          this.getCustomerSearch(
                            "searchCustomer",
                            e.target.value.trim()
                          );
                        }
                        // else {
                        //   this.getCustomerSearch("searchCustomer", "");
                        // }
                      }}
                      autoComplete="off"
                    />
                  </FormGroup>

                  <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv tableHeaderSelectDiv1">
                    <div>
                      <Input
                        name="search"
                        placeholder="Search By Name"
                        type="text"
                        className="mb-0 searchPhoneInput"
                        value={this.state.customerSearch}
                        // invalid= {(NAME_REGEX.test(this.state.userName) || this.state.userName?.length == 0) ? false : true}
                        onChange={(e) => {
                          this.getCustomerSearch(
                            "userName",
                            e.target.value.trim()
                          );
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </FormGroup>
                </div>
              </Form>
            </div>
          </div>
        </div>
        {this.state.dataLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="dataTableStyles LandScapeDisplayNone">
              <MDBDataTable
                className="text-center text-capitalize"
                responsive
                hover
                data={data}
                paging={false}
                searching={false}
              />
            </div>
            <div className="dataTableStyles LandScapeDisplay">
              <MDBDataTable
                className="text-center text-capitalize"
                responsive
                hover
                data={dataLandScape}
                paging={false}
                searching={false}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between paginationCss">
              <div>
                <p className="mb-0">
                  Showing{" "}
                  {this.props?.customerdata?.length != 0
                    ? (this.state.currentPage - 1) * this.state.limit + 1
                    : (this.state.currentPage - 1) * this.state.limit}{" "}
                  to{" "}
                  {(this.state.currentPage - 1) * this.state.limit +
                    (this.props?.customerdata.docs?.length
                      ? this.props?.customerdata.docs.length
                      : "")}{" "}
                  {} entries of {this.props?.customerdata?.length}
                </p>
              </div>
              <div className="custPaginationDiv noRightBorderPagination">
                <Pagination
                  shape="rounded"
                  count={totPages}
                  page={this.state.currentPage}
                  color="primary"
                  onChange={this.handleChangePage}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customerdata: state.rfc.customer,
    sellersCountData: state.rfco.sellerCountData,
  };
};
export default connect(mapStateToProps, {
  sortCustomerData,
  customerDeleteData,
  fetchAllSellerSyncToVinc,
  getSellerCount,
  // customerSearchdata
})(Customercreation);
