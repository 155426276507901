import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import {
  fetchProductViewData,
  productEditData,
  fetchDeliverySlot,
} from "../../../store/actions";
import { NotificationManager } from "react-notifications";
import DateDisable from "../../DateDisable";
import moment from "moment";
import Select from "react-select";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import SwitchButton from "../../../common/components/common/switchButton";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import {
  SKU_NAME_REGEX,
  NAME_REGEX,
  REMARK_REGEX,
  QUANTITY_PRICE_REGEX,
} from "../../../common/components/common/Rejex";
import { TimelineSeparator } from "@material-ui/lab";
import {
  handleErrorMsg,
  isVinculum,
} from "../../../common/components/common/GlobalFunctions";
import { moqUnitsList } from "../../../common/constants";
import SelectComponent from "./SelectComponent";

class ProductInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isEditMode: false,
      name: "",
      category: "",
      // seller: "",
      psku: "",
      size: "",
      totalQuantity: "",
      moq: "",
      // price: "",
      minPrice: "",
      maxPrice: "",
      // pin: "",
      sellerRating: "",
      stateRanking: "",
      skuRanking: "",
      remark: "",
      highlights: "",
      deliveryDate: "",
      deliverySlotOption: [],
      selectedDeliverySlot: "",
      seedingDate: "",
      nettingDate: "",
      harvestDate: "",
      trackNumber: 0,
      images: [],
      newProductImg: [],
      productImageBlob: [],
      Horeca: undefined,
      loading: false,
      moqUnits: "",
      limra:undefined,
    };
  }

  componentDidMount() {
    this.productInfo();
    this.deliverySlotList();
  }

  productInfo = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("isEditMode");
    this.setState({
      isEditMode: myParam,
      loading: true,
    });

    var queryParams = this.props.computedMatch.params.productid;
    if (queryParams) {
      this.props.fetchProductViewData(queryParams, (res) => {
        this.setState(
          {
            loading: false,
            Horeca: this.props?.productViewData?.Horeca,
            id: this.props.productViewData?._id,
            name: this.props.productViewData?.name,
            category: this.props.productViewData.category?.categoryName,
            // seller: this.props.productViewData.seller?.fullName,
            psku: this.props.productViewData?.sku?.skuName || "",
            size: this.props.productViewData?.size,
            totalQuantity: this.props.productViewData?.availableQuantity,
            moq: this.props.productViewData?.moq,
            // price: JSON.stringify(this.props.productViewData?.rate),
            minPrice: (this.props.productViewData?.minPrice).toString(),
            maxPrice: (this.props.productViewData?.maxPrice).toString(),
            // pin: this.props?.productViewData?.pinCode
            //   ? this.props?.productViewData?.pinCode
            //   : "N/A",
            sellerRating: this.props.productViewData?.supplierrating,
            stateRanking: this.props.productViewData?.stateRanking,
            productRanking: this.props.productViewData?.rating,
            skuRanking: this.props.productViewData?.skuRanking,
            remark: this.props.productViewData?.remark,
            highlights: this.props.productViewData?.highlights,
            deliveryDate: this.props.productViewData?.deliveryDate
              ? moment(this.props.productViewData?.deliveryDate).format(
                  "YYYY-MM-DD"
                )
              : "",
            selectedDeliverySlot: this.props.productViewData?.deliverySlot
              ? {
                  label: this.props.productViewData?.deliverySlot,
                  value: this.props.productViewData?.deliverySlot,
                }
              : "",
            images: this.props.productViewData?.images
              ? this.props.productViewData?.images
              : [],
            moqUnits: this.props.productViewData?.moqUnits
              ? {
                  label: this.props.productViewData?.moqUnits,
                  value: this.props.productViewData?.moqUnits,
                }
              : "",
              limra: this.props?.productViewData?.limra?.isActive ? true:false,
          },
          () => {}
        );
      });
    }
  };
  deliverySlotList = () => {
    let DeliverSlotOpt = [];
    this.setState({
      dataLoading: true,
    });
    this.props?.fetchDeliverySlot((res) => {
      res?.singleEnum?.EnumValue.forEach((item) => {
        DeliverSlotOpt.push({
          label: item,
          value: item,
        });
      });
      this.setState({ deliverySlotOption: DeliverSlotOpt, dataLoading: false });
    });
  };
  componentDidUpdate() {
    if (
      this.props.productViewData._id &&
      this.state.id !== this.props.productViewData._id &&
      this.state.trackNumber < 1
    ) {
      this.setState({ trackNumber: 1 }, () => {
        this.productInfo();
      });
    }
  }
  onChange(name, val) {
    this.setState({
      [`${name}`]: val,
    });
  }
  productRequestEditData() {
    let deliveryDate = this.state.deliveryDate
      ? moment(this.state.deliveryDate).format()
      : "";

    this.setState({ loading: true });
    let images = this.state.images.filter((item) => !item.name);
    let formData = new FormData();
    if (this.state.name) formData.append("name", this.state.name);
    if (this.state.category)
      formData.append("categoryName", this.state.category);
    if (this.state.totalQuantity)
      formData.append("availableQuantity", this.state.totalQuantity);
    if (this.state.size) formData.append("size", this.state.size);
    if (this.state.moq) formData.append("moq", this.state.moq);
    if (this.state.minPrice) formData.append("minPrice", this.state.minPrice);
    if (this.state.maxPrice) formData.append("maxPrice", this.state.maxPrice);
    if (this.state.rating) formData.append("rating", this.state.rating);
    if (this.state.deliveryDate) formData.append("deliveryDate", deliveryDate);
    if (this.state?.selectedDeliverySlot?.value)
      formData.append("deliverySlot", this.state?.selectedDeliverySlot?.value);
    if (this.state.skuRanking)
      formData.append("skuRanking", this.state.skuRanking);
    if (this.state.remark) formData.append("remark", this.state.remark);
    if (this.state.highlights)
      formData.append("highlights", this.state.highlights);
    if (this.state.stateRanking)
      formData.append("stateRanking", this.state.stateRanking);
    if (this.state?.moqUnits?.value)
      formData.append("moqUnits", this.state?.moqUnits?.value);
    if (this.state.stateRanking)
      formData.append("stateRanking", this.state.stateRanking);
    formData.append("images", JSON.stringify(images));
    formData.append("Horeca", this.state.Horeca);
    for (let newImage of this.state.newProductImg) {
      formData.append("file", newImage);
    }
    formData.append("limra", this.state.limra);
    this.props.productEditData(
      formData,
      this.props.productViewData._id,
      (res) => {
        this.setState({ loading: false });
        if (res && res.message === "Product has been successfully updated") {
          NotificationManager.success(res.message, "Product Updated!!");
          this.props.history.push("/product");
        } else {
          // NotificationManager.error(res.message, "Error");
          handleErrorMsg(res);
        }
        isVinculum(res?.product?.isVinculum?.toString(), "Product");
      }
    );
  }
  removeImage = (index, name) => {
    let updatedImages = this.state.images.filter((item, i) => i !== index);
    let newRemoveImages = [...this.state.newProductImg];
    if (name) {
      newRemoveImages = Array.from(this.state.newProductImg).filter(
        (item, i) => item.name != name
      );
    }
    this.setState({
      images: updatedImages,
      newProductImg: newRemoveImages,
    });
  };

  getFile = (file) => {
    if (file != undefined) {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        this.setState({
          images: [
            ...this.state.images,
            { new: fileReader.result, name: file.item(0).name },
          ],
          newProductImg: [...this.state.newProductImg, file.item(0)],
        });
      };
      fileReader.readAsDataURL(file[0]);
    }
  };
  deliveryDateFunc = (val) => {
    this.setState({ deliveryDate: val });
  };

  render() {
    return (
      <div className="mainParentSectionDiv">
        {this.state.loading ? (
          <div className="ViewPagesLoder">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="pd-15 bg-white borderedDiv">
            <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
              <h4>Product Information</h4>
              <div>&nbsp;</div>
            </div>
            <Row>
              <Col xs={12} sm={12} md={12} className="pd-15">
                <div className="formDivCss">
                  <Form>
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="sku">Product SKU</Label>
                          <Input
                            id="sku"
                            name="sku"
                            placeholder="#4 of 10 in ROHU"
                            type="text"
                            value={this.state.psku}
                            disabled={true}
                            onChange={(e) =>
                              this.onChange("psku", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="category">Category</Label>
                          <Input
                            id="category"
                            name="category"
                            placeholder="Fish"
                            value={this.state.category}
                            type="text"
                            disabled={true}
                            onChange={(e) =>
                              this.onChange("category", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="seller">Seller</Label>
                          <Input
                            id="seller"
                            name="seller"
                            type="text"
                            value={this.state.seller}
                            disabled={true}
                            onChange={(e) =>
                              this.onChange("seller", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col> */}

                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="size">Size/Count</Label>
                          <span style={{ color: "red" }}>*</span>
                          &nbsp;&nbsp;
                          <Input
                            id="size"
                            name="size"
                            type="text"
                            pattern="[0-9]{0,5}"
                            maxLength={7}
                            value={this.state.size}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            onChange={(e) => {
                              // let regularExpressionNumber = /^[0-9]*$/;

                              if (QUANTITY_PRICE_REGEX.test(e.target.value)) {
                                this.onChange("size", e.target.value);
                              } else {
                                this.onChange("size", "");
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="quantity">Total Quantity Available</Label>
                          <Input
                            id="quantity"
                            name="quantity"
                            type="text"
                            pattern="[0-9]{0,5}"
                            maxLength={7}
                            value={this.state.totalQuantity}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            onChange={(e) => {
                              // let regularExpressionNumber = /^[0-9]*$/;
                              if (QUANTITY_PRICE_REGEX.test(e.target.value)) {
                                this.onChange("totalQuantity", e.target.value);
                              } else {
                                this.onChange("totalQuantity", "");
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup className="dateRangePickerDiv">
                          <Label for="delieverydate">Expiry Date</Label>
                          {/* <Input
                            id="date"
                            name="date"
                            type="date"
                            value={this.state.deliveryDate}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            onChange={(e) => {
                              this.setState({
                                deliveryDate: e?.target?.value,
                              });
                            }}
                          /> */}
                          <DateDisable
                            deliveryDateFunc={this.deliveryDateFunc}
                            deliveryDateVal={this.state.deliveryDate}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={4} md={4} className="mb-15">
                        <FormGroup>
                          <Label for="delieverySlot">Delivery Slot</Label>
                          <span style={{ color: "red" }}>*</span>
                          &nbsp;&nbsp;
                          <Select
                            name="selectedDeliverySlot"
                            options={this.state.deliverySlotOption}
                            defaultValue={this.state.selectedDeliverySlot}
                            onChange={(e) => {
                              this.setState({
                                selectedDeliverySlot: {
                                  label: e?.value,
                                  value: e?.value,
                                },
                              });
                            }}
                            type="select"
                            isDisabled={
                              this.state.isEditMode === "true" ? false : true
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="moq">Moq</Label>
                          <span style={{ color: "red" }}>*</span>
                          &nbsp;&nbsp;
                          <Input
                            id="moq"
                            name="moq"
                            type="text"
                            pattern="[0-9]{0,5}"
                            maxLength={7}
                            value={this.state.moq}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            onChange={(e) => {
                              // let regularExpressionNumber = /^[0-9]*$/;
                              if (QUANTITY_PRICE_REGEX.test(e.target.value)) {
                                this.onChange("moq", e.target.value);
                              } else {
                                this.onChange("moq", "");
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="exampleEmail">MOQ Units</Label>
                          <span style={{ color: "red" }}>*</span>
                          <SelectComponent
                            disabled={
                              this.state.isEditMode == "false" ? true : false
                            }
                            // defaultValue={this.props?.customerviewData?.userType}
                            value={this.state.moqUnits}
                            onChange={(e) => this.onChange("moqUnits", e)}
                            options={moqUnitsList}
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="price">Price (GT)</Label>
                          <Input
                            id="price"
                            name="price"
                            type="text"
                            pattern="[0-9]{0,5}"
                            maxLength={10}
                            value={this.state.price}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            onChange={(e) => {
                              // let regularExpressionNumber = /^[0-9]*$/;
                              if (QUANTITY_PRICE_REGEX.test(e.target.value)) {
                                this.onChange("price", e.target.value);
                              } else {
                                this.onChange("price", "");
                              }
                            }}
                          />
                        </FormGroup>
                      </Col> */}
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="price">Minimum Price</Label>
                          <Input
                            id="minPrice"
                            name="minPrice"
                            type="price"
                            maxLength={7}
                            value={this.state.minPrice}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            onChange={(e) => {
                              this.onChange(
                                "minPrice",
                                e?.target?.value.toString()
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="price">Maximum Price</Label>
                          <Input
                            id="maxPrice"
                            name="maxPrice"
                            type="price"
                            maxLength={7}
                            value={this.state.maxPrice}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            onChange={(e) => {
                              this.onChange(
                                "maxPrice",
                                e?.target?.value.toString()
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="pin">Pick Up PIN</Label>
                          <span style={{ color: "red" }}>*</span>
                          &nbsp;&nbsp;
                          <Input
                            id="pin"
                            name="pin"
                            type="text"
                            value={this.state.pin}
                            maxlength="6"
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            onChange={(e) => {
                              let regularExpressionNumber = /^[0-9]*$/;
                              if (
                                regularExpressionNumber.test(e.target.value)
                              ) {
                                this.onChange("pin", e.target.value);
                              } else {
                                this.onChange("pin", "");
                              }
                            }}
                          />
                        </FormGroup>
                      </Col> */}

                      {/* <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="rating">Seller Rating</Label>
                          <Input
                            id="rating"
                            name="rating"
                            type="select"
                            value={this.state.sellerRating}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            onChange={(e) =>
                              this.onChange("sellerRating", e.target.value)
                            }
                          >
                            <option value=""></option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                          </Input>
                        </FormGroup>
                      </Col> */}
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="ranking">State Ranking</Label>
                          <Input
                            id="ranking"
                            name="ranking"
                            type="text"
                            maxLength="7"
                            value={this.state.stateRanking}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            onChange={(e) => {
                              let regularExpressionNumber = /^[0-9]*$/;
                              if (
                                regularExpressionNumber.test(e.target.value)
                              ) {
                                this.onChange("stateRanking", e.target.value);
                              } else {
                                this.onChange("stateRanking", "");
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="categoryrank">SKU Ranking</Label>
                          <Input
                            id="categoryrank"
                            name="categoryrank"
                            type="text"
                            maxLength={3}
                            value={this.state.skuRanking}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            onChange={(e) => {
                              let regularExpressionNumber = /^[0-9]*$/;
                              if (
                                regularExpressionNumber.test(e.target.value)
                              ) {
                                this.onChange("skuRanking", e.target.value);
                              } else {
                                this.onChange("skuRanking", "");
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <FormGroup>
                          <Label for="remarks">Additional Remarks</Label>
                          <Input
                            id="remarks"
                            name="remarks"
                            type="text"
                            value={this.state.remark}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            onChange={(e) =>
                              this.onChange("remark", e.target.value)
                            }
                            invalid={
                              REMARK_REGEX.test(this.state.remark) ||
                              this.state.remark?.length == 0
                                ? false
                                : true
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={4} className="mb-15">
                        <Row>
                          {/* {this.state.images.length > 0 && ( */}
                          <>
                            <Label for="productimage">Product Image</Label>
                            <Row>
                              <Col xs={12} className="mb-15">
                                <Input
                                  id="image"
                                  name="image"
                                  className="add-product-image"
                                  type="file"
                                  disabled={
                                    this.state.isEditMode === "false"
                                      ? true
                                      : false
                                  }
                                  accept=".jpg, .jpeg, .png"
                                  onChange={(e) => {
                                    this.getFile(e.target.files);
                                  }}
                                />
                              </Col>
                            </Row>
                          </>
                          {/* )} */}
                        </Row>
                      </Col>

                      <Col xs={12} sm={12} md={6} lg={3} className="mb-15">
                        <FormGroup>
                          <Label for="highlight">Highlights </Label>
                          <Input
                            id="highlight"
                            name="highlight"
                            type="text"
                            pattern="[a-z0-9]$"
                            value={this.state.highlights}
                            disabled={
                              this.state.isEditMode === "false" ? true : false
                            }
                            invalid={
                              REMARK_REGEX.test(this.state.highlights) ||
                              this.state.highlights?.length == 0
                                ? false
                                : true
                            }
                            // onChange={(e) => {
                            //   let regularExpressionNumber = /[]/;
                            //   if (regularExpressionNumber.test(e.target.value)) {
                            //     this.onChange("highlight", e.target.value);
                            //   } else {
                            //     this.onChange("highlight", "");
                            //   }
                            // }}
                            onChange={(e) =>
                              this.onChange("highlights", e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={1} className="mb-15">
                        <FormGroup>
                          {/* {console.log(this.state.Horeca)} */}

                          {/* <div style={{ alignItems: "center" }} className="d-flex" >
                       <Label className="mb-0" for="Horeca">Is Heroku :</Label> &nbsp;&nbsp;&nbsp;
                       <input
                         id="heroku"
                         value={true}
                         type="radio"
                         name="heroku"
                         disabled={!this.state?.isEditMode}
                         checked={this.state.Horeca == true ? true : false}
                         onChange={() => this.onChange("Horeca", true)}
                       />&nbsp;
                       <label for='heroku' >true</label>&nbsp;&nbsp;&nbsp;

                       <input
                         id="nonheroku"
                         value={false}
                         type="radio"
                         name="heroku"
                         disabled={!this.state?.isEditMode}
                         checked={this.state.Horeca == false ? true : false}
                         onChange={() => this.onChange("Horeca", false)}
                       />&nbsp;
                       <label for='nonheroku'>false</label>
                     </div> */}

                          {this.state.limra != undefined && (
                            <SwitchButton
                              name="Limra"
                              value={this.state.limra}
                              onChange={(value) => {
                                this.onChange("limra", value);
                              }}
                              disabled={
                                this.state.isEditMode === "false" ? true : false
                              }
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12}>
                        <Row>
                          {this.state.images.length > 0 &&
                            this.state.images.map((ele, index) => {
                              return (
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={3}
                                  lg={2}
                                  className="mb-15"
                                >
                                  <div>
                                    <Button
                                      className="remove-image"
                                      onClick={() =>
                                        this.removeImage(index, ele?.name)
                                      }
                                      disabled={
                                        this.state.isEditMode === "false"
                                          ? true
                                          : false
                                      }
                                      style={{
                                        display: "inline",
                                        cursor: "pointer",
                                        float: "right",
                                      }}
                                    >
                                      X
                                    </Button>
                                    <div className="productImageDivStyle">
                                      <img
                                        src={ele?.new ?? ele}
                                        alt="productImage"
                                      />
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </Col>
                      <div className="pd-15 bg-white borderedDiv">
                        <div className="d-flex align-items-center justify-content-between ">
                          <h4>List Under Seller</h4>
                          <div>&nbsp;</div>
                        </div>
                        <div className="formDivCss">
                          <Form>
                            <Row>
                              {this.props.productViewData?.sellerWithRate
                                ?.length > 0 &&
                                this.props.productViewData?.sellerWithRate?.map(
                                  (data) => (
                                    <>
                                      <div className="sellerBorder">
                                        <div className="sellerPriceDiv text-capitalize">
                                          {data?.seller?.fullName}
                                        </div>
                                        <div className="sellerPriceDiv">
                                          <FormGroup>
                                            <Label for="member">
                                              Seller Price
                                            </Label>
                                            <Input
                                              id="member"
                                              name="member"
                                              type="text"
                                              value={
                                                data?.rate ? data?.rate : 0
                                              }
                                              disabled
                                            />
                                          </FormGroup>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )}
                              {/* </div> */}
                            </Row>
                          </Form>
                        </div>
                      </div>
                    </Row>
                    <br />
                    <div className="d-flex align-items-center justify-content-between">
                      <button
                        className="btn lightBtn"
                        onClick={() => {
                          if (
                            this.props?.location?.state?.navigatePage ===
                            "inventory"
                          ) {
                            this.props.history.push("/inventory");
                          } else {
                            this.props.history.push("/product");
                          }
                        }}
                      >
                        Back
                      </button>
                      <div>
                        <button
                          className="btn darkBtn"
                          onClick={(e) => {
                            e.preventDefault();
                            this.productRequestEditData();
                          }}
                          disabled={
                            this.state.isEditMode === "false" ? true : false
                          }
                        >
                          {this.state.loading && (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          )}
                          {this.state.loading && <span>Saving...</span>}
                          {!this.state.loading && <span> Save</span>}
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    productViewData: state.rfu.productviewdata,
  };
};
export default connect(mapStateToProps, {
  fetchProductViewData,
  productEditData,
  fetchDeliverySlot,
})(ProductInformation);
