import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { FormGroup, Input, Button, Form, Label, InputGroup, InputGroupText } from "reactstrap";
import { connect } from "react-redux";
import { fetchproductSortData, limraRateUpdate } from "../../../store/actions";
import Pagination from "@material-ui/lab/Pagination";
import { NotificationManager } from "react-notifications";
import LoadingSpinner from "../../../common/components/common/LoadingSpinner";
import {
  handleErrorMsg,
  handleKeyPress,
} from "../../../common/components/common/GlobalFunctions";
import { NUMBER_REGEX } from "../../../common/components/common/Rejex";
import moment from "moment";
let debounceThis;
class LimraPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      limit: 10,
      dataLoading: false,
      productListing: {},
      skuName: "",
      updatedProducts: [],
    };
  }

  componentDidMount() {
    this.listOfProductList();
  }
  listOfProductList = (offset = 0) => {
    this.setState({
      dataLoading: true,
    });
    this.props?.fetchproductSortData(
      offset,
      this.state.limit,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      this.state.skuName,
      "",
      true,
      (res) => {
        this.setState({
          dataLoading: false,
          productListing: res,
        });
      }
    );
  };

  onChange(name, val) {
    this.setState({
      [`${name}`]: val,
      currentPage: 1,
    });
  }

  handleChangePage = (event, val) => {
    this.setState({
      currentPage: val,
    });
    const offset = (val - 1) * this.state.limit;
    this.listOfProductList(offset);
  };

  handleInputChange = (val, index,id) => {
    let productData = [...this.state?.productListing?.docs];
    productData[index].limra.limraRate = val;
    this.setState({
      productListing: {
        ...this.state.productListing,
        docs: productData.length > 0 ? productData : [],
      },
    });
    if(this.state.updatedProducts.length === 0){
      this.setState({
        updatedProducts:[{
          id:id,
          limraRate: productData[index].limra.limraRate
        }]
      })
    }
    let newIndex = this.state.updatedProducts.findIndex((ele)=>ele?.id === id)
    if(newIndex === -1){
      this.setState({
        updatedProducts:[...this.state.updatedProducts,
        {
          limraRate:val,
          id:id,
        }]
      })
    }else{
      this.state.updatedProducts.splice(newIndex,1,{
        id:id,
        limraRate:val,
      })
    }
  };
  updateLimraRates = () => {
    this.setState({
      dataLoading: true,
    });
    let formObj = {
      limraProd: this.state.updatedProducts
    }
    this.props.limraRateUpdate(formObj, (res) => {
      if (res?.limraProduct?.length > 0) {
        NotificationManager.success(res?.message, "Limra rates updated!!");
        this.setState({
          dataLoading: false,
        });
        this.listOfProductList();
      } else {
        this.setState({
          dataLoading: false,
        });
        handleErrorMsg(res);
      }
    });
  };
  debouncing = (e) => {
    this.setState(
      {
        skuName: e.target.value.trim(),
      },
      () => {
        if (debounceThis) {
          clearTimeout(debounceThis);
          debounceThis = null;
        }
        debounceThis = setTimeout(() => {
          this.listOfProductList();
        }, 1000);
      }
    );
  };
  render() {
    const totPages = Math.ceil(
      this.props?.listOfProductData?.length / this.state.limit
    );
    const user = JSON.parse(localStorage.getItem('authUser'))
    var data = {};
    var landscapeData = {};
    data = {
      columns: [
        {
          label: <span>SKU ID</span>,
          field: "productId",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>PRODUCT NAME</span>,
          field: "sku",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>LAST UPDATED</span>,
          field: "updatedAt",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>AMOUNT</span>,
          field: "limraRates",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    landscapeData = {
      columns: [
        {
          label: <span>SKU ID</span>,
          field: "productId",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>PRODUCT NAME</span>,
          field: "sku",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>LAST UPDATED</span>,
          field: "updatedAt",
          sort: "asc",
          width: 300,
        },
        {
          label: <span>AMOUNT</span>,
          field: "limraRates",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };
    if(user?.userType === 'admin') 
    landscapeData.columns.splice(2,0,{
      label: <span>VINCULUM</span>,
      field: "vinculum",
      sort: "asc",
      width: 300,
    })
    if (
      this.state?.productListing?.docs &&
      this.state?.productListing?.docs?.length > 0
    ) {
      this.state?.productListing?.docs.forEach((ele, index) => {
        data.rows.push({
          productId: ele?.productId
              ? '#'+ele?.productId
            : "N/A",
          sku: (
            <div className="d-flex align-items-center skuAlign">
              <p className="mb-0">
                {ele.sku?.skuName ? ele.sku?.skuName : "null"}
              </p>
            </div>
          ),
          vinculum: ele?.isVinculum
            ? ele?.isVinculum?.toString() === "true"
              ? "true"
              : ele?.isVinculum?.toString() === "false"
              ? "false"
              : "pending"
            : "N/A",
          updatedAt: new Date(ele?.limra?.updatedAt) !== 'Invalid Date'
            ? moment(ele?.limra?.updatedAt).format('DD/MM/YYYY')
            : "N/A",
          limraRates:(
            <div className="limraRateDiv">
                <InputGroup>
                <InputGroupText style={{ height: '32px' }}>
                  ₹
                </InputGroupText>
                <Input
                  style={{width:'50px'}}
                  type="text"
                  value={ele?.limra?.limraRate}
                  onKeyPress={(evt) => {
                    handleKeyPress(evt);
                  }}
                  onChange={(e) => {
                    if (NUMBER_REGEX.test(e.target.value)) {
                      this.handleInputChange(e.target.value.trim(), index, ele?._id);
                    }
                  }}
                />
              </InputGroup>
              </div>
          ),
          // actions: (
          //   <div className="d-flex align-items-center tableBtns ">
          //     &nbsp;&nbsp;
          //     <Button
          //       className="btn lightBtn"
          //       onClick={(e) => {
          //         e.preventDefault();
          //         this.updateLimraRates(ele._id, ele?.limra?.limraRate);
          //       }}
          //     >
          //       Update
          //     </Button>
          //     &nbsp;&nbsp;
          //   </div>
          // ),
        });
        landscapeData.rows.push({
          productId: ele?.productId
              ? '#'+ele?.productId
            : "N/A",
          sku: (
            <div className="d-flex align-items-center skuAlign">
              <p className="mb-0">
                {ele.sku?.skuName ? ele.sku?.skuName : "null"}
              </p>
            </div>
          ),
          vinculum: ele?.isVinculum
            ? ele?.isVinculum?.toString() === "true"
              ? "true"
              : ele?.isVinculum?.toString() === "false"
              ? "false"
              : "pending"
            : "N/A",
          updatedAt: new Date(ele?.limra?.updatedAt) !== 'Invalid Date'
              ? moment(ele?.limra?.updatedAt).format('DD/MM/YYYY')
            : "N/A",
          limraRates:(
            <div className="orderbuyerdetailflex">
                <InputGroup>
                <InputGroupText className="productPriceInput">
                  ₹
                </InputGroupText>
                <Input
                  type="text"
                  className="productPriceInput limraInputSize"
                  value={ele?.limra?.limraRate}
                  onKeyPress={(evt) => {
                    handleKeyPress(evt);
                  }}
                  onChange={(e) => {
                    if (NUMBER_REGEX.test(e.target.value)) {
                      this.handleInputChange(e.target.value.trim(), index, ele?._id);
                    }
                  }}
                />
                </InputGroup>
              </div>
          ),
        });
      });
    }

    return (
      <>
        <div className="dashInnerMainDiv custCreationDivSec sosDiv">
          <div className="d-flex align-items-center justify-content-between  dashInnerHeadDiv">
            <div className="d-flex align-items-center justify-content-between w-100">
              <h4>Limra</h4>
              <div>
                <Form className="d-flex align-items-center justify-content-end tableHeaderDivCss">
                  <FormGroup className="mb-0 pos-rel tableHeaderSearchDiv buyerIdSearch responsiveHeader">
                    <Input
                      name="search"
                      placeholder="Sku Name"
                      type="text"
                      className="mb-0"
                      value={this.state.skuName}
                      onChange={(e) => this.debouncing(e)}
                      autoComplete="off"
                    />
                  </FormGroup>
                  <FormGroup className="d-flex align-items-center tableHeaderSelectDiv selectDivTwo">
                    <Label>Show</Label>&nbsp;&nbsp;
                    <Input
                      onChange={(e) => {
                        this.setState(
                          {
                            limit: e.target.value,
                            currentPage: 1,
                          },
                          () => {
                            this.listOfProductList();
                          }
                        );
                      }}
                      name="select"
                      type="select"
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                    </Input>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
          {this.state.dataLoading ? (
            <div className="ViewPagesLoder">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <div className="dataTableStyles LandScapeDisplayNone">
                <MDBDataTable
                  className="text-center text-capitalize"
                  responsive
                  hover
                  data={data}
                  paging={false}
                  searching={false}
                  displayEntries={false}
                />
              </div>
              <div className="dataTableStyles LandScapeDisplay">
                <MDBDataTable
                  className="text-center text-capitalize"
                  responsive
                  hover
                  data={landscapeData}
                  paging={false}
                  searching={false}
                  displayEntries={false}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p className="mb-0">
                    Showing{" "}
                    {this.props?.listOfProductData?.length != 0
                      ? (this.state.currentPage - 1) * this.state.limit + 1
                      : (this.state.currentPage - 1) * this.state.limit}{" "}
                    to{" "}
                    {(this.state.currentPage - 1) * this.state.limit +
                      (this.props?.listOfProductData.docs?.length
                        ? this.props?.listOfProductData.docs.length
                        : "")}{" "}
                    {} entries of {this.props?.listOfProductData?.length}
                  </p>
                </div>
                <div className="custPaginationDiv">
                  <Pagination
                    shape="rounded"
                    count={totPages}
                    page={this.state.currentPage}
                    color="primary"
                    onChange={this.handleChangePage}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
              <Button
                className="btn darkBtn"
                disabled={this.state.updatedProducts?.length <= 0}
                onClick={(e) => {
                  e.preventDefault();
                  this.updateLimraRates();
                }}
              >
                Update
              </Button>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    listOfProductData: state.rfu.productdata,
  };
};

export default connect(mapStateToProps, {
  fetchproductSortData,
  limraRateUpdate,
})(LimraPrice);
