import React, { Component } from "react";
import { Container, Row, Col, Progress, Button } from "reactstrap";
import MainHeader from "../../components/common/MainHeader";
import Analytics from "./common/Analytics";
import Customers from "./common/Customers";
import Inventory from "./common/Inventory";
import Orders from "./common/Orders";
import ServiceRequests from "./common/ServiceRequests";

export default class Dashboard extends Component {
  render() {
    return (
      <div>
        <MainHeader pageTitle={"Dashboard"} />
        <br />
        <div className="mainParentSectionDiv">
          <Container fluid className="pd-0">
            <Row className="d-flex align-items-stretch">
              <Col xs={12} sm={12} md={12}>
                <Customers />
              </Col>
              {/* <Col xs={12} sm={4} md={4}>
                               <Analytics />
                            </Col> */}
            </Row>
            <br />
            <Row>
              <Col xs={12} sm={12} md={12}>
                <ServiceRequests />
              </Col>
            </Row>
            <br />
            {/* <Row>
                            <Col xs={12} sm={6} md={6}>
                                <Orders />
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                                <Inventory />
                            </Col>
                        </Row> */}
          </Container>
        </div>
      </div>
    );
  }
}
