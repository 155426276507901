import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody,Button,ModalFooter } from "reactstrap";
export default class ConfirmDialogue extends Component {
    constructor(props) {
        super(props);
        this.state = {};
      }
  
  render() {
    return (
        <div className="DeleteModal">
        
          <Modal
            isOpen={this.props.modal}
            toggle={this.props.toggle}
            className={this.props.className}
          >
            <ModalHeader toggle={this.props.toggle}>{this.props.heading}</ModalHeader>
            <ModalBody>
             {this.props.body}
            </ModalBody>
            <ModalFooter>
              <Button className="btn-danger" onClick={()=>{this.props.actionFunc(this.props.orderId,this.props.orderDetail)}}>
               {this.props.action}
              </Button>{" "}
              <Button color="secondary" onClick={this.props.toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
  }
}
