import React, { Component } from 'react'
import {Container,Row,Col} from 'reactstrap'
import MainHeader from '../../components/common/MainHeader'
import InventoryMainPage from './common/Inventorymain'
// import InventoryDetails from './common/InventoryDetails'

export default class Inventory extends Component {
    render() {
        return (
            <div>
                <MainHeader pageTitle={"Inventory"}/>   
                <div className="mainParentSectionDiv">
                    <Container fluid className="pd-15">
                        <Row className="d-flex align-items-stretch">
                            <Col xs={12} sm={12} md={12} className="pd-0">
                                <InventoryMainPage />
                            </Col>
                        </Row>
                    </Container>
                </div>              
            </div>
        )
    }
}
